
import Vue from "vue";

export default Vue.extend({
  name: "FormHeader",
  props: {
    validations: {
      type: Object,
    },
    modelCode: {
      type: String,
      default: "",
    },
    modelType: {
      type: String,
      default: "",
    },
  },
  computed: {
    modelName: {
      get(): string {
        return this.$store.state.updateModelForm.model_in.title;
      },
      set(value: string) {
        this.$store.dispatch("setUpdateModelForm", {
          ...this.$store.state.updateModelForm,
          model_in: {
            ...this.$store.state.updateModelForm.model_in,
            title: value,
          },
        });
      },
    },
  },
});
