
import Vue from "vue";
import { mapActions } from "vuex";

import Navbar from "@/components/layouts/Navbar.vue";
import Sidebar from "@/components/layouts/Sidebar.vue";

import EditUserPage from "@/features/user/pages/EditUserPage.vue";

import CreateModelFormPage from "@/features/model/pages/CreateModelFormPage.vue";

export default Vue.extend({
  components: {
    Navbar,
    Sidebar,
    CreateModelFormPage,
    EditUserPage,
  },
  computed: {
    isSidebar: {
      get() {
        return this.$store.state.app.isSidebar;
      },
    },
    alertMessage: {
      get() {
        return this.$store.state.openAlertMessage;
      },
    },
  },
  async created() {
    await this.fetchProfile();
  },
  methods: {
    ...mapActions(["getUser"]),
    async fetchProfile() {
      try {
        await this.getUser();
      } catch (error) {
        console.log(error);
      }
    },
    handleSidebar() {
      this.$store.dispatch("setApp", {
        ...this.$store.state.app,
        isSidebar: !this.isSidebar,
      });
    },
  },
  watch: {
    alertMessage() {
      if (this.alertMessage.open) {
        setTimeout(() => {
          this.$store.dispatch("openAlertMessage", {
            open: false,
            message: "",
            type: "success",
          });
        }, 4000);
      }
    },
  },
});
