import Vue from "vue";

export default Vue.extend({
  methods: {
    async dialogDelete(title: string): Promise<boolean> {
      return this.$swal({
        html: title,
        icon: "error",
        iconHtml: '<i class="fas fa-trash"></i>',
        confirmButtonText: "Delete",
        showCancelButton: true,
        reverseButtons: true,
        customClass: {
          confirmButton: "!text-[#fff] !bg-[#FF0000]",
          cancelButton: "!text-[#000] !bg-[#fff]",
        },
      }).then((result: any) => {
        const { isConfirmed } = result;

        return isConfirmed ? true : false;
      });
    },
  },
});
