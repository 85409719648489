
import Vue from "vue";

import FolderTreeListMenu from "../components/FolderTreeListMenu.vue";
import FolderLayoutModal from "../components/FolderLayoutModal.vue";
import EquipmentTagLayoutModal from "../../equipmentTag/components/EquipmentTagLayoutModal.vue";
import MemberAssignModal from "@/features_old/member/components/MemberAssignModal.vue";
import FolderMenuListModal from "../components/FolderMenuListModal.vue";
export default Vue.extend({
  data() {
    const isLoading = false;

    return {
      isLoading,
    };
  },
  components: {
    FolderTreeListMenu,
    FolderLayoutModal,
    EquipmentTagLayoutModal,
    MemberAssignModal,
    FolderMenuListModal,
  },
  computed: {
    folders() {
      return this.$store.getters.allFolder;
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("getAllFolder");
    this.isLoading = false;
  },
  methods: {
    showLoading() {
      this.isLoading = true;
    },
  },
  watch: {
    async folders() {
      this.isLoading = false;
    },
  },
});
