
import Vue from "vue";
import permission from "@/common/mixins/permission";
import dialog from "@/common/mixins/dialog";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  mixins: [permission, dialog],
  computed: {
    hasManageFolderPermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN"].includes(role) ? true : false;

      return permission;
      // return (this as any).hasPermission(PermissionName.ManageFolder);
    },
    hasManageEquipmentTagPermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN"].includes(role) ? true : false;

      return permission;
      // return (this as any).hasPermission(PermissionName.ManageEquipmentTag);
    },
    hasAssignMemberToEquipmentTagPermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;

      // return (this as any).hasPermission(
      //   PermissionName.AssignMemberToEquipmentTag
      // );
    },
    hasManageDataModelPermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN"].includes(role) ? true : false;

      return permission;

      // return (this as any).hasPermission(PermissionName.ManageDataModel);
    },
    hasManageEditModelPermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;
    },
    ...mapState(["contextMenu"]),
    isShowMenu: {
      get() {
        return this.$store.getters.contextMenu.isShowMenu;
      },
      set() {
        (this as any).openContextMenu({ isShowMenu: false });
      },
    },
  },
  methods: {
    ...mapActions([
      "getFolder",
      "getEquipment",
      "openModalFolder",
      "openModalEquipment",
      "openModalModel",
      "selectFolder",
      "openMemberAssignModal",
      "openContextMenu",
      "getModel",
      "getModelOffline",
      "getModelTagAlarmLimit",
    ]),
    async createFolder(state: string, id: number, folderName: string, folderType: string) {
      await (this as any).openModalFolder({ state: state });
      await (this as any).selectFolder({
        folderName: folderName,
        parentId: id,
        folderType,
      });
    },
    async updateFolder(state: string, id: number, folderName: string, folderType: string) {
      await (this as any).openModalFolder({ state: state });
      await (this as any).getFolder(id);
      await (this as any).selectFolder({
        folderName: folderName,
        parentId: id,
        folderType,
      });
    },
    async createEquipmentTag(state: string, id: number, folderName: string) {
      await (this as any).openModalEquipment({ state: state });
      await (this as any).selectFolder({
        folderName: folderName,
        parentId: id,
      });
    },
    async updateEquipmentTag(state: string, id: number, folderName: string) {
      await (this as any).selectFolder({
        folderName: folderName,
        parentId: id,
      });
      await (this as any).getEquipment(id);
      await (this as any).openModalEquipment({ state: state });
    },
    async createModel(state: string, parentId: number, folderName: string) {
      await (this as any).selectFolder({ folderName, parentId });

      this.$router.push({
        name: "CreateModelPage",
        params: { id: parentId.toString(), action: "create", mode: "new" },
      });
    },
    async updateModel(state: string, parentId: number, folderName: string, isOnline: string) {
      await (this as any).selectFolder({ folderName, parentId });

      this.$store.dispatch("clickFolder", `model-${parentId}`);
      this.$router.push({
        name: "CreateModelPage",
        params: {
          id: parentId.toString(),
          action: "update",
          mode: isOnline,
        },
      });
    },
    async updateModel2(state: string, id: number, name: string, isOnline: string) {
      if (isOnline === "online") {
        await (this as any).getModel(id);
        // const equipmentCode = (this as any).$store.getters.modelCreateForm
        //   .equipmentCode;
        // await (this as any).getModelTagAlarmLimit([id, equipmentCode]);
      } else if (isOnline === "offline") {
        await (this as any).getModelOffline(id);
      }

      await (this as any).selectFolder({
        folderName: name,
        parentId: id,
      });

      await this.$store.dispatch("getAllFolder");
      // await (this as any).$store.dispatch(
      //   "setModelTagAlarmLimitForm",
      //   []
      // );
      // await (this as any).$store.dispatch("setModelMotorDataForm", []);

      this.$router.push({
        name: "CreateModelPage",
        params: { id: id.toString(), action: "update" },
      });
    },
    async deleteFolder(id: number) {
      const isComfirmed = await (this as any).alertConfirm("ลบ Folder", `คุณต้องการลบ Folder นี้ใช่หรือไม่?`);
      if (isComfirmed) {
        try {
          this.$emit("loading");
          await (this as any).$dep.equipmentUseCase.deleteFolder(id);
          await (this as any).alertSuccess("ลบ Folder สำเร็จ", `คุณข้อมูล Folder สำเร็จ`);
          await this.$store.dispatch("getAllFolder");
          this.$router.push({ name: "FolderViewPage" }).catch();
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Delete Folder!";
          await (this as any).alertError("Error", msgError);
        }
      }
    },
    async deleteModel(id: number, isOnline: string) {
      return this.$swal({
        title: "ลบ Model",
        html: "คุณต้องการลบ Model นี้ใช่หรือไม่?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#db3d32",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            if (isOnline === "online") {
              await (this as any).$dep.modelUseCase.deleteModel(id);
            } else if (isOnline === "offline") {
              await (this as any).$dep.modelUseCase.deleteModelOffline(id);
            }
          } catch (error: any) {
            (this as any).alertError("Error", error.response?.data?.detail || "Can not Delete Model!");
          }
        },
        allowOutsideClick: () => this.$swal.isLoading(),
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          (this as any).alertSuccess("ลบ Model สำเร็จ", `คุณข้อมูล Model สำเร็จ`);
          this.$emit("loading");
          await this.$store.dispatch("getAllFolder");
          this.$router.push({ name: "FolderViewPage" });
        }
      });
    },
    async openModalMemberAssign(folderId: number, folderName: string) {
      await (this as any).openMemberAssignModal({ folderId, folderName });
    },
  },
});
