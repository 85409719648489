
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "AuthLoginPage",
  components: { Spinner },
  async created() {
    try {
      await (this as any).$dep.authUseCase.login();
    } catch (error) {
      //
    }
  },
});
