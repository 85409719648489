
import Vue from "vue";
import dayjs from "dayjs";
import { mapActions } from "vuex";

import Container from "@/components/Container.vue";
import TransformerDetail from "@/features/model/components/transformer/TransformerDetail.vue";
import McaDetail from "@/features/model/components/mca/McaDetail.vue";
import EvsDetail from "@/features/model/components/evs/EvsDetail.vue";
import VcaDetail from "@/features/model/components/vca/VcaDetail.vue";
import RvsDetail from "@/features/model/components/rvs/RvsDetail.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "ModelDetailPage",
  components: {
    Container,
    TransformerDetail,
    McaDetail,
    EvsDetail,
    VcaDetail,
    RvsDetail,
    LoadingGraph,
  },
  computed: {
    isAutoRefresh: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.enable;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          enable: value,
        });
        if (value) {
          this.onStartAutoReloadData();
        } else {
          this.onStopAutoReloadData();
        }
      },
    },
    timestamp() {
      return this.$store.state.modelViewData.autoRefresh.timestamp;
    },
    timeout: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.timeout;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          timeout: value,
        });
        this.onStopAutoReloadData();
        setTimeout(() => {
          this.onStartAutoReloadData();
        }, 500);
      },
    },
    modelParamId() {
      return this.$route.params.id || "";
    },
    model() {
      return this.$store.getters.model;
    },
    faultEventAlarmCount() {
      return this.$store.state.modelViewData.fault_event_alarm_count;
    },
    companyId() {
      return this.$store.state.company.id;
    },
  },
  data() {
    return {
      isAutoReloadData: 0,
      timeoutAutoRefreshList: [
        { name: "1 minute", value: 60000 },
        { name: "5 minute", value: 300000 },
        { name: "10 minute", value: 600000 },
        { name: "15 minute", value: 900000 },
      ],
      isLoadingModel: true,
      isLoadingSummary: true,
    };
  },
  async created() {
    await this.fetchModel();
    await this.fetchTrSummaryStatus();
  },
  methods: {
    ...mapActions([
      "getModel",
      "getModelViewData",
      "getGraphInformationPrimaryTabGroupData",
      "getGraphInformationSecondaryTabGroupData",
      "setAutoRefresh",
    ]),
    async fetchModel() {
      try {
        this.isLoadingModel = true;
        await this.getModel(this.modelParamId);
      } catch (error) {
        //
      } finally {
        this.isLoadingModel = false;
      }
    },
    async fetchTrSummaryStatus() {
      try {
        this.isLoadingSummary = true;
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          timestamp: new Date(),
        });

        await this.getModelViewData();
        await this.getGraphInformationPrimaryTabGroupData();
        await this.getGraphInformationSecondaryTabGroupData();
      } catch (error) {
        //
      } finally {
        this.isLoadingSummary = false;
      }
    },
    handleRefresh() {
      // เมื่อกดปุ่ม refresh อยากให้ fetch data อะไร มาใส่ตรงนี้เลยจ้า
      this.fetchTrSummaryStatus();
    },
    onStartAutoReloadData() {
      this.isAutoReloadData = setInterval(() => {
        // เมื่อ auto refresh เป็น true อยากให้ fetch data อะไร มาใส่ตรงนี้เลยจ้า
        this.fetchTrSummaryStatus();
      }, this.timeout);
    },
    onStopAutoReloadData() {
      clearInterval(this.isAutoReloadData);
    },
    formatDate(date: any, format = "DD MMMM YYYY, HH:mm") {
      return dayjs(date).format(format);
    },
    handleBacktoOverall() {
      this.$router.push({ name: "CompanyPage", params: { id: this.companyId } });
    },
  },
  watch: {
    async modelParamId() {
      this.isLoadingModel = true;
      this.isLoadingSummary = true;

      // route model id change อยากให้ fetch data อะไร มาใส่ตรงนี้เลยจ้า
      await this.fetchModel();
      await this.fetchTrSummaryStatus();
    },
  },
  beforeMount() {
    // เมื่อเข้ามาที่ page ถ้า auto refresh เป็น true ให้เริ่ม auto reload data
    if (this.isAutoRefresh) {
      this.onStartAutoReloadData();
    }
  },
  beforeDestroy() {
    // เมื่อออกจาก page ให้ stop auto reload data
    this.onStopAutoReloadData();
  },
});
