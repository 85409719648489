
import Vue from "vue";

export default Vue.extend({
  name: "ButtonDanger",
  props: {
    text: {
      type: String,
      default: "Button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
