
import Vue from "vue";
import dayjs from "dayjs";
import debounce from "debounce";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import XLSX from "xlsx";
import { required } from "vuelidate/lib/validators";

import { Chart } from "highcharts-vue";
import NoDataRVS from "@/components/NoDataRVS.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

import "@/assets/scss/custom-datetime-picker.css";

stockInit(Highcharts);

export default Vue.extend({
  name: "RvsTrendGraph",
  components: {
    highcharts: Chart,
    DeleteIcon,
    EditIcon,
    NoDataRVS,
    DateTimeRange,
    LoadingGraph,
  },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
    userRole(): string {
      return this.$store.getters.user.role;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  validations: {
    isSensorType: { required },
    validationGroup: ["isSensorType"],
  },
  data() {
    const date = new Date();
    const maxLength = 2;

    const sensorTypeData: [] = [];
    const isSensorType: any[] = [];

    const phaseGroup: any[] = [
      { id: 1, phaseName: "RMS Acceleration [m/s\u00B2]", unit: "RMS Acceleration [m/s\u00B2]", phaseValue: "acceleration" },
      { id: 1, phaseName: "RMS Acceleration [g]", unit: "RMS Acceleration [g]", phaseValue: "accelerationG" },
      { id: 2, phaseName: "RMS Velocity [mm/s]", unit: "RMS Velocity [mm/s]", phaseValue: "velocity" },
      { id: 3, phaseName: "Envelope", unit: "Envelope", phaseValue: "envelope" },
      { id: 4, phaseName: "Temperature [\u2103]", unit: "Temperature [\u2103]", phaseValue: "temperature" },
    ];
    const isPhaseGroup = phaseGroup[0].phaseValue;

    const itemsPerPageData: { label: string; value: number }[] = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "15", value: 15 },
      { label: "All", value: -1 },
    ];

    return {
      maxLength,
      sensorTypeData,
      isSensorType,
      isLoadingSensorGroup: false,
      phaseGroup,
      isPhaseGroup,
      isNormal: true,
      selectDateType: "normal",
      startDate: dayjs(date).subtract(7, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isGraphLoading: false,
      excelLoading: false,
      graphResultData: [],
      graphResultDataRaw: [],
      chartOptions: {
        title: { text: "Trend Dashboard" },
        subtitle: { text: "" },
        chart: {
          type: "line",
          zoomType: "xy",
          animation: false,
          height: 500,
          style: { fontSize: "14px" },
        },
        plotOptions: {
          series: {
            allowPointSelect: false,
            showInNavigator: true,
            animation: false,
            point: {
              events: {
                click: async (e: any) => {
                  if (!["ADMIN", "OWNER", "SUPMEM"].includes((this as any).userRole)) return;
                  await (this as any).createSensorPointNoteModal(e);
                },
              },
            },
            events: {
              legendItemClick: function () {
                const series = this.chart.series;

                setTimeout(() => {
                  const remaining = series.filter((val) => val.visible);
                  const count = remaining.length;

                  if (count === 2) {
                    const seriesY = remaining[0].yData;
                    const alarm = remaining[0].options.alarm;
                    const warning = remaining[0].options.warning;
                    const minY =
                      Math.min(...[...seriesY, warning, alarm]) >= 0 ? 0 : Math.min(...[...seriesY, warning, alarm]) - 40;
                    const maxY = Math.max(...[...seriesY, warning, alarm]) + 40;

                    this.chart.yAxis[0].update({
                      min: minY,
                      max: maxY,
                    });

                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-alarm",
                      value: alarm,
                      color: "#D9263B",
                      width: 2,
                      zIndex: 5,
                      label: {
                        useHTML: true,
                        text: `<div class="relative mt-[4px] bg-[#D9263B] p-[5px] text-white">
                      <span>Alarm ${alarm}</span>
                      </div>`,
                        align: "center",
                      },
                      dashStyle: "ShortDash",
                    });
                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-warning",
                      value: warning,
                      color: "#FCA239",
                      width: 2,
                      zIndex: 5,
                      label: {
                        useHTML: true,
                        text: `<div class="relative mt-[4px] bg-[#FCA239] p-[5px] text-white">
                      <span>Warning ${warning}</span>
                      </div>`,
                        align: "center",
                      },
                      dashStyle: "ShortDash",
                    });
                  } else {
                    this.chart.yAxis[0].update({
                      min: undefined,
                      max: undefined,
                    });
                    this.chart.yAxis[0].removePlotLine("plot-alarm");
                    this.chart.yAxis[0].removePlotLine("plot-warning");
                  }
                }, 500);
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          split: false,
          style: { fontSize: "14px", padding: "0px" },
          borderWidth: 0,
          shadow: false,
          positioner: function (labelWidth) {
            const x = this.chart.plotWidth - labelWidth;
            const y = 10;
            return { x, y };
          },
          formatter: function () {
            return `<div style='border: 2px solid black; padding: 5px'>
                <p><span style='color: ${this.color};'>●</span> Time: ${dayjs(this.x).format("DD/MM/YYYY HH:mm น.")}</p>
                <div>${this.series.name} : <span class='text-[#307DF1] font-bold'>${this.y}</span></div>
                <div>Activity: ${this.point?.marker?.description || "-"}</div>
              </div>`;
          },
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          itemStyle: { fontSize: "14px", fontWeight: "300" },
        },
        yAxis: {
          title: { text: "" },
          labels: { style: { fontSize: "14px" } },
          opposite: false,
          max: undefined,
          min: undefined,
        },
        xAxis: {
          categories: [],
          labels: {
            style: { fontSize: "14px" },
            formatter: function () {
              return dayjs(this.value).format("DD/MM/YYYY HH:mm น.");
            },
          },
          minRange: 1,
        },
        series: [],
        scrollbar: { liveRedraw: false },
        rangeSelector: { enabled: false, selected: 0 },
        navigator: {
          series: { type: "line", marker: { enabled: false } },
          yAxis: {},
          xAxis: {
            categories: [],
            labels: {
              style: { fontSize: "10px" },
              formatter: function () {
                return "";
              },
            },
          },
        },
        credits: { enabled: false },
      },
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      sensorPointNoteThead: [
        { text: "Timestamp", value: "timestamp", sortable: false },
        { text: "Sensor ID", value: "sensor_id", sortable: false },
        { text: "Sensor Name", value: "sensor_name", sortable: false },
        { text: "Vibration Unit", value: "type", sortable: false },
        { text: "Axis", value: "axis", sortable: false },
        { text: "Value", value: "y_position", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      sensorPointNoteData: [],
      dialogEdit: false,
      selectEditSensorPointValue: 0,
      stateEditSensorPointValue: {
        //   description: "",
      },
      dialogDelete: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      itemsPerPageData,
      stateCreateSensorPointValue: {},
      dialogCreate: false,
      noteCreate: {},
      customTextBox: null,
    };
  },
  async created() {
    await this.fetchSensorTypeData();
  },
  methods: {
    async fetchGraphData() {
      try {
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;

        const resData = await (this as any).$dep.modelUseCase.getRvsGraphResultTrendData({
          modelCode: this.getModelCode,
          phase: this.isPhaseGroup === "accelerationG" ? "acceleration" : this.isPhaseGroup,
          startDate: this.startDate,
          endDate: this.endDate,
          sensor: this.isSensorType,
          isNormal: this.isNormal,
        });
        const noteData = await (this as any).$dep.modelUseCase.getSensorPointNote({
          modelCode: this.getModelCode,
          sensor: this.isSensorType,
          phase: this.isPhaseGroup === "accelerationG" ? "acceleration" : this.isPhaseGroup,
          startDate: this.startDate,
          endDate: this.endDate,
        });

        const graphData =
          this.isPhaseGroup === "accelerationG"
            ? {
                ...resData,
                series: resData.series.map((item: any) => {
                  return {
                    ...item,
                    data: item.data.map((val: any) => {
                      return {
                        ...val,
                        y: val.y / 9.81,
                      };
                    }),
                  };
                }),
              }
            : resData;

        this.graphResultData = graphData.series;
        this.graphResultDataRaw = graphData;

        const yTimestamp = this.graphResultDataRaw.timestamp;

        setTimeout(() => {
          this.sensorPointNoteData = noteData;
          this.chartOptions.series = graphData.series;
          this.chartOptions.yAxis.title.text =
            this.phaseGroup.find((val) => val.phaseValue === this.isPhaseGroup).unit || "";
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            categories: graphData.timestamp,
            tickPositioner: function () {
              const interval = Math.max(1, Math.ceil(yTimestamp.length / 10));
              const positions = [];
              for (let i = 0; i < yTimestamp.length; i += interval) {
                positions.push(i);
              }
              return positions;
            },
          };
          this.chartOptions.navigator.xAxis = {
            ...this.chartOptions.navigator.xAxis,
            categories: graphData.timestamp,
          };
          this.xAxisMaxRange = graphData.timestamp.length;
        }, 500);
      } catch (error) {
        this.graphResultData = [];
        this.graphResultDataRaw = [];
        this.sensorPointNoteData = [];
      }
    },
    async fetchSensorTypeData() {
      try {
        this.isLoadingSensorGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsSensorType(this.getModelId);

        this.sensorTypeData = datas;
      } catch (error) {
        this.sensorTypeData = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },
    createSensorPointNoteModal(e: any) {
      if (!this.isNormal) return;
      const timestamp = e.point.category;
      const { axis, phase, sensor_id } = e.point.series.userOptions;
      const val = e.point.options;

      this.noteCreate = {
        description: "-",
        model_code: this.getModelCode,
        timestamp: timestamp,
        y_position: val.y,
        type: phase,
        axis,
        rvs_graph_id: val.rvs_graph_id,
        sensor_id,
      };
      this.dialogCreate = true;
    },
    async createSensorPointNote(params: any) {
      if (!this.isNormal) return;
      this.$swal({
        title: "Create Note",
        html: "<p>Are you sure to create note?</p>",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#db3d32",
        reverseButtons: true,
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          this.isGraphLoading = true;

          try {
            const param = {
              ...params,
              description: this.stateCreateSensorPointValue.description ?? "-",
            };

            await (this as any).$dep.modelUseCase.createSensorPointNote(param);
            await this.fetchGraphData();
          } catch (error) {
            //
          } finally {
            this.isGraphLoading = false;
            this.dialogCreate = false;
          }
        }
      });
    },
    // async createSensorPointNote(e: any) {
    //   if (!this.isNormal) return;

    //   this.$swal({
    //     title: "Create Note",
    //     html: "<p>Are you sure to create note?</p>",
    //     icon: "question",
    //     showCancelButton: true,
    //     confirmButtonText: "Confirm",
    //     confirmButtonColor: "#db3d32",
    //     reverseButtons: true,
    //   }).then(async (result: any) => {
    //     if (result.isConfirmed) {
    //       this.isGraphLoading = true;

    //       try {
    //         const timestamp = e.point.category;
    //         const { axis, phase, sensor_id } = e.point.series.userOptions;
    //         const val = e.point.options;

    //         const params = {
    //           description: "-",
    //           model_code: this.getModelCode,
    //           timestamp: timestamp,
    //           y_position: val.y,
    //           type: phase,
    //           axis,
    //           rvs_graph_id: val.rvs_graph_id,
    //           sensor_id,
    //         };

    //         await (this as any).$dep.modelUseCase.createSensorPointNote(params);
    //         await this.fetchGraphData();
    //       } catch (error) {
    //         //
    //       } finally {
    //         this.isGraphLoading = false;
    //       }
    //     }
    //   });
    // },
    editSensorPointNote(item: any) {
      this.selectEditSensorPointValue = item.id;
      this.stateEditSensorPointValue = { ...this.stateEditSensorPointValue, description: item.description };
      this.dialogEdit = true;
    },
    editSensorPointDetail(params: any): string {
      if (params.length && this.selectEditSensorPointValue) {
        const param = params.find((item) => item.id === this.selectEditSensorPointValue);
        return `<div class='flex flex-col text-[12px] text-[#1a1a1a]'>
        <p>Timestamp: ${this.formatDate(param.timestamp)}</p>
        <div class='flex justify-between gap-[10px]'>
          <div class='flex flex-col items-start justify-center'>
            <div>Sensor ID:</div>
            <div class='font-500'>${param.sensor_id}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Phase:</div>
            <div class='font-500'>${param.type}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Axis:</div>
            <div class='font-500'>${param.axis}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Value:</div>
            <div class='font-medium text-[#307DF1]'>${param.y_position}</div>
          </div>
        </div>
        </div>`;
      } else {
        return "";
      }
    },
    async onSaveEditSensorPointNote(params: any) {
      try {
        this.isGraphLoading = true;
        const param = params.find((item) => item.id === this.selectEditSensorPointValue);
        const state = {
          modelCode: this.getModelCode,
          description: this.stateEditSensorPointValue.description,
          rvsGraphId: param.rvs_graph_id,
          noteId: param.id,
        };
        await (this as any).$dep.modelUseCase.updateSensorPointNote(state);
        await this.fetchGraphData();
      } catch (error) {
        //
      } finally {
        this.selectEditSensorPointValue = 0;
        this.stateEditSensorPointValue = {
          description: "",
        };
        this.isGraphLoading = false;
        this.dialogEdit = false;
      }
    },
    onCloseEditSensorPointNote() {
      this.dialogEdit = false;
    },
    async deleteSensorPointNote(item: any) {
      if (!["ADMIN", "OWNER", "SUPMEM"].includes(this.userRole)) {
        return this.alertDialog({
          title: "Permission Denied",
          html: "<p>You cannot access this feature because you are not authorized.</p>",
          icon: "warning",
        });
      }

      this.$swal({
        title: "Are you sure?",
        html: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#db3d32",
        reverseButtons: true,
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          this.isGraphLoading = true;
          try {
            await (this as any).$dep.modelUseCase.deleteSensorPointNote({
              rvs_graph_id: item.rvs_graph_id,
              model_code: item.model_code,
              note_id: item.id,
            });
            await this.fetchGraphData();
          } catch (error) {
            //
          } finally {
            this.isGraphLoading = false;
          }
        }
      });
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    onChangeSensorType: debounce(async function (values: any[]) {
      this.isSensorType = values.map((val: any) => ({
        id: val.id,
        sensorId: val.sensorId,
        sensorName: val.sensorName,
      }));
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    }, 1000),
    async onChangePhase(value: string) {
      this.isPhaseGroup = value;
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    async onChangeSelectDateType(param: any) {
      this.selectDateType = param;
      this.isNormal = param === "yearly" ? false : true;
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    changePerPage(num: number) {
      this.itemsPerPage = num;
    },
    limiter(tag: any[]) {
      if (tag.length > this.maxLength) tag.shift();
    },
    formatDate(value: any): string {
      return dayjs(value).format("DD/MM/YYYY HH:mm น.");
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
    alertDialog({
      title,
      html,
      icon = "success",
    }: {
      title: string;
      html: string;
      icon: "success" | "error" | "warning" | "info" | "question";
    }) {
      this.$swal({
        title,
        html,
        icon: icon,
      }).then(() => {
        //
      });
    },
    exportExcel() {
      this.excelLoading = true;
      const name = `rvs_trend_model_${this.getModelId}`.toUpperCase();
      const datas = this.graphResultDataRaw;

      const phaseIndex = this.phaseGroup.findIndex((item: any) => item.phaseValue === this.isPhaseGroup);
      const unit = this.phaseGroup[phaseIndex].unit;

      const result = datas.series.map((series) => {
        return {
          sname: `${series.sensor_id} ${series.phase} ${series.paxis}`,
          data: series.data.map((item, index) => {
            return {
              timestamp: dayjs(datas.timestamp[index]).format("YYYY-MM-DD HH:mm:ss"),
              [unit]: item.y,
            };
          }),
        };
      });

      const wb = XLSX.utils.book_new();

      for (let i = 0; i < result.length; i++) {
        const { sname, data } = result[i];
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, sname);
      }

      XLSX.writeFile(wb, `${name}.xlsx`);

      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },

    createSensorPointDetail(params: any): string {
      if (params) {
        return `<div class='flex flex-col text-[12px] text-[#1a1a1a]'>
        <p>Timestamp: ${this.formatDate(params.timestamp)}</p>
        <div class='flex justify-between gap-[10px]'>
          <div class='flex flex-col items-start justify-center'>
            <div>Sensor ID:</div>
            <div class='font-500'>${params.sensor_id}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Phase:</div>
            <div class='font-500'>${params.type}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Axis:</div>
            <div class='font-500'>${params.axis}</div>
          </div>
          <div class='flex flex-col items-start justify-center'>
            <div>Value:</div>
            <div class='font-medium text-[#307DF1]'>${params.y_position}</div>
          </div>
        </div>
        </div>`;
      } else {
        return "";
      }
    },
    onCloseCreateSensorPointNote() {
      this.dialogCreate = false;
    },
  },
  watch: {
    async getModelId() {
      this.sensorTypeData = [];
      this.isSensorType = [];

      this.graphResultData = [];
      this.graphResultDataRaw = [];

      await this.fetchSensorTypeData();
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
  },
});
