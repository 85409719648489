
import Vue from "vue";
import ModelTreeListMenu from "./ModelTreeListMenu.vue";
export default Vue.extend({
  components: {
    ModelTreeListMenu,
  },
  props: {
    items: Array,
  },
  data() {
    return {
      folderOpen: true,
    };
  },
  computed: {
    isFolder: function () {
      return true;
    },
  },
  methods: {
    async openFolder() {
      (this as any).folderOpen = !(this as any).folderOpen;
    },
  },
});
