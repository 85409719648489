
import Vue from "vue";

import MapsIcon from "@/components/icons/Maps.vue";

export default Vue.extend({
  name: "NoMap",
  props: {
    height: {
      type: Number,
      default: 210,
    },
    message: {
      type: String,
      default: "No data available, please create some data",
    },
  },
  components: { MapsIcon },
});
