
import Vue from "vue";
import dayjs from "dayjs";

import DatePicker from "vue2-datepicker";
import "@/assets/scss/custom-datetime-picker.css";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "TrAnalysisDgaGraph",
  components: { DatePicker, LoadingGraph },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    const v0 = { x: 114, y: 366 };
    const v1 = { x: 306, y: 30 };
    const v2 = { x: 498, y: 366 };
    const triangle = [v0, v1, v2];

    let pointSize = 4.5;
    // pre-create a canvas-image of the arrowhead
    let arrowheadLength = 10;
    let arrowheadWidth = 8;
    let arrowhead = document.createElement("canvas");

    // label styles
    let labelfontsize = 12;
    let labelfontface = "verdana";
    let labelpadding = 3;

    const segments = [
      {
        label: { text: "D1", cx: 165, cy: 395, withLine: false, endX: null, endY: null },
        fill: "#81f3e0",
        points: [
          { x: 114, y: 366 },
          { x: 281, y: 76 },
          { x: 324, y: 150 },
          { x: 201, y: 366 },
        ],
      },
      {
        label: { text: "D2", cx: 300, cy: 395, withLine: false, endX: null, endY: null },
        fill: "#4500a4",
        points: [
          { x: 385, y: 366 },
          { x: 201, y: 366 },
          { x: 324, y: 150 },
          { x: 356, y: 204 },
          { x: 321, y: 256 },
        ],
      },
      {
        label: { text: "DT", cx: 245, cy: 60, withLine: true, endX: 280, endY: 55 },
        fill: "#cd25b9",
        points: [
          { x: 297, y: 46 },
          { x: 392, y: 214 },
          { x: 372, y: 248 },
          { x: 441, y: 366 },
          { x: 385, y: 366 },
          { x: 321, y: 256 },
          { x: 356, y: 204 },
          { x: 281, y: 76 },
        ],
      },
      {
        label: { text: "PD", cx: 356, cy: 40, withLine: true, endX: 321, endY: 40 },
        fill: "#ff0201",
        points: [
          { x: 306, y: 30 },
          { x: 312, y: 40 },
          { x: 300, y: 40 },
        ],
      },
      {
        label: { text: "T1", cx: 375, cy: 70, withLine: true, endX: 340, endY: 75 },
        fill: "#ff2292",
        points: [
          { x: 312, y: 40 },
          { x: 348, y: 103 },
          { x: 337, y: 115 },
          { x: 297, y: 46 },
          { x: 300, y: 40 },
        ],
      },
      {
        label: { text: "T2", cx: 400, cy: 125, withLine: true, endX: 366, endY: 120 },
        fill: "#ffd503",
        points: [
          { x: 348, y: 103 },
          { x: 402, y: 199 },
          { x: 392, y: 214 },
          { x: 337, y: 115 },
        ],
      },
      {
        label: { text: "T3", cx: 480, cy: 270, withLine: true, endX: 450, endY: 270 },
        fill: "#000000",
        points: [
          { x: 402, y: 199 },
          { x: 498, y: 366 },
          { x: 441, y: 366 },
          { x: 372, y: 248 },
        ],
      },
    ];

    var ch4x, ch4y, c2h4x, c2h4y, c2h2x, c2h2y;

    return {
      canvas: null,
      ctx: null,
      vueCanvas: null,
      v0,
      v1,
      v2,
      pointSize,
      triangle,
      segments,
      labelfontsize,
      labelfontface,
      labelpadding,
      arrowheadLength,
      arrowheadWidth,
      arrowhead,
      ch4x,
      ch4y,
      c2h4x,
      c2h4y,
      c2h2x,
      c2h2y,
      diagnosisResultColor: "#ffffff",
      diagnosisResultName: "",
      diagnosisResultTimestamp: "",
      ch4Input: 0,
      c2h2Input: 0,
      c2h4Input: 0,
      labels: [],
      sensorTypeData: ["Main tank", "OTLC"],
      isSensorType: "Main tank",
      datetimeInput: new Date(),
      datetimeOpen: false,
      isLoading: true,
    };
  },
  mounted() {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    const ctx = canvas.getContext("2d");

    this.canvas = canvas;
    this.vueCanvas = ctx;
  },
  async created() {
    setTimeout(() => {
      this.draw();
    }, 300);

    await this.fetchResultDotGraph();
    await this.fetchLabels();
  },
  methods: {
    async fetchResultDotGraph() {
      try {
        this.isLoading = true;
        const { id, modelCode, equipmentId } = this.model;

        const response = await (this as any).$dep.modelUseCase.getTrDgaCalData(
          modelCode,
          id,
          this.isSensorType,
          dayjs(this.datetimeInput).format("YYYY-MM-DDTHH:mm"),
          equipmentId
        );

        const { c2h2_level, c2h2_level_cal, c2h4_level, c2h4_level_cal, ch4_level, ch4_level_cal, new_result } = response;

        if (!ch4_level_cal && !c2h2_level_cal && !c2h4_level_cal) {
          setTimeout(async () => {
            this.isLoading = false;
          }, 500);
        }

        if (ch4_level_cal && c2h2_level_cal && c2h4_level_cal) {
          setTimeout(async () => {
            this.draw();
            this.calcOprByValue(ch4_level_cal, c2h2_level_cal, c2h4_level_cal);

            this.diagnosisResultColor = new_result?.colorCode || "#ffffff";
            this.diagnosisResultName = new_result?.description || "";
            this.diagnosisResultTimestamp = new_result?.timeStamp
              ? dayjs(new_result?.timeStamp).format("DD/MM/YYYY, HH:mm")
              : "";
            this.ch4Input = ch4_level;
            this.c2h2Input = c2h2_level;
            this.c2h4Input = c2h4_level;

            this.isLoading = false;
          }, 500);
        }
      } catch (error) {
        //
      }
    },
    async fetchLabels() {
      try {
        const response = await (this as any).$dep.modelUseCase.getTrDgaLabelName();
        this.labels = response || [];
      } catch (error) {
        this.labels = [];
      }
    },
    draw() {
      for (var i = 0; i < this.segments.length; i++) {
        this.drawSegment(this.segments[i]);
      }

      // แกน label
      this.ticklines(this.v0, this.v1, 9, 0, 20);
      this.ticklines(this.v1, this.v2, 9, (Math.PI * 3) / 4, 20);
      this.ticklines(this.v2, this.v0, 9, (Math.PI * 5) / 4, 20);

      this.premakeArrowhead();
      this.moleculeLabel(this.v0, this.v1, 100, Math.PI, "% CH4");
      this.moleculeLabel(this.v1, this.v2, 100, 0, "% C2H4");
      this.moleculeLabel(this.v2, this.v0, 75, Math.PI / 2, "% C2H2");

      this.drawTriangle(this.triangle);
    },
    calcOpr() {
      const ch4 = Number(this.ch4Input);
      const c2h2 = Number(this.c2h2Input);
      const c2h4 = Number(this.c2h4Input);

      if (!ch4 || !c2h2 || !c2h4) {
        return alert("กรุณากรอกข้อมูลให้ครบถ้วน");
      }

      setTimeout(() => {
        this.draw();
        this.calcOprByValue(ch4, c2h2, c2h4);
      }, 500);
    },
    calcOprByValue(ch4, c2h2, c2h4) {
      const BottomCoordinates = (c2h2_contr) => {
        let dx = (this.v2.x - this.v0.x) * c2h2_contr;
        let coor_x = this.v2.x - dx;
        let coor_y = this.v0.y;
        return { x: coor_x, y: coor_y };
      };
      const LeftCoordinates = (ch4_contr) => {
        let l = Math.sqrt(Math.pow(this.v1.x - this.v0.x, 2) + Math.pow(this.v1.y - this.v0.y, 2));
        let l_eff = l * ch4_contr;
        let coor_x = this.v0.x + l_eff * Math.cos(Math.PI / 3);
        let coor_y = this.v0.y - l_eff * Math.sin(Math.PI / 3);
        return { x: coor_x, y: coor_y };
      };
      const RightCoordinates = (c2h4_contr) => {
        let l = Math.sqrt(Math.pow(this.v1.x - this.v2.x, 2) + Math.pow(this.v1.y - this.v2.y, 2));
        let l_eff = l * c2h4_contr;
        let coor_x = this.v1.x + l_eff * Math.cos(Math.PI / 3);
        let coor_y = this.v1.y + l_eff * Math.sin(Math.PI / 3);
        return { x: coor_x, y: coor_y };
      };
      const refLeftCoordinates = (ch4_contr) => {
        let l = Math.sqrt(Math.pow(this.v2.x - this.v1.x, 2) + Math.pow(this.v2.y - this.v1.y, 2));
        let l_eff = l * ch4_contr;
        let coor_x = this.v2.x - l_eff * Math.cos(Math.PI / 3);
        let coor_y = this.v2.y - l_eff * Math.sin(Math.PI / 3);
        return { x: coor_x, y: coor_y };
      };
      const refRightCoordinates = (c2h4_contr) => {
        let dx = (this.v2.x - this.v0.x) * c2h4_contr;
        let coor_x = this.v0.x + dx;
        let coor_y = this.v0.y;
        return { x: coor_x, y: coor_y };
      };

      const total = ch4 + c2h2 + c2h4;
      let ch4_contr = ch4 / total;
      let c2h2_contr = c2h2 / total;
      let c2h4_contr = c2h4 / total;
      let c2h2_line = BottomCoordinates(c2h2_contr);
      let ch4_line = LeftCoordinates(ch4_contr);
      let c2h4_line = RightCoordinates(c2h4_contr);
      this.ch4x = ch4_line.x;
      this.ch4y = ch4_line.y;
      this.c2h4x = c2h4_line.x;
      this.c2h4y = c2h4_line.y;
      this.c2h2x = c2h2_line.x;
      this.c2h2y = c2h2_line.y;
      let ref_ch4 = refLeftCoordinates(ch4_contr);
      let ref_c2h4 = refRightCoordinates(c2h4_contr);
      let res = this.checkLineIntersection(
        ch4_line.x,
        ch4_line.y,
        ref_ch4.x,
        ref_ch4.y,
        c2h4_line.x,
        c2h4_line.y,
        ref_c2h4.x,
        ref_c2h4.y
      );

      let color = this.detectColor(res.x, res.y);
      this.findAndDisplayColor(color);
      this.drawCoordinates(res.x, res.y);
      this.drawCords(res.x, res.y);
    },
    drawSegment(s) {
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(s.points[0].x, s.points[0].y);
      for (var i = 1; i < s.points.length; i++) {
        this.vueCanvas.lineTo(s.points[i].x, s.points[i].y);
      }
      this.vueCanvas.closePath();
      this.vueCanvas.fillStyle = s.fill;
      this.vueCanvas.fill();
      this.vueCanvas.lineWidth = 0;
      this.vueCanvas.strokeStyle = "black";
      this.vueCanvas.stroke();

      // draw D1, D2, DT, PD, T1, T2, T3 labels
      if (s.label.withLine) {
        this.lineBoxedLabel(s, this.labelfontsize, this.labelfontface, this.labelpadding);
      } else {
        this.boxedLabel(s, this.labelfontsize, this.labelfontface, this.labelpadding);
      }
    },
    drawCircle(point1, point2, radius, color) {
      this.vueCanvas.beginPath();
      this.vueCanvas.arc(point1, point2, radius, 0, 2 * Math.PI, false);
      this.vueCanvas.fillStyle = color;
      this.vueCanvas.fill();
    },
    ticklines(start, end, count: number, angle: number, length: number) {
      let dx = end.x - start.x;
      let dy = end.y - start.y;
      this.vueCanvas.lineWidth = 1;
      for (let i = 1; i < count; i++) {
        let x0 = parseInt(start.x + (dx * i) / count);
        let y0 = parseInt(start.y + (dy * i) / count);
        // let y1 = parseInt(y0 + length * Math.sin(angle));
        // let x1 = parseInt(x0 + length * Math.cos(angle));
        let y1 = y0 + length * Math.sin(angle);
        let x1 = x0 + length * Math.cos(angle);
        this.vueCanvas.beginPath();
        this.vueCanvas.moveTo(x0, y0);
        this.vueCanvas.lineTo(x1, y1);
        this.vueCanvas.stroke();
        if (i == 2 || i == 4 || i == 6 || i == 8) {
          let labelOffset = (length * 3) / 4;
          // x1 = parseInt(x0 - labelOffset * Math.cos(angle));
          // y1 = parseInt(y0 - labelOffset * Math.sin(angle));
          x1 = x0 - labelOffset * Math.cos(angle);
          y1 = y0 - labelOffset * Math.sin(angle);
          this.vueCanvas.fillStyle = "black";
          this.vueCanvas.fillText(i * 10, x1, y1);
        }
      }
    },
    lineBoxedLabel(s, fontsize, fontface, padding) {
      let centerX = s.label.cx;
      let centerY = s.label.cy;
      let text = s.label.text;
      let lineToX = s.label.endX;
      // let lineToY = s.label.endY;
      this.vueCanvas.textAlign = "center";
      this.vueCanvas.textBaseline = "middle";
      this.vueCanvas.font = fontsize + "px " + fontface;
      let textwidth = this.vueCanvas.measureText(text).width;
      let textheight = fontsize * 1.286;
      let leftX = centerX - textwidth / 2 - padding;
      let topY = centerY - textheight / 2 - padding;
      // the line
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(leftX, topY + textheight / 2);
      this.vueCanvas.lineTo(lineToX, topY + textheight / 2);
      this.vueCanvas.strokeStyle = "black";
      this.vueCanvas.lineWidth = 1;
      this.vueCanvas.stroke();
      // the boxed text
      this.vueCanvas.fillStyle = "white";
      this.vueCanvas.fillRect(leftX, topY, textwidth + padding * 2, textheight + padding * 2);
      this.vueCanvas.strokeRect(leftX, topY, textwidth + padding * 2, textheight + padding * 2);
      this.vueCanvas.fillStyle = "black";
      this.vueCanvas.fillText(text, centerX, centerY);
    },
    boxedLabel(s, fontsize, fontface, padding) {
      var centerX = s.label.cx;
      var centerY = s.label.cy;
      var text = s.label.text;
      this.vueCanvas.textAlign = "center";
      this.vueCanvas.textBaseline = "middle";
      this.vueCanvas.font = fontsize + "px " + fontface;
      var textwidth = this.vueCanvas.measureText(text).width;
      var textheight = fontsize * 1.286;
      var leftX = centerX - textwidth / 2 - padding;
      var topY = centerY - textheight / 2 - padding;
      this.vueCanvas.fillStyle = "white";
      this.vueCanvas.fillRect(leftX, topY, textwidth + padding * 2, textheight + padding * 2);
      this.vueCanvas.lineWidth = 1;
      this.vueCanvas.strokeRect(leftX, topY, textwidth + padding * 2, textheight + padding * 2);
      this.vueCanvas.fillStyle = "black";
      this.vueCanvas.fillText(text, centerX, centerY);
    },
    moleculeLabel(start, end, offsetLength, angle, text) {
      this.vueCanvas.textAlign = "center";
      this.vueCanvas.textBaseline = "middle";
      this.vueCanvas.font = "14px verdana";
      var dx = end.x - start.x;
      var dy = end.y - start.y;
      var x0 = parseInt(start.x + dx * 0.5);
      var y0 = parseInt(start.y + dy * 0.5);
      var x1 = x0 + offsetLength * Math.cos(angle);
      var y1 = y0 + offsetLength * Math.sin(angle);
      this.vueCanvas.fillStyle = "black";
      this.vueCanvas.fillText(text, x1, y1);
      // arrow
      x0 = parseInt(start.x + dx * 0.35);
      y0 = parseInt(start.y + dy * 0.35);
      x1 = x0 + 50 * Math.cos(angle);
      y1 = y0 + 50 * Math.sin(angle);
      var x2 = parseInt(start.x + dx * 0.65);
      var y2 = parseInt(start.y + dy * 0.65);
      var x3 = x2 + 50 * Math.cos(angle);
      var y3 = y2 + 50 * Math.sin(angle);
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(x1, y1);
      this.vueCanvas.lineTo(x3, y3);
      this.vueCanvas.strokeStyle = "black";
      this.vueCanvas.lineWidth = 1;
      this.vueCanvas.stroke();
      angle = Math.atan2(dy, dx);
      this.vueCanvas.translate(x3, y3);
      this.vueCanvas.rotate(angle);
      this.vueCanvas.drawImage(this.arrowhead, -this.arrowheadLength, -this.arrowheadWidth / 2);
      this.vueCanvas.setTransform(1, 0, 0, 1, 0, 0);
    },
    drawTriangle(t) {
      this.vueCanvas.beginPath();
      this.vueCanvas.moveTo(t[0].x, t[0].y);
      this.vueCanvas.lineTo(t[1].x, t[1].y);
      this.vueCanvas.lineTo(t[2].x, t[2].y);
      this.vueCanvas.closePath();
      this.vueCanvas.strokeStyle = "black";
      this.vueCanvas.lineWidth = 2;
      this.vueCanvas.stroke();
    },
    premakeArrowhead() {
      let actx = this.arrowhead.getContext("2d");
      this.arrowhead.width = this.arrowheadLength;
      this.arrowhead.height = this.arrowheadWidth;
      actx.beginPath();
      actx.moveTo(0, 0);
      actx.lineTo(this.arrowheadLength, this.arrowheadWidth / 2);
      actx.lineTo(0, this.arrowheadWidth);
      actx.closePath();
      actx.fillStyle = "black";
      actx.fill();
    },
    drawCoordinates(x, y) {
      this.vueCanvas.fillStyle = "red"; // Red color
      this.vueCanvas.beginPath();
      this.vueCanvas.arc(x, y, this.pointSize, 0, Math.PI * 2, true);
      this.vueCanvas.fill();
    },
    drawCords(x, y) {
      this.vueCanvas.moveTo(x, y);
      this.vueCanvas.lineTo(this.ch4x, this.ch4y);
      this.vueCanvas.moveTo(x, y);
      this.vueCanvas.lineTo(this.c2h4x, this.c2h4y);
      this.vueCanvas.moveTo(x, y);
      this.vueCanvas.lineTo(this.c2h2x, this.c2h2y);
      this.vueCanvas.strokeStyle = "red";
      this.vueCanvas.stroke();
    },
    detectColor(x, y) {
      const data = this.vueCanvas.getImageData(x, y, 1, 1).data;
      const col = {
        r: data[0],
        g: data[1],
        b: data[2],
      };
      return col;
    },
    findAndDisplayColor(color) {
      var red, green, blue;
      red = color.r;
      green = color.g;
      blue = color.b;
      var diagResult;
      if (color.r == 255 && color.g == 0 && color.b == 0) {
        diagResult = "PD = Partial Discharge";
      } else if (color.r == 255 && color.g == 102 && color.b == 153) {
        diagResult = "T1 = Thermal fault < 300 celcius";
      } else if (color.r == 255 && color.g == 204 && color.b == 0) {
        diagResult = "T2 = Thermal fault 300 < T < 700 celcius";
      } else if (color.r == 0 && color.g == 0 && color.b == 0) {
        diagResult = "T3 = Thermal fault < 300 celcius";
      } else if (color.r == 172 && color.g == 236 && color.b == 222) {
        diagResult = "D1 = Thermal fault T > 700 celcius";
      } else if (color.r == 51 && color.g == 51 && color.b == 153) {
        diagResult = "D2 = Discharge of High Energy";
      } else {
        diagResult = "DT = Electrical and Thermal";
      }
      this.vueCanvas.fillStyle = "rgb(" + red + "," + green + "," + blue + ")";
      this.vueCanvas.fillRect(350, 550, 25, 12);
      this.vueCanvas.fillStyle = "black";
      this.vueCanvas.fillText(diagResult, 380, 546, 300);
    },
    checkLineIntersection(line1StartX, line1StartY, line1EndX, line1EndY, line2StartX, line2StartY, line2EndX, line2EndY) {
      let denominator,
        a,
        b,
        numerator1,
        numerator2,
        result = {
          x: null,
          y: null,
          onLine1: false,
          onLine2: false,
        };
      denominator =
        (line2EndY - line2StartY) * (line1EndX - line1StartX) - (line2EndX - line2StartX) * (line1EndY - line1StartY);

      if (!denominator) {
        return result;
      }
      a = line1StartY - line2StartY;
      b = line1StartX - line2StartX;
      numerator1 = (line2EndX - line2StartX) * a - (line2EndY - line2StartY) * b;
      numerator2 = (line1EndX - line1StartX) * a - (line1EndY - line1StartY) * b;
      a = numerator1 / denominator;
      b = numerator2 / denominator;

      result.x = line1StartX + a * (line1EndX - line1StartX);
      result.y = line1StartY + a * (line1EndY - line1StartY);

      if (a > 0 && a < 1) {
        result.onLine1 = true;
      }

      if (b > 0 && b < 1) {
        result.onLine2 = true;
      }

      return result;
    },
    async onChangeSensorType(ev) {
      this.isSensorType = ev;
      await this.fetchResultDotGraph();
    },
    async onChangeDate(value, type) {
      if (type === "minute" || type === "hour") {
        this.datetimeOpen = type === "minute" ? false : true;
        this.datetimeInput = value;
        await this.fetchResultDotGraph();
      }
    },
  },
});
