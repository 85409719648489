
import Vue from "vue";
import dialog from "@/common/mixins/dialog";
import { required } from "vuelidate/lib/validators";
import { FolderUpdateForm } from "../forms/FolderUpdateForm";
import FolderCreateModal from "./FolderCreateModal.vue";
import FolderUpdateModal from "./FolderUpdateModal.vue";
import dayjs from "dayjs";

export default Vue.extend({
  mixins: [dialog],
  validations: {
    folderCreateForm: {
      structureItemTitle: { required },
    },
    folderUpdateForm: {
      structureItemTitle: { required },
    },
  },
  data() {
    return { isLoading: false };
  },
  components: {
    FolderCreateModal,
    FolderUpdateModal,
  },
  computed: {
    isShowModal() {
      return (this as any).$store.getters.isShowFolderModal;
    },
    modalState() {
      return (this as any).$store.getters.modalState;
    },
    folderCreateForm() {
      return (this as any).$store.getters.folderCreateForm;
    },
    folderUpdateForm() {
      return (this as any).$store.getters.folderUpdateForm;
    },
    headLine() {
      if (this.modalState === "CREATE-FOLDER") return "Create Folder";
      if (this.modalState === "UPDATE-FOLDER") return "Update Folder";
      else return "";
    },
    folderName() {
      return (this as any).$store.getters.selectFolder.folderName;
    },
  },
  methods: {
    async createFolder() {
      this.$v.$touch();
      if (this.$v.folderCreateForm.$invalid) return;
      const { parentId, folderType } = this.$store.getters.selectFolder;
      this.$store.dispatch("setFolderCreateForm", {
        ...this.$store.getters.folderCreateForm,
        parentId,
        structureItemType: folderType,
      });
      const body = {
        ...this.$store.getters.folderCreateForm,
        companies_id: Number(sessionStorage.getItem("company_id")),
        createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        is_disabled: false,
      };

      try {
        this.isLoading = true;
        await (this as any).$dep.folderUseCase.createFolder(body);
        await (this as any).alertSuccess("Successful", `Create Folder Successful`);
        this.closeDialog();
        this.$emit("loading");
        await this.$store.dispatch("getAllFolder");
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          (this as any).alertError("Error", error.response.data.message);
        } else {
          process.env.NODE_ENV === "production"
            ? (this as any).alertError("Error", "ไม่สามารถสร้าง Folder ได้")
            : (this as any).alertError("Error", error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateFolder() {
      this.$v.$touch();
      if (this.$v.folderUpdateForm.$invalid) return;

      const { parentId, folderType } = this.$store.getters.selectFolder;
      const body = {
        ...this.$store.getters.folderUpdateForm,
        structureItemType: folderType,
        companies_id: Number(sessionStorage.getItem("company_id")),
        createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        id: parentId,
      };

      try {
        this.isLoading = true;
        await (this as any).$dep.folderUseCase.updateFolderTitle(body, parentId);
        await (this as any).alertSuccess("Successful", `Update Folder Successful`);
        this.closeDialog();
        this.$emit("loading");
        await this.$store.dispatch("getAllFolder");
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          (this as any).alertError("Error", error.response.data.message);
        } else {
          process.env.NODE_ENV === "production"
            ? (this as any).alertError("Error", "ไม่สามารถอัพเดต Folder ได้")
            : (this as any).alertError("Error", error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    closeDialog() {
      this.$store.dispatch("closeModal");
      this.clearForm();
    },
    async clearForm() {
      this.$v.$reset();
      const folderCreateForm: any = {
        parentId: 0,
        structureItemTitle: "",
        isPrimaryFolder: false,
      };
      const folderUpdateForm: FolderUpdateForm = {
        structureItemTitle: "",
      };

      await this.$store.dispatch("setFolderCreateForm", {
        ...this.$store.getters.folderCreateForm,
        ...folderCreateForm,
      });
      await this.$store.dispatch("setFolderUpdateForm", {
        ...this.$store.getters.folderUpdateForm,
        ...folderUpdateForm,
      });
    },
  },
});
