
import Vue from "vue";
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";

use([CanvasRenderer, PieChart]);

export default Vue.extend({
  name: "VcaOverall",
  components: {
    VChart,
    LoadingGraph,
    NoDataAvailable,
  },
  props: ["items"],
  data() {
    const isLoading = true;

    const options = {
      tooltip: {
        trigger: "item",
      },
      series: [],
    };

    return {
      options,
      isLoading,
      healthMonitoringType: 1,
      healthMonitoringSensorType: 1,
      healthMonitoringTypeItems: [],
      healthMonitoringSensorTypeItems: [],
      resultDatas: [],
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    filteredItems() {
      return this.resultDatas.filter((item: any) => {
        return item.tag?.sensorTypeId === this.healthMonitoringSensorType;
      });
    },
    modelType() {
      return this.$store.getters.model.modelTypeId;
    },
  },
  async created() {
    this.resultDatas = await (this as any).fetchData();
    this.setSeriesData(this.items);
  },
  methods: {
    async fetchData() {
      try {
        (this as any).isLoading = true;
        const datas = await (this as any).$dep.modelUseCase.modelResultOverallDataStatusVca(this.model.modelCode, "rul_p_y");

        return datas;
      } catch (error) {
        //
      } finally {
        (this as any).isLoading = false;
      }
    },
    setSeriesData(datas: any) {
      const valueNormal = datas.filter((p: any) => p.status === 0);
      const valueWarning = datas.filter((p: any) => p.status === 1);
      const valueDanger = datas.filter((p: any) => p.status === 2);

      const status: any[] = [...new Map(datas.map((item: any) => [item["status"], item])).values()];
      const data = status.map((p: any) => {
        return {
          value: p.status === 0 ? valueNormal.length : p.status === 1 ? valueWarning.length : valueDanger.length,
          name: p.status === 0 ? "Remaining Life > 20%" : p.status === 1 ? "Remaining Life 10-20%" : "Remaining Life < 10%",
          itemStyle: {
            color: p.status === 0 ? "#4caf50" : p.status === 1 ? "#ffc12b" : "#f44336",
          },
        };
      });

      (this as any).options.series = {
        type: "pie",
        radius: ["50%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data,
      };
    },
    getStatus(code: number) {
      return code === 0 ? "success" : code === 1 ? "warning" : code === 2 ? "error" : "normal";
    },
    getResultType(type: string) {
      if (type === "rul_percent") return "RUL (%)";
      else return "Years";
    },
    checkResultValue(type: string, value: number) {
      if (type === "rul_year" && value > 15) {
        return "> 15";
      }
      return value.toFixed(1);
    },
    async fetchHealthMonitoringType() {
      try {
        const res = await Vue.prototype.$dep.modelUseCase.getVsdSensorType("vsdtag");
        const data = res.filter((val) => val.equipmentModelTypeId == this.modelType);
        this.healthMonitoringType = data[0].id;
        this.healthMonitoringTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));

        await this.fetchHealthMonitoringSensorType(data[0].id);
      } catch (error) {
        this.healthMonitoringTypeItems = [];
      }
    },
  },
  watch: {
    async items() {
      this.setSeriesData(this.items);
    },
  },
});
