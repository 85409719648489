
import Vue from "vue";

import EChart from "@/features/model/components/graph/EChart.vue";
import { required } from "vuelidate/lib/validators";

import NoDataRVS from "@/components/NoDataRVS.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "Rvs3dTimeWaveFormGraph",
  components: {
    EChart,
    NoDataRVS,
    LoadingGraph,
  },
  validations: {
    isSensor: { required },
    isFileName: { required },
    validationGroup: ["isSensor", "isFileName"],
  },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
    getRotationalSpeed() {
      const model = this.$store.state.model.equipmentModelConfiguration.find(
        (v) => v.modelTypeConfiguration.title === "Rotational speed"
      );

      return model?.value || 1;
    },
  },
  data() {
    return {
      maxLength: 3,
      xAxisType: "time",
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      sensorGroup: [],
      isSensor: null,
      isLoadingSensorGroup: false,
      phaseGroup: [
        {
          id: 1,
          phaseName: "Acceleration",
          phaseValue: "acceleration",
          phaseUnit: "[m/s\u00B2]",
          phaseLabel: "RMS Acceleration [m/s\u00B2]",
        },
        {
          id: 2,
          phaseName: "AccelerationG",
          phaseValue: "accelerationG",
          phaseUnit: "[g]",
          phaseLabel: "RMS Acceleration [g]",
        },
      ],
      isPhase: "acceleration",
      axisGroup: [
        { id: 1, axisName: "H", axisValue: "x" },
        { id: 2, axisName: "V", axisValue: "y" },
        { id: 3, axisName: "A", axisValue: "z" },
      ],
      isAxis: "x",
      fileNameGroup: [],
      isFileName: [],
      isLoadingFileNameGroup: false,
      graphResultData: [],
      graphLoading: false,
      calPoint: [],
      chartOptions: {
        title: {
          text: "3D Time Waveform",
          left: "center",
        },
        grid3D: {
          boxWidth: 200,
          boxHeight: 80,
          viewControl: {
            projection: "perspective", // perspective orthographic
            alpha: 15,
            beta: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.5,
            },
          },
        },
        tooltip: {
          trigger: "item",
          position: function () {
            // fixed at top
            return ["75%", "0%"];
          },
          formatter: function (param: any) {
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            return `
            <p>${param.marker} ${param.seriesName}</p>
            <div style="min-width: 220px">
              <div class='flex items-center gap-x-[6px]'>
                <span>X : </span>
                <span>${formatter.format(param.value[0])}</span>
              </div>
              <div class='flex items-center gap-x-[6px]'>
                <span>Y : </span>
                <span>${formatter.format(param.value[2])}</span>
              </div>
            </div>`;
          },
        },
        legend: { data: [], top: 40 },
        // toolbox: { feature: { dataZoom: { yAxisIndex: "none" } } },
        xAxis3D: {
          name: "",
          type: "value",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
        },
        yAxis3D: {
          name: "",
          type: "category",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
          // nameGap: 70,
        },
        zAxis3D: {
          name: "",
          type: "value",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
          // nameGap: 35,
        },
        series: [],
      },
      excelLoading: false,
    };
  },
  async created() {
    await this.fetchSensorGroup();
  },
  methods: {
    async fetchGraphData() {
      try {
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;

        const param = {
          sensor: this.isSensor,
          phase: this.isPhase === "accelerationG" ? "acceleration" : this.isPhase,
          axis: this.isAxis,
          fileNames: this.isFileName.map((val: any) => val.fileName),
        };
        const data = await (this as any).$dep.modelUseCase.getRvsGraphResultTimeWaveFormData(param);

        const graphData =
          this.isPhase === "accelerationG"
            ? {
                ...data,
                series: data.series.map((serie: any) => ({
                  ...serie,
                  data: serie.data.map((s: any) => [s[0], s[1] / 9.81]),
                })),
              }
            : data;

        this.graphResultData = graphData.series;

        const xAxisMin = 0;
        const xAxisMax = Math.max(...graphData.series.map((item) => item.data[item.data.length - 1][0]));
        const numOfItems = 10;
        const tickPositions = Array.from({ length: numOfItems }, (_, i) =>
          Number((xAxisMin + (i * (xAxisMax - xAxisMin)) / (numOfItems - 1)).toFixed(2))
        );

        this.chartOptions.zAxis3D.name = this.phaseGroup.find((val) => val.phaseValue === this.isPhase).phaseLabel || "";
        this.chartOptions.legend.data = graphData.series.map(
          (val: any) => this.fileNameGroup.find((item) => item.fileName === val.file_name)?.alias || null
        );
        const series = graphData.series.map((val: any) => {
          const fileName = this.fileNameGroup.find((item) => item.fileName === val.file_name)?.alias || val.file_name;
          return {
            name: fileName,
            type: "line3D",
            data: val.data.map((d: any) => [d[0], fileName, d[1]]),
            lineStyle: {
              width: 0.75,
              lineStyle: {
                width: 0.75,
              },
            },
            dataGrouping: { enabled: false },
            lineWidth: 0.75,
            boostThreshold: 1,
            turboThreshold: 1,
            showInNavigator: false,
          };
        });
        this.xAxisMaxRange = xAxisMax;
        this.chartOptions.xAxis3D = {
          ...this.chartOptions.xAxis3D,
          name: this.xAxisType === "revolution" ? "Revolution [REV]" : "Time [seconds]",
          tickInterval: tickPositions.length / 10,
          tickPositions,
        };
        this.chartOptions.series = series;
      } catch (error) {
        this.graphResultData = [];
      }
    },
    async fetchSensorGroup() {
      try {
        this.isLoadingSensorGroup = true;

        const sensorGroup = await (this as any).$dep.modelUseCase.getRvsSensorType(this.getModelId);
        const sensorPoint = await (this as any).$dep.modelUseCase.getSensorPoints(this.getModelCode);

        this.sensorGroup = sensorGroup;
        this.sensorPointGroup = sensorPoint;
      } catch (error) {
        this.sensorGroup = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },
    async fetchFileNameGroup() {
      try {
        this.isLoadingFileNameGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsGraphResultSpectrumFileName({
          modelCode: this.getModelCode,
          sensor: this.isSensor.sensorId,
          phase: this.isPhase === "accelerationG" ? "acceleration" : this.isPhase,
          axis: this.isAxis,
        });

        this.fileNameGroup = datas;
      } catch (error) {
        this.fileNameGroup = [];
      } finally {
        this.isLoadingFileNameGroup = false;
      }
    },
    async onSubmit() {
      this.graphResultData = [];
      this.xAxisType = "time";
      this.graphLoading = true;
      await this.fetchGraphData();
      this.graphLoading = false;
    },
    async onChangeSensorType(val: any) {
      this.isSensor = {
        id: val.id,
        sensorId: val.sensorId,
        sensorName: val.sensorName,
      };
      await this.fetchAxis(val.sensorId);
      await this.fetchFileNameGroup();
    },
    async fetchAxis(sensorId: string) {
      const res = await (this as any).$dep.modelUseCase.getRvsAxis(sensorId);
      this.axisGroup = [
        { id: 1, axisName: res.x, axisValue: "x" },
        { id: 2, axisName: res.y, axisValue: "y" },
        { id: 3, axisName: res.z, axisValue: "z" },
      ];
    },
    onChangePhase(value: string) {
      this.isPhase = value;
    },
    onChangeAxis(value: string) {
      this.isAxis = value;
    },
    onChangeFileName(values: any[]) {
      this.isFileName = values.map((val: any) => ({
        fileName: val.fileName,
        alias: val.alias,
      }));
    },
    async reloadData() {
      this.graphResultData = [];
      this.graphLoading = true;
      await this.fetchGraphData();
      this.graphLoading = false;
    },

    onChangeXAxisType(val: string) {
      this.graphLoading = true;
      const hz = this.getRotationalSpeed / 60;
      const datas = this.graphResultData;

      this.chartOptions.series = this.chartOptions.series.map((serie: any, index: number) => ({
        ...serie,
        data:
          val === "revolution"
            ? serie.data.map((s: any) => [s[0] * hz, s[1], s[2]])
            : datas[index].data.map((d: any) => [d[0], serie.name, d[1]]),
      }));

      const xAxisMin = Math.min(...this.chartOptions.series.map((item) => item.data[0][0]));
      const xAxisMax = Math.max(...this.chartOptions.series.map((item) => item.data[item.data.length - 1][0]));
      const numOfItems = 10;
      const tickPositions = Array.from({ length: numOfItems }, (_, i) =>
        Number((xAxisMin + (i * (xAxisMax - xAxisMin)) / (numOfItems - 1)).toFixed(2))
      );

      this.chartOptions.xAxis3D = {
        ...this.chartOptions.xAxis3D,
        name: this.xAxisType === "revolution" ? "Revolution [REV]" : "Time [seconds]",
        tickInterval: tickPositions.length / 10,
        tickPositions,
      };

      this.graphLoading = false;
    },
  },
  watch: {
    async getModelId() {
      this.sensorGroup = [];
      this.isSensor = null;
      this.fileNameGroup = [];
      this.isFileName = [];
      this.graphResultData = [];
      this.tableBodyData = [];

      await this.fetchSensorGroup();
    },
    getRotationalSpeed() {
      if (this.xAxisType === "revolution") {
        this.onChangeXAxisType("revolution");
      }
    },
  },
});
