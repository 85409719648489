
import Vue from "vue";
import { mapActions } from "vuex";
import dayjs from "dayjs";
import Container from "@/components/Container.vue";
import StatusEquip from "@/features/company/components/statusEquipments.vue";
import EquipmentsMap from "@/features/company/components/EquipmentsMap.vue";
import NoMap from "@/components/NoMap.vue";
import ModelOverall from "@/features/company/components/ModelOverall.vue";

export default Vue.extend({
  name: "CompanyPage",
  components: { Container, StatusEquip, EquipmentsMap, NoMap, ModelOverall },
  data() {
    return {
      isAutoReloadData: 0,
      timeoutAutoRefreshList: [
        { name: "1 minute", value: 60000 },
        { name: "5 minute", value: 300000 },
        { name: "10 minute", value: 600000 },
        { name: "15 minute", value: 900000 },
      ],
      isLoading: false,
    };
  },
  computed: {
    modelsStatusAll() {
      return this.$store.state.company.dashboard_data;
    },
    allEquipment() {
      if (!this.$store.state.company.structure_item) return;
      const allEqt = this.$store.state.company.structure_item.filter((val) => typeof val === "object");
      return allEqt || 0;
    },
    isAutoRefresh: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.enable;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          enable: value,
        });
        if (value) {
          this.onStartAutoReloadData();
        } else {
          this.onStopAutoReloadData();
        }
      },
    },
    timestamp() {
      return this.$store.state.modelViewData.autoRefresh.timestamp;
    },
    timeout: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.timeout;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          timeout: value,
        });
        this.onStopAutoReloadData();
        setTimeout(() => {
          this.onStartAutoReloadData();
        }, 500);
      },
    },
  },
  methods: {
    ...mapActions(["getCompany", "getStructure", "setAutoRefresh"]),
    async handleRefresh() {
      // เมื่อกดปุ่ม refresh อยากให้ fetch data อะไร มาใส่ตรงนี้เลยจ้า
      this.fetchDataCompany();
    },
    onStartAutoReloadData() {
      this.isAutoReloadData = setInterval(() => {
        // เมื่อ auto refresh เป็น true อยากให้ fetch data อะไร มาใส่ตรงนี้เลยจ้า
        this.fetchDataCompany();
      }, this.timeout);
    },
    onStopAutoReloadData() {
      clearInterval(this.isAutoReloadData);
    },
    formatDate(date: any, format = "DD MMMM YYYY, HH:mm") {
      return dayjs(date).format(format);
    },
    async fetchDataCompany() {
      try {
        this.isLoading = true;
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          timestamp: new Date(),
        });

        await this.getCompany();
      } catch (error) {
        //
      } finally {
        this.isLoading = false;
      }
    },
    // handleOnchangeFilterMap(filter: string, position) {

    // },
  },
});
