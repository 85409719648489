
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "CThermal",
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.$store.state.modelViewData.tr_summary_status.c.sort((a, b) => b.unit.localeCompare(a.unit));
    },
  },
  data() {
    return {
      option: null,
      isLenderComponent: false,
    };
  },
  methods: {
    statusName(code: any) {
      const val = typeof code === "number" ? code : parseInt(code);

      if (val === -1) return "#949494";
      if (val === 0) return "#2ec577";
      if (val === 1) return "#FFC107";
      if (val === 2) return "#FF0000";
      else return "";
    },
    calsOilLevel(val, warning, alarm) {
      const value = Math.min(val, warning, alarm);
      const max = Math.max(val, warning, alarm) * 1.2;
      const res = (value / max) * 100;
      return res;
    },
    calsMeanTs(warning, alarm) {
      const res = ((alarm - warning) / warning) * 100;
      if (Math.round(res) <= 10) return 10;
      else return 0;
    },
    calsValue(val, warning, alarm) {
      if (val <= warning) {
        return this.calsMeanTs(warning, alarm);
      } else {
        return 0;
      }
    },
    textValueOilLevel(status: number, value: number, alarm: number) {
      if (status < 0) return "-";
      if (value > alarm) return alarm * 1.2;
      else return value;
    },
    textValue(status: number, value: number) {
      if (status < 0) return "-";
      else return value;
    },
  },
  // watch: {
  //   items() {
  //     this.renderGraph();
  //   },
  // },
});
