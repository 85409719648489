import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@fortawesome/fontawesome-free/css/all.css";

import RectangleIcon from "@/components/icons/RectangleIcon.vue";
import CircleIcon from "@/components/icons/CircleIcon.vue";
import CheckCircleIcon from "@/components/icons/CheckCircleIcon.vue";
import CheckBoxIcon from "@/components/icons/CheckBoxIcon.vue";
import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import CompanyIcon from "@/components/icons/CompanyIcon.vue";
import FolderIcon from "@/components/icons/FolderIcon.vue";
import FolderOpenIcon from "@/components/icons/FolderOpenIcon.vue";
import EquipmentIcon from "@/components/icons/EquipmentIcon.vue";
import ModelIcon from "@/components/icons/ModelIcon.vue";
import CalendarIcon from "@/components/icons/CalendarIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2E53A3",
        secondary: "#FF0000",
        info: "#307DF1",
        success: "#2EC577",
        error: "#D9263B",
        warning: "#FCA239",
        gray: "#A5ACB2",
        "gray-1": "#FBFBFB",
        border: "#F1EFEF",
        black: "#000000",
        white: "#FFFFFF",
        accent: "#bbbbbb",
      },
    },
  },
  icons: {
    iconfont: "fa",
    values: {
      "custom-rectangle": { component: RectangleIcon },
      "custom-checkbox": { component: CheckBoxIcon },
      "custom-circle": { component: CircleIcon },
      "custom-check-circle": { component: CheckCircleIcon },
      "chevron-left": { component: ChevronLeftIcon },
      "chevron-right": { component: ChevronRightIcon },
      "custom-company": { component: CompanyIcon },
      "custom-folder": { component: FolderIcon },
      "custom-folder-open": { component: FolderOpenIcon },
      "custom-equipment": { component: EquipmentIcon },
      "custom-model": { component: ModelIcon },
      "custom-calendar": { component: CalendarIcon },
    },
  },
});
