
import Vue from "vue";
import dayjs from "dayjs";
import { mapActions } from "vuex";
import { between, required, alphaNum } from "vuelidate/lib/validators";

import dialogMixin from "@/common/mixins/dialogModal";
import CreateFolderPage from "@/features/folder/pages/CreateFolderPage.vue";
import CreateEquipmentPage from "@/features/equipment/pages/CreateEquipmentPage.vue";
import AssignMemberEquipment from "@/features/equipment/pages/AssignMemberEquipment.vue";
import EditModelOfflineFormPage from "@/features/model/pages/EditModelOfflineFormPage.vue";
import ModelIcon from "@/components/icons/ModelIcon.vue";
import SetEmailNotification from "@/features/equipment/pages/SetEmailNotification.vue";

export default Vue.extend({
  name: "MenuTreeView",
  mixins: [dialogMixin],
  validations: {
    equipmentCreateForm: {
      structureItemTitle: { required, alphaNum },
      itemGroupType: { required },
      position: {
        lat: { required, between: between(1, 200) },
        lng: { required, between: between(1, 200) },
      },
    },
  },
  components: {
    ModelIcon,
    CreateFolderPage,
    CreateEquipmentPage,
    AssignMemberEquipment,
    EditModelOfflineFormPage,
    SetEmailNotification,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    modelParamId(): number {
      return Number(this.$route?.params?.id) || 0;
    },
    companyId(): any {
      return this.$store.getters.company.id || 0;
    },
    user() {
      return this.$store.getters.user;
    },
    contextMenuState() {
      return this.$store.state.contextMenuStructure;
    },
  },
  data: () => ({
    isContextMenu: false,
    contextMenu: {
      id: 0,
      type: "",
      name: "",
      positionY: 0,
      positionX: 0,
    },
    tree: [],
    types: {
      company: "$vuetify.icons.custom-company",
      folder: "$vuetify.icons.custom-folder",
      folderOpen: "$vuetify.icons.custom-folder-open",
      equipment: "$vuetify.icons.custom-equipment",
      model: "$vuetify.icons.custom-model",
    } as any,
  }),
  methods: {
    ...mapActions([
      "getStructure",
      "getCompany",
      "setContextMenuStructure",
      "openFolderFormModal",
      "openEquipmentFormModal",
      "openCreateModelModal",
      "openEditModelOfflineModal",
      "openMemberAssignModal",
      "openEmailNotificationFormModal",
    ]),
    handleOpenMenu(item: any) {
      if (item.type === "model") {
        if (item.status === "online") {
          this.$router.push({ name: "ViewModelPage", params: { id: item.id } });
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
        if (item.status === "offline") {
          window.open(!item.link.match(/^https?:\/\//i) ? `https://${item.link}` : item.link, "_blank");
        }
      }
    },
    async handleOpenContextMenu(action: string) {
      this.setContextMenuStructure({
        id: this.contextMenu.id,
        name: this.contextMenu.name,
        type: this.contextMenu.type,
        status: null,
      });

      // # ----------- # NOTE: Company
      if (action === "EDIT_ROOT") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Edit" });
        this.openFolderFormModal({
          open: true,
          type: "EDIT",
          param: { id: this.contextMenu.id, name: this.contextMenu.name },
        });
      }
      if (action === "MANAGE_MEMBER_COMPANY") {
        this.$router.push({ name: "CompanyMemberPage", params: { id: this.companyId } });
      }

      // # ----------- # NOTE: Folder
      if (action === "CREATE_FOLDER") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Create" });
        this.openFolderFormModal({ open: true, type: "CREATE", param: null });
      }
      if (action === "EDIT_FOLDER") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Edit" });
        this.openFolderFormModal({
          open: true,
          type: "EDIT",
          param: { id: this.contextMenu.id, name: this.contextMenu.name },
        });
      }
      if (action === "DELETE_FOLDER") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Delete" });
        this.onDeleteFolder(this.contextMenu.id);
      }

      // # ----------- # NOTE: Equipment
      if (action === "ADD_EQUIPMENT") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Create" });
        this.openEquipmentFormModal({ open: true, type: "CREATE", param: null });
      }
      if (action === "SET_EMAIL_NOTIFICATION") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Email" });
        this.openEmailNotificationFormModal({ open: true, type: "Email", param: { id: this.contextMenu.id } });
      }
      if (action === "EDIT_EQUIPMENT") {
        const response = await (this as any).$dep.equipmentUseCase.getEquipmentTagById(this.contextMenu.id);

        this.setContextMenuStructure({ ...this.contextMenuState, status: "Edit" });
        this.openEquipmentFormModal({
          open: true,
          type: "EDIT",
          param: { ...response, id: this.contextMenu.id, name: this.contextMenu.name },
        });
      }
      if (action === "DELETE_EQUIPMENT") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Delete" });
        this.onDeleteEquipment(this.contextMenu.id);
      }
      if (action === "ASSIGN_MEMBER_EQUIPMENT") {
        await this.openMemberAssignModal({ folderId: this.contextMenu.id, folderName: this.contextMenu.name });
      }

      // # ----------- # NOTE: Model
      if (action === "CREATE_MODEL") {
        this.setContextMenuStructure({
          ...this.contextMenuState,
          status: "Create",
        });
        this.openCreateModelModal({
          open: true,
          type: "CREATE",
          param: { ...this.contextMenu },
        });
      }
      if (action === "EDIT_MODEL") {
        if ((this as any).contextMenu.status === "online") {
          this.setContextMenuStructure({ ...this.contextMenuState, status: "Edit" });
          this.$router.push({ name: "EditModelFormPage", params: { id: `${this.contextMenu.id}` } });
        }
        if ((this as any).contextMenu.status === "offline") {
          const response = await (this as any).$dep.modelUseCase.getModelOfflineById(this.contextMenu.id);

          this.setContextMenuStructure({ ...this.contextMenuState, status: "Edit" });
          this.openEditModelOfflineModal({
            open: true,
            type: "EDIT",
            param: { ...response },
          });
        }
      }
      if (action === "INACTIVE_MODEL") {
        this.setContextMenuStructure({ ...this.contextMenuState, status: "Delete" });
        this.onDeleteModel(this.contextMenu.id);
      }
    },
    openContextMenu(e: any, item: any) {
      e.preventDefault();

      if (["company", "folder", "equipment", "model"].includes(item.type) && ["ADMIN"].includes(this.user.role)) {
        this.isContextMenu = true;
        this.contextMenu = {
          ...item,
          positionY: e.clientY,
          positionX: e.clientX,
        };
      }

      if (["equipment", "model"].includes(item.type) && ["OWNER"].includes(this.user.role)) {
        this.isContextMenu = true;
        this.contextMenu = {
          ...item,
          positionY: e.clientY,
          positionX: e.clientX,
        };
      }
    },
    async onSubmitFolderModal(param: any) {
      try {
        const { action_type, id, name } = param;

        if (action_type === "CREATE") {
          await (this as any).$dep.folderUseCase.createFolder({
            parentId: this.contextMenuState.id,
            structureItemType: "Folder",
            structureItemTitle: name,
            companies_id: this.companyId,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            isPrimaryFolder: false,
            is_disabled: false,
          });
        }

        if (action_type === "EDIT") {
          await (this as any).$dep.folderUseCase.updateFolderTitle(
            {
              id,
              structureItemType: "Folder",
              structureItemTitle: name,
              companies_id: this.companyId,
              createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            },
            id
          );
        }

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `${action_type === "CREATE" ? "Create" : "Update"} folder successfully`,
          type: "success",
        });
        await this.getStructure();
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmitEquipmentModal(param: any) {
      try {
        const {
          action_type,
          parentId,
          structureItemTitle,
          structureItemAlias,
          itemGroupType,
          position,
          isPrimaryFolder,
          emailTaskScheduler,
          images,
          imageDelete,
        } = param;

        if (action_type === "CREATE") {
          const equipmentTag = await (this as any).$dep.folderUseCase.createFolder({
            parentId,
            companies_id: this.companyId,
            structureItemTitle,
            structureItemAlias,
            structureItemType: "EquipmentTag",
            equipmentGroupType: itemGroupType,
            posLat: position.lat,
            posLng: position.lng,
            isPrimaryFolder,
            is_disabled: false,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          });

          if (images.length > 0) {
            images.forEach((fl: any) => {
              if (fl.imageUpload) this.onAddImage(fl.imageUpload, equipmentTag.id, fl.image_name, fl.image_size);
            });
          }

          if (emailTaskScheduler) {
            const { daily, weekly, monthly } = emailTaskScheduler;
            if (daily.isActive || weekly.isActive || monthly.isActive) {
              const timezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
              await (this as any).$dep.equipmentUseCase.sendEmailTaskScheduler({
                companies_id: this.companyId,
                createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                equipment_tag_id: equipmentTag.id,
                is_daily: daily.isActive,
                is_daily_warning: daily.isWarning,
                is_daily_alarm: daily.isAlarm,
                daily_start_time:
                  !daily.isActive || !daily.timedate ? [] : [daily.timedate, !daily.timedate ? null : timezone],
                is_weekly: weekly.isActive,
                is_weekly_warning: weekly.isWarning,
                is_weekly_alarm: weekly.isAlarm,
                weekly_start_time:
                  !weekly.isActive || !weekly.timedate ? [] : [weekly.timedate, !weekly.timedate ? null : timezone],
                is_monthly: monthly.isActive,
                is_monthly_warning: monthly.isWarning,
                is_monthly_alarm: monthly.isAlarm,
                monthly_start_time:
                  !monthly.isActive || !monthly.timedate ? [] : [monthly.timedate, !monthly.timedate ? null : timezone],
              });
            }
          }
        }

        if (action_type === "EDIT") {
          await (this as any).$dep.folderUseCase.updateFolderTitle(
            {
              companies_id: this.companyId,
              structureItemTitle,
              structureItemAlias,
              structureItemType: "EquipmentTag",
              equipmentGroupType: itemGroupType,
              posLat: position.lat,
              posLng: position.lng,
              isPrimaryFolder,
              status: null,
              is_disabled: false,
              createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            },
            parentId
          );
          if (imageDelete.length > 0) {
            imageDelete.forEach((id: any) => {
              this.deleteEquipmentImages(id);
            });
          }
          if (images.length > 0) {
            images.forEach((fl: any) => {
              if (fl.imageUpload) this.onAddImage(fl.imageUpload, parentId, fl.image_name, fl.image_size);
            });
          }
        }

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `${action_type === "CREATE" ? "Create" : "Update"} equipment successfully`,
          type: "success",
        });
        await this.getStructure();
        await this.getCompany();
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmitModalOfflineModal(param: any) {
      try {
        const { id, title, linkUrl, isActive } = param;

        await (this as any).$dep.modelUseCase.updateModelOffline(id, { title, linkUrl, isActive });

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `Update model successfully`,
          type: "success",
        });
        await this.getStructure();
        await this.getCompany();
      } catch (error) {
        console.log(error);
      }
    },
    async onDeleteFolder(id: number) {
      try {
        const is = await (this as any).dialogDelete("Do you want to delete this folder?");
        if (is) {
          await (this as any).$dep.equipmentUseCase.deleteFolder(id);
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: `Delete folder successfully`,
            type: "success",
          });
          await this.getStructure();
          await this.getCompany();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onDeleteEquipment(id: number) {
      try {
        const is = await (this as any).dialogDelete("Do you want to delete this equipment?");
        if (is) {
          await (this as any).$dep.equipmentUseCase.deleteFolder(id);
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: `Delete equipment successfully`,
            type: "success",
          });
          await this.getStructure();
          await this.getCompany();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onDeleteModel(id: number) {
      try {
        const is = await (this as any).dialogDelete("Do you want to inactive this model?");
        if (is) {
          await (this as any).$dep.modelUseCase.deleteModel(id);
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: `Delete model successfully`,
            type: "success",
          });
          await this.getStructure();
          await this.getCompany();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onAddImage(file: any, id: any, image_name: any, image_size: any) {
      try {
        let formdata = new FormData();
        formdata.append("image_file", file, `model_${id}_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}.png`);

        const { url } = await (this as any).$dep.modelUseCase.uploadImage(formdata);
        await (this as any).$dep.modelUseCase.saveEquipmentImage({
          url,
          equipmentTagId: id,
          image_name,
          image_size,
        });
      } catch (error) {
        //
        console.log(error);
      }
    },
    async deleteEquipmentImages(id: any) {
      try {
        await (this as any).$dep.modelUseCase.deleteEquipmentImage(id);
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmitEmailNotiModal(param: any) {
      try {
        const { parentId, emailTaskScheduler } = param;

        const timezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        let status = "";

        if (emailTaskScheduler.type === "none") {
          await (this as any).$dep.equipmentUseCase.deleteEmailTaskScheduler(emailTaskScheduler.jobId);
          status = "Delete";
        } else if (emailTaskScheduler.jobId) {
          await (this as any).$dep.equipmentUseCase.updateEmailTaskScheduler({
            companies_id: this.companyId,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            equipment_tag_id: parentId,
            start_time: !emailTaskScheduler.timedate
              ? []
              : [emailTaskScheduler.timedate, !emailTaskScheduler.timedate ? null : timezone],
            type: emailTaskScheduler.type,
            is_warning: emailTaskScheduler.isWarning,
            is_alarm: emailTaskScheduler.isAlarm,
            day: emailTaskScheduler.indexDay,
            job_id: emailTaskScheduler.jobId,
          });
          status = "Update";
        } else {
          await (this as any).$dep.equipmentUseCase.sendEmailTaskScheduler({
            companies_id: this.companyId,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            equipment_tag_id: parentId,
            start_time: !emailTaskScheduler.timedate
              ? []
              : [emailTaskScheduler.timedate, !emailTaskScheduler.timedate ? null : timezone],
            type: emailTaskScheduler.type,
            is_warning: emailTaskScheduler.isWarning,
            is_alarm: emailTaskScheduler.isAlarm,
            day: emailTaskScheduler.indexDay,
          });
          status = "Create";
        }

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `${status} equipment successfully`,
          type: "success",
        });
        await this.getStructure();
        await this.getCompany();
      } catch (error) {
        console.log(error);
      }
    },
    checkColorStatus(status: number, type: string) {
      if (type !== "equipment" && type !== "model") return "black";
      if (status === -1) return "#949494";
      if (status === 0) return "#4caf50";
      if (status === 1) return "#ffc12b";
      if (status === 2) return "#f44336";
    },
  },
});
