import { MutationTree } from "vuex";

import { EquipmentModelType } from "@/features/model/models/EquipmentModelType";
import { EquipmentModelFormType } from "@/features/model/models/EquipmentModelFormType";
import { ModelSettingFormType } from "@/features/model/models/ModelSettingFormType";

import { State } from "./states";

export const APP = "APP";
export const USER = "USER";
export const COMPANY = "COMPANY";
export const STRUCTURE = "STRUCTURE";
export const MODEL = "MODEL";
export const MODEL_VIEW_DATA = "MODEL_VIEW_DATA";
export const CREATE_MODEL_FORM = "CREATE_MODEL_FORM";
export const UPDATE_MODEL_FORM = "UPDATE_MODEL_FORM";
export const UPLOAD_FILE_RECOMMENDATION_FORM = "UPLOAD_FILE_RECOMMENDATION_FORM";
export const MODEL_SETTING_FORM = "MODEL_SETTING_FORM";
export const MODEL_IMAGE_INFORMATION = "MODEL_IMAGE_INFORMATION";
export const MODEL_DOCUMENT_INFORMATION = "MODEL_DOCUMENT_INFORMATION";
export const MODEL_CONFIGURATION_INFORMATION = "MODEL_CONFIGURATION_INFORMATION";
export const GRAPH_INFORMATION_TAB_GROUP_DATA = "GRAPH_INFORMATION_TAB_GROUP_DATA";
export const GRAPH_INFORMATION_TAB = "GRAPH_INFORMATION_TAB";
export const CONTEXT_MENU_STRUCTURE = "CONTEXT_MENU_STRUCTURE";
export const OPEN_MEMBER_ASSIGN_MODAL = "OPEN_MEMBER_ASSIGN_MODAL";
export const SET_MEMBER_ASSIGN_MODAL = "SET_MEMBER_ASSIGN_MODAL";
export const CLOSE_MEMBER_ASSIGN_MODAL = "CLOSE_MEMBER_ASSIGN_MODAL";
export const OPEN_ALERT_MESSAGE = "OPEN_ALERT_MESSAGE";
export const OPEN_FOLDER_FORM_MODAL = "OPEN_FOLDER_FORM_MODAL";
export const OPEN_EQUIPMENT_FORM_MODAL = "OPEN_EQUIPMENT_FORM_MODAL";
export const OPEN_CREATE_MODEL_MODAL = "OPEN_CREATE_MODEL_MODAL";
export const OPEN_EDIT_MODEL_OFFLINE_MODAL = "OPEN_EDIT_MODEL_OFFLINE_MODAL";
export const OPEN_EDIT_USER_MODAL = "OPEN_EDIT_USER_MODAL";
export const OPEN_COMPANY_FORM_MODAL = "OPEN_COMPANY_FORM_MODAL";
export const OPEN_ADMIN_FORM_MODAL = "OPEN_ADMIN_FORM_MODAL";
export const SET_EQUIPMENT_CREATE_FORM = "SET_EQUIPMENT_CREATE_FORM";
export const OPEN_SET_EMAIL_NOTIFICATION = "OPEN_SET_EMAIL_NOTIFICATION";
export const OPEN_MEMBER_FORM_MODAL = "OPEN_MEMBER_FORM_MODAL";
export const OPEN_EDIT_MEMBER_MODAL = "OPEN_EDIT_MEMBER_MODAL";
export const SET_MODEL_MOTOR_DATA_FORM = "SET_MODEL_MOTOR_DATA_FORM";
export const SET_MODEL_PHASE_GROUP = "SET_MODEL_PHASE_GROUP";
export const SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR = "SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR";

export const mutations: MutationTree<State> = {
  [APP](state: State, payload: any) {
    state.app = payload;
  },
  [USER](state: State, payload: any) {
    state.user = payload;
  },
  [COMPANY](state: State, payload: any) {
    state.company = payload;
  },
  [STRUCTURE](state: State, payload: any) {
    state.structure = payload;
  },
  [MODEL](state: State, payload: EquipmentModelType) {
    state.model = payload;
  },
  [MODEL_VIEW_DATA](state: State, payload: any) {
    state.modelViewData = payload;
  },
  [CREATE_MODEL_FORM](state: State, payload: EquipmentModelFormType) {
    state.createModelForm = payload;
  },
  [UPDATE_MODEL_FORM](state: State, payload: EquipmentModelFormType) {
    state.updateModelForm = payload;
  },
  [UPLOAD_FILE_RECOMMENDATION_FORM](state: State, payload: any) {
    state.uploadFileRecommendationForm = payload;
  },
  [MODEL_SETTING_FORM](state: State, payload: ModelSettingFormType) {
    state.modelSettingForm = payload;
  },
  [MODEL_IMAGE_INFORMATION](state: State, payload: any) {
    state.modelImageInformation = payload;
  },
  [MODEL_DOCUMENT_INFORMATION](state: State, payload: any) {
    state.modelDocumentInformation = payload;
  },
  [MODEL_CONFIGURATION_INFORMATION](state: State, payload: any) {
    state.modelConfigurationInformation = payload;
  },
  [GRAPH_INFORMATION_TAB_GROUP_DATA](state: State, payload: any) {
    state.graphInformationTabGroupData = payload;
  },
  [GRAPH_INFORMATION_TAB](state: State, payload: any) {
    state.graphInformationTab = payload;
  },
  [SET_EQUIPMENT_CREATE_FORM](state: State, form: any) {
    state.equipmentTagCreateForm = form;
  },
  [CONTEXT_MENU_STRUCTURE](state: State, payload: any) {
    state.contextMenuStructure = payload;
  },
  [OPEN_MEMBER_ASSIGN_MODAL](state: State, payload: Record<any, any>) {
    state.memberAssignForm = {
      isShowModal: true,
      equipmentTagId: payload.folderId,
      equipmentTagName: payload.folderName,
      selectedMemberIds: [],
      search: "",
    };
  },
  [SET_MEMBER_ASSIGN_MODAL](state: State, payload: Record<any, any>) {
    state.memberAssignForm = {
      ...state.memberAssignForm,
      selectedMemberIds: payload.selectedMemberIds,
      search: payload.search,
    };
  },
  [CLOSE_MEMBER_ASSIGN_MODAL](state: State) {
    state.memberAssignForm = {
      isShowModal: false,
      selectedMemberIds: [],
      search: "",
    };
  },
  [OPEN_ALERT_MESSAGE](
    state: State,
    payload: {
      open: boolean;
      message: string;
      type: string;
    }
  ) {
    state.openAlertMessage = payload;
  },
  [OPEN_COMPANY_FORM_MODAL](state: State, payload: any) {
    state.openCompanyFormModal = payload;
  },
  [OPEN_ADMIN_FORM_MODAL](state: State, payload: any) {
    state.openAdminFormModal = payload;
  },
  [OPEN_MEMBER_FORM_MODAL](state: State, payload: any) {
    state.openMemberFormModal = payload;
  },
  [OPEN_FOLDER_FORM_MODAL](state: State, payload: any) {
    state.openFolderFormModal = payload;
  },
  [OPEN_EQUIPMENT_FORM_MODAL](state: State, payload: any) {
    state.openEquipmentFormModal = payload;
  },
  [OPEN_CREATE_MODEL_MODAL](state: State, payload: any) {
    state.openCreateModelModal = payload;
  },
  [OPEN_EDIT_USER_MODAL](state: State, payload: boolean) {
    state.openEditUserModal = payload;
  },
  [OPEN_EDIT_MODEL_OFFLINE_MODAL](state: State, payload: any) {
    state.openEditModelOfflineModal = payload;
  },
  [OPEN_SET_EMAIL_NOTIFICATION](state: State, payload: any) {
    state.openEmailNotificationFormModal = payload;
  },
  [OPEN_EDIT_MEMBER_MODAL](state: State, payload: any) {
    state.openEditMemberModal = payload;
  },
  [SET_MODEL_MOTOR_DATA_FORM](state: State, form: any) {
    state.modelMotorDataForm = form;
  },
  [SET_MODEL_PHASE_GROUP](state: State, payload: Record<any, any>) {
    state.modelPhaseGroup = payload;
  },
  [SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR](state: State, payload: Array<any>) {
    state.modelTrendSignalAlarmLimitSensor = payload;
  },
};
