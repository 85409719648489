
import Vue from "vue";
import Spinner from "@/components/Spinner.vue";
import EChart from "../../graph/EChart.vue";
export default Vue.extend({
  name: "VcaPercentOfLoad",
  components: { EChart, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    item() {
      const raw = this.$store.state.modelViewData.vca_diagram_status;
      if (!Array.isArray(raw)) {
        return {
          status: -2, // Return a default value or handle the absence of data
          value: 100, // Return a default value or handle the absence of data
          unit: "%",
        };
      }
      const filteredItems = raw?.filter((val) => val.tagCode === "stress");
      if (filteredItems.length === 0) {
        return {
          status: -2, // Return a default value or handle the absence of data
          value: 100, // Return a default value or handle the absence of data
          unit: "%",
        };
      }
      const latestItem = filteredItems.reduce((prev, current) => {
        return prev.timestamp > current.timestamp ? prev : current;
      });
      const val = latestItem.value;
      const status = latestItem.status;
      return {
        status: status,
        value: val,
        unit: "%",
      };
    },
  },
  data() {
    const initOption = {
      type: "gauge",
      progress: { show: false },
      pointer: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: false },
      detail: { show: false },
    };
    return {
      initOption,
      option: { series: [] },
      isLenderComponent: false,
    };
  },
  methods: {
    async renderGraph() {
      this.isLenderComponent = false;
      const val = this.item.value;
      const status = this.item.status;
      (this as any).option.series = [
        {
          ...this.initOption,
          axisLine: { lineStyle: { width: 20 } },
        },
        {
          ...this.initOption,
          name: "value",
          progress: {
            show: true,
            width: 20,
            itemStyle: { color: this.statusCode(status) },
            dataLabels: {
              formatter: function () {
                if (status === -2 || status === 3) {
                  return "Disabled";
                } else if (status === -1) {
                  return "Offline";
                } else {
                  return `${this.item.value} ${this.item.unit}`;
                }
              },
            },
          },
          data: [{ value: val }],
        },
        {
          ...this.initOption,
          name: "detail",
          detail: {
            offsetCenter: [0, "-15%"],
            fontSize: 20,
            formatter: () => {
              if (status === -2 || status === 3) {
                return "Disabled";
              } else {
                return `${this.item.value} ${this.item.unit}`;
              }
            },
          },
          data: [{ value: val, name: `Percent of Load` }],
        },
      ];
      await this.alarmLimitSensor();
      this.isLenderComponent = true;
    },
    statusCode(status: number) {
      if (status === 2) {
        return "#f44336";
      } else if (status === 1) {
        return "#ffc12b";
      } else if (status === 0) {
        return "#4caf50";
      } else if (status === -1) {
        return "#a5acb2";
      } else {
        return "#808080";
      }
    },
    async alarmLimitSensor() {
      try {
        const vsdAlarmLimitSensorNew = await Vue.prototype.$dep.modelUseCase.getVsdAlarmLimitSensorNewForm(this.model.id);
        const stressAlarmLimit = vsdAlarmLimitSensorNew.filter((val) => val.tagCode === "stress")[0];
        const alarm = {
          ...this.initOption,
          name: "alarm-pointer",
          pointer: {
            show: true,
            icon: "rect",
            width: 3,
            length: 23,
            offsetCenter: [0, -68],
            itemStyle: {
              color: "#D9263B",
              borderCap: "square",
            },
          },
          z: 1999,
          data: [{ value: stressAlarmLimit.alarm }],
        };
        const warning = {
          ...this.initOption,
          name: "warning-pointer",
          pointer: {
            show: true,
            icon: "rect",
            width: 3,
            length: 23,
            offsetCenter: [0, -68],
            itemStyle: {
              color: "#FDBA36",
              borderCap: "square",
            },
          },
          z: 1999,
          data: [{ value: stressAlarmLimit.warning }],
        };
        (this as any).option.series.push(alarm, warning);
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    item() {
      this.renderGraph();
    },
  },
});
