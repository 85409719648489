
import Vue from "vue";

export default Vue.extend({
  name: "FormConfigMachine",
  props: {
    data: Object,
    onUpdateState: Function,
    searchBearing: Function,
    bearings: Array,
  },
  data() {
    return {
      inputRules: [(v) => v >= 0 || "Value must be non-negative"],
    };
  },
  computed: {
    sensor() {
      return (this as any).data.item.item;
    },
  },
  methods: {
    handleKeyDown(event) {
      if (event.key === "-" || event.key === "e" || event.key === "E") {
        event.preventDefault();
      }
    },
  },
});
