
import Vue from "vue";

import Card from "@/components/Card.vue";
import ALoadConsumption from "../details/summaryStatus/ALoadConsumption.vue";
import BCurrentVoltage from "../details/summaryStatus/BCurrentVoltage.vue";
import CThermal from "../details/summaryStatus/CThermal.vue";
import DWindingCore from "../details/summaryStatus/DWindingCore.vue";
import EMainTankPD from "../details/summaryStatus/EMainTankPD.vue";
import FBushing from "../details/summaryStatus/FBushing.vue";
// import GCooling from "../details/summaryStatus/GCooling.vue";
import HMainTankDGA from "../details/summaryStatus/HMainTankDGA.vue";
import IOLTCDGA from "../details/summaryStatus/IOLTCDGA.vue";
import JOLTC from "../details/summaryStatus/JOLTC.vue";
import DiagramInformation from "../details/diagrams/DiagramInformation.vue";
import GraphTabGroup from "../graph/GraphTabGroup.vue";
import ConfigurationInformation from "../details/ConfigurationInformation.vue";
import FaultEventAlarm from "../details//faultEventAlarm/FaultEventAlarm.vue";
import NoteHistoryLog from "../details/NoteHistoryLog.vue";

export default Vue.extend({
  name: "TransformerDetail",
  components: {
    Card,
    ALoadConsumption,
    BCurrentVoltage,
    CThermal,
    DWindingCore,
    EMainTankPD,
    FBushing,
    // GCooling,
    HMainTankDGA,
    IOLTCDGA,
    JOLTC,
    DiagramInformation,
    GraphTabGroup,
    ConfigurationInformation,
    FaultEventAlarm,
    NoteHistoryLog,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
});
