
import Vue from "vue";

import UploadArrowIcon from "@/components/icons/UploadArrowIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import ExcelIcon from "@/components/icons/ExcelIcon.vue";

export default Vue.extend({
  name: "FormRecommendation",
  components: { UploadArrowIcon, DeleteIcon, ExcelIcon },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    return { fileUpload: null };
  },
  methods: {
    downloadFormat() {
      window.open((this as any).$dep.modelUseCase.downloadFileRecommendation(this.model.modelTypeId, this.model.modelCode));
    },
    onChange() {
      const file = (this as any).$refs.file.files[0];

      if (!file) return alert("Please select file");

      if (
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/msexcel",
          "application/x-msexcel",
          "application/x-ms-excel",
          "application/x-excel",
          "application/x-dos_ms_excel",
          "application/xls",
          "application/x-xls",
        ].includes(file.type)
      ) {
        return alert("Please upload file excel");
      }

      let formdata = new FormData();
      formdata.append("recommendation_msg_file", file);
      this.$store.dispatch("setUploadReccomendationForm", { formdata });
      (this as any).fileUpload = file;
    },
    onDrop(event: any) {
      event.preventDefault();

      (this as any).$refs.file.files = event.dataTransfer.files;
      this.onChange();

      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    remove() {
      this.$store.dispatch("setUploadReccomendationForm", { formdata: null });
      (this as any).fileUpload = null;
    },
    dragover(event: any) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-[#EAEEF6]")) {
        event.currentTarget.classList.remove("bg-[#FFFFFF]");
        event.currentTarget.classList.add("bg-[#EAEEF6]");
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    humanFileSize(bytes: number) {
      const thresh = 1000;
      if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
      }

      const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** 0;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return `${bytes.toFixed(0)} / ${bytes.toFixed(0)} ${units[u]}`;
    },
  },
});
