
import Vue from "vue";
import { required, email } from "vuelidate/lib/validators";
import { proxyModel } from "@/common/utils/proxyModel";

import { MemberRole } from "../models/Member";
import { MemberInviteForm } from "../models/MemberInviteForm";
import MemberCompanyInfo from "./MemberCompanyInfo.vue";

export default Vue.extend({
  data() {
    return {
      role: "",
      email: "",
    };
  },
  validations: {
    email: { required, email },
    role: { required },
    validationGroup: ["email", "role"],
  },
  computed: {
    roles() {
      return [MemberRole.OWNER, MemberRole.MEMBER, MemberRole.SUPERMEMBER];
    },
    emailError() {
      return this.$v.email.$error ? "Email is required or invalid." : "";
    },
    roleError() {
      return this.$v.role.$error ? "Role is required." : "";
    },
    company() {
      return this.$store.getters.company;
    },
    dialog: {
      get(): boolean {
        return this.$store.state.openMemberFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openMemberFormModal", {
          ...this.$store.state.openMemberFormModal,
          open: value,
        });
      },
    },
  },
  methods: {
    async invite() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$v.$reset();
      this.$emit("invite", {
        role: this.role,
        email: this.email,
      });

      this.dialog = false;
    },
    close() {
      this.$v.$reset();
      this.$emit("close");
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.email = "";
      }
    },
  },
});
