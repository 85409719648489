
import Vue from "vue";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import XLSX from "xlsx";
import { required } from "vuelidate/lib/validators";

import { Chart } from "highcharts-vue";
import NoDataRVS from "@/components/NoDataRVS.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

import "@/assets/scss/custom-datetime-picker.css";

stockInit(Highcharts);

export default Vue.extend({
  name: "RvsParameterTrendGraph",
  components: {
    highcharts: Chart,
    NoDataRVS,
    DateTimeRange,
    LoadingGraph,
  },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
    userRole(): string {
      return this.$store.getters.user.role;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  validations: {
    isSensorType: { required },
    validationGroup: ["isSensorType"],
  },
  data() {
    const date = new Date();
    const maxLength = 2;

    const sensorTypeData: [] = [];
    const isSensorType = undefined;

    const parameterGroup: any[] = [];

    return {
      maxLength,
      sensorTypeData,
      isSensorType,
      isLoadingSensorGroup: false,
      isLoadingParameterGroup: false,
      isLoadingTrendGroup: false,
      parameterGroup,
      parameterValue: undefined,
      trendGroup: [],
      trendValue: undefined,
      keyValue: [],
      startDate: dayjs(date).subtract(1, "month").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isGraphLoading: false,
      excelLoading: false,
      graphResultData: [],
      graphResultDataRaw: [],
      chartOptions: {
        title: { text: "Parameter trend" },
        subtitle: { text: "" },
        chart: {
          type: "line",
          zoomType: "xy",
          animation: false,
          height: 500,
          style: { fontSize: "14px" },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
              align: "center",
              verticalAlign: "top",
              style: { fontSize: "14px" },
              formatter: function () {
                return this.point.index === 0 &&
                  (this.series.name === "Fast average Trend" || this.series.name === "Slow average Trend")
                  ? this.series.name
                  : null;
              },
            },
          },
          series: {
            allowPointSelect: false,
            showInNavigator: true,
            animation: false,
            events: {
              legendItemClick: function () {
                setTimeout(() => {
                  const series = this.chart.legend.allItems.filter((item) => item.visible);
                  if (series.length === 1) {
                    //if visible 1 legend show average
                    const fastAverage = series[0].options.data_fast || null;
                    const slowAverage = series[0].options.data_slow || null;

                    if (fastAverage) {
                      this.chart.addSeries({
                        name: "Fast average Trend",
                        data: fastAverage,
                        color: "red",
                        showInLegend: false,
                      });
                    }
                    if (slowAverage) {
                      this.chart.addSeries({
                        name: "Slow average Trend",
                        data: slowAverage,
                        color: "yellow",
                        showInLegend: false,
                      });
                    }
                  } else {
                    //else remove average
                    const seriesToRemove = [];
                    this.chart.series.forEach(function (series) {
                      if (series.name === "Fast average Trend" || series.name === "Slow average Trend") {
                        seriesToRemove.push(series);
                      }
                    });
                    if (seriesToRemove.length > 0) {
                      seriesToRemove.forEach(function (series) {
                        series.remove();
                      });
                    }
                  }
                }, 500);
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          split: false,
          shared: false,
          style: { fontSize: "14px", padding: "0px" },
          borderWidth: 0,
          shadow: false,
          positioner: function (labelWidth) {
            const x = this.chart.plotWidth - labelWidth;
            const y = 10;
            return { x, y };
          },
          formatter: function (e) {
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
            const yAxisText = e.chart?.options?.yAxis["title"]?.text || e.chart?.options?.yAxis[0]?.title?.text || "Y";

            return `<div style='border: 1px ; padding: 5px'>
                  <p><span style='color: ${this.color};'>●</span> ${this.series.name}</span></p>
                  <p>${dayjs(this.x).format("DD/MM/YYYY HH:mm น.")}</p>
                  <p>${yAxisText}: ${formatter.format(this.y)}</p>
                  </div>`;
          },
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          itemStyle: { fontSize: "14px", fontWeight: "300" },
        },
        yAxis: {
          title: { text: "" },
          labels: { style: { fontSize: "14px" } },
          opposite: false,
          max: undefined,
          min: undefined,
        },
        xAxis: {
          categories: [],
          labels: {
            style: { fontSize: "14px" },
            formatter: function () {
              return dayjs(this.value).format("DD/MM/YYYY HH:mm น.");
            },
          },
          minRange: 1,
        },
        series: [],
        scrollbar: { liveRedraw: false },
        rangeSelector: { enabled: false, selected: 0 },
        navigator: {
          series: { type: "line", marker: { enabled: false } },
          yAxis: {},
          xAxis: {
            categories: [],
            labels: {
              style: { fontSize: "10px" },
              formatter: function () {
                return "";
              },
            },
          },
        },
        credits: { enabled: false },
      },
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
    };
  },
  async created() {
    await this.fetchSensorTypeData();
    await this.fetchFailure();
  },
  methods: {
    async fetchGraphData() {
      console.log("first");
      try {
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        const graphData = await (this as any).$dep.modelUseCase.getRvsGraphParameter({
          keyValue: this.keyValue,
          parameterValue: this.parameterGroup.find((val) => val.id === this.parameterValue).name || "",
          modelCode: this.getModelCode,
          startDate: this.startDate,
          endDate: this.endDate,
          sensorId: this.isSensorType.sensorId,
        });
        this.chartOptions.series = [];
        this.graphResultData = graphData.series;
        this.graphResultDataRaw = graphData;

        setTimeout(() => {
          this.chartOptions.series = graphData.series.map((item) => ({
            ...item,
            data: item.data,
            name: `${item.parameter_trend}(${item.axis})`,
          }));
          if (graphData.series.length === 1) {
            if (graphData.series[0].data_fast?.length > 0) {
              this.chartOptions.series.push({
                name: "Fast average Trend",
                data: graphData.series[0].data_fast,
                color: "red",
                showInLegend: false,
              });
            }
            if (graphData.series[0].data_slow?.length > 0) {
              this.chartOptions.series.push({
                name: "Slow average Trend",
                data: graphData.series[0].data_slow,
                color: "yellow",
                showInLegend: false,
              });
            }
          }
          this.chartOptions.yAxis.title.text = graphData.series?.[0]?.y_label_unit || "";
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            categories: graphData.timestamps,
          };
          this.chartOptions.navigator.xAxis = {
            ...this.chartOptions.navigator.xAxis,
            categories: graphData.timestamps,
          };
          this.xAxisMaxRange = graphData.timestamps.length;
        }, 500);
      } catch (error) {
        console.log(error, "error");

        this.graphResultData = [];
        this.graphResultDataRaw = [];
      }
    },

    async fetchSensorTypeData() {
      try {
        this.isLoadingSensorGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsSensorType(this.getModelId);

        this.sensorTypeData = datas;
      } catch (error) {
        this.sensorTypeData = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },

    async fetchFailure() {
      try {
        this.isLoadingParameterGroup = true;
        const data = await (this as any).$dep.modelUseCase.getRVSFailureMode();
        this.parameterGroup = data;
      } catch (error) {
        this.parameterGroup = [];
      } finally {
        this.isLoadingParameterGroup = false;
      }
    },

    async fetchFailureTrend(id: number) {
      try {
        this.isLoadingTrendGroup = true;
        const data = await (this as any).$dep.modelUseCase.getRVSFailureModeById(id);
        // this.trendGroup = data;
        const replacedData = data.map((item) => {
          return {
            ...item,
            // Assuming the plus minus value is stored in a property named 'value'
            name: item.name.replace("+-", "±"),
          };
        });
        this.trendGroup = replacedData;
        this.trendValue = this.trendGroup[0]["id"];
        this.keyValue = this.trendGroup[0]["key_list"];
      } catch (error) {
        this.trendGroup = [];
      } finally {
        this.isLoadingTrendGroup = false;
      }
    },

    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    onChangeSensorType(value: any) {
      this.isSensorType = {
        id: value.id,
        sensorId: value.sensorId,
        sensorName: value.sensorName,
      };
      this.isGraphLoading = true;
      this.chartOptions.series = [];
      this.graphResultData = [];
      this.isGraphLoading = false;
    },
    async onChangeParameter(parameter: any) {
      this.parameterValue = parameter.id;
      this.trendValue = undefined;
      this.chartOptions.series = [];
      this.graphResultData = [];
      await this.fetchFailureTrend(parameter.id);
      this.startDate = dayjs(this.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(this.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    async onChangeTrend(value: any) {
      this.trendValue = value.id;
      this.keyValue = value.key_list;
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    limiter(tag: any[]) {
      if (tag.length > this.maxLength) tag.shift();
    },
    formatDate(value: any): string {
      return dayjs(value).format("DD/MM/YYYY HH:mm น.");
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
    alertDialog({
      title,
      html,
      icon = "success",
    }: {
      title: string;
      html: string;
      icon: "success" | "error" | "warning" | "info" | "question";
    }) {
      this.$swal({
        title,
        html,
        icon: icon,
      }).then(() => {
        //
      });
    },
    exportExcel() {
      this.excelLoading = true;
      const name = `rvs_parameter_trend_${this.getModelId}`.toUpperCase();
      const cloneData = this.graphResultDataRaw;

      const result = cloneData.series.map((res) => {
        return {
          sname: `${res.parameter_trend} ${res.axis}`,
          data: res.data.map((item, index) => {
            return {
              timestamp: cloneData.timestamps[index]
                ? dayjs(cloneData.timestamps[index]).format("YYYY-MM-DD HH:mm:ss")
                : "-",
              [res.y_label_unit]: item,
            };
          }),
        };
      });

      const wb = XLSX.utils.book_new();
      for (let i = 0; i < result.length; i++) {
        const { sname, data } = result[i];
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, sname);
      }

      XLSX.writeFile(wb, `${name}.xlsx`);
      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },
  },
  watch: {
    async getModelId() {
      this.sensorTypeData = [];
      this.isSensorType = undefined;

      this.graphResultData = [];
      this.graphResultDataRaw = [];

      await this.fetchSensorTypeData();
    },
  },
});
