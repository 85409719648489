
import Vue from "vue";
import TableCustom from "@/components/TableCustom.vue";
import colorStatus from "@/common/mixins/colorStatus";

export default Vue.extend({
  name: "McaOverallStatus",
  mixins: [colorStatus],
  components: { TableCustom },
  computed: {
    companyId(): any {
      return this.$store.getters.company.id || 0;
    },
  },
  data() {
    let datas = {};
    let overallStatus = -1;
    const headersOverall = [
      { text: "Equipment Name", value: "equipmentName", sortable: false },
      { text: "Airgap", value: "airGrap", sortable: false },
      { text: "Bearing", value: "bearing", sortable: false },
      { text: "Rotor", value: "rotor", sortable: false },
      { text: "Stator", value: "stator", sortable: false },
      { text: "Terminal Box", value: "terminalBox", sortable: false },
    ];

    return {
      datas: [],
      headersOverall,
      overallStatus,
    };
  },
  async created() {
    this.fetchOverallModel();
  },
  methods: {
    async fetchOverallModel() {
      if (!this.companyId) {
        return;
      }
      let maxOverall = [];
      this.datas = await Vue.prototype.$dep.modelUseCase.getOverallStatusMca(this.companyId);
      this.datas = this.datas?.map((val) => {
        let tempData = {
          airGrap: val.airgapStatus,
          bearing: val.bearingStatus,
          rotor: val.rotorStatus,
          stator: val.statorStatus,
          terminalBox: val.terminalBoxStatus,
          equipmentName: val.equipment_name,
          modelId: val.model_id,
        };
        maxOverall.push(val.overAllStatus);
        return tempData;
      });
      // this.datas = [
      //   {
      //     overallStatus: res[0]?.overAllStatus,
      //     overDetails: {
      //       airGrap: res[0]?.modelDetailStatus.airgap_status,
      //       bearing: res[0]?.modelDetailStatus.bearing_status,
      //       rotor: res[0]?.modelDetailStatus.rotor_status,
      //       stator: res[0]?.modelDetailStatus.stator_status,
      //       terminalBox: res[0]?.modelDetailStatus.terminalbox_status,
      //       modelCode: res[0]?.modelDetailStatus.model_code,
      //     },
      //   },
      // ];
      this.overallStatus = Math.max(...maxOverall);
    },
    colorStatus(status: number) {
      if (status === 0) {
        return "normal";
      } else if (status === 1) {
        return "warning";
      } else if (status === 2) {
        return "alarm";
      } else if (status === -1) {
        return "offline";
      } else {
        return "disabled";
      }
    },
    statusCode(status: number) {
      if (status === 2) {
        return "#f44336";
      } else if (status === 1) {
        return "#ffc12b";
      } else if (status === 0) {
        return "#4caf50";
      } else if (status === -1) {
        return "#a5acb2";
      } else {
        return "#808080";
      }
    },
    handleOpenMenu(id: any) {
      this.$router.push({ name: "ViewModelPage", params: { id } });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    companyId() {
      this.fetchOverallModel();
    },
  },
});
