import { HTTPClient } from "@/common/HTTPClient";

export interface FolderCreateRequestParam {
  parentId?: number;
  structureItemTitle: string;
  isPrimaryFolder: boolean;
}

export interface EquipmentCreateRequestParam {
  parentId?: number;
  structureItemTitle: string;
  equipmentGroupType?: string;
  posLat?: number;
  posLng?: number;
  isPrimaryFolder: boolean;
}

export interface FolderUpdateRequestParam {
  structureItemTitle: string;
}

export interface EquipmentUpdateRequestParam {
  structureItemTitle: string;
  equipmentGroupType?: string;
  posLat: number;
  posLng: number;
}

export interface FolderUpdateAssignMembersRequestParams {
  memberIds: number[];
}

export interface CreateEquipmentImage {
  url: string;
  equipmentTagId: number;
}

export class EquipmentClient {
  constructor(private httpClient: HTTPClient) {}

  public async getFolder(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      method: "GET",
      url: `/api/v1/structure-item-company?company_id=${id}`,
    });

    return data;
  }

  public async getFolderAssignMembers(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment_model_member?equipment_tag_id=${id}&page=1&size=50`,
    });
    return data;
  }

  public async postCeateFolder(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/structure-item`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async getEquipmentTagById(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-tag/${id}`,
      method: "GET",
    });

    return data;
  }

  public async postCeateEquipment(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/equipment-tag",
      method: "POST",
      data: params,
    });

    return data;
  }

  public async pacthUpdateFolder(params: any, id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/structure-item?folder_id=${id}`,
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async pacthUpdateEquipment(params: any, id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-tag?equipment_tag_id=${id}`,
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async patchFolderAssignMembers(companyId: number, equipmentTagId: number, params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment_model_member?equipment_tag_id=${equipmentTagId}&company_id=${companyId}`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async deleteFolder(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/structure-item/${id}`,
      method: "DELETE",
    });

    return data;
  }

  public async getAllModelTypes(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types`,
      method: "GET",
    });

    return data;
  }

  public async getModelTypeConfigurationByIdType(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${id}/configuration-informations`,
      method: "GET",
    });

    return data;
  }

  public async getModelTypeConfigurationByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/configuration-informations`,
      method: "GET",
    });

    return data;
  }

  public async getModelMotorDataByIdType(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${id}/motor-datas`,
      method: "GET",
    });

    return data;
  }

  public async getModelMotorDataByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/motor-data`,
      method: "GET",
    });

    return data;
  }

  public async getModelVibrationSensorsByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/vibration-sensors`,
      method: "GET",
    });

    return data;
  }

  public async getModelQSetting(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/q-setting`,
      method: "GET",
    });

    return data;
  }

  public async getModelAlarmLimitSensorsByIdType(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${id}/alarm-limit-sensors`,
      method: "GET",
    });

    return data;
  }
  public async getModelAlarmLimitSensorsByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/alarm-limit-sensors`,
      method: "GET",
    });

    return data;
  }

  public async getModelTrendSignalAlarmLimitSensorByIdType(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${id}/trend_signal_alarm_limit_sensor`,
      method: "GET",
    });

    return data;
  }

  public async getModelTrendSignalAlarmLimitSensorByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/trend-signal-alarm-limit-sensor`,
      method: "GET",
    });

    return data;
  }

  public async getModelVsdTagAlarmLimitByIdType(modelType: number, modelId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${modelType}/vsd_tag_alarm_limit?sensor_model_id=${modelId}`,
      method: "GET",
    });

    return data;
  }
  public async getModelVsdTagAlarmLimitByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/vsd-tag-alarm-limit`,
      method: "GET",
    });
    return data;
  }

  public async getModelElectronicCardsByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/electronic-cards`,
      method: "GET",
    });

    return data;
  }

  public async getAllEquipmentTags(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/equipment-tags",
      method: "GET",
    });

    return data;
  }

  // public async getAllEquipmentTags(): Promise<any> {
  //   const { data } = await this.httpClient.request({
  //     url: "/api/v1/equipment-tag/4/models",
  //     method: "GET",
  //   });

  //   return data;
  // }

  public async getExportRecommendationMessageFile(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipments/model-types/${id}/export-recommendation-message-file`,
      method: "GET",
    });
    return data;
  }

  public async getEmailTaskSchedulerById(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/email_task_scheduler/scheduler/${id}`,
      method: "GET",
    });
    return data;
  }

  public async sendEmailTaskScheduler(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/email_task_scheduler/scheduler`,
      data: params,
      method: "POST",
    });
    return data;
  }

  public async updateEmailTaskScheduler(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/email_task_scheduler/scheduler`,
      data: params,
      method: "PUT",
    });
    return data;
  }

  public async deleteEmailTaskScheduler(jobId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/email_task_scheduler/scheduler/${jobId}`,
      method: "DELETE",
    });
    return data;
  }

  public async postEquipmentImage(params: CreateEquipmentImage): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-tag-images`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async getEquipmentImages(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-tag-images/${id}`,
      method: "GET",
    });

    return data;
  }

  public async deleteEquipmentImage(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-tag-images/${id}`,
      method: "DELETE",
    });
    return data;
  }
}
