
import Vue from "vue";
import ModelOnlineTreeListMenu from "../../model/components/ModelOnlineTreeListMenu.vue";
import ModelOfflineTreeListMenu from "../../model/components/ModelOfflineTreeListMenu.vue";
export default Vue.extend({
  components: {
    ModelOnlineTreeListMenu,
    ModelOfflineTreeListMenu,
  },
  props: {
    items: Object,
    index: Number,
    length: Number,
  },
  data() {
    return {
      folderOpen: true,
    };
  },
  computed: {
    modelItemsOnline() {
      return (this as any).items.equipmentModels.filter((data: any) => !data.is_offline);
    },
    modelItemsOffline() {
      return (this as any).items.equipmentModels.filter((data: any) => data.is_offline);
    },
    hasOnlineModels() {
      return (this as any).modelItemsOnline.length > 0;
    },
    hasOfflineModels() {
      return (this as any).modelItemsOffline.length > 0;
    },
    isFolder: function () {
      return (this as any).items.children.length || (this as any).items.equipmentModels?.length;

      // return (
      //   (this as any).items.children.length ||
      //   (this as any).items.___equipmentModels__?.length ||
      //   (this as any).items.___equipmentModelOfflines__?.length
      // );
    },
    isPrimaryFolder() {
      return (this as any).items.isPrimaryFolder;
    },
    isActive() {
      if ((this as any).currentFolderSelect == (this as any).items.id) {
        return "active";
      } else {
        return "";
      }
    },
    currentFolderSelect() {
      return (this as any).$store.getters.currentFolderClick;
    },
    arrowIcon() {
      if ((this as any).folderOpen) {
        return "fa-chevron-down";
      } else {
        return "fa-chevron-right";
      }
    },
    lastChild() {
      if (this.index === this.length) {
        return "last-child-node";
      } else {
        return "";
      }
    },
  },
  methods: {
    async openFolder() {
      if ((this as any).isFolder) {
        (this as any).folderOpen = !(this as any).folderOpen;
      }
    },
    async selectFolder(type: any, id: any) {
      this.$store.dispatch("clickFolder", (this as any).items.id);
      this.$router.push({
        name: "FolderViewPage",
        query: { folderSelect: (this as any).items.id },
      });
    },
    openContextMenu(e: any) {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      if (permission) {
        e.preventDefault();
        this.$store.dispatch("openContextMenu", {
          folderType: (this as any).items.structureItemType,
          folderId: (this as any).items.id,
          folderName: (this as any).items.structureItemTitle,
          isPrimary: (this as any).items.isPrimaryFolder,
          positionY: e.clientY,
          positionX: e.clientX,
          isShowMenu: true,
        });
      }
    },
    getColorStatus(code: any) {
      return code === 0 ? "status-normal" : code === 1 ? "status-warning" : code === 2 ? "status-danger" : "status-offline";
    },
  },
});
