
import Vue from "vue";
import permission from "@/common/mixins/permission";
import pagination from "@/common/mixins/pagination";
import dialog from "@/common/mixins/dialog";
import TableCustom from "@/components/TableCustom.vue";
import Container from "@/components/Container.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

import MemberInviteModal from "../components/MemberInviteModal.vue";
import MemberEditInviteModal from "../components/MemberEditInviteModal.vue";
import { MemberPaginationForm } from "../models/MemberPaginationForm";
import { MemberInviteForm } from "../models/MemberInviteForm";
import { MemberEditInviteForm } from "../models/MemberEditInviteForm";
import { Member } from "../models/Member";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

export default Vue.extend({
  name: "CompanyMemberPage",
  mixins: [permission, pagination, dialog],
  components: {
    MemberInviteModal,
    TableCustom,
    Container,
    LoadingGraph,
    DeleteIcon,
    MemberEditInviteModal,
  },
  data() {
    const isLoading = false;

    const memberHeaders = [
      { text: "", value: "profileImageUrl", sortable: false },
      { text: "Name", value: "name", width: "30%" },
      { text: "Email", value: "email", width: "30%" },
      { text: "Role", value: "role", align: "center" },
      { text: "Status", value: "status", align: "center" },
      { text: "", value: "action", sortable: false },
    ];

    const members: Member[] = [];

    const memberPaginationForm: MemberPaginationForm = {
      search: "",
      page: 0,
      perPage: 10,
    };

    const memberInviteForm: MemberInviteForm = {
      isShowModal: false,
      isLoading: false,
      email: "",
      role: "",
    };
    const debounce = 0;
    const rowsPerPageItems: Array<any> = [5, 10, 20, 30, 40];
    const paginations: any = {};

    const memberEditInviteForm: MemberEditInviteForm = {
      isLoading: false,
      role: "",
      email: "",
    };

    return {
      isLoading,
      memberHeaders,
      members,
      memberPaginationForm,
      memberInviteForm,
      debounce,
      paginations,
      rowsPerPageItems,
      memberEditInviteForm,
    };
  },
  async created() {
    await this.getMembers();
  },
  computed: {
    companyId() {
      return this.$route.params.id;
    },
    hasInviteMemberToCompanyPermission() {
      const role = this.$store.getters.user?.role;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;
    },
    hasRemoveMemberFromCompanyPermission() {
      const role = this.$store.getters.user?.role;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;
    },
    search: {
      get() {
        return (this as any).memberPaginationForm.search;
      },
      set(search: string) {
        (this as any).memberPaginationForm = {
          ...(this as any).memberPaginationForm,
          search,
        };
      },
    },
  },
  methods: {
    async getMembers() {
      try {
        this.isLoading = true;
        const { items } = await (this as any).$dep.memberUseCase.getMembers(this.companyId);
        this.members = items;
      } catch (error) {
        //
        this.members = [];
      } finally {
        this.isLoading = false;
      }
    },
    openMemberInviteModal() {
      this.memberInviteForm = { ...this.memberInviteForm, isShowModal: true };
    },
    closeMemberInviteModal() {
      this.memberInviteForm = {
        ...this.memberInviteForm,
        isShowModal: false,
        email: "",
        role: "",
      };
    },
    closeMemberEditInviteModal() {
      this.memberEditInviteForm = {
        ...this.memberEditInviteForm,
        isShowModal: false,
        member: undefined,
      };
    },
    async inviteMemberInviteModal(params: any) {
      try {
        this.isLoading = true;
        this.memberInviteForm = { ...params, isLoading: true };
        await (this as any).$dep.memberUseCase.inviteMember(this.companyId, this.memberInviteForm);

        this.closeMemberInviteModal();
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Invite member successfully",
          type: "success",
        });
        await this.getMembers();
      } catch (error: any) {
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Create Admin!");
      } finally {
        this.memberInviteForm = { ...params, isLoading: false };
        this.isLoading = false;
      }
    },
    async editMemberEditInviteModal(params: any) {
      try {
        this.isLoading = true;
        this.memberEditInviteForm = {
          ...params,
          isLoading: true,
        };

        await (this as any).$dep.memberUseCase.updateMemberProfile(this.memberEditInviteForm);

        this.closeMemberEditInviteModal();
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Edit member successfully",
          type: "success",
        });
        await this.getMembers();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: edit member failed",
          type: "error",
        });
      } finally {
        this.isLoading = false;
        this.memberEditInviteForm = {
          ...this.memberEditInviteForm,
          isLoading: false,
        };
      }
    },
    async resendInviteMember(email: string) {
      try {
        await (this as any).$dep.memberUseCase.resendInviteMember(email);
        await (this as any).alertSuccess("Success", `resend invite completed`);
      } catch (error: any) {
        //
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Resend Invite Member!");
      }
    },
    updatePagination(val: any) {
      const page = val.page;
      const perPage = val.itemsPerPage;

      (this as any).memberPaginationForm = {
        ...(this as any).memberPaginationForm,
        page,
        perPage,
      };
    },
    async updateMember(memberId: number) {
      const member = this.members.find((e) => e.id === memberId);

      this.memberEditInviteForm = {
        ...this.memberEditInviteForm,
        isShowModal: true,
        member,
        role: member?.role || "",
      };
    },
    async restoreMember(data: any) {
      const { name, email } = data;
      const isComfirmed = await (this as any).alertBasic(
        name,
        `<p>Are you sure you want to active this member?</p>`,
        "question",
        "Confirm"
      );

      if (isComfirmed) {
        try {
          this.isLoading = true;
          this.members = [];

          await (this as any).$dep.memberUseCase.activeMember(email);
          await (this as any).alertSuccess("Success", `"${email}" has been active`);
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Active Member!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;

          await this.getMembers();
        }
      }
    },
    async deleteMember(email: string) {
      const isConfirmed = await (this as any).alertConfirm("ลบ Member", `คุณต้องการลบ ${email} ใช่หรือไม่ ?`);
      if (isConfirmed) {
        try {
          this.isLoading = true;
          this.members = [];

          await (this as any).$dep.memberUseCase.deleteMember(email);
          await (this as any).alertSuccess("ลบ Member สำเร็จ", ``);
        } catch (error) {
          process.env.NODE_ENV === "production"
            ? (this as any).alertError("Error", "ไม่สามารถลบ Member ได้")
            : (this as any).alertError("Error", error);
        } finally {
          this.isLoading = false;
          await this.getMembers();
        }
      }
    },
    isOnPermission(status: string, type: string) {
      if (type === "ACTIVE") {
        return ["inactive"].includes(status);
      }
      if (type === "DELETE") {
        return ["active", "pending"].includes(status);
      }
      if (type === "RESEND_INVITE") {
        return ["pending"].includes(status);
      }
      if (type === "SWITCH_ROLE") {
        return ["active"].includes(status);
      }
      if (type === "EDIT_ROLE") {
        return ["active"].includes(status);
      }

      return false;
    },
    async memberSwitchRole(data: any) {
      const { id, email, role } = data;
      const isConfirmed = await (this as any).alertConfirm(
        "Change Role",
        `คุณต้องการเปลี่ยน Role เป็น ${role === "member" ? "Owner" : "Member"} ใช่หรือไม่ ?`
      );
      if (isConfirmed) {
        try {
          this.isLoading = true;
          this.members = [];

          await (this as any).$dep.memberUseCase.memberSwitchRole(id);
          await (this as any).alertSuccess("Success", `"${email}" has been switch role!`);
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Switch Role!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;
          await this.getMembers();
        }
      }
    },
    async onOpenEditMemberModal(memberId: number) {
      await this.updateMember(memberId);
      (this as any).$store.dispatch("openEditMemberModal", {
        open: true,
        type: "CREATE",
        param: null,
      });
    },
  },
  watch: {
    async memberPaginationForm() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getMembers();
      }, 800);
    },
  },
});
