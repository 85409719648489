import { RouteConfig } from "vue-router";

import AuthLoginPage from "./pages/AuthLoginPage.vue";
import AuthLoginRedirectPage from "./pages/AuthLoginRedirectPage.vue";
import AuthLogoutRedirectPage from "./pages/AuthLogoutRedirectPage.vue";

export const AuthRouter: (prefix?: string) => RouteConfig[] = () => [
  {
    name: "AuthLoginPage",
    path: "login",
    component: AuthLoginPage,
  },
  {
    name: "AuthLoginRedirectPage",
    path: "login/redirect",
    component: AuthLoginRedirectPage,
  },
  {
    name: "AuthLogoutRedirectPage",
    path: "logout/redirect",
    component: AuthLogoutRedirectPage,
  },
];
