
import Vue from "vue";

import Card from "@/components/Card.vue";
import DiagramInformation from "../details/diagrams/DiagramInformation.vue";
import GraphTabGroup from "../graph/GraphTabGroup.vue";
import ConfigurationInformationSide from "../details/ConfigurationInformationSide.vue";
import FaultEventAlarm from "../details//faultEventAlarm/FaultEventAlarm.vue";
import NoteHistoryLog from "../details/NoteHistoryLog.vue";

export default Vue.extend({
  name: "McaDetail",
  components: {
    Card,
    // GCooling,
    DiagramInformation,
    GraphTabGroup,
    ConfigurationInformationSide,
    FaultEventAlarm,
    NoteHistoryLog,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
});
