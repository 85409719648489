
import Vue from "vue";

import TrAnalysisPdGraph from "./TrAnalysisPdGraph.vue";
import TrAnalysisDgaGraph from "./TrAnalysisDgaGraph.vue";
import TrAnalysisLocusGraph from "./TrAnalysisLocusGraph.vue";
import TrAnalysisLossesGraph from "./TrAnalysisLossesGraph.vue";

export default Vue.extend({
  name: "TrAdvanceAnalysisGraph",
  components: { TrAnalysisPdGraph, TrAnalysisDgaGraph, TrAnalysisLocusGraph, TrAnalysisLossesGraph },
  computed: {
    secondary() {
      return this.$store.state.graphInformationTab.is_secondary_tab;
    },
  },
});
