
import Vue from "vue";

export default Vue.extend({
  name: "CheckBoxIcon",
  props: {
    color: {
      type: String,
      default: "currentColor",
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  },
});
