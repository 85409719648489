
import Vue from "vue";

import FormConfigurationInformation from "../forms/FormConfigurationInformation.vue";
import FormMotorDataTable from "../forms/FormMotorDataTable.vue";
import FormRecommendation from "../forms/FormRecommendation.vue";
import FormVsdTagAlarmLimit from "../forms/FormVsdTagAlarmLimit.vue";
import FormVsdAlarmLimitSensor from "../forms/FormAlarmLimitSensorVsd.vue";
import FormVsdAlarmLimit from "../forms/FormVsdAlarmLimit.vue";
import FormAlarmManagement from "../forms/FormAlarmManagement.vue";

export default Vue.extend({
  name: "VcaForm",
  components: {
    FormConfigurationInformation,
    FormRecommendation,
    FormMotorDataTable,
    FormVsdTagAlarmLimit,
    FormVsdAlarmLimitSensor,
    FormVsdAlarmLimit,
    FormAlarmManagement,
  },
});
