
import Vue from "vue";
import { required, email } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "AdminFormModal",
  validations: {
    fullname: { required },
    email: { required, email },
    image: { required },
    validationGroup: ["fullname", "email", "image"],
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openAdminFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openAdminFormModal", {
          ...this.$store.state.openAdminFormModal,
          open: value,
        });
      },
    },
    initailForm() {
      return this.$store.state.openAdminFormModal.param;
    },
    actionType() {
      return this.$store.state.openAdminFormModal.type;
    },
  },
  data() {
    return {
      fullname: "",
      email: "",
      image: "",
      statusUser: false,
    };
  },
  methods: {
    onChangeImage() {
      (this as any).$refs.file.click();
    },
    async onChange(e: any) {
      try {
        const file = e.target.files[0];
        const imageType = e.target.files[0].type;

        if (!["image/jpeg", "image/jpg", "image/png"].includes(imageType)) {
          return alert("Please upload file with type: jpeg, jpg, png");
        }

        let formdata = new FormData();
        formdata.append("image_file", file, `image_profile_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}`);

        const { url } = await (this as any).$dep.memberUseCase.uploadProfileImage(formdata);
        this.image = url;
      } catch (error) {
        //
      }
    },
    async onSubmit() {
      this.$emit("onSubmit", {
        fullname: this.fullname,
        email: this.email,
        profileImageUrl: this.image,
        status: this.statusUser,
      });

      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.fullname = this.initailForm?.name ? this.initailForm?.name : "";
        this.email = this.initailForm?.email ? this.initailForm?.email : "";
        this.image = this.initailForm?.profileImageUrl ? this.initailForm?.profileImageUrl : "";
        this.statusUser = this.initailForm?.status === "active" ? true : false;
      }
    },
  },
});
