
import Vue from "vue";

import colorStatus from "@/common/mixins/colorStatus";

export default Vue.extend({
  name: "FormAlarmManagement",
  mixins: [colorStatus],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    alarmManagement() {
      return this.$store.state.updateModelForm.alarm_management;
    },
  },
  methods: {
    onUpdateState() {
      const alarm = this.alarmManagement;
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        alarm_management: {
          ...alarm,
          persistence_from: +alarm.persistence_from,
          persistence_to: +alarm.persistence_to,
        },
      });
    },
  },
});
