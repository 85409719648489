
import Vue from "vue";

export default Vue.extend({
  name: "FolderOpenIcon",
  props: {
    color: {
      type: String,
      default: "currentColor",
    },
    width: {
      type: String,
      default: "16",
    },
    height: {
      type: String,
      default: "16",
    },
  },
});
