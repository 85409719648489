
import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "DateTimeRange2",
  props: ["disabled"],
  data() {
    return {
      item: {
        name: "Today",
        type: "DAILY",
        startDate: dayjs().format("YYYY-MM-DDT00:00:00"),
        endDate: dayjs().format("YYYY-MM-DDT23:59:59"),
      },
      items: [
        {
          name: "Today",
          type: "DAILY",
          startDate: dayjs().format("YYYY-MM-DDT00:00:00"),
          endDate: dayjs().format("YYYY-MM-DDT23:59:59"),
        },
        {
          name: "Weekly",
          type: "WEEKLY",
          startDate: dayjs().startOf("week").add(1, "day").format("YYYY-MM-DDT00:00:00"),
          endDate: dayjs().endOf("week").add(1, "day").format("YYYY-MM-DDT23:59:59"),
        },
        {
          name: "Monthly",
          type: "MONTHLY",
          startDate: dayjs().startOf("month").format("YYYY-MM-DDT00:00:00"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DDT23:59:59"),
        },
        {
          name: "Yearly",
          type: "YEARLY",
          startDate: dayjs().startOf("year").format("YYYY-MM-DDT00:00:00"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DDT23:59:59"),
        },
      ],
    };
  },
  methods: {
    changeDate(ev: any) {
      this.$emit("submit", ev);
    },
    setLimitDate() {
      console.log("setLimitDate");
    },
    clearLimitDate() {
      console.log("clearLimitDate");
    },
  },
});
