
import Vue from "vue";
import { mapActions } from "vuex";

import UploadArrowIcon from "@/components/icons/UploadArrowIcon.vue";
import dialogMixin from "@/common/mixins/dialogModal";

export default Vue.extend({
  name: "ImageInformation",
  mixins: [dialogMixin],
  components: { UploadArrowIcon },
  computed: {
    modelParamId() {
      return this.$route.params.id || "";
    },
    images() {
      return this.$store.state?.modelImageInformation || [];
    },
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    return {
      imageIsFullScreen: false,
      imageUrl: "",
    };
  },
  async created() {
    await this.getModelImageInformation(this.model.id);
  },
  methods: {
    ...mapActions(["getModelImageInformation"]),
    onChange() {
      (this as any).$refs.file.click();
    },
    onChangeClick() {
      const file = (this as any).$refs.file.files[0];
      this.onAddImage(file);
    },
    onChangeDrag() {
      const file = (this as any).$refs.file.files[0];
      this.onAddImage(file);
    },
    async onAddImage(file: any) {
      try {
        const { id } = this.model;
        const imageType = file.type;

        if (!["image/jpeg", "image/jpg", "image/png"].includes(imageType)) {
          return alert("Please upload file with type: jpeg, jpg, png");
        }

        let formdata = new FormData();
        formdata.append("image_file", file, `model_${id}_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}.png`);

        const { url } = await (this as any).$dep.modelUseCase.uploadImage(formdata);

        await (this as any).$dep.modelUseCase.saveModelImage({
          url,
          equipmentModelId: id,
        });
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Add image Success.",
          type: "success",
        });
        await this.getModelImageInformation(id);
      } catch (error) {
        //
      }
    },
    async onDeleteImage(idImage: number) {
      try {
        const is = await (this as any).dialogDelete("Do you want to delete this image?");

        if (is) {
          const { id } = this.model;
          await (this as any).$dep.modelUseCase.deleteModelImage(idImage);
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: "Delete image Success.",
            type: "success",
          });
          await this.getModelImageInformation(id);
        }
      } catch (error) {
        //
      }
    },
    onDrop(event: any) {
      event.preventDefault();

      (this as any).$refs.file.files = event.dataTransfer.files;
      this.onChangeDrag(event);

      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    dragover(event: any) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-[#EAEEF6]")) {
        event.currentTarget.classList.remove("bg-[#FFFFFF]");
        event.currentTarget.classList.add("bg-[#EAEEF6]");
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    fullscreen(src: string) {
      if (src) {
        this.imageIsFullScreen = true;
        this.imageUrl = src;
      }
    },
  },
  watch: {
    async modelParamId() {
      await this.getModelImageInformation(this.modelParamId);
    },
  },
});
