
import Vue from "vue";

import FormInformationRvs from "../forms/FormInformationRVS.vue";
import FormRawVibrationSensor from "../forms/FormRawVibrationSensor.vue";
import FormDiagramRvs from "../forms/FormDiagramRVS.vue";
import FormAlarmManagement from "../forms/FormAlarmManagement.vue";
// import FormRecommendation from "../forms/FormRecommendation.vue";

export default Vue.extend({
  name: "RvsForm",
  components: {
    FormInformationRvs,
    FormRawVibrationSensor,
    FormDiagramRvs,
    FormAlarmManagement,
    // FormRecommendation,
  },
});
