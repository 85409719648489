
import Vue from "vue";

import DiagramTransformer from "./DiagramTransformer.vue";
import ImageInformation from "./ImageInformation.vue";
import DocumentInformation from "./DocumentInformation.vue";
import DiagramMca from "./DiagramMCA.vue";
import DiagramEvs from "./DiagramEvs.vue";
import DiagramVca from "./DiagramVca.vue";
import DiagramRvs from "./DiagramRvs.vue";

export default Vue.extend({
  name: "DiagramInformation",
  components: { DiagramTransformer, ImageInformation, DocumentInformation, DiagramMca, DiagramEvs, DiagramVca, DiagramRvs },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tab: null,
    };
  },
  computed: {
    modelType() {
      return this.$store.getters.model.modelTypeId;
    },
    currentTabComponent() {
      let modelName = "";
      switch (this.modelType) {
        case 1:
          modelName = "diagram-transformer";
          break;
        case 2:
          modelName = "diagram-mca";
          break;
        case 3:
          modelName = "diagram-vca";
          break;
        case 4:
          modelName = "diagram-evs";
          break;
        case 5:
          modelName = "diagram-rvs";
          break;
        default:
          break;
      }
      switch (this.tab) {
        case 0:
          return modelName;

        case 1:
          return "image-information";

        case 2:
          return "document-information";

        default:
          return "";
      }
    },
  },
});
