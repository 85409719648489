
import Vue from "vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import Card from "@/components/Card.vue";
import Container from "@/components/Container.vue";
import LoadingRedirectPage from "@/components/LoadingRedirectPage.vue";
import FormHeader from "@/features/model/components/forms/FormHeader.vue";
import TransformerForm from "@/features/model/components/transformer/TransformerForm.vue";
import McaForm from "@/features/model/components/mca/McaForm.vue";
import VcaForm from "@/features/model/components/vca/VcaForm.vue";
import EvsForm from "@/features/model/components/evs/EvsForm.vue";
import RvsForm from "@/features/model/components/rvs/RvsForm.vue";

export default Vue.extend({
  name: "EditModelFormPage",
  components: {
    Container,
    Card,
    FormHeader,
    TransformerForm,
    LoadingRedirectPage,
    McaForm,
    VcaForm,
    EvsForm,
    RvsForm,
  },
  validations: {
    modelName: {
      required,
    },
  },
  computed: {
    modelParamId() {
      return this.$route.params.id || "";
    },
    model: {
      get(): Record<any, any> {
        return this.$store.getters.model;
      },
    },
    modelForm: {
      get() {
        return this.$store.state.updateModelForm;
      },
    },
    fileRecommendation: {
      get() {
        return this.$store.state.uploadFileRecommendationForm;
      },
    },
    isActive: {
      get(): boolean {
        return this.$store.getters.model.isActive;
      },
      set(value: boolean) {
        this.$store.dispatch("setUpdateModelForm", {
          ...this.$store.state.updateModelForm,
          model_in: {
            ...this.$store.state.updateModelForm.model_in,
            isActive: value ? "active" : "inactive",
          },
        });
      },
    },
  },
  data() {
    return { isLoading: true, isLoadingBtn: false };
  },
  async created() {
    await this.fetchModel();
  },
  methods: {
    ...mapActions(["getModel", "getStructure", "getModelSettingForm", "getUpdateModelDataForm"]),
    async fetchModel() {
      try {
        await this.getModel(this.modelParamId);
        await this.getModelSettingForm();
        await this.getUpdateModelDataForm();
      } catch (error) {
        //
      } finally {
        this.isLoading = false;
      }
    },
    async updateModel() {
      try {
        this.isLoadingBtn = true;
        if (this.fileRecommendation?.formdata) {
          await (this as any).$dep.modelUseCase.uploadFileRecommendation(
            this.model.id,
            this.model.modelCode,
            this.fileRecommendation.formdata
          );
        }
        if (this.model.modelTypeId === 5) {
          const resVal = this.validationRvs();
          if (resVal === 2) return;
        }

        await (this as any).$dep.modelUseCase.updateModel(this.model.id, {
          ...this.modelForm,
          alarm_limit_sensor_new_in: !this.modelForm.alarm_limit_sensor_new_in
            ? ""
            : this.modelForm.alarm_limit_sensor_new_in.map((item) => ({
                ...item,
                isSelect: !item.isSelect ? false : true,
              })),
        });
        this.$router.push({ name: "ViewModelPage", params: { id: this.model.id } });
        await this.getStructure();
      } catch (error) {
        return this.alertDialog({
          title: "Error",
          html: `<p>${error?.response?.data?.detail || "error Information cannot be changed."}</p>`,
          icon: "error",
        });
      } finally {
        this.isLoading = false;
        this.isLoadingBtn = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      await this.updateModel();
    },
    async onCancel() {
      await this.getUpdateModelDataForm();
      this.$router.push({ name: "ViewModelPage", params: { id: this.model.id } });
    },
    alertDialog({
      title,
      html,
      icon = "success",
    }: {
      title: string;
      html: string;
      icon: "success" | "error" | "warning" | "info" | "question";
    }) {
      this.$swal({
        title,
        html,
        icon: icon,
      }).then(() => {
        //
      });
    },
    validationRvs() {
      let status = 1;

      for (const item of this.$store.state.updateModelForm.raw_vibration_sensor_in) {
        const uniquePaxisValues: string[] = [];
        const acceleration = item.acceleration;

        for (const axis in acceleration) {
          if (Object.prototype.hasOwnProperty.call(acceleration, axis)) {
            const paxisValue = acceleration[axis].paxis;

            if (uniquePaxisValues.includes(paxisValue)) {
              this.alertDialog({
                title: "Error",
                html: `<p>Sensor Location: ${item.sensorName} has duplicate axis</p>`,
                icon: "error",
              });
              status = 2;
              return status;
            }

            uniquePaxisValues.push(paxisValue);
          }
        }
      }

      return status;
    },
  },
  watch: {
    async modelParamId() {
      this.isLoading = true;
      await this.fetchModel();
    },
  },
});
