import { render, staticRenderFns } from "./FolderOpenIcon.vue?vue&type=template&id=afd0bcc6&"
import script from "./FolderOpenIcon.vue?vue&type=script&lang=ts&"
export * from "./FolderOpenIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports