
import Vue from "vue";
import { mapActions } from "vuex";

import MenuTreeView from "./MenuTreeView.vue";

export default Vue.extend({
  name: "Sidebar",
  components: { MenuTreeView },
  computed: {
    isSidebar: {
      get() {
        return this.$store.state.app.isSidebar;
      },
      set(value: boolean) {
        this.$store.dispatch("setApp", {
          ...this.$store.state.app,
          isSidebar: value,
        });
      },
    },
    company() {
      return this.$store.getters.company;
    },
    menuItems() {
      return this.$store.getters.structure || [];
    },
    remainingDay() {
      const dt = new Date(this.company.end_date);
      const dateToday = new Date();
      const diffDate = dt.getTime() - dateToday.getTime();
      const dfDays = diffDate / (1000 * 60 * 60 * 24);

      if (dateToday < dt) {
        return `Remaining day: ${dfDays.toFixed(0)} days left`;
      } else {
        return `-`;
      }
    },
  },
  data() {
    return { width: 300, borderSize: 5, isLoading: true };
  },
  async created() {
    try {
      this.isLoading = true;
      const companyId = sessionStorage.getItem("company_id");
      if (companyId) {
        await this.$store.dispatch("getCompany");
        await this.$store.dispatch("getStructure");
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions(["getStructure", "getCompany"]),
    setBorderWidth() {
      const i = (this as any).$refs.drawer.$el.querySelector(".v-navigation-drawer__border");
      i.style.width = this.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const minSize = this.borderSize;
      const vm = this;
      const el = (this as any).$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");

      const minMax = (w: number): number => (w < 300 ? 300 : w > 480 ? 480 : w);

      function resize(e: any) {
        const { clientX } = e;
        document.body.style.cursor = "ew-resize";
        el.style.width = minMax(clientX) + "px";
      }

      drawerBorder.addEventListener("mousedown", function (e: any) {
        if (e.offsetX < minSize) {
          document.addEventListener("mousemove", resize, false);
        }
      });

      document.addEventListener(
        "mouseup",
        function () {
          const size = Number(el.style.width.replace("px", "")) || 0;
          el.style.transition = "";
          vm.width = minMax(size);
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
    convertExpiredDate(date: any) {
      if (!date) return "-";
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dt = new Date(date);
      const day = `${dt.getDate()}`.padStart(2, "0");
      const month = `${monthNames[dt.getMonth()]}`;
      const year = `${dt.getFullYear()}`;
      return `${day} ${month} ${year}`;
    },
    checkDateExpired(date: any) {
      if (!date) return false;
      const dt = new Date(date);
      const dateToday = new Date();
      const diffDate = dt.getTime() - dateToday.getTime();
      const dfDays = diffDate / (1000 * 60 * 60 * 24);

      if (dfDays <= 30) return true;
      return false;
    },
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();
  },
  watch: {
    isSidebar(val) {
      if (val) {
        this.width = 300;
      }
    },
  },
});
