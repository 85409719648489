
import Vue from "vue";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  components: { Spinner },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    modelsStatusAll: {
      type: Object,
    },
  },
});
