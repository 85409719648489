
import Vue from "vue";

import Card from "@/components/Card.vue";
import DiagramInformation from "../details/diagrams/DiagramInformation.vue";
import GraphTabGroup from "../graph/GraphTabGroup.vue";
import ConfigurationInformationSide from "../details/ConfigurationInformationSide.vue";
import FaultEventAlarm from "../details//faultEventAlarm/FaultEventAlarm.vue";
import NoteHistoryLog from "../details/NoteHistoryLog.vue";
import VcaPercentOfLoad from "../details/summaryStatus/VcaPercentOfLoad.vue";
import VcaSummaryStatus from "../details/summaryStatus/VcaSummaryStatus.vue";

export default Vue.extend({
  name: "VcaDetail",
  components: {
    Card,
    DiagramInformation,
    GraphTabGroup,
    ConfigurationInformationSide,
    FaultEventAlarm,
    NoteHistoryLog,
    VcaPercentOfLoad,
    VcaSummaryStatus,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
});
