import { Client } from "@/clients/client";
import { CancelToken } from "axios";
import { EquipmentModelType } from "./models/EquipmentModelType";
import {
  EquipmentModelFormType,
  ModelAlarmLimitGroupInType,
  ModelHealthMonitoringSensorInType,
  ModelVAndILocusInType,
} from "./models/EquipmentModelFormType";
import { DateRangeProps } from "@/interface/dateRange";
export class ModelUseCase {
  constructor(private client: Client) {}

  public async getModel(modelId: number): Promise<EquipmentModelType> {
    const { data } = await this.client.equipmentModelClient.getModel(modelId);
    return data;
  }
  public async createModel(form: EquipmentModelFormType): Promise<void> {
    const { data } = await this.client.equipmentModelClient.createModel(form);
    return data;
  }
  public async updateModel(modelId: number, form: EquipmentModelFormType): Promise<void> {
    await this.client.equipmentModelClient.updateModel(modelId, form);
  }
  public async uploadFileRecommendation(id: number, modelCode: string, form: FormData): Promise<any> {
    return await this.client.modelClient.modelUploadFileRecommendation(id, modelCode, form);
  }
  public async getDiagramRvs(modelId: number): Promise<any> {
    return await this.client.modelClient.getDiagramRvs(modelId);
  }
  public async modelUploadDiagramRvs(form: FormData): Promise<any> {
    return await this.client.modelClient.modelUploadDiagramRvs(form);
  }
  public downloadFileRecommendation(modelTypeId: number, modelCode: string): string {
    return `${process.env.VUE_APP_API_BASE_URL}/api/v1/equipment-models/${modelTypeId}/export?modelCode=${modelCode}`;
  }
  public async getModelVandILocus(modelId: number): Promise<ModelVAndILocusInType[]> {
    const { data } = await this.client.equipmentModelClient.getModelVandILocus(modelId);
    return data;
  }
  public async getAlarmLimitGroupMaster(modelTypeId: number): Promise<ModelAlarmLimitGroupInType[]> {
    const { data } = await this.client.equipmentModelClient.getAlarmLimitGroupMaster(modelTypeId);
    return data;
  }
  public async getAlarmLimitGroup(alarmLimitGroupId?: number): Promise<ModelAlarmLimitGroupInType[]> {
    const { data } = await this.client.equipmentModelClient.getAlarmLimitGroup(alarmLimitGroupId);
    return data;
  }
  public async getModelHealthMonitoringSensorType(
    healthMonitoringId: number,
    modelId?: number
  ): Promise<ModelHealthMonitoringSensorInType[]> {
    const { data } = await this.client.equipmentModelClient.getModelHealthMonitoringSensorType(healthMonitoringId, modelId);
    return data;
  }
  public async getModelConfigurationInformationForm(modelTypeId: number): Promise<{ id: number; title: string }[]> {
    const { data } = await this.client.equipmentModelClient.getModelConfigurationInformationForm(modelTypeId);
    return data;
  }
  public async getAlarmLimitSensorMasterForm(modelTypeId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getAlarmLimitSensorMasterForm(modelTypeId);
    return data;
  }
  public async getVsdAlarmLimitSensorMasterForm(modelTypeId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVsdAlarmLimitSensorMasterForm(modelTypeId);
    return data;
  }
  public async getVsdTrendSignalAlarmLimitSensorMasterForm(): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVsdTrendSignalAlarmLimitSensorMasterForm();
    return data;
  }
  public async getAlarmLimitSensorNewForm(modelId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getAlarmLimitSensorNewForm(modelId);
    return data;
  }
  public async getVsdAlarmLimitSensorNewForm(modelId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVsdAlarmLimitSensorNewForm(modelId);
    return data;
  }
  public async getTrSummaryStatus(modelId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrSummaryStatus(modelId);
    return data;
  }
  public async getTrHealMonitoringResultData(params: Record<any, any>, cancelToken: CancelToken): Promise<Record<any, any>> {
    const { modelId, modelCode, sensorId, startDate, endDate, isNormal, equipmentId } = params;
    const { data } = await this.client.equipmentModelClient.getTrHealMonitoringResultData(
      modelId,
      modelCode,
      sensorId,
      startDate,
      endDate,
      isNormal,
      equipmentId,
      cancelToken
    );
    return data;
  }
  public async getNoteHistoryLogAll(
    modelId: number,
    equipmentId: number,
    stateDate: string,
    endDate: string
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getNoteHistoryLogAll(modelId, equipmentId, stateDate, endDate);
    return data;
  }
  public async getNoteHistoryLogComment(noteId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getNoteHistoryLogComment(noteId);
    return data;
  }
  public async createNoteHistoryLog(
    equipmentId: number,
    params: {
      topic: string;
      description: string;
      equipmentModelId: number;
    }
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.createNoteHistoryLog(equipmentId, params);
    return data;
  }
  public async updateNoteHistoryLog(
    noteId: number,
    params: {
      topic: string;
      description: string;
      equipmentModelId: number;
    }
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.updateNoteHistoryLog(noteId, params);
    return data;
  }
  public async deleteNoteHistoryLog(noteId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.deleteNoteHistoryLog(noteId);
    return data;
  }
  public async sendMessageNoteHistoryLog(
    equipmentId: number,
    params: {
      description: string;
      equipmentModelId: number;
      noteTopicId: number;
    }
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.sendMessageNoteHistoryLog(equipmentId, params);
    return data;
  }
  public async updateMessageNoteHistoryLog(
    commentId: number,
    params: {
      description: string;
      equipmentModelId: number;
      noteTopicId: number;
    }
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.updateMessageNoteHistoryLog(commentId, params);
    return data;
  }
  public async deleteMessageNoteHistoryLog(commentId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.deleteMessageNoteHistoryLog(commentId);
    return data;
  }
  public async getProcessViLocusDataTr(params: any): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getProcessViLocusDataTr(params);
    return data;
  }
  public async getCountFaultEventAlarm(modelId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getCountFaultEventAlarm(modelId);
    return data;
  }
  public async getTrPdFilename(modelCode: string, subModuleType: string): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrPdFilename(modelCode, subModuleType);
    return data;
  }
  public async getTrPdResultData(equipmentId: number, modelCode: string, fileName: string): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrPdResultData(equipmentId, modelCode, fileName);
    return data;
  }
  public async getTrLossesResultData(
    modelId: number,
    modelCode: string,
    equipmentId: number,
    startDate: string,
    endDate: string
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrLossesResultData(
      modelId,
      modelCode,
      equipmentId,
      startDate,
      endDate
    );
    return data;
  }
  public async getTrDgaCalData(
    modelCode: string,
    modelId: number,
    sennsorName: string,
    datetime: string,
    equipmentId: number
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrDgaCalData(
      modelCode,
      modelId,
      sennsorName,
      datetime,
      equipmentId
    );
    return data;
  }
  public async getTrDgaLabelName(): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrDgaLabelName();
    return data;
  }
  public async getTrDgaRawData(modelId: number, sennsorName: string, equipmentId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrDgaRawData(modelId, sennsorName, equipmentId);
    return data;
  }
  public async getTrVandLocus(modelId: number, equipmentId: number): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrVandLocus(modelId, equipmentId);
    return data;
  }
  public async getTrVandLocusFilename(modelCode: string, sensorType: string): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrVandLocusFilename(modelCode, sensorType);
    return data;
  }
  public async getTrVandLocusFilenameDefault(
    modelId: number,
    equipmentId: number,
    locusName: string
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrVandLocusFilenameDefault(modelId, equipmentId, locusName);
    return data;
  }
  public async getTrVandLocusResultData(
    sensorType: string,
    equipmentId: number,
    modelCode: string,
    fileNamePresent: string,
    filenameLast: string
  ): Promise<Record<any, any>> {
    const { data } = await this.client.equipmentModelClient.getTrVandLocusResultData(
      sensorType,
      equipmentId,
      modelCode,
      fileNamePresent.slice(0, -4),
      filenameLast.slice(0, -4)
    );
    return data;
  }
  // ##############################
  public async getModelQSetting(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelQSetting(id);
  }
  public async getSensorTagSetting(modelType: number): Promise<any> {
    return await this.client.modelClient.getSensorTagSetting(modelType);
  }
  public async getSensorTagCorrelation(modelType: number): Promise<any> {
    return await this.client.modelClient.getSensorTagCorrelation(modelType);
  }
  // ##############################
  public async getModelById(id: number): Promise<any> {
    const { data } = await this.client.modelClient.getModelById(id);
    return data;
  }

  public async getModelOfflineById(id: number): Promise<any> {
    return await this.client.modelClient.getModelOfflineById(id);
  }

  public async getModelConfigurations(id: number): Promise<any> {
    return await this.client.modelClient.getModelConfigurations(id);
  }

  public async updateModelConfiguration(id: number, form: any): Promise<any> {
    return await this.client.modelClient.updateModelConfiguration(id, {
      value: form.value,
    });
  }

  public async uploadImage(form: FormData): Promise<any> {
    return await this.client.modelClient.uploadImage(form);
  }

  public async getModelImages(id: number): Promise<any> {
    return await this.client.modelClient.getModelImages(id);
  }

  public async saveModelImage(form: any): Promise<any> {
    return await this.client.modelClient.postModelImage(form);
  }

  public async deleteModelImage(id: number): Promise<any> {
    return await this.client.modelClient.deleteModelImage(id);
  }

  public async getEquipmentImages(id: number): Promise<any> {
    return await this.client.equipmentClient.getEquipmentImages(id);
  }

  public async saveEquipmentImage(form: any): Promise<any> {
    return await this.client.equipmentClient.postEquipmentImage(form);
  }

  public async deleteEquipmentImage(id: number): Promise<any> {
    return await this.client.equipmentClient.deleteEquipmentImage(id);
  }

  public async getAllModelTypes(): Promise<any> {
    return this.client.equipmentClient.getAllModelTypes();
  }

  public async getModelTypeConfiguration(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelTypeConfigurationByIdType(id);
  }

  public async getModelTypeConfigurationByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelTypeConfigurationByIdModel(id);
  }

  public async getModelMotorDataByIdType(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelMotorDataByIdType(id);
  }

  public async getModelMotorDataByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelMotorDataByIdModel(id);
  }

  public async getModelAlarmLimitSensors(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelAlarmLimitSensorsByIdType(id);
  }

  public async getModelAlarmLimitSensorsByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelAlarmLimitSensorsByIdModel(id);
  }

  public async getModelTrendSignalAlarmLimitSensorByIdType(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelTrendSignalAlarmLimitSensorByIdType(id);
  }

  public async getModelTrendSignalAlarmLimitSensorByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelTrendSignalAlarmLimitSensorByIdModel(id);
  }

  public async getModelVsdTagAlarmLimitByIdType(modelType: number, modelId: number): Promise<any> {
    return await this.client.equipmentClient.getModelVsdTagAlarmLimitByIdType(modelType, modelId);
  }

  public async getModelVsdTagAlarmLimitByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelVsdTagAlarmLimitByIdModel(id);
  }

  public async getModelElectronicCardsByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelElectronicCardsByIdModel(id);
  }

  public async getModelVibrationSensorsByIdModel(id: number): Promise<any> {
    return await this.client.equipmentClient.getModelVibrationSensorsByIdModel(id);
  }

  public async getEquipmentCodes(): Promise<any> {
    return await this.client.modelClient.getEquipmentCodes();
  }

  public async createOfflineModel(form: any): Promise<void> {
    return this.client.modelClient.postCeateOfflineModel({
      equipmentTagId: form.equipment_tag_id,
      title: form.title,
      isActive: form.isActive,
      linkUrl: form.linkUrl ? form.linkUrl : "",
    });
  }

  public async updateModelOffline(id: number, form: any): Promise<void> {
    return await this.client.modelClient.pactchUpdateModelOffline(id, {
      title: form.title,
      isActive: form.isActive,
      linkUrl: form.linkUrl ? form.linkUrl : "",
    });
  }

  public async deleteModel(id: number): Promise<any> {
    return await this.client.modelClient.deleteModel(id);
  }

  public async deleteModelOffline(id: number): Promise<any> {
    return await this.client.modelClient.deleteModelOffline(id);
  }

  public async getEquipmentModelDataResult(
    equipmentCode: string,
    groupCode: string,
    dateRange: DateRangeProps
  ): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResult(equipmentCode, groupCode, {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  }

  public async getEquipmentModelDataResultMCA(
    equipmentCode: string,
    groupCode: string,
    dateRange: DateRangeProps
  ): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultMCA(equipmentCode, groupCode, {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  }

  public async getEquipmentModelDataResultVCA(
    equipmentCode: string,
    groupCode: string,
    dateRange: DateRangeProps
  ): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultVCA(equipmentCode, groupCode, {
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
  }

  public async getEquipmentModelDataResultEVS(
    equipmentCode: string,
    tagCode: string,
    modelId: number,
    dateRange: DateRangeProps,
    isNormal: boolean
  ): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultEVS(
      equipmentCode,
      tagCode,
      modelId,
      dateRange,
      isNormal
    );
  }

  public async getEquipmentModelDataResultFrequenct(
    equipmentCode: string,
    filename: string,
    frequency: string
  ): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultFrequenct(equipmentCode, filename, frequency);
  }

  public async getEquipmentModelDataResultFrequenctAnalysis(equipmentCode: string, filename: string): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultFrequenctAnalysis(equipmentCode, filename);
  }

  public async getEquipmentModelDataResultTimeDomain(modelCode: string, filename: string): Promise<any> {
    return await this.client.modelClient.getEquipmentModelDataResultTimeDomain(modelCode, filename);
  }

  public async getResultGroup(id: string): Promise<any> {
    return await this.client.modelClient.getResultGroup(id);
  }

  public async getFileNameMca(equipmentCode: string, type: string): Promise<any> {
    return await this.client.modelClient.getFileNameMca(equipmentCode, type);
  }

  public async getFileNameVca(modelCode: string, groupCode: string): Promise<any> {
    return await this.client.modelClient.getFileNameVca(modelCode, groupCode);
  }

  public async getAvailableGroupTags(id: string, resultGroupId: string): Promise<any> {
    return await this.client.modelClient.getAvailableGroupTags(id, resultGroupId);
  }

  public async getLimitAlarms(id: string): Promise<any> {
    return await this.client.modelClient.getLimitAlarms(id);
  }

  public async getFaultEvents(id: string): Promise<any[]> {
    return await this.client.modelClient.getFaultEvents(id);
  }

  public async getNewFaultEvents(id: string, month = 0, year = 0): Promise<any[]> {
    return await this.client.modelClient.getNewFaultEvents(id, month, year);
  }

  public async getCommentsNewFaultEvents(id: string): Promise<any[]> {
    return await this.client.modelClient.getCommentsNewFaultEvents(id);
  }

  public async postFaultEventAcknowledge(equipmentId: number, emailForm: string, body: any): Promise<any[]> {
    return await this.client.modelClient.postFaultEventAcknowledge(equipmentId, emailForm, body);
  }

  public async postFaultEventDone(equipmentId: number, emailForm: string, body: any): Promise<any[]> {
    return await this.client.modelClient.postFaultEventDone(equipmentId, emailForm, body);
  }

  public async updateFaultEventComments(body: any): Promise<any[]> {
    return await this.client.modelClient.updateFaultEventComments(body);
  }

  public async getRecommendations(id: number): Promise<any[]> {
    return await this.client.modelClient.getRecommendations(id);
  }

  public async modelResultDataStatusTr(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.modelResultDataStatusTr(equipmentCode);
  }

  public async modelResultDataStatusMca(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.modelResultDataStatusMca(equipmentCode);
  }

  public async modelResultDataStatusVca(equipmentCode: string, resultType?: string): Promise<any> {
    return await this.client.modelClient.modelResultDataStatusVca(equipmentCode, resultType);
  }

  public async modelResultDataStatusEvs(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.modelResultDataStatusEvs(equipmentCode);
  }

  public async modelResultDataStatusRvs(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.modelResultDataStatusRvs(equipmentCode);
  }

  public async modelNewResultDataStatusRvs(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.modelNewResultDataStatusRvs(equipmentCode);
  }

  public async createModelTagAlarmLimit(form: any[]): Promise<void> {
    return this.client.modelClient.postCreateModelTagAlarmLimit(form);
  }

  public async getTagStatusMessage(sensorCode: string, equipmentCode: string): Promise<any> {
    return this.client.modelClient.getTagStatusMessage(sensorCode, equipmentCode);
  }

  public async postCreateTagStatusMessage(form: any): Promise<any> {
    return this.client.modelClient.postCreateTagStatusMessage(form);
  }

  public async getRecommendationMessages(): Promise<any> {
    return this.client.modelClient.getRecommendationMessages();
  }

  public async postCreateRecommendationMessage(id: number, message: string): Promise<any> {
    return this.client.modelClient.postCreateRecommendationMessage(id, message);
  }

  public async getTagRecommendationList(tagId: number): Promise<any> {
    return this.client.modelClient.getTagRecommendationList(tagId);
  }

  public async postUpsertTagRecommendationList(tagMessageRecommendationForm: any[]): Promise<any> {
    return this.client.modelClient.postUpsertTagRecommendationList(tagMessageRecommendationForm);
  }

  public async modelUploadFileRecommendation(id: number, modelCode: string, form: FormData): Promise<any> {
    return await this.client.modelClient.modelUploadFileRecommendation(id, modelCode, form);
  }

  public async getSensorBrands(): Promise<any> {
    return await this.client.modelClient.getSensorBrands();
  }

  public async getSensorModels(id: number): Promise<any> {
    return await this.client.modelClient.getSensorModels(id);
  }

  public async getDocumentations(id: number, type: string): Promise<any> {
    try {
      return await this.client.modelClient.getDocumentations(id, type);
    } catch (error) {
      return null;
    }
  }

  public async uploadDocumentations(id: number, type: string, form: FormData): Promise<any> {
    return await this.client.modelClient.uploadDocumentations(id, type, form);
  }

  public async getSensorPoints(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.getSensorPoints(equipmentCode);
  }

  public async getSensorPointsAll(): Promise<any> {
    return await this.client.modelClient.getSensorPointsAll();
  }

  public async getMachineTemplate(): Promise<any> {
    return await this.client.modelClient.getMachineTemplate();
  }

  public async getBearing(txt?: string): Promise<any> {
    return await this.client.modelClient.getBearing(txt);
  }

  public async getBearingById(id: any): Promise<any> {
    return await this.client.modelClient.getBearingById(id);
  }

  public async getSensorDetail(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.getSensorDetail(equipmentCode);
  }

  public async getSensorDetailRvs(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.getSensorDetailRvs(equipmentCode);
  }

  public async getAlarmLimitSensorRvs(equipmentCode: string): Promise<any> {
    return await this.client.modelClient.getAlarmLimitSensorRvs(equipmentCode);
  }

  public async getRvsResultDataFileName(
    modelCode: string,
    resultGroup: string,
    phase: string,
    axis: string,
    sensorId: string,
    sensorPointId: number
  ): Promise<any> {
    return await this.client.modelClient.getRvsResultDataFileName(
      modelCode,
      resultGroup,
      phase,
      axis,
      sensorId,
      sensorPointId
    );
  }

  public async getRvsSensor(modelCode: string, sensorPointId: number): Promise<any> {
    return await this.client.modelClient.getRvsSensor(modelCode, sensorPointId);
  }

  public async getRvsResultData(
    sensorId: string,
    resultGroup: string,
    phase: string,
    axis: string,
    filename: string
  ): Promise<any> {
    return await this.client.modelClient.getRvsResultData(sensorId, resultGroup, phase, axis, filename);
  }

  public async getRvsTrendResultData(
    modelCode: string,
    sensorId: string,
    sensorPointId: number,
    dateRange: DateRangeProps
  ): Promise<any> {
    const { startDate, endDate } = dateRange;
    return await this.client.modelClient.getRvsTrendResultData(modelCode, sensorId, sensorPointId, startDate, endDate);
  }

  public async getTrPartialChartResultData(filename: string, modelCode: string): Promise<any> {
    return await this.client.modelClient.getTrPartialChartResultData(filename, modelCode);
  }

  public async getTrPartialChartResultDataFilename(modelCode: string, type: string): Promise<any> {
    return await this.client.modelClient.getTrPartialChartResultDataFilename(modelCode, type);
  }

  public async getTrCurrentSpectrumData(modelCode: string, filename: string): Promise<any> {
    return await this.client.modelClient.getTrCurrentSpectrumData(modelCode, filename);
  }

  public async getTrPartialChartQData(modelCode: string, dateRange: DateRangeProps): Promise<any> {
    const { startDate, endDate } = dateRange;
    return await this.client.modelClient.getTrPartialChartQData(modelCode, startDate, endDate);
  }

  public async getTrPartialChartQTable(modelCode: string): Promise<any> {
    return await this.client.modelClient.getTrPartialChartQTable(modelCode);
  }

  public async getTrCorrelationResultData(
    modelId: number,
    modelCode: string,
    equipmentId: number,
    tagA: string,
    tagB: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return await this.client.modelClient.getTrCorrelationResultData(
      modelId,
      modelCode,
      equipmentId,
      tagA,
      tagB,
      startDate,
      endDate
    );
  }

  public async getTrCorrelationResultDataScore(
    modelCode: string,
    equipmentId: number,
    tagScore: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return await this.client.modelClient.getTrCorrelationResultDataScore(
      modelCode,
      equipmentId,
      tagScore,
      startDate,
      endDate
    );
  }

  public async getRealTimeFaultEvent(): Promise<any> {
    return await this.client.modelClient.getRealTimeFaultEvent();
  }

  public async switchRealTimeFaultEvent(is: boolean): Promise<any> {
    return is
      ? await this.client.modelClient.enableRealTimeFaultEvent()
      : await this.client.modelClient.disableRealTimeFaultEvent();
  }

  public async getTRDiagramStatus(modelId: number, companyId: number): Promise<any> {
    const { data } = await this.client.equipmentModelClient.getTRDiagramStatus(modelId, companyId);
    return data;
  }

  public async getVsdTagAlarmLimit(modelId: number): Promise<any[]> {
    return await this.client.equipmentClient.getModelVsdTagAlarmLimitByIdModel(modelId);
  }
  public async getVsdSensorType(type: string): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVsdSensorType(type);
    return data;
  }
  public async getVsdTrendsignalResultData(params: Record<any, any>, cancelToken: CancelToken): Promise<Record<any, any>> {
    const { modelId, modelCode, sensorId, startDate, endDate, isNormal, equipmentId } = params;
    const { data } = await this.client.equipmentModelClient.getVsdTrendsignalResultData(
      modelId,
      modelCode,
      sensorId,
      startDate,
      endDate,
      isNormal,
      equipmentId,
      cancelToken
    );
    return data;
  }
  public async getVsdRulResultData(params: Record<any, any>, cancelToken: CancelToken): Promise<Record<any, any>> {
    const { modelCode, startDate, endDate, equipmentId, tagCode, model_id } = params;
    const { data } = await this.client.equipmentModelClient.getVsdRulResultData(
      tagCode,
      modelCode,
      startDate,
      endDate,
      equipmentId,
      model_id,
      cancelToken
    );
    return data;
  }
  public async getVsdTemperatureResultData(params: Record<any, any>, cancelToken: CancelToken): Promise<Record<any, any>> {
    const { modelCode, startDate, endDate, equipmentId, tagCode, modelId } = params;
    const { data } = await this.client.equipmentModelClient.getVsdTemperatureResultData(
      tagCode,
      modelCode,
      startDate,
      endDate,
      equipmentId,
      modelId,
      cancelToken
    );
    return data;
  }

  public async getRvsSensorType(modelId: number): Promise<any[]> {
    return await this.client.modelClient.getRvsSensorType(modelId);
  }

  public async getRvsGraphResultTrendData({
    modelCode,
    phase,
    startDate,
    endDate,
    sensor = [],
    isNormal,
  }: {
    modelCode: string;
    phase: string;
    startDate: string;
    endDate: string;
    sensor: any[];
    isNormal: boolean;
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultTrendData(modelCode, phase, startDate, endDate, sensor, isNormal);
  }

  public async getSensorPointNote({
    modelCode,
    sensor,
    phase,
    startDate,
    endDate,
  }: {
    modelCode: string;
    sensor: any[];
    phase: string;
    startDate: string;
    endDate: string;
  }): Promise<any[]> {
    return await this.client.modelClient.getSensorPointNote(modelCode, sensor, phase, startDate, endDate);
  }

  public async createSensorPointNote(params: any): Promise<void> {
    await this.client.modelClient.createSensorPointNote(params);
  }

  public async updateSensorPointNote({
    modelCode,
    description,
    rvsGraphId,
    noteId,
  }: {
    modelCode: string;
    description: string;
    rvsGraphId: number;
    noteId: number;
  }): Promise<void> {
    await this.client.modelClient.updateSensorPointNote(modelCode, description, rvsGraphId, noteId);
  }

  public async deleteSensorPointNote(params: any): Promise<void> {
    await this.client.modelClient.deleteSensorPointNote(params);
  }

  public async getRvsGraphResultSpectrumFileName({
    modelCode,
    sensor,
    phase,
    axis,
  }: {
    modelCode: string;
    sensor: string;
    phase: string;
    axis: string;
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultSpectrumFileName({ modelCode, sensor, phase, axis });
  }

  public async getRvsGraphResultSpectrumData({
    sensor,
    phase,
    axis,
    mark,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    mark: string;
    fileNames: any[];
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultSpectrumData({
      sensor,
      phase,
      axis,
      mark,
      fileNames,
    });
  }

  public async getRvsGraphResultSpectrumTable({
    sensor,
    phase,
    axis,
    mark,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    mark: string;
    fileNames: any[];
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultSpectrumTable({
      sensor,
      phase,
      axis,
      mark,
      fileNames,
    });
  }

  public async getRvsGraphResultTimeWaveFormData({
    sensor,
    phase,
    axis,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    fileNames: any[];
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultTimeWaveFormData({
      sensor,
      phase,
      axis,
      fileNames,
    });
  }

  public async getRvsWaterfallSensorType({ modelCode }: { modelCode: string }): Promise<any> {
    return await this.client.modelClient.getRvsWaterfallSensorType({ modelCode });
  }

  public async getRvsGraphResultWaterfallFileName({
    modelCode,
    sensor,
    phase,
    axis,
  }: {
    modelCode: string;
    sensor: string;
    phase: string;
    axis: string;
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultWaterfallFileName({ modelCode, sensor, phase, axis });
  }

  public async getRvsGraphResultWaterfallData({
    sensorId,
    phase,
    axis,
    fileNames,
  }: {
    sensorId: string;
    phase: string;
    axis: string;
    fileNames: any[];
  }): Promise<any> {
    return await this.client.modelClient.getRvsGraphResultWaterfallData({ sensorId, phase, axis, fileNames });
  }

  public async getVcaAlarmLimitSensorMasterForm(modelTypeId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVcaAlarmLimitSensorMasterForm(modelTypeId);
    return data;
  }

  public async modelResultOverallDataStatusVca(equipmentCode: string, resultType: string): Promise<any> {
    return await this.client.modelClient.modelResultOverallDataStatusVca(equipmentCode, resultType);
  }

  public async getVsdTrendSignalAlarmLimitSensorForm(modelId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getVsdTrendSignalAlarmLimitSensorForm(modelId);
    return data;
  }

  public async getMcaAlarmLimitSensorMasterForm(modelTypeId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getMcaAlarmLimitSensorMasterForm(modelTypeId);
    return data;
  }

  public async getMcaAlarmLimitSensorForm(modelId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getMcaAlarmLimitSensorForm(modelId);
    return data;
  }
  public async getMcaResultModelDataNew(
    modelCode: string,
    resultGroupId: number,
    startDate: string,
    endDate: string,
    isNormal: boolean,
    modelId: number
  ): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getMcaResultModelDataNew(
      modelCode,
      resultGroupId,
      startDate,
      endDate,
      isNormal,
      modelId
    );
    return data;
  }
  public async getMcaResultDiagnostic(
    modelCode: string,
    resultGroupId: number,
    startDate: string,
    endDate: string,
    modelId: number
  ): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getMcaResultDiagnostic(
      modelCode,
      resultGroupId,
      startDate,
      endDate,
      modelId
    );
    return data;
  }
  public async getOverallModelStatus(companyId: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getOverallModelStatus(companyId);
    return data;
  }

  public async getAlarManagement(modelCode: string): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getAlarManagement(modelCode);
    return data;
  }

  public async getAlarmManagementMaster(): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getAlarmManagementMaster();
    return data;
  }

  public async getRVSFailureMode(): Promise<any[]> {
    const data = await this.client.modelClient.modelRVSFailureMode();
    return data;
  }

  public async getRVSFailureModeById(id: number): Promise<any[]> {
    const data = await this.client.modelClient.modelRVSFailureModeById(id);
    return data;
  }
  public async getRvsGraphParameter(props: {
    keyValue: string[];
    parameterValue: string;
    modelCode: string;
    startDate: string;
    endDate: string;
    isHistory: boolean;
    sensorId: string;
  }): Promise<any[]> {
    const data = await this.client.modelClient.getRvsGraphResultParameter({ ...props });
    return data;
  }

  public async getOverallStatusRvs(company_id: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getOverallStatusRvs(company_id);
    return data;
  }

  public async getOverallStatusMca(company_id: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getOverallStatusMca(company_id);
    return data;
  }

  public async getOverallStatusVca(company_id: number): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getOverallStatusVca(company_id);
    return data;
  }

  public async getFailureModeRvs(model_code: string): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getFailureModeRvs(model_code);
    return data;
  }

  public async getFailureModeRvsGraph(
    model_code: string,
    sensor_id: string,
    startdate: string,
    enddate: string
  ): Promise<any[]> {
    const { data } = await this.client.equipmentModelClient.getFailureModeRvsGraph(
      model_code,
      sensor_id,
      startdate,
      enddate
    );
    return data;
  }

  public async getRvsAxis(sensor_id: string): Promise<any> {
    const { data } = await this.client.equipmentModelClient.getRvsAxis(sensor_id);
    return data;
  }
}
