var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center h-[242px]"},[_c('spinner',{attrs:{"size":"sm"}})],1):_c('div',[_c('div',{staticClass:"flex container text-center justify-center overflow-hidden"},[_c('div',{staticClass:"grid grid-cols-6 gap-2"},_vm._l((_vm.items),function(item,index){return _c('div',{key:item.name,staticClass:"relative max-w-[23px]"},[_c('span',{staticClass:"text__progress"},[_vm._v(_vm._s(_vm.textValue(item.status, item.value, item.alarm)))]),_c('div',{staticClass:"progress"},[(index === 0)?_c('span',{class:`absolute whitespace-nowrap right-[20px] text-[10px] bottom-[${
                (item.alarm / (item.alarm * 1.2)) * 100 - 4
              }%]`},[_vm._v(_vm._s(item.alarm)+_vm._s(item.unit))]):_vm._e(),(index === 0)?_c('span',{class:`absolute whitespace-nowrap right-[20px] text-[10px] bottom-[${
                ((item.warning - _vm.calsMeanTs(item.warning, item.alarm)) / (item.alarm * 1.2)) * 100 - 4
              }%]`},[_vm._v(_vm._s(item.warning)+_vm._s(item.unit))]):_vm._e(),_c('div',{class:`alarm__progress bottom-[${(item.alarm / (item.alarm * 1.2)) * 100}%]`}),_c('div',{class:`warning__progress bottom-[${
                ((item.warning - _vm.calsMeanTs(item.warning, item.alarm)) / (item.alarm * 1.2)) * 100
              }%]`}),_c('div',{class:`bar bg-[${_vm.statusName(item.status)}] h-[${
                item.value > item.alarm * 1.2
                  ? 100
                  : ((item.value - _vm.calsValue(item.value, item.warning, item.alarm)) / (item.alarm * 1.2)) * 100
              }%]`}),(index === _vm.items.length - 1)?_c('span',{class:`absolute whitespace-nowrap left-[20px] text-[10px] bottom-[${
                (item.alarm / (item.alarm * 1.2)) * 100 - 4
              }%]`},[_vm._v(_vm._s(item.alarm)+_vm._s(item.unit))]):_vm._e(),(index === _vm.items.length - 1)?_c('span',{class:`absolute whitespace-nowrap left-[20px] text-[10px] bottom-[${
                ((item.warning - _vm.calsMeanTs(item.warning, item.alarm)) / (item.alarm * 1.2)) * 100 - 4
              }%]`},[_vm._v(_vm._s(item.warning)+_vm._s(item.unit))]):_vm._e()]),_c('span',{staticClass:"text__progress whitespace-nowrap truncate"},[_vm._v(_vm._s(item.name))])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center border-b-[1px] border-border p-[24px] h-[64px]"},[_c('span',{staticClass:"text-[16px] font-[600]"},[_vm._v(" B : Current & Voltage ")])])
}]

export { render, staticRenderFns }