import { GetterTree } from "vuex";
import { State } from "./states";

import { MemberAssignForm } from "@/features/equipment/models/MemberAssignForm";

export const getters: GetterTree<State, State> = {
  user(state: State): Record<any, any> {
    const { id, name, email, profileImageUrl, role } = state.user;

    return {
      id,
      name,
      email,
      image: profileImageUrl,
      role: role.toUpperCase(),
    };
  },
  company(state: State): Record<any, any> {
    const { id, title, image_path, end_date } = state.company;

    return {
      id,
      name: title,
      image: image_path,
      end_date,
    };
  },
  model(state: State): Record<any, any> {
    const { model: row } = state;

    return {
      id: row.id,
      modelName: row.title,
      modelCode: row.equipmentCode.code,
      equipmentId: row.equipmentTagId,
      isActive: row.isActive === "active" ? true : false,
      linkUrl: row.linkUrl,
      modelTypeId: row.equipmentModelType.id,
      modelTypeName: row.equipmentModelType.title,
    };
  },
  structure(state: State): any[] {
    const { id, structureItemTitle, children } = state.structure;

    const convert = (data: any, isModel: boolean) => {
      const result: any[] = [];

      if (!isModel) {
        if (data.structureItemType === "Folder") {
          data.children.forEach((d: any, i: number) => {
            result.push(convert(data.children[i], false));
          });
        } else if (data.structureItemType === "EquipmentTag") {
          const online: any[] = [];
          const offline: any[] = [];
          data.equipmentModels.forEach((d: any) => {
            if (!d.is_offline) {
              online.push({
                id: d.id,
                name: d.title,
                type: "model",
                active: d.isActive === "active" ? true : false,
                status: "online",
                currentStatus: d.status,
                link: null,
              });
            } else {
              offline.push({
                id: d.id,
                name: d.title,
                type: "model",
                active: d.isActive === "active" ? true : false,
                status: "offline",
                currentStatus: -1,
                link: d.linkUrl,
              });
            }
          });

          result.push({
            name: "SMART Detection",
            type: "online",
            ...(online.length && { children: online }),
          });
          result.push({
            name: "External Source",
            type: "offline",
            ...(offline.length && { children: offline }),
          });
        }
      }

      return {
        id: data?.id,
        name: !isModel
          ? data?.structureItemAlias
            ? data?.structureItemAlias
            : data?.structureItemTitle || ""
          : data?.title || "",
        type: isModel ? "model" : data?.structureItemType === "EquipmentTag" ? "equipment" : "folder",
        equipmentGroupType: !isModel ? data.equipmentGroupType : null,
        currentStatus: data?.status,
        ...(result.length && { children: result }),
      };
    };

    const childrens = (datas: any[]) => {
      const result: any[] = [];
      datas.forEach((child: any) => {
        const x = convert(child, false);
        result.push(x);
      });
      return result;
    };

    return [
      {
        id,
        name: structureItemTitle,
        type: "company",
        children: children && children?.length ? childrens(children) : [],
      },
    ];
  },
  getMemberAssignForm(state: State): MemberAssignForm {
    return state.memberAssignForm;
  },
  configurationInformation(state: State): Record<any, any> {
    const rawData = state.model.equipmentModelConfiguration;

    const datasA: any[] = [];
    const datasB: any[] = [];

    rawData.forEach((element, index) => {
      if (index % 2 === 0) {
        datasA.push({
          id: element.id,
          value: element.value,
          configInformationId: element.modelTypeConfiguration.id,
          title: element.modelTypeConfiguration.title,
          unitCode: element.modelTypeConfiguration.unitCode,
        });
      } else {
        datasB.push({
          id: element.id,
          value: element.value,
          configInformationId: element.modelTypeConfiguration.id,
          title: element.modelTypeConfiguration.title,
          unitCode: element.modelTypeConfiguration.unitCode,
        });
      }
    });

    return {
      datasA,
      datasB,
    };
  },
  modelMotorDataForm(state: State): any[] {
    return state.modelMotorDataForm;
  },
  modelVibrationSensorForm(state: State): any[] {
    return state.updateModelForm.vibration_sensor_in;
  },
};
