import { RouteConfig } from "vue-router";

import AdminPage from "./pages/AdminPage.vue";

export const UserRouter: (prefix?: string) => RouteConfig[] = (prefix = "user") => [
  {
    name: "AdminPage",
    path: `${prefix}/admin`,
    component: AdminPage,
    meta: { title: "Admin" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
];
