
import Vue from "vue";
import { mapActions } from "vuex";

import NoDataAvailable from "@/components/NoDataAvailable.vue";
import TrHealthMonitoringGraph from "./TrHealthMonitoringGraph.vue";
import TrAdvanceAnalysisGraph from "./TrAdvanceAnalysisGraph.vue";
import TrCorrelationGraph from "./TrCorrelationGraph.vue";
import McaCurrentSpectrum from "./McaCurrentSpectrum.vue";
import McaTimeSeries from "./McaTimeSeries.vue";
import EvsModelChart from "./EvsModelChart.vue";
import VcaTrendSignal from "./VcaTrendSignal.vue";
import VcaCurrentAnalysis from "./VcaCurrentAnalysis.vue";
import remainingLife from "./remainingLife.vue";
import McaPetternRecog from "./McaPetternRecog.vue";

export default Vue.extend({
  name: "GraphTabGroup",
  components: {
    TrHealthMonitoringGraph,
    TrAdvanceAnalysisGraph,
    TrCorrelationGraph,
    NoDataAvailable,
    McaCurrentSpectrum,
    McaTimeSeries,
    EvsModelChart,
    VcaTrendSignal,
    VcaCurrentAnalysis,
    remainingLife,
    McaPetternRecog,
  },
  data() {
    const primaryNameLists = [
      "Health Monitoring",
      "Advance analysis",
      "Correlation (Anomaly)",
      "Current Spectrum",
      "Time Series Detail",
      "Frequency Detail",
      "Current Analysis",
      "Trend Signal",
      "Trend",
    ];

    return {
      primaryNameLists,
    };
  },
  computed: {
    modelType() {
      return this.$store.state.model.equipmentModelType.id;
    },
    primary() {
      return this.$store.state.graphInformationTab.is_primary_tab;
    },
    secondary() {
      return this.$store.state.graphInformationTab.is_secondary_tab;
    },
    primaryTab() {
      return this.$store.state.graphInformationTabGroupData.graph_primary_tab;
    },
    secondaryTab() {
      return this.$store.state.graphInformationTabGroupData.graph_secondary_tab;
    },
    modelPrimary: {
      get() {
        return this.$store.state.graphInformationTab.is_primary_tab.key;
      },
      set(value) {
        this.setGraphInformationTabGroup({
          ...this.$store.state.graphInformationTab,
          is_primary_tab: { ...this.$store.state.graphInformationTab.is_primary_tab, key: value },
        });
      },
    },
    modelSecondary: {
      get() {
        return this.$store.state.graphInformationTab.is_secondary_tab.key;
      },
      set(value: number) {
        const groupCode = this.secondaryTab[value]?.groupCode;
        this.setGraphInformationTabGroup({
          ...this.$store.state.graphInformationTab,
          is_secondary_tab: { ...this.$store.state.graphInformationTab.is_secondary_tab, key: value, groupCode },
        });
      },
    },
  },
  methods: {
    ...mapActions(["setGraphInformationTabGroup", "getGraphInformationSecondaryTabGroupData"]),
    async onChangePrimaryTab(event: any) {
      const { id, name, key } = event;

      this.setGraphInformationTabGroup({
        ...this.$store.state.graphInformationTab,
        is_primary_tab: { id, name, key },
      });
      await this.getGraphInformationSecondaryTabGroupData();
    },
    onChangeSecondaryTab(event: any) {
      const { id, name, key, groupCode } = event;

      this.setGraphInformationTabGroup({
        ...this.$store.state.graphInformationTab,
        is_secondary_tab: { id, name, key, groupCode },
      });
    },
  },
});
