import { Client } from "../../clients/client";
import { Company } from "./models/Company";
import { CompanyForm, CreateCompanyForm } from "./forms/CompanyForm";
import { InviteAdminForm } from "./forms/InviteAdminForm";

export class CompanyUseCase {
  constructor(private client: Client) {}

  public async getCompanies(): Promise<Company[]> {
    return this.client.companyClient.getCompanies();
  }

  public async getCompanyById(companyId: string): Promise<Company[]> {
    return this.client.companyClient.getCompanyById(companyId);
  }

  public async createCompany(body: CreateCompanyForm): Promise<Company[]> {
    return this.client.companyClient.createCompany(body);
  }

  public async uploadCompanyImage(form: FormData): Promise<any> {
    return await this.client.companyClient.uploadCompanyImage(form);
  }

  public async updateCompany(id: number, form: CompanyForm): Promise<Company[]> {
    return this.client.companyClient.updateCompany(id, form);
  }

  public async deleteCompany(id: number): Promise<Company[]> {
    return this.client.companyClient.deleteCompany(id);
  }

  public async getAdmins(): Promise<Company[]> {
    return this.client.companyClient.getAdmins();
  }

  public async addAdmins(params: InviteAdminForm): Promise<Company[]> {
    return this.client.companyClient.addAdmins(params);
  }

  public async deleteAdmins(email: string): Promise<Company[]> {
    return this.client.companyClient.deleteAdmins(email);
  }

  public async editAdmins(params: InviteAdminForm): Promise<Company[]> {
    return this.client.companyClient.editAdmins(params);
  }
}
