import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AuthLayout from "@/layouts/AuthLayout.vue";
import AppLayout from "@/layouts/AppLayout.vue";

import { AuthRouter } from "@/features/auth/AuthRouter";
import { CompanyRouter } from "@/features/company/CompanyRouter";
import { UserRouter } from "@/features/user/UserRouter";
import { ModelRouter } from "@/features/model/ModelRouter";

Vue.use(VueRouter);

const loginGuard = (next: any): boolean => {
  const hasAccessToken = Vue.prototype.$dep.httpClient.hasAccessToken();

  if (!hasAccessToken) {
    next({ name: "AuthLoginPage" });
  }

  return !hasAccessToken;
};

const routes: Array<RouteConfig> = [
  { path: "/auth", component: AuthLayout, children: [...AuthRouter()] },
  {
    path: "/",
    component: AppLayout,
    children: [...ModelRouter(), ...CompanyRouter(), ...UserRouter()],
    beforeEnter: (to, from, next) => {
      if (loginGuard(next)) return;
      next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
