
import Vue from "vue";
import { Chart } from "highcharts-vue";

import colorStatus from "@/common/mixins/colorStatus";
import ButtonDanger from "@/components/ButtonDanger.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";

export default Vue.extend({
  name: "TrAnalysisLocusGraph",
  mixins: [colorStatus],
  components: { highcharts: Chart, ButtonDanger, LoadingGraph, NoDataAvailable },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    const statusName = [
      "N/A",
      "Normal",
      "Winding Short Circuit",
      "Axial Displacement",
      "Radial Displacement",
      "Disk Space Variation",
      "Core Deformation",
      "Inter disk",
      "Leakage fault",
      "Forced buckling",
      "Disk to ground",
    ];
    return {
      isLoading: false,
      defaultOption: {
        locusName: "",
        laseFile: "",
        presentFile: "",
      },
      isSensorType: null,
      sensorTypeData: [],
      isFileNamePresent: null,
      fileNamePresentData: [],
      isFileNameLast: null,
      fileNameLastData: [],
      statusName,
      data: null,
      noDataAvailable: false,
      chartOptions: {
        chart: { zoomType: "xy" },
        title: { text: "" },
        subtitle: { text: "" },
        tooltip: { shared: true, crosshairs: true },
        legend: {
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000" },
        },
        yAxis: {
          title: { useHTML: true, text: "<p>&#916V (V)</p>" },
          max: null,
          min: null,
        },
        xAxis: {
          categories: [],
          title: {
            useHTML: true,
            text: "<p>I<sub>l</sub> (A)</p>",
          },
        },
        series: [],
        credits: { enabled: false },
      },
      renderGraph: false,
    };
  },
  async created() {
    this.renderGraph = false;

    await this.fetchSensorType();
    await this.fetchDefaultOption();
    await this.fetchFileName();
    await this.fetchTableData();
    await this.fetchResultData();
  },
  methods: {
    async fetchDefaultOption() {
      try {
        const { id, equipmentId } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrVandLocusFilenameDefault(
          id,
          equipmentId,
          this.isSensorType
        );

        this.defaultOption = response;
      } catch (error) {
        this.defaultOption.locusName = "";
        this.defaultOption.laseFile = "";
        this.defaultOption.presentFile = "";
      }
    },
    async fetchSensorType() {
      try {
        const { id, equipmentId } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrVandLocus(id, equipmentId);

        if (response && response.length) {
          this.sensorTypeData = response.map((prev) => prev.locus_name);
          this.isSensorType = response.map((prev) => prev.locus_name)[0];
        }
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = null;
      }
    },
    async fetchFileName() {
      try {
        const { modelCode } = this.model;
        const filenameData = await (this as any).$dep.modelUseCase.getTrVandLocusFilename(
          modelCode,
          this.defaultOption.locusName
        );

        if (filenameData && filenameData.length) {
          this.fileNamePresentData = filenameData;
          this.isFileNamePresent = filenameData.find((prev) => prev.fileName === this.defaultOption.presentFile);
        } else {
          this.fileNamePresentData = [];
          this.isFileNamePresent = null;
        }

        if (filenameData && filenameData.length) {
          this.fileNameLastData = filenameData;
          this.isFileNameLast = filenameData.find((prev) => prev.fileName === this.defaultOption.laseFile);
        } else {
          this.fileNameLastData = [];
          this.isFileNameLast = null;
        }
      } catch (error) {
        //
        this.fileNamePresentData = [];
        this.fileNameLastData = [];
        this.isFileNamePresent = null;
        this.isFileNameLast = null;
      }
    },
    async fetchResultData() {
      try {
        const { equipmentId, modelCode } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrVandLocusResultData(
          this.isSensorType,
          equipmentId,
          modelCode,
          this.isFileNamePresent.fileName,
          this.isFileNameLast.fileName
        );

        this.chartOptions.series = response.map((prev) => ({
          name: prev.sub_module,
          data: prev.result_list,
        }));

        this.renderGraph = true;
        this.noDataAvailable = false;
      } catch (error) {
        this.renderGraph = true;
        this.noDataAvailable = true;
      }
    },
    async fetchTableData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getProcessViLocusDataTr({
          locus_name: this.isSensorType,
          model_id: id,
          file_name_present: this.isFileNamePresent.fileName,
          file_name_last: this.isFileNameLast.fileName,
        });

        this.data = response;
      } catch (error) {
        console.log(error);
      }
    },
    async onChangeSensorType(val: any) {
      try {
        this.renderGraph = false;
        this.defaultOption.locusName = val;

        await this.fetchDefaultOption();
        await this.fetchFileName();
        await this.fetchTableData();
        await this.fetchResultData();
      } catch (error) {
        this.renderGraph = true;
        this.noDataAvailable = true;
      }
    },
    async onChangeFileNameType(type: string, ev) {
      if (type === "present") {
        this.isFileNamePresent = ev;
      } else {
        this.isFileNameLast = ev;
      }
    },
    async onExecute() {
      try {
        this.renderGraph = false;

        await this.fetchTableData();
        await this.fetchResultData();
      } catch (error) {
        this.renderGraph = true;
        this.noDataAvailable = true;
      }
    },
  },
});
