
import Vue from "vue";

export default Vue.extend({
  name: "NoDataRVS",
  props: {
    height: {
      type: Number,
      default: 210,
    },
    message: {
      type: String,
      default: "No data available",
    },
    typeGraph: {
      type: String,
      default: "overtrend",
    },
  },
});
