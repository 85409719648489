
import Vue from "vue";

export default Vue.extend({
  name: "EditModelOfflineFormPage",
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openEditModelOfflineModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openEditModelOfflineModal", {
          ...this.$store.state.openEditModelOfflineModal,
          open: value,
        });
      },
    },
    contextMenuState() {
      return this.$store.state.contextMenuStructure;
    },
    contextMenuStructure() {
      return this.$store.state.contextMenuStructure;
    },
    initailForm() {
      return this.$store.state.openEditModelOfflineModal.param;
    },
    actionType() {
      return this.$store.state.openEditModelOfflineModal.type;
    },
  },
  data() {
    return {
      title: "",
      linkUrl: "",
      isActive: false,
    };
  },
  methods: {
    onSubmit() {
      const param = {
        id: this.initailForm.id,
        title: this.title,
        linkUrl: this.linkUrl,
        isActive: this.isActive ? "active" : "inactive",
      };
      this.$emit("onSubmit", param);
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.title = this.initailForm ? this.initailForm.title : "";
        this.linkUrl = this.initailForm ? this.initailForm.linkUrl : "";
        this.isActive = this.initailForm && this.initailForm.isActive === "active" ? true : false;
      }
    },
  },
});
