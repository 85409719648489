
import Vue from "vue";
import ProfileMenu from "./ProfileMenu.vue";
import SettingMenu from "./SettingMenu.vue";
import DateTime from "./DateTime.vue";

export default Vue.extend({
  components: {
    ProfileMenu,
    SettingMenu,
    DateTime,
  },
  async created() {
    try {
      await this.$store.dispatch("setUserRole");
    } catch (error) {
      (this as any).$dep.authUseCase.logout();
    }
  },
  computed: {
    hasSettingMenuPermission() {
      const role = this.$store.getters.getCurrentRole;
      return ["ADMIN"].includes(role) ? true : false;
    },
    userName() {
      return this.$store.state.userProfile?.name;
    },
    visible() {
      const route = this.$route.name;
      const routeName = ["SelectCompanyPage", "SettingCompanyPage", "SettingInviteAdminPage"];
      const find = routeName.find((name: string) => route === name);

      return !find ? true : false;
    },
  },
  methods: {
    redirectPage(name: string) {
      const company_id = sessionStorage.getItem("company_id");
      if (company_id) {
        this.$router.push({ name });
      }
    },
  },
});
