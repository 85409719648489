
import Vue from "vue";

export default Vue.extend({
  name: "EditUserPage",
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openEditUserModal;
      },
      set(value: boolean) {
        this.$store.dispatch("openEditUserModal", value);
      },
    },
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      name: "",
      email: "",
      image: "",
    };
  },
  methods: {
    onChangeImage() {
      (this as any).$refs.file.click();
    },
    async onChange(e: any) {
      try {
        const file = e.target.files[0];
        const imageType = e.target.files[0].type;

        if (!["image/jpeg", "image/jpg", "image/png"].includes(imageType)) {
          return alert("Please upload file with type: jpeg, jpg, png");
        }

        let formdata = new FormData();
        formdata.append("image_file", file, `image_profile_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}`);

        const { url } = await (this as any).$dep.memberUseCase.uploadProfileImage(formdata);
        this.image = url;
      } catch (error) {
        //
      }
    },
    async onSubmit() {
      try {
        this.dialog = false;

        await (this as any).$dep.memberUseCase.updateMember({
          name: this.name,
          profileImageUrl: this.image,
        });

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Update profile successfully",
          type: "success",
        });

        this.$store.dispatch("setUser", {
          ...this.$store.state.user,
          name: this.name,
          profileImageUrl: this.image,
        });
      } catch (error) {
        //
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.name = this.user?.name || "";
        this.email = this.user?.email || "";
        this.image = this.user?.image || "";
      }
    },
  },
});
