
import Vue from "vue";
import dayjs from "dayjs";
import axios from "axios";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
// import ButtonReload from "@/components/ButtonReload.vue";
// import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "McaCurrentSpectrum",
  components: { highcharts: Chart, ButtonDanger, LoadingGraph, NoDataAvailable },
  data() {
    const date = new Date();

    //for debug
    const fileName = [];
    const storeData: any = [];
    return {
      source: null,
      isSensorType: {},
      sensorTypeData: [],
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      chartLoading: true,
      chartOptions: {
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: false,
            },
          },
        },
        scrollbar: {
          enabled: true,
          showFull: false,
          liveRedraw: false,
        },
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: {
          formatter: function () {
            const unit = this.series.options.unit === "A" ? "s" : "Hz";
            let tooltipText = `<b>${this.x} ${unit}</b><br/>`;
            // const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            // const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            this.series.chart.series.forEach((series) => {
              if (series.yData.length > 0 && series.visible && !series.name.includes("Navigator")) {
                tooltipText += `<span style="color:${series.color}">\u25CF</span> <b>${series.name}: </b>${
                  series.yData[this.point.index]
                } ${this.series.options.unit}<br/>`;
              }
            });
            // tooltipText += `<span style="color:${this.point.color}">\u25CF</span> <b>${
            //   this.point.series.name
            // }: </b>${this.y.toFixed(2)} dB<br/>`;

            // Check if the series has only 1 line and add warning/alarm information

            return tooltipText;
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: { type: "category", categories: [] },
        yAxis: {
          title: {
            text: `-`,
          },
          labels: {
            format: `{value}`, // Example format: add '°C' to the label value
          },
        },
        series: [],
        credits: { enabled: false },
      },
      fileName,
      isFileName: "",
      storeData,
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  async created() {
    await this.fetchFileName();
  },
  methods: {
    async fetchSensorTypeData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelHealthMonitoringSensorType(this.isSecondaryTab, id);
        this.sensorTypeData = response.map((prev) => ({ id: prev.id, name: prev.name }));
        this.isSensorType = { id: this.sensorTypeData[0].id, name: this.sensorTypeData[0].name };
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = {};
      }
    },
    async fetchResultData() {
      try {
        this.chartLoading = true;
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        this.chartResultData = [];
        // const { id, modelCode, equipmentId } = this.model;
        if (this.source) {
          this.source.cancel();
        }
        let storeData: Array<any> = [];
        if (this.groupCode === "TD") {
          storeData = await (this as any).$dep.modelUseCase.getEquipmentModelDataResultTimeDomain(
            this.model.modelCode,
            this.isFileName
          );
        } else {
          storeData = await (this as any).$dep.modelUseCase.getEquipmentModelDataResultFrequenctAnalysis(
            this.model.modelCode,
            this.isFileName
          );
        }
        this.source = axios.CancelToken.source();
        // const cancelToken = this.source.token;
        (this as any).storeData = storeData.map((item: any) => {
          return {
            ...item,
          };
        });
        this.chartResultData = this.storeData;
        this.chartOptions.title.text = `current analysis`;
        this.chartOptions.yAxis.title = this.storeData[0]?.unit;
        this.chartOptions.xAxis = this.aAxis(this.storeData);
        this.chartOptions.series = this.series(this.storeData);
      } catch (error) {
        console.log(error);
      } finally {
        this.source = null;
        this.chartLoading = false;
      }
    },
    onChangeSensorType(param: any) {
      const { id, name } = param;
      this.isSensorType = { id, name };
    },
    onChangeSelectDateType(param: any) {
      this.selectDateType = param;
    },
    aAxis(datas: any) {
      let labels = "";
      if (this.groupCode === "TD") {
        labels = datas.map((val) => val.ts);
      } else {
        labels = datas.map((val) => val.FHZ);
      }
      this.xAxisMaxRange = labels.length;
      const count = labels.length;
      const maxLabelsToShow = 10; // You can adjust this value to display more or fewer labels
      const unit = this.groupCode === "TD" ? "s" : "Hz";
      return {
        type: "category",
        labels: {
          align: "left",
          formatter: function () {
            return `${this.value.toFixed(2)} ${unit}`;
          },
        },
        tickPositioner: function () {
          const interval = Math.max(1, Math.ceil(count / maxLabelsToShow));
          const positions = [];
          for (let i = 0; i < count; i += interval) {
            positions.push(i);
          }
          return positions;
        },
        categories: labels,
      };
    },
    series(datas: any) {
      // const customLegend = {
      //   name: "All",
      // };
      const unit = this.groupCode === "TD" ? "A" : "dB";
      let sers = [];
      if (this.groupCode === "TD") {
        sers = [
          {
            name: "Ia",
            data: datas.map((prev) => prev.Ia),
            lineWidth: 2,
            unit: unit,
          },
          {
            name: "Ib",
            data: datas.map((prev) => prev.Ib),
            lineWidth: 2,
            unit: unit,
          },
          {
            name: "Ic",
            data: datas.map((prev) => prev.Ic),
            lineWidth: 2,
            unit: unit,
          },
        ];
      } else {
        sers = [
          {
            name: "Ia",
            data: datas.map((prev) => prev.IA),
            lineWidth: 2,
            unit: unit,
          },
          {
            name: "Ib",
            data: datas.map((prev) => prev.IB),
            lineWidth: 2,
            unit: unit,
          },
          {
            name: "Ic",
            data: datas.map((prev) => prev.IC),
            lineWidth: 2,
            unit: unit,
          },
        ];
      }
      return sers;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `vsd_${id}__current_analysis`.toUpperCase();
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const ws = XLSX.utils.json_to_sheet(this.chartResultData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, name); // => sheet name
      XLSX.writeFile(wb, `${name}.xlsx`); // => file name
    },
    async onSelectFileName(el: any) {
      this.isFileName = el.fileName;
      await this.fetchResultData();
    },
    async fetchFileName() {
      try {
        // (this as any).fileName = await (this as any).$dep.modelUseCase.getFileNameMca(this.model.modelCode, this.groupCode);
        (this as any).fileName = await (this as any).$dep.modelUseCase.getFileNameVca(this.model.modelCode, this.groupCode);
        if (this.fileName.length === 0 || !this.fileName) {
          this.chartLoading = false;
          this.chartResultData = [];
          return;
        }
        (this as any).isFileName = await (this as any).fileName[0].fileName;
        this.fetchResultData();
      } catch (error) {
        //
        (this as any).fileName = [];
      }
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
  },
  watch: {
    async groupCode() {
      if (!this.groupCode) {
        return;
      }
      await this.fetchFileName();
    },
    // async isSensorType() {
    //   await this.fetchResultData();
    // },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
  },
});
