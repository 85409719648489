
import Vue from "vue";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import EquipmentIcon from "@/components/icons/EquipmentIcon.vue";

export default Vue.extend({
  name: "CreateModelFormPage",
  components: { EquipmentIcon },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openCreateModelModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openCreateModelModal", {
          ...this.$store.state.openCreateModelModal,
          open: value,
        });
      },
    },
    contextMenuState() {
      return this.$store.state.contextMenuStructure;
    },
    openCreateModelModal() {
      return this.$store.state.openCreateModelModal;
    },
  },
  validations: {
    modelName: { required },
    validationGroup: ["modelName"],
  },
  data() {
    const modelType = [
      { name: "Transformer", id: 1 },
      { name: "Motor Current Analysis", id: 2 },
      { name: "VSD Current Analysis", id: 3 },
      { name: "Edge Vibration Sensor", id: 4 },
      { name: "Raw Vibration Sensor", id: 5 },
    ];
    const tabs = ["Online", "Offline"];

    return {
      isActive: false,
      isLoading: false,
      modelName: "",
      applicationName: "",
      linkUrl: "",
      modelType,
      isModelType: { name: "Transformer", id: 1 },
      tabs,
      isTab: 0,
    };
  },
  methods: {
    ...mapActions(["getStructure"]),
    async onCreateModel() {
      if (this.isTab === 0) {
        // Online
        await this.onCreateModelOnline();
      }
      if (this.isTab === 1) {
        // Offline
        await this.onCreateModelOffline();
      }
    },
    async onCreateModelOnline() {
      try {
        this.isLoading = true;

        const alarmLimitSensorNewIn = await (this as any).$dep.modelUseCase.getAlarmLimitSensorMasterForm(
          this.isModelType.id
        );
        const alarmManagementMaster = await (this as any).$dep.modelUseCase.getAlarmManagementMaster();
        let vsdAlarmLimitSensorNewIn = [];
        let vsdTrendSignalSensor = [];
        let mcaAlarmLimitSensor = [];
        if (this.isModelType.id === 3) {
          vsdTrendSignalSensor = await (this as any).$dep.modelUseCase.getVsdTrendSignalAlarmLimitSensorMasterForm();
          vsdAlarmLimitSensorNewIn = await (this as any).$dep.modelUseCase.getVsdAlarmLimitSensorMasterForm(
            this.isModelType.id
          );
        }
        if (this.isModelType.id === 2) {
          mcaAlarmLimitSensor = await (this as any).$dep.modelUseCase.getMcaAlarmLimitSensorMasterForm(this.isModelType.id);
        }
        const param = {
          equipment_tag_id: this.contextMenuState.id,
          model_in: {
            title: this.modelName,
            isActive: this.isActive ? "active" : "inactive",
            equipmentModelTypeId: this.isModelType.id,
          },
          configuration_in: [],
          vibration_sensor_in: [],
          raw_vibration_sensor_in: [],
          alarm_limit_sensor_in: [],
          trend_signal_alarm_limit_sensor_in: [],
          vsd_tag_alarm_limit_in: this.isModelType.id == 3 ? alarmLimitSensorNewIn || [] : [],
          motor_data_in: [],
          electronic_card_in: [],
          q_setting_in: [],
          v_and_i_locus_in: [],
          alarm_limit_sensor_new_in: this.isModelType.id !== 3 ? alarmLimitSensorNewIn || [] : [],
          alarm_limit_configuration_in: [],
          vsd_alarm_limit_sensor_new_in: vsdAlarmLimitSensorNewIn || [],
          vsd_trend_signal_alarm_limit_sensor: vsdTrendSignalSensor || [],
          mca_alarm_limit_sensor_new: mcaAlarmLimitSensor || [],
          alarm_management: alarmManagementMaster || {},
        };

        const response = await (this as any).$dep.modelUseCase.createModel(param);

        this.$router.push({ name: "EditModelFormPage", params: { id: response.id } });

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `Create model successfully`,
          type: "success",
        });
        this.dialog = false;
        await this.getStructure();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async onCreateModelOffline() {
      try {
        await (this as any).$dep.modelUseCase.createOfflineModel({
          title: this.applicationName,
          linkUrl: this.linkUrl,
          isActive: this.isActive ? "active" : "inactive",
          equipment_tag_id: this.contextMenuState.id,
        });

        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: `Create model successfully`,
          type: "success",
        });
        this.dialog = false;
        await this.getStructure();
      } catch (error) {
        //
      }
    },
    onChangeTab(tab: string) {
      this.isTab = tab;
    },
    closeModal() {
      this.modelName = "";
      this.applicationName = "";
      this.linkUrl = "";

      this.dialog = false;
    },
  },
});
