import Vue from "vue";

export default Vue.extend({
  methods: {
    getColorStatus(status: number | string): string {
      if (typeof status === "number") {
        return status === -1
          ? "offline"
          : status === 0
          ? "normal"
          : status === 1
          ? "warning"
          : status === 2
          ? "alarm"
          : "disabled";
      } else {
        return status === "-1"
          ? "offline"
          : status === "0"
          ? "normal"
          : status === "1"
          ? "warning"
          : status === "2"
          ? "alarm"
          : "disabled";
      }
    },
  },
});
