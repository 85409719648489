import { render, staticRenderFns } from "./TrAnalysisLocusGraph.vue?vue&type=template&id=43f3c706&scoped=true&"
import script from "./TrAnalysisLocusGraph.vue?vue&type=script&lang=ts&"
export * from "./TrAnalysisLocusGraph.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43f3c706",
  null
  
)

export default component.exports