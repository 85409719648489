import { HTTPClient } from "../common/HTTPClient";

import { Auth0Client } from "@auth0/auth0-spa-js";
import { CompanyClient } from "./CompanyClient";
import { MemberClient } from "./MemberClient";
import { EquipmentClient } from "./EquipmentClient";
import { ModelClient } from "./ModelClient";
import { EquipmentModelClient } from "./EquipmentModelClient";

export class Client {
  constructor(public auth0Client: Auth0Client, public httpClient: HTTPClient) {}

  public memberClient: MemberClient = new MemberClient((this as any).httpClient);
  public companyClient: CompanyClient = new CompanyClient((this as any).httpClient);
  public equipmentClient: EquipmentClient = new EquipmentClient((this as any).httpClient);
  public modelClient: ModelClient = new ModelClient((this as any).httpClient);

  public equipmentModelClient: EquipmentModelClient = new EquipmentModelClient((this as any).httpClient);
}
