
import Vue from "vue";

export default Vue.extend({
  name: "CreateFolderPage",
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openFolderFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openFolderFormModal", {
          ...this.$store.state.openFolderFormModal,
          open: value,
        });
      },
    },
    contextMenuStructure() {
      return this.$store.state.contextMenuStructure;
    },
    initailForm() {
      return this.$store.state.openFolderFormModal.param;
    },
    actionType() {
      return this.$store.state.openFolderFormModal.type;
    },
  },
  data() {
    return {
      name: "",
    };
  },
  methods: {
    onSubmit() {
      const param = {
        action_type: this.actionType,
        name: this.name,
        ...(this.actionType === "EDIT" && { id: this.initailForm.id }),
      };
      this.$emit("onSubmit", param);
      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.name = this.initailForm ? this.initailForm.name : "";
      }
    },
  },
});
