
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";
import colorStatus from "@/common/mixins/colorStatus";

export default Vue.extend({
  name: "HMainTankDGA",
  mixins: [colorStatus],
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      return this.$store.state.modelViewData.tr_summary_status.h;
    },
  },
});
