import { render, staticRenderFns } from "./FaultEventAlarmDetails.vue?vue&type=template&id=16cb72c6&scoped=true&"
import script from "./FaultEventAlarmDetails.vue?vue&type=script&lang=ts&"
export * from "./FaultEventAlarmDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16cb72c6",
  null
  
)

export default component.exports