import { RouteConfig } from "vue-router";

import CompaniesPage from "./pages/CompaniesPage.vue";
import CompanyPage from "./pages/CompanyPage.vue";
import CompanyMemberPage from "./pages/CompanyMemberPage.vue";

export const CompanyRouter: (prefix?: string) => RouteConfig[] = () => [
  {
    name: "CompaniesPage",
    path: `companies`,
    component: CompaniesPage,
    meta: { title: "Companies" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
  {
    name: "CompanyPage",
    path: `company/:id`,
    component: CompanyPage,
    meta: { title: "Company Page" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
  {
    name: "CompanyMemberPage",
    path: `company/:id/members`,
    component: CompanyMemberPage,
    meta: { title: "Member in company" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
];
