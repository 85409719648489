
import Vue from "vue";
import { mapActions } from "vuex";

import dialogMixin from "@/common/mixins/dialogModal";
import Container from "@/components/Container.vue";
import LoadingRedirectPage from "@/components/LoadingRedirectPage.vue";
import CreateCompanyPage from "@/features/company/pages/CreateCompanyPage.vue";
import ThreeDotIcon from "@/components/icons/ThreeDotIcon.vue";

export default Vue.extend({
  name: "CompaniesPage",
  mixins: [dialogMixin],
  components: { Container, LoadingRedirectPage, CreateCompanyPage, ThreeDotIcon },
  data() {
    return { companies: [], total: 0, isLoading: true, logoDefault: require("@/assets/images/background.png") };
  },
  async created() {
    await this.fetchCompanies();
  },
  methods: {
    ...mapActions(["getCompany", "getStructure"]),
    async fetchCompanies() {
      try {
        this.isLoading = true;
        const response = await (this as any).$dep.companyUseCase.getCompanies();
        this.companies = response.items;
        this.total = response.total;
      } catch (error) {
        this.companies = [];
        this.total = 0;
        //
      } finally {
        this.isLoading = false;
      }
    },
    async onSelected(company: any) {
      this.isLoading = true;
      const { id } = company;
      sessionStorage.setItem("company_id", id);
      await this.getCompany();
      await this.getStructure();
      this.isLoading = false;
      this.$router.push({ name: "CompanyPage", params: { id } });
    },
    imagePathError(event: any) {
      event.target.src = this.logoDefault;
    },
    onSubmitModal(param: any) {
      if (param.action_type === "CREATE") {
        this.createCompany(param);
      }
      if (param.action_type === "EDIT") {
        this.updateCompany(param);
      }
    },
    async createCompany(param: any) {
      try {
        this.isLoading = true;
        await (this as any).$dep.companyUseCase.createCompany(param);
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Create company successfully",
          type: "success",
        });
        await this.fetchCompanies();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: create company failed",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async updateCompany(param: any) {
      try {
        const { id, image_path, title, initial_code, description, start_date, end_date, rvs_upgrade } = param;
        this.isLoading = true;
        await (this as any).$dep.companyUseCase.updateCompany(id, {
          title,
          description,
          initial_code,
          image_path,
          start_date,
          end_date,
          rvs_upgrade,
        });
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Update company successfully",
          type: "success",
        });
        await this.fetchCompanies();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: update company failed",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCompany(id: number) {
      try {
        const is = await (this as any).dialogDelete("Do you want to delete this company?");
        if (is) {
          this.isLoading = true;
          await (this as any).$dep.companyUseCase.deleteCompany(id);
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: "Delete company successfully",
            type: "success",
          });
          await this.fetchCompanies();
        }
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: delete company failed",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
    manageMember(id: number, title: string) {
      this.$router.push({ name: "CompanyMemberPage", params: { id, title } });
    },
    convertExpiredDate(date: any) {
      if (!date) return "-";
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dt = new Date(date);
      const dateToday = new Date();
      const diffDate = dt.getTime() - dateToday.getTime();
      const dfDays = diffDate / (1000 * 60 * 60 * 24);

      if (dateToday < dt) {
        const day = `${dt.getDate()}`.padStart(2, "0");
        const month = `${monthNames[dt.getMonth()]}`;
        const year = `${dt.getFullYear()}`;
        return `${day} ${month} ${year} (${dfDays.toFixed(0)} days left)`;
      } else {
        const day = `${dt.getDate()}`.padStart(2, "0");
        const month = `${monthNames[dt.getMonth()]}`;
        const year = `${dt.getFullYear()}`;
        return `${day} ${month} ${year}`;
      }
    },
    checkDateExpired(date: any) {
      if (!date) return false;
      const dt = new Date(date);
      const dateToday = new Date();
      const diffDate = dt.getTime() - dateToday.getTime();
      const dfDays = diffDate / (1000 * 60 * 60 * 24);

      if (dfDays <= 30) return true;
      return false;
    },
  },
});
