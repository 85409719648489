
import Vue from "vue";
import { mapActions } from "vuex";

import TableCustom from "@/components/TableCustom.vue";
import EditIcon from "@/components/icons/EditIcon.vue";

export default Vue.extend({
  name: "ConfigurationInformation",
  components: { TableCustom, EditIcon },
  props: ["isSplit"],
  computed: {
    model() {
      return this.$store.getters.model;
    },
    items() {
      return this.$store.state.modelConfigurationInformation;
    },
    itemsA() {
      const datas = this.$store.state.modelConfigurationInformation;
      const halfLength = Math.ceil(datas.length / 2);

      return datas.slice(0, halfLength);
    },
    itemsB() {
      const datas = this.$store.state.modelConfigurationInformation;
      const halfLength = Math.ceil(datas.length / 2);

      return datas.slice(halfLength, datas.length);
    },
    ModelTypeName() {
      return this.$store.state.model.equipmentModelType.title;
    },
    ModelTypeId() {
      return this.$store.state.model.equipmentModelType.id;
    },
  },
  data() {
    const headers = [
      { text: "Type", value: "title", sortable: false, width: "40%" },
      { text: "Value", value: "value", sortable: false, width: "30%" },
      { text: "Unit", value: "unitCode", sortable: false, width: "30%" },
    ];

    const unicode = [
      { name: "celsius", htmlEntity: "&#8451;", src: "\u2103" },
      { name: "m3/h", htmlEntity: "m&#179;/h", src: "m\u00B3/h" },
    ];

    return { headers, unicode, dialog: false };
  },
  methods: {
    ...mapActions(["getModel"]),
    async onSave() {
      try {
        const editData = this.items.filter((prev) => prev.isEdit);
        for (const data of editData) {
          await this.fetchUpdateValue(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.dialog = false;
      }
    },
    async onClose() {
      this.dialog = false;

      const { id } = this.model;
      await this.getModel(id);
    },
    async fetchUpdateValue(data: any) {
      try {
        const { id, value } = data;
        await (this as any).$dep.modelUseCase.updateModelConfiguration(id, { value });
        setTimeout(async () => {
          await this.getModel(this.model.id);
        }, 1000);
      } catch (error) {
        console.log(error);
      } finally {
        const index = this.items.findIndex((prev) => prev.id === data.id);
        this.items[index].isEdit = false;
      }
    },
    onChangeValue(item: any, ev: any) {
      const index = this.items.findIndex((prev) => prev.id === item.id);
      this.items[index].value = ev;
      this.items[index].isEdit = true;
    },
  },
});
