var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('container',[_c('div',{staticClass:"flex items-center justify-between mb-[32px]"},[_c('div',[_c('h3',{staticClass:"text-[30px] font-[600] text-black"},[_vm._v("Company list")]),_c('span',{staticClass:"text-[16px] font-[400] text-[#747B83]"},[_vm._v(_vm._s(_vm.total.toLocaleString())+" company")])]),_c('create-company-page',{on:{"onSubmit":_vm.onSubmitModal}}),_c('button',{staticClass:"w-[196px] h-[44px] rounded-[6px] bg-[#FF0000]",attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.dispatch('openCompanyFormModal', {
          open: true,
          type: 'CREATE',
          param: null,
        })}}},[_c('span',{staticClass:"text-[#ffffff] font-[600]"},[_vm._v(" Create Company ")])])],1),(!_vm.isLoading)?_c('div',{staticClass:"grid grid-cols-4 gap-[24px]"},_vm._l((_vm.companies),function(company){return _c('div',{key:company.id,staticClass:"bg-[#ffffff] p-[24px] rounded-[6px] border-[0px] shadow cursor-pointer relative",on:{"click":function($event){return _vm.onSelected(company)}}},[_c('div',{staticClass:"grid grid-cols-[48px_1fr_28px] gap-x-[16px] items-center"},[_c('div',{staticClass:"w-[48px] h-[48px] border-[1px] border-border rounded-[6px]"},[(company?.image_path)?_c('img',{staticClass:"w-[46px] h-[46px] rounded-[inherit] object-cover",attrs:{"src":company.image_path,"alt":company.title},on:{"error":_vm.imagePathError}}):_c('img',{staticClass:"w-[46px] h-[46px] rounded-[inherit] object-cover",attrs:{"src":_vm.logoDefault,"alt":company.title}})]),_c('div',{staticClass:"flex flex-col justify-center"},[_c('span',{staticClass:"text-[16px] font-[400] text-black"},[_vm._v(_vm._s(company.title))]),_c('span',{class:_vm.checkDateExpired(company.end_date) ? 'text-[12px] text-[#F00000]' : 'text-[12px] text-[#747B83]'},[_vm._v("Expired: "+_vm._s(_vm.convertExpiredDate(company.end_date)))])]),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"flex items-center justify-center w-[28px] h-[28px]"},'div',attrs,false),on),[_c('three-dot-icon',{attrs:{"width":"4","height":"14","color":"#3C444B"}})],1)]}}],null,true)},[_c('div',{staticClass:"min-w-[192px] py-[10px] px-[16px]"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.manageMember(company.id, company.title)}}},[_c('img',{staticClass:"w-[16px] h-[16px]",attrs:{"src":require("@/assets/icons/members-solid-icon.svg"),"alt":"edit_company"}}),_vm._v(" Manage Member ")]),_c('li',{on:{"click":function($event){return _vm.$store.dispatch('openCompanyFormModal', {
                    open: true,
                    type: 'EDIT',
                    param: {
                      id: company.id,
                      title: company.title,
                      image_path: company.image_path,
                      initial_code: company.initial_code,
                      start_date: company.start_date,
                      end_date: company.end_date,
                      rvsUpgrade: company.rvs_upgrade,
                    },
                  })}}},[_c('img',{staticClass:"w-[16px] h-[16px]",attrs:{"src":require("@/assets/icons/edit-icon.svg"),"alt":"edit_company"}}),_vm._v(" Edit ")]),_c('li',{on:{"click":function($event){return _vm.deleteCompany(company.id)}}},[_c('img',{staticClass:"w-[16px] h-[16px]",attrs:{"src":require("@/assets/icons/delete-icon.svg"),"alt":"delete_company"}}),_vm._v(" Delete ")])])])])],1)])}),0):_c('div',{staticClass:"flex flex-col justify-center items-center h-[60vh]"},[_c('loading-redirect-page')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }