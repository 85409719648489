
import Vue from "vue";
import dayjs from "dayjs";
import axios from "axios";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "VcaTemperature",
  components: { highcharts: Chart, ButtonDanger, ButtonReload, DateTimeRange, LoadingGraph, NoDataAvailable },
  props: ["items"],
  data() {
    const date = new Date();
    return {
      source: null,
      isSensorType: {},
      sensorTypeData: [],
      selectDateType: "normal",
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      chartLoading: true,
      chartOptions: {
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: false,
            },
          },
        },
        scrollbar: {
          enabled: true,
          showFull: false,
          liveRedraw: false,
        },
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        yAxis: { title: { text: null }, min: null, max: null, plotLines: [] },
        title: { text: null },
        subtitle: { text: null },
        tooltip: {
          formatter: function () {
            let tooltipText = `<b>${this.x}</b><br/>`;
            const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            remaining.forEach((series) => {
              if (series.data.length > 0 && series.visible) {
                tooltipText += `<span style="color:${series.color}">\u25CF</span> <b>${series.name}: </b>${
                  series.yData[this.point.index]
                } &deg;C<br/>`;
              }
            });

            // Check if the series has only 1 line and add warning/alarm information
            if (remaining.length === 1) {
              const { warning, alarm } = this.series.options;

              tooltipText += `<span style="color:#FCA239">\u25CF</span> <b>Warning: </b>${warning || 0} &deg;C<br/>`;
              tooltipText += `<span style="color:#D9263B">\u25CF</span> <b>Alarm: </b>${alarm || 0} &deg;C<br/>`;
            }

            return tooltipText;
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
      cardSelected: 0,
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  async created() {
    this.cardSelected = this.items[0]?.tag.name;
    await this.fetchResultData();
  },
  methods: {
    async fetchSensorTypeData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelHealthMonitoringSensorType(this.isSecondaryTab, id);
        this.sensorTypeData = response.map((prev) => ({ id: prev.id, name: prev.name }));
        this.isSensorType = { id: this.sensorTypeData[0].id, name: this.sensorTypeData[0].name };
        this.chartResultData = [];
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = {};
      }
    },
    async fetchResultData() {
      try {
        this.chartLoading = true;
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        const { id, modelCode, equipmentId } = this.model;
        if (this.source) {
          this.source.cancel();
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        const response = await (this as any).$dep.modelUseCase.getVsdTemperatureResultData(
          {
            tagCode: this.cardSelected,
            modelCode: modelCode,
            startDate: this.startDate,
            endDate: this.endDate,
            equipmentId: equipmentId,
            modelId: id,
          },
          cancelToken
        );
        this.chartResultData = response;
        this.chartOptions.title.text = `Temperature : ${this.cardSelected}`;
        this.chartOptions.xAxis = this.aAxis(response);
        this.chartOptions.series = this.series(response);
        const warning = response.map((val) => val.warning).flat();
        const alarm = response.map((val) => val.alarm).flat();
        const combined = [...warning, ...alarm, ...response.map((val) => val.data).flat()];

        this.chartOptions.yAxis.min = Math.min(...combined) - 20;
        this.chartOptions.yAxis.max = Math.max(...combined) + 20;
        this.chartOptions.yAxis.plotLines = [
          {
            value: warning[0],
            width: 2,
            dashStyle: "dash",
            color: "#FCA239",
          },
          {
            value: alarm[0],
            width: 2,
            dashStyle: "dash",
            color: "#D9263B",
          },
        ];
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.source = null;
      }
    },
    onChangeSensorType(param: any) {
      const { id, name } = param;
      this.isSensorType = { id, name };
    },
    onChangeSelectDateType(param: any) {
      this.selectDateType = param;
    },
    aAxis(datas: any) {
      let labels = [];
      for (const res of datas) {
        labels.push(...res.timestamp);
      }
      this.xAxisMaxRange = labels.length;
      const formatDate = labels.map((label) => dayjs(label).format("DD-MM-YYYY HH:mm"));
      const count = formatDate.length;
      return {
        type: "category",
        labels: {
          align: "left",
          step: count <= 10 ? 0 : count <= 100 ? 1 : count <= 200 ? 2 : count <= 300 ? 3 : count <= 400 ? 4 : 5,
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: formatDate,
      };
    },
    series(datas: any) {
      const sers = datas.map((prev) => ({
        name: prev.tagCode,
        data: prev.data,
        lineWidth: 2,
      }));
      return sers;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `vsd_${id}_temperature`.toUpperCase();
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const result = {
        sname: `vsd_${this.$store.state.graphInformationTab.is_secondary_tab.name}`.toUpperCase().replace(/\s+/g, "_"),
        data: this.chartResultData.map((val, key) => {
          return val.data.map((value, idx) => {
            const temp = {
              timestamp: val.timestamp[idx],
              tagCode: val.tagCode,
              data: value,
              warning: val.warning[idx],
              alarm: val.alarm[idx],
            };
            return temp;
          });
        }),
      };
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(result.data.flat());
      XLSX.utils.book_append_sheet(wb, ws, result.sname); // => sheet name

      try {
        XLSX.writeFile(wb, `${name}.xlsx`);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
      }

      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },
    getStatus(code: number) {
      return code === 0 ? "success" : code === 1 ? "warning" : code === 2 ? "error" : "normal";
    },
    async onSelectCard(tageName: string) {
      this.cardSelected = tageName;
      await this.fetchResultData();
    },
    getResultType(type: string) {
      if (type === "rul_percent") return "RUL (%)";
      else return "Years";
    },
    findMathMinMax(data: number[]) {
      const min = Math.min(...data);
      const max = Math.max(...data);

      return { min: min < 0 ? min - 20 : 0, max: max + 20 };
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
  },
  watch: {
    async isSecondaryTab() {
      await this.fetchSensorTypeData();
    },
    async isSensorType() {
      await this.fetchResultData();
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
    async items() {
      this.cardSelected = this.items[0]?.tag.name;
      await this.fetchResultData();
    },
  },
});
