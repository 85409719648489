
import Vue from "vue";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "FaultEventAlarmAcknowledge",
  props: {
    item: { type: Object, default: null },
  },
  validations: {
    inputEmails: { required },
    inputComment: { required },
    validationGroup: ["inputEmails", "inputComment"],
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      isLoading: true,
      inputEmails: [],
      inputComment: null,
      dialog: false,
    };
  },
  methods: {
    async onSave(method: string) {
      return this.$swal({
        title: "Confirm",
        html: "Would you like to send a comment?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#db3d32",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const body = {
              comment_info: {
                fault_event_id: this.item.id,
                email_to: this.inputEmails,
                comment: this.inputComment,
              },
              data: {
                overAllStatus: this.item.alarmType === 2 ? "Alarm" : "Warning",
                date: dayjs(this.item.eventDate).format("DD-MM-YYYY"),
                time: dayjs(this.item.eventDate).format("HH:mm"),
                sensorId: this.item.sensorId,
                modelName: this.model.modelName,
                alarmMessage: this.item.alarmMessage,
                cause: this.item.cause,
                recommendation: this.item.recommendation,
                alarmValue: this.item.alarmValue,
                warningLimit: this.item.warningLimit,
                alarmLimit: this.item.alarmLimit,
              },
            };

            if (method === "CREATE") {
              await (this as any).$dep.modelUseCase.postFaultEventAcknowledge(this.model.equipmentId, this.user.email, body);
            }
            if (method === "UPDATE") {
              await (this as any).$dep.modelUseCase.updateFaultEventComments({ ...body, comment_type: 1 });
            }
          } catch (error) {
            console.log(error);
            alert(error.response?.data?.detail || "There was an error sending your data!");
          }
        },
        allowOutsideClick: () => false,
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          this.$store.dispatch("openAlertMessage", {
            open: true,
            message: "Your data was send.",
            type: "success",
          });
          (this as any).onCancel();
          this.$emit("reload");
        }
      });
    },
    onCancel() {
      (this as any).inputComment = null;
      (this as any).inputEmails = [];
      (this as any).dialog = false;
    },
    onInputComment(ev: any) {
      (this as any).inputComment = ev;
    },
    watchAcked() {
      (this as any).inputEmails = (this as any).item?.acknowledged_user || [];
      (this as any).inputComment = (this as any).item?.acknowledged_msg || null;
    },
    formatDate(date: any, format = "DD MMM YYYY, HH:mm") {
      return dayjs(date).format(format);
    },
  },
});
