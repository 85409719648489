
import Vue from "vue";
import ProfileUpdateModal from "./ProfileUpdateModal.vue";
import { MemberUpdateForm } from "../../features_old/member/forms/MemberUpdateForm";
import dialog from "../mixins/dialog";
import { mapActions } from "vuex";

export default Vue.extend({
  mixins: [dialog],
  components: {
    ProfileUpdateModal,
  },
  data() {
    const memberUpdateForm: MemberUpdateForm = {
      name: "",
      imageUrl: "",
      isUpdating: false,
      isShowModal: false,
    };
    return {
      memberUpdateForm,
    };
  },
  computed: {
    userImage() {
      return this.$store.getters.getCurrentUserImage;
    },
    currentRole() {
      return (this as any).$store.getters.getCurrentRole;
    },
    userProfile() {
      const userEmail = this.$store.getters.getCurrentUserEmail;
      return {
        email: userEmail,
      };
    },
  },
  async created() {
    const { ...profile } = await (this as any).$dep.authUseCase.getProfileToken();
    await this.setUserProfile(profile);
    this.setMemberUpdateForm();
  },
  methods: {
    ...mapActions(["setUserProfile"]),
    async logout() {
      (this as any).$dep.authUseCase.logout();
    },
    setMemberUpdateForm() {
      this.memberUpdateForm = {
        ...this.memberUpdateForm,
        name: this.$store.getters.getCurrentUserName,
        imageUrl: this.$store.getters.getCurrentUserImage,
      };
    },
    async updateProfile() {
      const { name, imageUrl: profileImageUrl } = this.memberUpdateForm;

      try {
        this.memberUpdateForm = { ...this.memberUpdateForm, isUpdating: true };
        const updateProfile = await (this as any).$dep.memberUseCase.updateMember({
          name,
          profileImageUrl,
        });

        const { ...profile } = await (this as any).$dep.authUseCase.getProfileToken();

        await this.setUserProfile({
          ...profile,
          name: updateProfile.name,
          picture: profileImageUrl,
        });
        await (this as any).alertSuccess("Successful", `Update Profile Successful`);
      } catch (error: any) {
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Update Profile!");
      } finally {
        this.memberUpdateForm = {
          ...this.memberUpdateForm,
          isUpdating: false,
          isShowModal: false,
        };
      }
    },
  },
});
