
import Vue from "vue";

import FormConfigurationInformation from "../forms/FormConfigurationInformation.vue";
import FormMotorDataTable from "../forms/FormMotorDataTable.vue";
import FormAlarmLimitSensorMCA from "../forms/FormAlarmLimitSensorMCA.vue";
import FormRecommendation from "../forms/FormRecommendation.vue";
import FormAlarmManagement from "../forms/FormAlarmManagement.vue";

export default Vue.extend({
  name: "McaForm",
  components: {
    FormConfigurationInformation,
    FormAlarmLimitSensorMCA,
    FormRecommendation,
    FormMotorDataTable,
    FormAlarmManagement,
  },
});
