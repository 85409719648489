
import Vue from "vue";
import { mapActions } from "vuex";

import DocumentFileReport from "./documentsFile/DocumentFileReport.vue";
import DocumentFileManual from "./documentsFile/DocumentFileManual.vue";

export default Vue.extend({
  name: "DocumentInformation",
  components: { DocumentFileReport, DocumentFileManual },
  async created() {
    await (this as any).getModelDocumentInformation();
  },
  methods: {
    ...mapActions(["getModelDocumentInformation"]),
  },
});
