import { AxiosResponse, CancelToken } from "axios";
import { HTTPClient } from "@/common/HTTPClient";

import { EquipmentModelFormType } from "@/features/model/models/EquipmentModelFormType";

export class EquipmentModelClient {
  constructor(private httpClient: HTTPClient) {}

  public async getModel(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}`,
    });
  }
  public async createModel(form: EquipmentModelFormType): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "POST",
      url: `/api/v1/equipment-models`,
      data: form,
    });
  }
  public async updateModel(modelId: number, form: EquipmentModelFormType): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "PUT",
      url: `/api/v1/equipment-models/${modelId}`,
      data: form,
    });
  }
  public async getModelMotorDataByIdModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/motor-data`,
      method: "GET",
    });

    return data;
  }
  public async getModelVandILocus(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/v_and_i_locus`,
    });
  }
  public async getAlarmLimitGroupMaster(modelTypeId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/alarm-limit-group_master/all?equipment_type=${modelTypeId}`,
    });
  }
  public async getAlarmLimitGroup(alarmLimitGroupId?: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: alarmLimitGroupId
        ? `/api/v1/equipment-models/alarm-limit-group/all?alarm_limit_group_id=${alarmLimitGroupId}`
        : "/api/v1/equipment-models/alarm-limit-group/all",
    });
  }
  public async getModelHealthMonitoringSensorType(healthMonitoringId: number, modelId?: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: modelId
        ? `/api/v1/equipment-models/alarm-limit-group/${healthMonitoringId}/sensor-type?equipment_model_id=${modelId}`
        : `/api/v1/equipment-models/alarm-limit-group/${healthMonitoringId}/sensor-type`,
    });
  }
  public async getModelConfigurationInformationForm(modelTypeId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipments/model-types/${modelTypeId}/configuration-informations`,
    });
  }
  public async getAlarmLimitSensorMasterForm(modelTypeId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelTypeId}/alarm_limit_sensor_master`,
    });
  }
  public async getVcaAlarmLimitSensorMasterForm(modelTypeId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelTypeId}/vca_alarm_limit_sensor_master`,
    });
  }
  public async getVsdAlarmLimitSensorMasterForm(modelTypeId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelTypeId}/vsd_alarm_limit_sensor_master`,
    });
  }
  public async getVsdTrendSignalAlarmLimitSensorMasterForm(): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/vsd_trend_signal_alarm_limit_sensor_master/all`,
    });
  }
  public async getAlarmLimitSensorNewForm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/alarm-limit-sensor`,
    });
  }
  public async getVsdAlarmLimitSensorNewForm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/vsd-alarm-limit-sensor`,
    });
  }
  public async getTrSummaryStatus(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/tr-summary-status/${modelId}`,
    });
  }
  public async getTrHealMonitoringResultData(
    modelId: number,
    modelCode: string,
    sensorId: number,
    startDate: string,
    endDate: string,
    isNormal: string,
    equipmentId: number,
    cancelToken: CancelToken
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/health_monitoring/tr-model-result-data/new?modelCode=${modelCode}&equipment_model_id=${modelId}&sensor_type_id=${
        sensorId || 0
      }&start_date=${startDate}&end_date=${endDate}&is_normal=${isNormal}&equipment_tag_id=${equipmentId}`,
      cancelToken,
    });
  }
  public async getNoteHistoryLogAll(
    modelId: number,
    equipmentId: number,
    stateDate: string,
    endDate: string
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/note/get/note-topic/all?equipment_model_id=${modelId}&equipment_tag_id=${equipmentId}&startDate=${stateDate}&endDate=${endDate}`,
    });
  }
  public async createNoteHistoryLog(
    equipmentId: number,
    params: {
      topic: string;
      description: string;
      equipmentModelId: number;
    }
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "POST",
      url: `/api/v1/note/note-topic?equipment_tag_id=${equipmentId}`,
      data: params,
    });
  }
  public async updateNoteHistoryLog(
    noteId: number,
    params: {
      topic: string;
      description: string;
      equipmentModelId: number;
    }
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "PUT",
      url: `/api/v1/note/update/note-topic?note_topic_id=${noteId}`,
      data: params,
    });
  }
  public async deleteNoteHistoryLog(noteId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "DELETE",
      url: `/api/v1/note/note-topic/${noteId}`,
    });
  }
  public async getNoteHistoryLogComment(noteId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/note/note/comment?note_topic_id=${noteId}`,
    });
  }
  public async sendMessageNoteHistoryLog(
    equipmentId: number,
    params: {
      description: string;
      equipmentModelId: number;
      noteTopicId: number;
    }
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "POST",
      url: `/api/v1/note-comment?equipment_tag_id=${equipmentId}`,
      data: params,
    });
  }
  public async updateMessageNoteHistoryLog(
    commentId: number,
    params: {
      description: string;
      equipmentModelId: number;
      noteTopicId: number;
    }
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "PUT",
      url: `/api/v1/note-comment/${commentId}`,
      data: params,
    });
  }
  public async deleteMessageNoteHistoryLog(commentId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "DELETE",
      url: `/api/v1/note-comment/${commentId}`,
    });
  }
  public async getCountFaultEventAlarm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/fault-eventfault-event-count?equipment_model_id=${modelId}`,
    });
  }
  public async getProcessViLocusDataTr(params: any): Promise<AxiosResponse> {
    return await this.httpClient.request({
      baseURL: process.env.VUE_APP_REPCO_API_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        // Accept: "application/json",
      },
      method: "POST",
      url: `/process-vi-locus-data-tr`,
      data: params,
    });
  }
  public async getTrDgaCalData(
    modelCode: string,
    modelId: number,
    sennsorName: string,
    datetime: string,
    equipmentId: number
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/advance-analysis/tr-model-result-data/new/dga-result?modelCode=${modelCode}&equipment_model_id=${modelId}&sensor_type_name=${sennsorName}&start_date=${datetime}&equipment_tag_id=${equipmentId}`,
    });
  }
  public async getTrDgaRawData(modelId: number, sennsorName: string, equipmentId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/advance-analysis/tr-model-result-data/new/dga-raw?equipment_model_id=${modelId}&sensor_type_name=${sennsorName}&equipment_tag_id=${equipmentId}`,
    });
  }
  public async getTrDgaLabelName(): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/advance-analysis/tr-model-result-data/new/dga-detail`,
    });
  }
  public async getTrVandLocus(modelId: number, equipmentId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vi-locus/vi_locus?equipment_model_id=${modelId}&equipment_tag_id=${equipmentId}`,
    });
  }
  public async getTrVandLocusFilename(modelCode: string, sensorType: string): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/tr_model_result_data/filename?model_code=${modelCode.toLowerCase()}&type=vi_locus&module=${sensorType}&sub_module=last_present`,
    });
  }
  public async getTrVandLocusFilenameDefault(
    modelId: number,
    equipmentId: number,
    locusName: string
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vi-locus/last-present?equipment_tag_id=${equipmentId}&equipment_model_Id=${modelId}&locus_name=${locusName}`,
    });
  }
  public async getTrVandLocusResultData(
    sensorType: string,
    equipmentId: number,
    modelCode: string,
    fileNamePresent: string,
    filenameLast: string
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vi-locus/vi_locus/${sensorType}?equipment_tag_id=${equipmentId}&model_code=${modelCode.toLowerCase()}&file_name_present=${fileNamePresent}&file_name_last=${filenameLast}`,
    });
  }

  public async getTrPdFilename(modelCode: string, subModuleType: string): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/tr_model_result_data/filename?model_code=${modelCode.toLowerCase()}&type=pd&module=prpd&sub_module=${subModuleType}`,
    });
  }

  public async getTrPdResultData(equipmentId: number, modelCode: string, fileName: string): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/advance-analysis/pd/prpd/drain_valve?model_code=${modelCode.toLowerCase()}&type=pd&file_name=${fileName}&equipment_tag_id=${equipmentId}`,
    });
  }
  public async getTrLossesResultData(
    modelId: number,
    modelCode: string,
    equipmentId: number,
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/advance-analysis/tr-model-result-data/new/transformer-losses?modelCode=${modelCode.toLowerCase()}&equipment_model_id=${modelId}&sensor_type_id=22&start_date=${startDate}&end_date=${endDate}&equipment_tag_id=${equipmentId}`,
    });
  }
  public async getTRDiagramStatus(modelId: number, companyId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/tr-model-result-data/summary-status?equipment_model_id=${modelId}&company_id=${companyId}`,
    });
  }
  public async getVsdSensorType(type: string): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: type
        ? `/api/v1/equipment-models/alarm-limit-group/vsd-sensor-type?vsdtype=vsdtag`
        : `/api/v1/equipment-models/alarm-limit-group/vsd-sensor-type`,
    });
  }
  public async getVsdTrendsignalResultData(
    modelId: number,
    modelCode: string,
    sensorId: number,
    startDate: string,
    endDate: string,
    isNormal: string,
    equipmentId: number,
    cancelToken: CancelToken
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vca-model-trend-signal/trend-signal/new?modelCode=${modelCode}&equipment_model_id=${modelId}&sensor_type_id=${
        sensorId || 0
      }&start_date=${startDate}&end_date=${endDate}&is_normal=${isNormal}&equipment_tag_id=${equipmentId}`,
      cancelToken,
    });
  }
  public async getVsdRulResultData(
    tagCode: string,
    modelCode: string,
    startDate: string,
    endDate: string,
    equipmentId: number,
    model_id: number,
    cancelToken: CancelToken
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vca-result-data/rul?tagCode=${tagCode}&modelCode=${modelCode}&start_date=${startDate}&end_date=${endDate}&equipment_tag_id=${equipmentId}&model_id=${model_id}`,
      cancelToken,
    });
  }
  public async getVsdTemperatureResultData(
    tagCode: string,
    modelCode: string,
    startDate: string,
    endDate: string,
    equipmentId: number,
    modelId: number,
    cancelToken: CancelToken
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/vca-result-data/temperature?tagCode=${tagCode}&modelCode=${modelCode}&start_date=${startDate}&end_date=${endDate}&equipment_tag_id=${equipmentId}&model_id=${modelId}`,
      cancelToken,
    });
  }
  public async getVsdTrendSignalAlarmLimitSensorForm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/vsd_trend_signal_alarm_limit_sensor`,
    });
  }
  public async getMcaAlarmLimitSensorMasterForm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/mca_alarm_limit_sensor_master/all`,
    });
  }
  public async getMcaAlarmLimitSensorForm(modelId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/equipment-models/${modelId}/mca_alarm_limit_sensor`,
    });
  }
  public async getMcaResultModelDataNew(
    modelCode: string,
    resultGroupId: number,
    startDate: string,
    endDate: string,
    isNormal: boolean,
    modelId: number
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/mca-result-data-new/${modelId}/result_data_new?modelCode=${modelCode}&result_group_id=${resultGroupId}&start_date=${startDate}&end_date=${endDate}&is_normal=${isNormal}`,
    });
  }
  public async getMcaResultDiagnostic(
    modelCode: string,
    resultGroupId: number,
    startDate: string,
    endDate: string,
    modelId: number
  ): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/mca-result-diagnostic/${modelId}/result_dianostic?modelCode=${modelCode}&result_group_id=${resultGroupId}&start_date=${startDate}&end_date=${endDate}`,
    });
  }
  public async getOverallModelStatus(companyId: number): Promise<AxiosResponse> {
    return await this.httpClient.request({
      method: "GET",
      url: `/api/v1/model_summary/overall_status?company_id=${companyId}`,
    });
  }

  public async getAlarManagement(modelCode: string): Promise<any> {
    const res = await this.httpClient.request({
      url: `/api/v1/equipment-models/alarm-management/persistence/${modelCode}`,
      method: "GET",
    });

    return res;
  }

  public async getAlarmManagementMaster(): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/equipment-models/alarm-management/master`,
      method: "GET",
    });

    return res;
  }

  public async getOverallStatusRvs(company_id: number): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/rvs-failure-mode/overall-failure-mode/${company_id}`,
      method: "GET",
    });

    return res;
  }

  public async getOverallStatusMca(company_id: number): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/model_summary/overall_status/mca?company_id=${company_id}`,
      method: "GET",
    });

    return res;
  }

  public async getOverallStatusVca(company_id: number): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/model_summary/overall_status/vca?company_id=${company_id}`,
      method: "GET",
    });

    return res;
  }

  public async getFailureModeRvs(model_code: string): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/rvs-failure-mode/model-failure-mode/${model_code}`,
      method: "GET",
    });

    return res;
  }

  public async getFailureModeRvsGraph(
    model_code: string,
    sensor_id: string,
    startdate: string,
    enddate: string
  ): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/rvs-failure-mode/graph/${model_code}-failure-mode/${sensor_id}?startdate=${startdate}&enddate=${enddate}`,
      method: "GET",
    });

    return res;
  }

  public async getRvsAxis(sensor_id: string): Promise<any> {
    const res = await this.httpClient.request({
      url: `api/v1/rvs-graph-result/axis-filter?sensor_id=${sensor_id}`,
      method: "GET",
    });

    return res;
  }
}
