
import Vue from "vue";

export default Vue.extend({
  name: "diagramVca",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modelId() {
      return this.$store.getters.model.id;
    },
    companyId() {
      return this.$store.getters.company.id;
    },
    items() {
      return this.$store.state.modelViewData.vca_diagram_status;
    },
    overall() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter(
          (val) => val.tagCode === "overall-status" || val.tagCode === "overall_status"
        );
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    dcLink() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "dc_link_capacitor");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    environment() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "environment");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    stress() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "stress");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    coolingFan() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "cooling_fan");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    powerUnit() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "power_unit");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    temp() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "temperature");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    rul() {
      let status = -1;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "rul");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
  },
  methods: {
    statusName(code: number) {
      if (code === -1) return "offline";
      if (code === 0) return "normal";
      if (code === 1) return "warning";
      if (code === 2) return "alarm";
      if (code === -2) return "disabled";
      else return "";
    },
  },
});
