import { VueConstructor } from "vue";

import { Auth0Config } from "@/auth0-config";
import { Auth0Client } from "@auth0/auth0-spa-js";

import { Config } from "@/config";
import { HTTPClient } from "@/common/HTTPClient";
import { Client } from "@/clients/client";

import { AuthUseCase } from "@/features/auth/AuthUseCase";
import { CompanyUseCase } from "@/features_old/company/CompanyUseCase";
import { MemberUseCase } from "@/features_old/member/MemberUseCase";
import { EquipmentUseCase } from "@/features_old/equipmentTag/EquipmentUseCase";
import { FolderUseCase } from "@/features_old/folder/FolderUseCase";
// import { ModelUseCase } from "@/features_old/model/ModelUseCase";

// TODO: รวม usecase ของ model และ equipment ทั้งหมดในไฟล์เดียว
import { ModelUseCase } from "@/features/model/ModelUseCase";

const { domain, client_id, connection, redirect_uri, logout_redirect_uri } = Auth0Config;
export const auth0Client = new Auth0Client({
  domain,
  client_id,
  redirect_uri,
  connection,
});

const { baseURL, assetUploadURL } = Config;
export const httpClient = new HTTPClient({
  baseURL,
  assetUploadURL,
});
export const client = new Client(auth0Client, httpClient);

export const authUseCase = new AuthUseCase(client, { logout_redirect_uri });
export const companyUseCase = new CompanyUseCase(client);
export const memberUseCase = new MemberUseCase(client);
export const folderUseCase = new FolderUseCase(client);
export const equipmentUseCase = new EquipmentUseCase(client);
export const modelUseCase = new ModelUseCase(client);

export interface Dependency {
  httpClient: HTTPClient;
  authUseCase: AuthUseCase;
  companyUseCase: CompanyUseCase;
  memberUseCase: MemberUseCase;
  folderUseCase: FolderUseCase;
  equipmentUseCase: EquipmentUseCase;
  modelUseCase: ModelUseCase;
}

export const DependencyPlugin = {
  install(Vue: VueConstructor) {
    const dep: Dependency = {
      httpClient,
      authUseCase,
      companyUseCase,
      memberUseCase,
      folderUseCase,
      equipmentUseCase,
      modelUseCase,
    };

    Object.defineProperty(Vue.prototype, "$dep", {
      get() {
        return dep;
      },
    });
  },
};
