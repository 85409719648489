
import Vue from "vue";

import TableCustom from "@/components/TableCustom.vue";

export default Vue.extend({
  name: "FormVsdTagAlarmLimitSensor",
  components: { TableCustom },
  computed: {
    items: {
      get() {
        return this.$store.state.updateModelForm.vsd_tag_alarm_limit_in;
      },
    },
    filteredItems() {
      return (this as any).items.filter((item: any) => {
        return item.sensorTypeId === this.healthMonitoringSensorType;
      });
    },
    modelType() {
      return this.$store.getters.model.modelTypeId;
    },
  },
  data() {
    const headerTransformer = [
      { text: "Sensor name", value: "sensorName", sortable: false, width: "30%" },
      { text: "Unit", value: "unit", sortable: false, width: "20%" },
      { text: "Warning", value: "warning", sortable: false, width: "20%" },
      { text: "Alarm", value: "alarm", sortable: false, width: "20%" },
      { text: "Criteria", value: "criteria", sortable: false, width: "10%" },
    ];

    const headerVCA = [
      {
        text: "System Tag Name",
        align: "start",
        sortable: false,
        value: "modelConfigurationTag.title",
      },
      {
        text: "Tag Name",
        align: "start",
        sortable: false,
        value: "modelConfigurationTag.title",
      },
      {
        text: "Temperature Warning",
        align: "center",
        sortable: false,
        value: "warning",
      },
      {
        text: "Temperature Alarm",
        align: "center",
        sortable: false,
        value: "alarm",
      },
      {
        text: "RUL Warning",
        align: "center",
        sortable: false,
        value: "warning",
      },
      {
        text: "RUL Alarm",
        align: "center",
        sortable: false,
        value: "alarm",
      },
      {
        text: "Initial usage duration (day)",
        align: "center",
        sortable: false,
        value: "initial_day",
      },
      {
        text: "RUL sampling rate (day)",
        align: "center",
        sortable: false,
        value: "avg_rate",
      },
      {
        text: "L0 (day)",
        align: "center",
        sortable: false,
        value: "l0",
      },
      {
        text: "Tmax (C)",
        align: "center",
        sortable: false,
        value: "tmax",
      },
    ];

    return {
      headerTransformer,
      healthMonitoringType: 1,
      healthMonitoringSensorType: 1,
      healthMonitoringTypeItems: [],
      healthMonitoringSensorTypeItems: [],
      headerVCA,
    };
  },
  async created() {
    await this.fetchHealthMonitoringType();
  },
  methods: {
    checkHeader() {
      switch (this.modelType) {
        case 1:
          return this.headerTransformer;
        case 2:
          return this.headerMCA;
        case 3:
          return this.headerVCA;
      }
    },
    async fetchHealthMonitoringType() {
      try {
        const res = await Vue.prototype.$dep.modelUseCase.getVsdSensorType("vsdtag");
        const data = res.filter((val) => val.equipmentModelTypeId == this.modelType);
        this.healthMonitoringType = data[0].id;
        this.healthMonitoringTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));

        await this.fetchHealthMonitoringSensorType(data[0].id);
      } catch (error) {
        this.healthMonitoringTypeItems = [];
      }
    },
    async fetchHealthMonitoringSensorType(id: number) {
      try {
        const data = await Vue.prototype.$dep.modelUseCase.getModelHealthMonitoringSensorType(id);
        this.healthMonitoringSensorType = data[0].id;
        this.healthMonitoringSensorTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));
      } catch (error) {
        this.healthMonitoringSensorTypeItems = [];
      }
    },
    async onHealthMonitoringTypeChange(id: number) {
      try {
        this.healthMonitoringType = id;
        await this.fetchHealthMonitoringSensorType(id);
      } catch (error) {
        console.log(error);
      }
    },

    onUpdateState() {
      const isSelected = this.items.filter((item: any) => item.isSelect);
      const duplicate = isSelected
        .reduce((acc: any, current: any) => {
          const x = acc.find((item: any) => item.sensorTypeId === current.sensorTypeId);
          return !x ? acc.concat([current]) : acc;
        }, [])
        .map((row: any) => ({ sensorTypeId: row.sensorTypeId }));

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        vsd_tag_alarm_limit_in: this.items.map((item: any) => ({
          ...item,
          alarm: +item.alarm,
          warning: +item.warning,
          expectedLifeTime: +item.expectedLifeTime,
        })),
      });
    },
    onSelectAll(props: { items: any[]; value: boolean }) {
      const { items, value } = props;

      const x = Object.fromEntries(items.map((o) => [o.id, { ...o, isSelect: value }]));
      const result = this.items.map((o: any) => x[o.id] || o);

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        vsd_tag_alarm_limit_in: result,
      });
    },
  },
});
