
import Vue from "vue";
import { required } from "vuelidate/lib/validators";

export interface FormNoteHistoryLogPropsType {
  topic: string;
  description: string;
  isUpdating: boolean;
  isShowModal: boolean;
}

export default Vue.extend({
  name: "FormNoteHistoryLog",
  validations: {
    value: {
      topic: { required },
      description: { required },
    },
    validationGroup: ["value.topic", "value.description"],
  },
  props: {
    value: {
      type: Object as () => FormNoteHistoryLogPropsType,
      default: () => ({
        topic: "",
        description: "",
        isUpdating: false,
        isShowModal: false,
      }),
    },
  },
  data() {
    return {
      maxLength: 1000,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", { ...(this as any).value });
      this.clearForm();
    },
    onClose() {
      this.$emit("close");
      this.clearForm();
    },
    clearForm() {
      (this as any).value.topic = "";
      (this as any).value.description = "";
      (this as any).value.isUpdating = false;
      (this as any).value.isShowModal = false;
    },
  },
});
