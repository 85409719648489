
import { MemberUpdateForm } from "@/features_old/member/forms/MemberUpdateForm";
import Vue from "vue";
import { proxyModel } from "../utils/proxyModel";
export default Vue.extend({
  props: {
    value: {
      type: Object as () => MemberUpdateForm,
    },
    userProfile: {
      type: Object,
    },
  },
  data() {
    return {
      isUploading: false,
    };
  },
  computed: {
    ...proxyModel("isUpdating", "isShowModal", "name", "imageUrl"),
  },
  methods: {
    openUploadImage() {
      (this as any).$refs.uploader.click();
    },
    async uploadImage(e: any) {
      (this as any).isUploading = true;
      const imageType = e.target.files[0].type;
      if (imageType !== "image/jpeg" && imageType !== "image/jpg" && imageType !== "image/png") {
        (this as any).isUploading = false;
        return (this as any).alertError("Error", "Please Select JPG, JPEG, PNG file only");
      }
      let formdata = new FormData();
      const customImageName = `image_profile_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}`;
      formdata.append("image_file", e.target.files[0], customImageName);

      try {
        const { url } = await (this as any).$dep.memberUseCase.uploadProfileImage(formdata);
        (this as any).imageUrl = url;
        (this as any).isUploading = false;
      } catch (error: any) {
        (this as any).isUploading = false;
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Upload Profile Image!");
      }
    },
    update() {
      this.$emit("update");
    },
    closeModalUpdateProfile() {
      (this as any).value.isShowModal = false;
    },
  },
});
