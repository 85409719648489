
import Vue from "vue";
import pagination from "@/common/mixins/pagination";
import dialog from "@/common/mixins/dialog";
import { mapActions } from "vuex";

import { Member, MemberStatus } from "@/features/company/models/Member";
import { MemberPaginationForm } from "@/features/company/models/MemberPaginationForm";

import Container from "@/components/Container.vue";
import MemberInviteModal from "../../company/components/MemberInviteModal.vue";
import MemberEditInviteModal from "../../company/components/MemberEditInviteModal.vue";
import { MemberInviteForm } from "../../company/models/MemberInviteForm";
import { MemberEditInviteForm } from "../../company/models/MemberEditInviteForm";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";

export default Vue.extend({
  name: "AssignMemberEquipment",
  mixins: [pagination, dialog],
  components: {
    Container,
    DeleteIcon,
    MemberEditInviteModal,
    MemberInviteModal,
  },
  data() {
    const isLoading = false;

    const memberHeaders = [
      { text: "", value: "action" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Role", value: "role" },
      { text: "Status", value: "status" },
      { text: "", value: "edit" },
    ];

    const members: Member[] = [];

    const memberPaginationForm: MemberPaginationForm = {
      search: "",
      page: 0,
      perPage: 10,
    };

    const rowsPerPageItems: Array<any> = [5, 10, 20, 30, 40];
    const paginations: any = {};

    const memberInviteForm: MemberInviteForm = {
      isShowModal: false,
      isLoading: false,
      email: "",
      role: "",
    };

    const memberEditInviteForm: MemberEditInviteForm = {
      isLoading: false,
      role: "",
      email: "",
    };

    return {
      isLoading,
      memberHeaders,
      members,
      memberPaginationForm,
      rowsPerPageItems,
      paginations,
      memberInviteForm,
      memberEditInviteForm,
    };
  },
  computed: {
    isShowModal: {
      get(): boolean {
        return this.$store.getters.getMemberAssignForm.isShowModal;
      },
      async set(value: boolean) {
        await this.closeMemberAssignModal();
      },
    },
    equipmentTagId() {
      return this.$store.getters.getMemberAssignForm.equipmentTagId;
    },
    equipmentTagName() {
      return this.$store.getters.getMemberAssignForm.equipmentTagName || "";
    },
    hasInviteMemberToCompanyPermission() {
      const role = this.$store.getters.user?.role;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;
    },
    hasRemoveMemberFromCompanyPermission() {
      const role = this.$store.getters.user?.role;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      return permission;
    },
    memberList() {
      return (this as any).members.map((member: Member) => {
        const statusColor = (() => {
          if (member.status == MemberStatus.ACTIVE) {
            return "success";
          } else if (member.status == MemberStatus.PENDING) {
            return "warning";
          }
        })();

        return {
          id: member.id,
          name: member.name,
          email: member.email,
          statusColor,
          status: member.status,
          profileImageUrl: member.profileImageUrl,
          role: member.role,
        };
      });
    },
    search: {
      get() {
        return this.$store.getters.getMemberAssignForm.search;
      },
      set(value: string) {
        this.setMemberAssignModal({
          selectedMemberIds: this.selectedMemberIds,
          search: value,
        });
      },
    },
    selectedMemberIds: {
      get() {
        return this.$store.getters.getMemberAssignForm.selectedMemberIds;
      },
      set(value) {
        this.setMemberAssignModal({
          selectedMemberIds: value,
          search: this.search,
        });
      },
    },
    company() {
      return this.$store.getters.company;
    },
  },
  methods: {
    ...mapActions(["setMemberAssignModal", "closeMemberAssignModal"]),
    async getMembers() {
      this.members = [];

      const company_id = this.company.id;
      const { items: dataMemberMembers } = await (this as any).$dep.memberUseCase.getMemberMembers(this.equipmentTagId);
      const { items: dataMember } = await (this as any).$dep.memberUseCase.getMembersOnly(company_id);

      const filter = dataMember.filter((dataMemberEl: any) => {
        return (
          dataMemberMembers.filter((dataMemberMembersEl: any) => {
            return dataMemberMembersEl.id === dataMemberEl.id;
          }).length === 0
        );
      });

      this.members = [...filter, ...dataMemberMembers];
    },
    async getFolderAssignMembers(id: number) {
      const assignMembers = await (this as any).$dep.equipmentUseCase.getFolderAssignMembers(id);

      const selectedMemberIds = assignMembers.items.map((e: any) => e.id);

      this.setMemberAssignModal({ selectedMemberIds, search: this.search });
    },
    updatePagination(val: any) {
      const page = val.page;
      const perPage = val.itemsPerPage;

      (this as any).memberPaginationForm = {
        ...(this as any).memberPaginationForm,
        page,
        perPage,
      };
    },
    async save() {
      try {
        this.isLoading = true;
        const company_id = this.company.id;
        const { equipmentTagId, selectedMemberIds } = this.$store.getters.getMemberAssignForm;
        const member_option = [];

        for (const member of this.members) {
          const { id } = member;
          const find = selectedMemberIds.find((param: any) => param === id);
          if (find) {
            member_option.push({ id, is_create: true, is_delete: false });
          } else {
            member_option.push({ id, is_create: false, is_delete: true });
          }
        }

        const body = {
          company_id,
          equipment_tag_id: equipmentTagId,
          member_option,
        };

        await (this as any).$dep.equipmentUseCase.updateFolderAssignMembers(body);

        this.closeMemberAssignModal();
      } catch (error: any) {
        const msgError = error.response?.data?.detail || "Error Assign Member";
        await (this as any).alertError("Error", msgError);
      } finally {
        this.isLoading = false;
      }
    },
    openMemberInviteModal() {
      this.memberInviteForm = { ...this.memberInviteForm, isShowModal: true };
    },
    closeMemberInviteModal() {
      this.memberInviteForm = {
        ...this.memberInviteForm,
        isShowModal: false,
        email: "",
        role: "",
      };
    },
    closeMemberEditInviteModal() {
      this.memberEditInviteForm = {
        ...this.memberEditInviteForm,
        isShowModal: false,
        member: undefined,
      };
    },
    async inviteMemberInviteModal(params: any) {
      try {
        this.isLoading = true;
        this.memberInviteForm = { ...params, isLoading: true };
        await (this as any).$dep.memberUseCase.inviteMember(this.companyId, this.memberInviteForm);

        this.closeMemberInviteModal();
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Invite member successfully",
          type: "success",
        });
        await this.getMembers();
      } catch (error: any) {
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Create Admin!");
      } finally {
        this.memberInviteForm = { ...params, isLoading: false };
        this.isLoading = false;
      }
    },
    async editMemberEditInviteModal(params: any) {
      try {
        this.isLoading = true;
        this.memberEditInviteForm = {
          ...params,
          isLoading: true,
        };

        await (this as any).$dep.memberUseCase.updateMemberProfile(this.memberEditInviteForm);

        this.closeMemberEditInviteModal();
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Edit member successfully",
          type: "success",
        });
        await this.getMembers();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: edit member failed",
          type: "error",
        });
      } finally {
        this.isLoading = false;
        this.memberEditInviteForm = {
          ...this.memberEditInviteForm,
          isLoading: false,
        };
      }
    },
    async resendInviteMember(email: string) {
      try {
        await (this as any).$dep.memberUseCase.resendInviteMember(email);
        await (this as any).alertSuccess("Success", `resend invite completed`);
      } catch (error: any) {
        //
        (this as any).alertError("Error", error.response?.data?.detail || "Can not Resend Invite Member!");
      }
    },
    async updateMember(memberId: number) {
      const member = this.members.find((e) => e.id === memberId);

      this.memberEditInviteForm = {
        ...this.memberEditInviteForm,
        isShowModal: true,
        member,
        role: member?.role || "",
      };
    },
    async restoreMember(data: any) {
      const { name, email } = data;
      const isComfirmed = await (this as any).alertBasic(
        name,
        `<p>Are you sure you want to active this member?</p>`,
        "question",
        "Confirm"
      );

      if (isComfirmed) {
        try {
          this.isLoading = true;
          this.members = [];

          await (this as any).$dep.memberUseCase.activeMember(email);
          await (this as any).alertSuccess("Success", `"${email}" has been active`);
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Active Member!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;

          await this.getMembers();
        }
      }
    },
    async deleteMember(email: string) {
      const isConfirmed = await (this as any).alertConfirm("ลบ Member", `คุณต้องการลบ ${email} ใช่หรือไม่ ?`);
      if (isConfirmed) {
        try {
          this.isLoading = true;
          this.members = [];

          await (this as any).$dep.memberUseCase.deleteMember(email);
          await (this as any).alertSuccess("ลบ Member สำเร็จ", ``);
        } catch (error) {
          process.env.NODE_ENV === "production"
            ? (this as any).alertError("Error", "ไม่สามารถลบ Member ได้")
            : (this as any).alertError("Error", error);
        } finally {
          this.isLoading = false;
          await this.getMembers();
        }
      }
    },
    isOnPermission(status: string, type: string) {
      if (type === "ACTIVE") {
        return ["inactive"].includes(status);
      }
      if (type === "DELETE") {
        return ["active", "pending"].includes(status);
      }
      if (type === "RESEND_INVITE") {
        return ["pending"].includes(status);
      }
      if (type === "SWITCH_ROLE") {
        return ["active"].includes(status);
      }
      if (type === "EDIT_ROLE") {
        return ["active"].includes(status);
      }

      return false;
    },
    async onOpenEditMemberModal(memberId: number) {
      await this.updateMember(memberId);
      (this as any).$store.dispatch("openEditMemberModal", {
        open: true,
        type: "CREATE",
        param: null,
      });
    },
  },
  watch: {
    async isShowModal(value) {
      this.searchField = "";
      if (value) {
        try {
          this.isLoading = true;

          await Promise.all([this.getMembers(), this.getFolderAssignMembers(this.equipmentTagId)]);
        } catch (error: any) {
          //
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
});
