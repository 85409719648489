
import Vue from "vue";

import NoDataAvailable from "@/components/NoDataAvailable.vue";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "DiagramRvs",
  components: { NoDataAvailable, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    diagramRvs() {
      return (this as any).$store.state.modelViewData?.rvs?.rvs_diagram_image?.diagram_url || null;
    },
  },
  data() {
    return {
      imageIsFullScreen: false,
      imageUrl: "",
    };
  },
  methods: {
    fullscreen(src: string) {
      if (src) {
        this.imageIsFullScreen = true;
        this.imageUrl = src;
      }
    },
  },
});
