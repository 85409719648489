import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosError } from "axios";
export interface HTTPClientOptions {
  baseURL: string;
  assetUploadURL: string;
}

export interface OAuthToken {
  accessToken: string;
}

export class HTTPClient {
  private client: AxiosInstance;
  private baseURL: string;
  private assetUploadURL: string;
  public accessToken: string | undefined;

  constructor({ baseURL, assetUploadURL }: HTTPClientOptions) {
    this.baseURL = baseURL;
    this.assetUploadURL = assetUploadURL;
    this.accessToken = this.cacheAccessToken;
    this.client = axios.create({ baseURL });
    this.setupClient();
  }

  public async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    let configWithAuthorization = null;
    if (!config?.headers) {
      configWithAuthorization = this.configWithAuthorization(config, this.accessToken);
    }

    const result = await this.client.request(
      config?.headers
        ? { ...config }
        : { ...configWithAuthorization, ...(config?.cancelToken && { cancelToken: config.cancelToken }) }
    );

    return result;
  }

  public openUrl(url: string): void {
    window.open(url, "_blank");
  }

  public openAssetUploadUrl(path: string): void {
    this.openUrl(this.getAssetUploadUrl(path));
  }

  public getAssetUploadUrl(path: string): string {
    return `${this.assetUploadURL}/${path}`;
  }

  public setToken(token: OAuthToken): void {
    this.accessToken = token.accessToken;
    this.cacheAccessToken = token.accessToken;
  }

  public async clearToken(): Promise<void> {
    this.accessToken = undefined;
    this.cacheAccessToken = undefined;
  }

  public hasAccessToken(): boolean {
    return !!this.cacheAccessToken;
  }

  private setupClient() {
    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!this.isAxiosError(error) && !this.isAuthenticationError(error)) {
          return Promise.reject(error);
        }

        // if (this.isAccessTokenExpired(error)) {
        //   window.location.href = window.location.origin + "/auth/login";
        // }

        return Promise.reject(error);
      }
    );
  }

  private isAxiosError(error: Error): error is AxiosError {
    const { isAxiosError }: AxiosError = error as AxiosError;

    return isAxiosError;
  }

  private isAuthenticationError(error: AxiosError): boolean | undefined {
    return (
      error.config &&
      // error.config.url === this.tokenURL &&
      error.response &&
      error.response.status === 401
    );
  }

  private isAccessTokenExpired(error: AxiosError): boolean | undefined {
    return (
      error.config &&
      // error.config.url !== this.tokenURL &&
      error.response &&
      error.response.status === 401
    );
  }

  private configWithAuthorization(config: AxiosRequestConfig, token: string | undefined): AxiosRequestConfig {
    const { headers = {} } = config;

    if (headers.Authorization) {
      return config;
    }
    return {
      ...config,
      headers: this.injectAuthorizationToken(headers, token),
    };
  }

  private injectAuthorizationToken(headers: Record<string, string>, token: string | undefined): Record<string, string> {
    return { ...headers, Authorization: `Bearer ${token}` };
  }

  private get cacheAccessToken(): string | undefined {
    return localStorage.getItem("scg_ieee::accessToken") || undefined;
  }

  private set cacheAccessToken(token: string | undefined) {
    if (typeof token === "undefined") {
      localStorage.removeItem("scg_ieee::accessToken");
      return;
    }

    localStorage.setItem("scg_ieee::accessToken", token);
  }
}
