
import Vue from "vue";
import EChart from "./EChart.vue";

import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "TrAnalysisPdGraph",
  components: { EChart, LoadingGraph },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    const subModuleTypeData = [
      {
        name: "Main tank UHF drain valve",
        value: "drain_valve",
      },
      {
        name: "Main tank UHF Window",
        value: "window",
      },
      {
        name: "Bushing_Phase_A",
        value: "p_a",
      },
      {
        name: "Bushing_Phase_B",
        value: "p_b",
      },
      {
        name: "Bushing_Phase_C",
        value: "p_c",
      },
      {
        name: "Bushing_Phase_a",
        value: "s_a",
      },
      {
        name: "Bushing_Phase_b",
        value: "s_b",
      },
      {
        name: "Bushing_Phase_c",
        value: "s_c",
      },
    ];

    return {
      isFileName: null,
      fileNameData: [],
      subModuleTypeData,
      isSubModuleType: {
        name: "Main tank UHF drain valve",
        value: "drain_valve",
      },
      renderGraph: false,
      eChartOptions: {
        title: {
          text: "Phase Resolved Partial Discharge Analytic",
          left: "center",
        },
        visualMap: {
          type: "continuous",
          orient: "vertical",
          min: 0,
          max: 0,
          right: 10,
          top: "middle",
          text: ["High", "Low"],
          calculable: true,
          inRange: {
            color: ["#0400ff", "#0400ff", "#15ff00", "#eeff00", "#ff0000"],
          },
        },
        toolbox: { feature: { dataZoom: { yAxisIndex: "none" } } },
        tooltip: {
          trigger: "item",
          axisPointer: { type: "cross" },
          formatter(params) {
            const { marker, data } = params;

            return `${marker} <br /> Amplitude : ${data[1]}<br /> Phase : ${data[0]}`;
          },
        },
        dataZoom: [
          { type: "inside", start: 0, end: 100 },
          { type: "slider", start: 0, end: 100 },
        ],
        xAxis: { type: "value" },
        yAxis: { type: "value" },
        series: [],
      },
    };
  },
  async created() {
    await this.fetchFileName();
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.renderGraph = false;
        const { modelCode, equipmentId } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrPdResultData(
          equipmentId,
          modelCode,
          this.isFileName.fileName.slice(0, -4)
        );

        this.eChartOptions.series = [
          {
            name: response.tag_name,
            type: "scatter",
            symbolSize: 5,
            data: response.value,
          },
        ];
        this.eChartOptions.visualMap.min = response.min;
        this.eChartOptions.visualMap.max = response.max;

        this.renderGraph = true;
      } catch (error) {
        console.log(error);
        this.renderGraph = true;
      }
    },
    async fetchFileName() {
      try {
        const { modelCode } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrPdFilename(modelCode, this.isSubModuleType.value);
        this.fileNameData = response;
        this.isFileName = response[0];
      } catch (error) {
        this.fileNameData = null;
        this.isFileName = [];
      }
    },
    async onChangeSubModuleType(ev: any) {
      this.isSubModuleType = ev;

      await this.fetchFileName();
      await this.fetchData();
    },
  },
});
