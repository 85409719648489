
import Vue from "vue";
import { mapActions } from "vuex";

import UploadArrowIcon from "@/components/icons/UploadArrowIcon.vue";

export default Vue.extend({
  name: "DocumentFileReport",
  components: { UploadArrowIcon },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    documents() {
      return this.$store.state?.modelDocumentInformation?.pm_report || null;
    },
  },
  data() {
    return { fileUpload: null };
  },
  methods: {
    ...mapActions(["getModelDocumentInformation"]),
    async onChange() {
      try {
        const { id } = this.model;
        const file = (this as any).$refs.file.files[0];

        if (!file) return;
        if (!["application/pdf"].includes(file.type)) {
          return alert("Please upload PDF file only.");
        }

        let formdata = new FormData();
        formdata.append("documentation_file", file);

        await (this as any).$dep.modelUseCase.uploadDocumentations(id, "pm_report", formdata);
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Import document Success.",
          type: "success",
        });
        await this.getModelDocumentInformation();
      } catch (error) {
        console.log(error);
      }
    },
    onDrop(event: any) {
      event.preventDefault();

      (this as any).$refs.file.files = event.dataTransfer.files;
      this.onChange();

      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    dragover(event: any) {
      event.preventDefault();

      if (!event.currentTarget.classList.contains("bg-[#EAEEF6]")) {
        event.currentTarget.classList.remove("bg-[#FFFFFF]");
        event.currentTarget.classList.add("bg-[#EAEEF6]");
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    exportFile(link: string) {
      window.open(link, "_blank");
    },
  },
});
