import { EquipmentModelType } from "@/features/model/models/EquipmentModelType";
import { EquipmentModelFormType } from "@/features/model/models/EquipmentModelFormType";
import { ModelSettingFormType } from "@/features/model/models/ModelSettingFormType";
import { MemberAssignForm } from "@/features/equipment/models/MemberAssignForm";
export class State {
  public app: {
    isSidebar: boolean;
    visibleSidebar: boolean;
  } = {
    isSidebar: false,
    visibleSidebar: false,
  };
  public user: Record<any, any> = {
    id: 0,
    name: "",
    email: "",
    image: "",
    role: "",
    status: "",
    raw: "",
    sub: "",
  };
  public company: Record<any, any> = {};
  public structure: Record<any, any> = {};
  public modelMotorDataForm: Array<any> = [];
  public model: EquipmentModelType = {
    id: 0,
    title: "",
    isActive: "",
    linkUrl: "",
    equipmentTagId: 0,
    equipmentCode: {
      code: "",
    },
    equipmentModelType: {
      id: 0,
      title: "",
      createdDate: "",
      updatedDate: "",
      deletedDate: "",
    },
    equipmentModelImages: [],
    equipmentModelConfiguration: [],
  };
  public createModelForm: EquipmentModelFormType = {
    equipment_tag_id: 0,
    model_in: {
      title: "",
      isActive: "inactive",
      equipmentModelTypeId: 0,
    },
    configuration_in: [],
    vibration_sensor_in: [],
    raw_vibration_sensor_in: [],
    alarm_limit_sensor_in: [],
    trend_signal_alarm_limit_sensor_in: [],
    vsd_tag_alarm_limit_in: [],
    motor_data_in: [],
    electronic_card_in: [],
    q_setting_in: [],
    v_and_i_locus_in: [],
    alarm_limit_sensor_new_in: [],
    alarm_limit_configuration_in: [],
    rvs_diagram_in: {
      diagram_url: "",
    },
  };
  public updateModelForm: EquipmentModelFormType = {
    model_in: {
      title: "",
      isActive: "inactive",
    },
    configuration_in: [],
    vibration_sensor_in: [],
    raw_vibration_sensor_in: [],
    alarm_limit_sensor_in: [],
    trend_signal_alarm_limit_sensor_in: [],
    vsd_tag_alarm_limit_in: [],
    motor_data_in: [],
    electronic_card_in: [],
    q_setting_in: [],
    v_and_i_locus_in: [],
    alarm_limit_sensor_new_in: [],
    alarm_limit_configuration_in: [],
    rvs_diagram_in: {
      diagram_url: "",
    },
  };
  public modelSettingForm: ModelSettingFormType = {
    configuration_information: [],
    correlation_mapping_tag_code: [],
  };
  public uploadFileRecommendationForm: Record<any, any> = {
    formdata: null,
  };
  public modelViewData: {
    autoRefresh: {
      enable: boolean;
      timeout: number;
      timestamp: Date;
    };
    tr_diagram_status: Record<any, any>;
    tr_summary_status: Record<any, any>;
    mca_diagram_status: Record<any, any>;
    vca_diagram_status: Record<any, any>;
    evs_diagram_status: Record<any, any>;
    rvs: Record<any, any>;
    fault_event_alarm_count: number;
  } = {
    autoRefresh: {
      enable: false,
      timeout: 600000, // 10 minute
      timestamp: new Date(),
    },
    tr_diagram_status: {},
    tr_summary_status: {},
    mca_diagram_status: {},
    vca_diagram_status: {},
    evs_diagram_status: {},
    rvs: {
      rvs_diagram_status: {},
      rvs_diagram_image: {},
    },
    fault_event_alarm_count: 0,
  };
  public modelImageInformation = [];
  public modelConfigurationInformation = [];
  public modelDocumentInformation: Record<any, any> = {
    pm_report: null,
    manual: null,
  };
  public graphInformationTabGroupData: {
    graph_primary_tab: { id: number; name: string }[];
    graph_secondary_tab: { id: number; name: string }[];
  } = {
    graph_primary_tab: [],
    graph_secondary_tab: [],
  };
  public graphInformationTab: Record<any, any> = {
    is_primary_tab: { id: 0, name: "", key: 0 },
    is_secondary_tab: { id: 0, name: "", key: 0 },
  };
  public equipmentTagCreateForm: any = {
    parentId: 0,
    structureItemAlias: "",
    structureItemTitle: "",
    itemGroupType: "",
    position: {
      lat: 0,
      lng: 0,
    },
    isPrimaryFolder: false,
    images: [],
  };
  public openAlertMessage: {
    open: boolean;
    message: string;
    type: string;
  } = {
    open: false,
    message: "",
    type: "success",
  };
  public contextMenuStructure: Record<any, any> = {
    id: 0,
    name: "",
    type: "",
    status: null,
  };
  public memberAssignForm: MemberAssignForm = {
    isShowModal: false,
    selectedMemberIds: [],
    search: "",
  };
  public openCompanyFormModal = { open: false, type: "", param: null };
  public openAdminFormModal = { open: false, type: "", param: null };
  public openFolderFormModal = { open: false, type: "", param: null };
  public openEquipmentFormModal = { open: false, type: "", param: null };
  public openEditModelOfflineModal = { open: false, type: "", param: null };
  public openCreateModelModal = { open: false, type: "", param: null };
  public openEditUserModal = false;
  public openEmailNotificationFormModal = { open: false, type: "", param: null };
  public openMemberFormModal = { open: false, type: "", param: null };
  public openEditMemberModal = { open: false, type: "", param: null };
  public modelPhaseGroup: Record<any, any> = {};
  public modelTrendSignalAlarmLimitSensor: Array<any> = [];
}
