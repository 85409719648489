export enum MemberStatus {
  PENDING = "pending",
  ACTIVE = "active",
}

export enum MemberRole {
  OWNER = "owner",
  MEMBER = "member",
}

export interface Member {
  id: number;
  createdDate?: string;
  updatedDate?: string;
  email: string;
  name: string;
  profileImageUrl: string;
  role: string;
  status: string;
}
