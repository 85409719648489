
import Vue from "vue";
import dialog from "@/common/mixins/dialog";
import { between, required, alphaNum } from "vuelidate/lib/validators";
import EquipmentTagCreateModal from "./EquipmentTagCreateModal.vue";
import EquipmentTagUpdateModal from "./EquipmentTagUpdateModal.vue";
import { EquipmentTagCreateForm } from "../forms/EquipmentTagCreateForm";
import { EquipmentTagUpdateForm } from "../forms/EquipmentTagUpdateForm";
import dayjs from "dayjs";

export default Vue.extend({
  mixins: [dialog],
  validations: {
    equipmentCreateForm: {
      structureItemTitle: { required, alphaNum },
      itemGroupType: { required },
      position: {
        lat: { required, between: between(1, 200) },
        lng: { required, between: between(1, 200) },
      },
    },
    equipmentUpdateForm: {
      structureItemTitle: { required },
    },
  },
  data() {
    const isLoading = false;

    return {
      isLoading,
    };
  },
  components: {
    EquipmentTagCreateModal,
    EquipmentTagUpdateModal,
  },
  computed: {
    isShowModal() {
      return this.$store.getters.isShowEquipmentModal;
    },
    modalState() {
      return this.$store.getters.modalState;
    },
    equipmentCreateForm() {
      return this.$store.getters.equipmentTagCreateForm;
    },
    equipmentUpdateForm() {
      return this.$store.getters.equipmentTagUpdateForm;
    },
    headLine() {
      if (this.modalState === "CREATE-EQUIPMENT-TAG") return "Create New Equipment tag";
      if (this.modalState === "UPDATE-EQUIPMENT-TAG") return "Update Equipment tag";
      else return "";
    },
    folderName() {
      return this.$store.getters.selectFolder.folderName;
    },
  },
  methods: {
    async createEquipmentTag() {
      this.$v.$touch();
      if (this.$v.equipmentCreateForm.$invalid) return;

      const companies_id = Number(sessionStorage.getItem("company_id"));
      const { parentId } = this.$store.getters.selectFolder;

      this.$store.dispatch("setEquipmentCreateForm", {
        ...this.$store.getters.equipmentTagCreateForm,
        parentId,
      });

      const bodyCreateEquipmentTag = {
        ...this.$store.getters.equipmentTagCreateForm,
        posLat: this.$store.getters.equipmentTagCreateForm.position.lat,
        posLng: this.$store.getters.equipmentTagCreateForm.position.lng,
        equipmentGroupType: this.$store.getters.equipmentTagCreateForm.itemGroupType,
        structureItemType: "EquipmentTag",
        companies_id,
        createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        is_disabled: false,
      };

      if (this.$store.getters.equipmentTagCreateForm?.emailTaskScheduler) {
        const {
          emailTaskScheduler: { daily, weekly, monthly },
        } = this.$store.getters.equipmentTagCreateForm;

        if (daily.isActive) {
          if (!daily.isWarning && !daily.isAlarm) {
            return (this as any).alertError("Error", "ที่ Daily กรุณาเลือก Warning หรือ Alarm");
          }
        }
        if (weekly.isActive) {
          if (!weekly.isWarning && !weekly.isAlarm) {
            return (this as any).alertError("Error", "ที่ Weekly กรุณาเลือก Warning หรือ Alarm");
          }
        }
        if (monthly.isActive) {
          if (!monthly.isWarning && !monthly.isAlarm) {
            return (this as any).alertError("Error", "ที่ Monthly กรุณาเลือก Warning หรือ Alarm");
          }
        }
      }

      try {
        this.isLoading = true;
        const createEquipmentTag = await (this as any).$dep.folderUseCase.createFolder(bodyCreateEquipmentTag);

        if (this.$store.getters.equipmentTagCreateForm?.emailTaskScheduler) {
          const {
            emailTaskScheduler: { daily, weekly, monthly },
          } = this.$store.getters.equipmentTagCreateForm;
          const timezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
          const bodyEmailTaskScheduler = {
            companies_id,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            equipment_tag_id: createEquipmentTag.id,
            is_daily: daily.isActive,
            is_daily_warning: daily.isWarning,
            is_daily_alarm: daily.isAlarm,
            daily_start_time: !daily.isActive || !daily.timedate ? [] : [daily.timedate, !daily.timedate ? null : timezone],
            is_weekly: weekly.isActive,
            is_weekly_warning: weekly.isWarning,
            is_weekly_alarm: weekly.isAlarm,
            weekly_start_time:
              !weekly.isActive || !weekly.timedate ? [] : [weekly.timedate, !weekly.timedate ? null : timezone],
            is_monthly: monthly.isActive,
            is_monthly_warning: monthly.isWarning,
            is_monthly_alarm: monthly.isAlarm,
            monthly_start_time:
              !monthly.isActive || !monthly.timedate ? [] : [monthly.timedate, !monthly.timedate ? null : timezone],
          };
          await (this as any).$dep.equipmentUseCase.sendEmailTaskScheduler(bodyEmailTaskScheduler);
        }
        await (this as any).alertSuccess("Successful", `Create Equipment Tag Successful`);
        this.closeDialog();
        this.$emit("loading");
        await this.$store.dispatch("setCompanyInfos");
        await this.$store.dispatch("getAllFolder");
        await this.clearForm();
      } catch (error: any) {
        if (error.response) {
          (this as any).alertError("Error", error.response.data.detail);
        } else {
          (this as any).alertError("Error", "ไม่สามารถสร้าง Equipment Tag ได้");
        }
      } finally {
        this.isLoading = false;
      }
    },
    async updateEquipmentTag() {
      this.$v.$touch();
      if (this.$v.equipmentUpdateForm.$invalid) return;

      if (this.$store.getters.equipmentTagUpdateForm?.emailTaskScheduler) {
        const {
          emailTaskScheduler: { daily, weekly, monthly },
        } = this.$store.getters.equipmentTagUpdateForm;

        if (daily.isActive) {
          if (!daily.isWarning && !daily.isAlarm) {
            return (this as any).alertError("Error", "ที่ Daily กรุณาเลือก Warning หรือ Alarm");
          }
        }
        if (weekly.isActive) {
          if (!weekly.isWarning && !weekly.isAlarm) {
            return (this as any).alertError("Error", "ที่ Weekly กรุณาเลือก Warning หรือ Alarm");
          }
        }
        if (monthly.isActive) {
          if (!monthly.isWarning && !monthly.isAlarm) {
            return (this as any).alertError("Error", "ที่ Monthly กรุณาเลือก Warning หรือ Alarm");
          }
        }
      }

      try {
        this.isLoading = true;

        await (this as any).$dep.equipmentUseCase.updateEquipmentTag(
          {
            ...this.$store.getters.equipmentTagUpdateForm,
            id: this.$store.getters.selectFolder.parentId,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          },
          this.$store.getters.selectFolder.parentId
        );

        if (this.$store.getters.equipmentTagUpdateForm?.emailTaskScheduler) {
          const companies_id = Number(sessionStorage.getItem("company_id"));
          const {
            emailTaskScheduler: { daily, weekly, monthly },
          } = this.$store.getters.equipmentTagUpdateForm;
          const timezone = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
          const bodyEmailTaskScheduler = {
            companies_id,
            createdDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            updatedDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            equipment_tag_id: this.$store.getters.selectFolder.parentId,
            is_daily: daily.isActive,
            is_daily_warning: daily.isWarning,
            is_daily_alarm: daily.isAlarm,
            daily_start_time: !daily.isActive || !daily.timedate ? [] : [daily.timedate, !daily.timedate ? null : timezone],
            is_weekly: weekly.isActive,
            is_weekly_warning: weekly.isWarning,
            is_weekly_alarm: weekly.isAlarm,
            weekly_start_time:
              !weekly.isActive || !weekly.timedate ? [] : [weekly.timedate, !weekly.timedate ? null : timezone],
            is_monthly: monthly.isActive,
            is_monthly_warning: monthly.isWarning,
            is_monthly_alarm: monthly.isAlarm,
            monthly_start_time:
              !monthly.isActive || !monthly.timedate ? [] : [monthly.timedate, !monthly.timedate ? null : timezone],
          };
          await (this as any).$dep.equipmentUseCase.updateEmailTaskScheduler(bodyEmailTaskScheduler);
        }
        await (this as any).alertSuccess("Successful", `Update Equipment-Tag Successful`);
        this.closeDialog();
        this.$emit("loading");
        await this.$store.dispatch("setCompanyInfos");
        await this.$store.dispatch("getAllFolder");
        await this.clearForm();
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          (this as any).alertError("Error", error.response.data.message);
        } else {
          process.env.NODE_ENV === "production"
            ? (this as any).alertError("Error", "ไม่สามารถสร้าง Equipment Tag ได้")
            : (this as any).alertError("Error", error);
        }
      } finally {
        this.isLoading = false;
      }
    },
    async closeDialog() {
      this.$store.dispatch("closeModal");
      await this.clearForm();
    },
    async clearForm() {
      this.$v.$reset();
      const equipmentCreateForm: EquipmentTagCreateForm = {
        parentId: 0,
        structureItemTitle: "",
        itemGroupType: "",
        position: {
          lat: 0,
          lng: 0,
        },
        isPrimaryFolder: false,
      };
      const equipmentTagUpdateForm: EquipmentTagUpdateForm = {
        emailTaskScheduler: null,
        structureItemTitle: "",
        equipmentGroupType: "",
        posLat: 0,
        posLng: 0,
      };
      await this.$store.dispatch("setEquipmentCreateForm", {
        ...this.$store.getters.equipmentTagCreateForm,
        ...equipmentCreateForm,
      });
      await this.$store.dispatch("setEquipmentUpdateForm", {
        ...this.$store.getters.equipmentTagUpdateForm,
        ...equipmentTagUpdateForm,
      });
    },
  },
});
