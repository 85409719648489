
import Vue from "vue";
import permission, { PermissionName } from "@/common/mixins/permission";

export default Vue.extend({
  mixins: [permission],
  props: {
    company: Object,
  },
  data() {
    return {
      iconCompanyInfo: require("@/assets/icons/company_info.svg"),
    };
  },
  computed: {
    hasAssignMemberToEquipmentTagPermission() {
      return (this as any).hasPermission(PermissionName.AssignMemberToEquipmentTag);
    },
  },
});
