import { Client } from "../../clients/client";
import { Member } from "./models/Member";
import { MemberPaginationForm } from "./forms/MemberPaginationForm";
import { MemberInviteForm } from "./forms/MemberInviteForm";
import { MemberUpdateForm } from "./forms/MemberUpdateForm";
import { MemberEditInviteForm } from "./forms/MemberEditInviteForm";

export class MemberUseCase {
  constructor(private client: Client) {}

  public async getMembers(compamy_id: number): Promise<Member[]> {
    return this.client.memberClient.getMembers(compamy_id);
  }

  public async getMembersOnly(compamy_id: number): Promise<Member[]> {
    return this.client.memberClient.getMembersOnly(compamy_id);
  }

  public async getMemberMembers(compamy_id: number): Promise<Member[]> {
    return this.client.memberClient.getMemberMembers(compamy_id);
  }

  public async inviteMember(company_id: number, form: MemberInviteForm): Promise<Member> {
    return this.client.memberClient.createMemberInvite(company_id, {
      email: form.email,
      role: form.role,
    });
  }

  public async resendInviteMember(email: string) {
    return this.client.memberClient.postMemberInviteResend(email);
  }

  public async activeMember(email: string) {
    return this.client.memberClient.postMemberActive(email);
  }

  public async uploadProfileImage(form: FormData): Promise<any> {
    return await this.client.memberClient.uploadProfileImage(form);
  }

  public async uploadUserProfile(form: MemberUpdateForm): Promise<any> {
    return await this.client.memberClient.updateUserProfile({
      name: form.name,
      picture: form.imageUrl,
    });
  }

  public async updateMember(form: any) {
    return this.client.memberClient.updateMember(form);
  }
  public async memberSwitchRole(id: number) {
    return this.client.memberClient.memberSwitchRole(id);
  }

  public async deleteMember(email: string) {
    return this.client.memberClient.deleteMember(email);
  }
  public async updateMemberProfile(form: any) {
    return this.client.memberClient.updateMemberProfile(form);
  }
}
