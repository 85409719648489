
import Vue from "vue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";

import DateTimeRange from "@/components/DateTimeRange.vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";

export default Vue.extend({
  name: "TrCorrelationGraph",
  components: { highcharts: Chart, DateTimeRange, ButtonDanger, ButtonReload, LoadingGraph, NoDataAvailable },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    const date = new Date();

    return {
      isSensorType: {},
      sensorTypeData: [],
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      chartLoading: true,
      chartResultData: [],
      chartScore: {
        chart: {
          type: "line",
          height: 400,
          zoomType: "xy",
          borderWidth: 1,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { shared: true, crosshairs: true },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        yAxis: { title: { text: null }, min: null, max: null, plotLines: [] },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
      chartTag: {
        chart: {
          type: "line",
          height: 400,
          zoomType: "xy",
          borderWidth: 1,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { shared: true, crosshairs: true },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        yAxis: { title: { text: null } },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
    };
  },
  async created() {
    await this.fetchSensor();
    await this.fetchResultData();
  },
  methods: {
    async fetchSensor() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelQSetting(id);

        this.sensorTypeData = response || [];
        this.isSensorType = response[0];
      } catch (error) {
        console.log(error);
      }
    },
    findMathMinMax(data: number[]) {
      const min = Math.min(...data);
      const max = Math.max(...data);

      return { min: min < 0 ? min - 20 : 0, max: max + 20 };
    },
    fetchResultData() {
      const { id, modelCode, equipmentId } = this.model;
      const { score, tagA, tagB } = this.isSensorType;

      this.chartLoading = true;

      const promise1 = (this as any).$dep.modelUseCase.getTrCorrelationResultDataScore(
        modelCode,
        equipmentId,
        score,
        this.startDate,
        this.endDate
      );
      const promise2 = (this as any).$dep.modelUseCase.getTrCorrelationResultData(
        id,
        modelCode,
        equipmentId,
        tagA,
        tagB,
        this.startDate,
        this.endDate
      );

      Promise.all([promise1, promise2])
        .then((values: any) => {
          const mathResult = this.findMathMinMax([values[0].alarm, values[0].warning, ...values[0].data]);

          this.chartResultData = values[1];

          this.chartScore.yAxis.min = mathResult.min;
          this.chartScore.yAxis.max = mathResult.max;
          this.chartScore.yAxis.plotLines = [
            {
              value: values[0].alarm,
              width: 2,
              dashStyle: "dash",
              color: "#D9263B",
              zIndex: 5,
              label: {
                align: "right",
                formatter: function () {
                  return "Alarm : " + this.options.value;
                },
              },
            },
            {
              value: values[0].warning,
              width: 2,
              dashStyle: "dash",
              color: "#FCA239",
              zIndex: 5,
              label: {
                formatter: function () {
                  return "Warning : " + this.options.value;
                },
              },
            },
          ];
          this.chartScore.xAxis = this.aAxis(values[0].timestamp);
          this.chartScore.series = [
            {
              name: values[0].tagScore,
              data: values[0].data,
            },
          ];

          this.chartTag.xAxis = this.aAxis(values[1][0].timestamp);
          this.chartTag.series = values[1].map((prev: any) => ({
            name: prev.tagCode,
            data: prev.data,
          }));
        })
        .catch((error: any) => {
          this.chartResultData = [];
          console.log(error);
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
    aAxis(datas: any[]) {
      const count = datas.length;
      return {
        type: "category",
        labels: {
          align: "left",
          step: count <= 10 ? 0 : count <= 100 ? 1 : count <= 200 ? 2 : count <= 300 ? 3 : count <= 400 ? 4 : 5,
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: datas.map((prev) => dayjs(prev).format("YYYY-MM-DD HH:mm")),
      };
    },
    async onChangeSensorType(val: any) {
      this.isSensorType = val;
      await this.fetchResultData();
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `tr_${id}_correlation`.toUpperCase();

      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);

      const ws = XLSX.utils.json_to_sheet(this.chartResultData);
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, ws, name); // => sheet name
      XLSX.writeFile(wb, `${name}.xlsx`); // => file name
    },
  },
});
