
import Vue from "vue";
import { required, alphaNum } from "vuelidate/lib/validators";
import Datepicker from "@/components/Datepicker.vue";

export default Vue.extend({
  name: "CreateCompanyPage",
  components: { Datepicker },
  validations: {
    image: { required },
    name: { required },
    initailCode: { required, alphaNum },
    validationGroup: ["image", "name", "initailCode"],
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openCompanyFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openCompanyFormModal", {
          ...this.$store.state.openCompanyFormModal,
          open: value,
        });
      },
    },
    initailForm() {
      return this.$store.state.openCompanyFormModal.param;
    },
    actionType() {
      return this.$store.state.openCompanyFormModal.type;
    },
  },
  data() {
    return {
      name: "",
      initailCode: "",
      image: "",
      startDate: "",
      endDate: "",
      rvsUpgrade: false,
    };
  },
  methods: {
    onChangeImage() {
      (this as any).$refs.file.click();
    },
    async onChange(e: any) {
      try {
        e.preventDefault();
        const file = e.target.files[0];
        const imageType = e.target.files[0].type;

        if (!["image/jpeg", "image/jpg", "image/png"].includes(imageType)) {
          return alert("Please upload file with type: jpeg, jpg, png");
        }

        let formdata = new FormData();
        formdata.append("image_file", file);

        const { url } = await (this as any).$dep.companyUseCase.uploadCompanyImage(formdata);

        this.image = url;
      } catch (error) {
        //
      }
    },
    async onSubmit() {
      this.$emit("onSubmit", {
        action_type: this.actionType,
        image_path: this.image,
        title: this.name,
        initial_code: this.initailCode,
        description: "",
        start_date: this.startDate,
        end_date: this.endDate,
        rvs_upgrade: this.rvsUpgrade,
        ...(this.actionType === "EDIT" && { id: this.initailForm.id }),
      });

      this.dialog = false;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.name = this.initailForm ? this.initailForm.title : "";
        this.initailCode = this.initailForm ? this.initailForm.initial_code : "";
        this.image = this.initailForm ? this.initailForm.image_path : "";
        this.startDate = this.initailForm ? this.initailForm.start_date : new Date().toISOString().substr(0, 10);
        this.endDate = this.initailForm ? this.initailForm.end_date : new Date().toISOString().substr(0, 10);
        this.rvsUpgrade = this.initailForm ? this.initailForm.rvsUpgrade : false;
      }
    },
  },
});
