
import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { proxyModel } from "@/common/utils/proxyModel";

import { MemberRole } from "../models/Member";
import { MemberEditInviteForm } from "../models/MemberEditInviteForm";

export default Vue.extend({
  props: {
    value: {
      type: Object as () => MemberEditInviteForm,
    },
  },
  data() {
    return {
      email: "",
      role: "",
      name: "",
      profileImageUrl: "",
      statusUser: false,
    };
  },
  validations: { role: { required } },
  computed: {
    ...proxyModel("isShowModal", "isLoading"),
    roles() {
      return [MemberRole.OWNER, MemberRole.MEMBER];
    },
    roleError() {
      return this.$v.role.$error ? "Role is required." : "";
    },
    company() {
      return this.$store.getters.company;
    },
    initailForm() {
      return this.$store.state.openEditMemberModal.param;
    },
    dialog: {
      get(): boolean {
        return this.$store.state.openEditMemberModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openEditMemberModal", {
          ...this.$store.state.openEditMemberModal,
          open: value,
        });
      },
    },
  },
  methods: {
    edit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$v.$reset();
      this.$emit("onSubmit", {
        role: this.role,
        email: this.email,
        name: this.name,
        profileImageUrl: this.profileImageUrl,
        status: this.statusUser ? "active" : "inactive",
      });

      this.dialog = false;
    },
    close() {
      this.$v.$reset();
      this.$emit("close");
    },
    onChangeImage() {
      (this as any).$refs.file.click();
    },
    async onChange(e: any) {
      try {
        const file = e.target.files[0];
        const imageType = e.target.files[0].type;

        if (!["image/jpeg", "image/jpg", "image/png"].includes(imageType)) {
          return alert("Please upload file with type: jpeg, jpg, png");
        }

        let formdata = new FormData();
        formdata.append("image_file", file, `image_profile_${Math.floor(Math.random() * (10000 - 1 + 1)) + 1}`);

        const { url } = await (this as any).$dep.memberUseCase.uploadProfileImage(formdata);
        this.profileImageUrl = url;
      } catch (error) {
        //
      }
    },
  },
  watch: {
    dialog() {
      this.email = this.initailForm.email;
      this.role = this.initailForm.role;
      this.name = this.initailForm.name;
      this.profileImageUrl = this.initailForm.profileImageUrl;
      this.statusUser = this.initailForm.status === "active" ? true : false;
    },
  },
});
