
import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "FaultEventAlarmDetails",
  props: {
    item: { type: Object, default: null },
    type: { type: String },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    onCancel() {
      (this as any).dialog = false;
    },
    formatDate(date: any, format = "DD MMM YYYY, HH:mm") {
      return dayjs(date).format(format);
    },
  },
});
