
import Vue from "vue";

import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import TableCustom from "@/components/TableCustom.vue";

export default Vue.extend({
  name: "FormVibrationSensor",
  components: { DeleteIcon, AddIcon, TableCustom },
  computed: {
    items: {
      get() {
        return this.$store.state.updateModelForm.vibration_sensor_in;
      },
    },
  },
  data() {
    const headers = [
      { text: "Sensor ID", value: "sensorId", width: "10%", sortable: true },
      { text: "Sensor Name", value: "sensorName", width: "20%", sortable: true },
      { text: "Acceleration (m/s<sup>2</sup>)", value: "acceleration", width: "15%", sortable: false },
      { text: "Velocity (mm/s)", value: "velocity", width: "15%", sortable: false },
      { text: "Temperature (&#186;C)", value: "temperature", width: "15%", sortable: false },
      { text: "Type of position", value: "sensorPointId", width: "10%", sortable: false },
      { text: "Machine Template", value: "machineTemplateId", width: "10%", sortable: false },
      { text: "", value: "action", width: "5%", sortable: false },
    ];
    const sensorPoints: any[] = [];
    const machineTemplate: any[] = [];
    return {
      headers,
      sensorPoints,
      machineTemplate,
    };
  },
  async created() {
    await (this as any).fetchSensorPoints();
  },
  methods: {
    onUpdateState() {
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        vibration_sensor_in: this.items.map((item) => ({
          ...item,
          acceleration: { warning: +item.acceleration.warning, alarm: +item.acceleration.alarm },
          velocity: { warning: +item.velocity.warning, alarm: +item.velocity.alarm },
          temperature: { warning: +item.temperature.warning, alarm: +item.temperature.alarm },
        })),
      });
    },
    addItem() {
      this.items.push({
        selected: true,
        sensorId: "",
        sensorName: "",
        acceleration: { warning: 0, alarm: 0 },
        velocity: { warning: 0, alarm: 0 },
        temperature: { warning: 0, alarm: 0 },
        sensorPointId: 1,
        machineTemplateId: 1,
      });
      (this as any).$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        vibration_sensor_in: this.items,
      });
    },
    removeItem(param: any) {
      const { index } = param;
      this.items.splice(index, 1);

      (this as any).$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        vibration_sensor_in: this.items,
      });
    },
    async fetchSensorPoints() {
      try {
        const sensorPoints = await (this as any).$dep.modelUseCase.getSensorPointsAll();
        const machineTemplate = await (this as any).$dep.modelUseCase.getMachineTemplate();
        (this as any).sensorPoints = sensorPoints;
        (this as any).machineTemplate = machineTemplate;
      } catch (error) {
        //
      }
    },
  },
});
