
import Vue from "vue";

import { ModelVAndILocusInType } from "@/features/model/models/EquipmentModelFormType";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import TableCustom from "@/components/TableCustom.vue";

export default Vue.extend({
  name: "FormLucus",
  components: { DeleteIcon, AddIcon, TableCustom },
  computed: {
    items: {
      get(): ModelVAndILocusInType[] {
        return this.$store.state.updateModelForm.v_and_i_locus_in;
      },
    },
  },
  data() {
    const headers = [
      { text: "Locus Name", value: "locus_name", sortable: false, width: "16%" },
      { text: "a", value: "a", sortable: false, width: "16%" },
      { text: "b", value: "b", sortable: false, width: "16%" },
      { text: "angle", value: "angle", sortable: false, width: "16%" },
      { text: "e", value: "e", sortable: false, width: "16%" },
      { text: "Area", value: "area", sortable: false, width: "16%" },
      { text: "", value: "action", sortable: false, width: "4%" },
    ];

    return { headers };
  },
  methods: {
    onUpdateState() {
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        v_and_i_locus_in: this.items.map((item) => ({
          ...item,
          a: +item.a,
          b: +item.b,
          e: +item.e,
          angle: +item.angle,
          area: +item.area,
        })),
      });
    },
    addItem() {
      this.items.push({ locus_name: "", a: 0, b: 0, angle: 0, e: 0, area: 0 });

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        v_and_i_locus_in: this.items,
      });
    },
    removeItem(row: any) {
      const { index } = row;
      this.items.splice(index, 1);

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        v_and_i_locus_in: this.items,
      });
    },
  },
});
