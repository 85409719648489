
import Vue from "vue";
import { mapStyles } from "@/plugins/vuejs-google-maps";
import { AzureMap, AzureMapDataSource, AzureMapPoint, AzureMapSymbolLayer } from "vue-azure-maps";
import dayjs from "dayjs";

export default Vue.extend({
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer,
  },
  props: {
    validations: {
      type: Object,
    },
  },
  data() {
    const isLoading = true;
    const map: any = null;
    const equipmentType = [
      {
        text: "Electric",
        value: "electric",
      },
      {
        text: "Rotating",
        value: "rotating",
      },
      {
        text: "Static",
        value: "static",
      },
      {
        text: "Instrument",
        value: "instrument",
      },
      {
        text: "Process",
        value: "process",
      },
    ];

    const emailTaskSchedulerNotification = {
      daily: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
        id: null,
      },
      weekly: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
        id: null,
      },
      monthly: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
        id: null,
      },
    };

    const equipmentForm = {
      type: "electric",
      lat: 13.75242636,
      lng: 100.51245287,
    };
    return {
      isLoading,
      map,
      equipmentForm,
      equipmentType,
      mapStyles: mapStyles,
      emailTaskSchedulerNotification,
      iconElectric: require("@/assets/icons/iconMarker/Electric.svg"),
      iconStatic: require("@/assets/icons/iconMarker/Static.svg"),
      iconRotating: require("@/assets/icons/iconMarker/Rotating.svg"),
      iconProcess: require("@/assets/icons/iconMarker/Process.svg"),
      iconInstrument: require("@/assets/icons/iconMarker/Instrument.svg"),
    };
  },
  async created() {
    await this.getTaskScheduler();
    this.isLoading = false;
  },
  computed: {
    iconGroupType() {
      const equipment = this.$store.getters.equipmentTagUpdateForm.equipmentGroupType;
      if (equipment === "electric") return (this as any).iconElectric;
      if (equipment === "rotating") return (this as any).iconRotating;
      if (equipment === "static") return (this as any).iconStatic;
      if (equipment === "instrument") return (this as any).iconInstrument;
      if (equipment === "process") return (this as any).iconProcess;
      else return "";
    },
    defaultCenter() {
      return {
        lat: this.$store.getters.equipmentTagUpdateForm.posLat,
        lng: this.$store.getters.equipmentTagUpdateForm.posLng,
      };
    },
    position: {
      get(): Record<string, unknown> {
        return {
          lat: this.$store.getters.equipmentTagUpdateForm.posLat,
          lng: this.$store.getters.equipmentTagUpdateForm.posLng,
        };
      },
    },
    itemTitle: {
      get(): string {
        return this.$store.getters.equipmentTagUpdateForm.structureItemTitle;
      },
      set(structureItemTitle: string) {
        (this as any).$store.dispatch("setEquipmentUpdateForm", {
          ...(this as any).$store.getters.equipmentTagUpdateForm,
          structureItemTitle,
        });
      },
    },
    itemGroupType: {
      get(): string {
        return this.$store.getters.equipmentTagUpdateForm.equipmentGroupType;
      },
      set(equipmentGroupType: string) {
        (this as any).$store.dispatch("setEquipmentUpdateForm", {
          ...(this as any).$store.getters.equipmentTagUpdateForm,
          equipmentGroupType,
        });
      },
    },
    itemTitleError() {
      return (this as any).validations.structureItemTitle.$error ? "Equipment Tag Name is required." : "";
    },
  },
  methods: {
    async setMap(mapEvent: any) {
      this.map = mapEvent.map;
      await this.addSprite("electric", this.iconElectric);
      await this.addSprite("static", this.iconStatic);
      await this.addSprite("rotating", this.iconRotating);
      await this.addSprite("process", this.iconProcess);
      await this.addSprite("instrument", this.iconInstrument);
    },
    addSprite(type: string, address: any) {
      if (!this.map) return;
      return this.map.imageSprite.add(type, address);
    },
    changeIcon(equipmentGroupType: string) {
      return {
        iconOptions: {
          size: 0.5,
          image: equipmentGroupType,
        },
      };
    },
    getCurrentPosition(e: any): void {
      this.equipmentForm = {
        ...this.equipmentForm,
        lat: e.position[1],
        lng: e.position[0],
      };
      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        posLat: e.position[1],
        posLng: e.position[0],
      });
    },
    async getTaskScheduler() {
      try {
        const { parentId } = this.$store.getters.selectFolder;

        const datas = await (this as any).$dep.equipmentUseCase.getEmailTaskSchedulerById(parentId);

        const findDaily = datas.find((p: any) => p.iterable === 1);
        const findWeekly = datas.find((p: any) => p.iterable === 7);
        const findMonthly = datas.find((p: any) => p.iterable === 30);

        if (findDaily) {
          this.emailTaskSchedulerNotification.daily.isActive = true;
          this.emailTaskSchedulerNotification.daily.isWarning = findDaily.is_warning;
          this.emailTaskSchedulerNotification.daily.isAlarm = findDaily.is_alarm;
          this.emailTaskSchedulerNotification.daily.timedate = findDaily.start_time;
          this.emailTaskSchedulerNotification.daily.id = findDaily.id;
        }
        if (findWeekly) {
          this.emailTaskSchedulerNotification.weekly.isActive = true;
          this.emailTaskSchedulerNotification.weekly.isWarning = findWeekly.is_warning;
          this.emailTaskSchedulerNotification.weekly.isAlarm = findWeekly.is_alarm;
          this.emailTaskSchedulerNotification.weekly.timedate = findWeekly.start_time;
          this.emailTaskSchedulerNotification.weekly.id = findWeekly.id;
        }
        if (findMonthly) {
          this.emailTaskSchedulerNotification.monthly.isActive = true;
          this.emailTaskSchedulerNotification.monthly.isWarning = findMonthly.is_warning;
          this.emailTaskSchedulerNotification.monthly.isAlarm = findMonthly.is_alarm;
          this.emailTaskSchedulerNotification.monthly.timedate = findMonthly.start_time;
          this.emailTaskSchedulerNotification.monthly.id = findMonthly.id;
        }

        const emailTaskScheduler = this.emailTaskSchedulerNotification;
        (this as any).$store.dispatch("setEquipmentUpdateForm", {
          ...(this as any).$store.getters.equipmentTagUpdateForm,
          emailTaskScheduler,
        });
      } catch (error) {
        //
      }
    },
    addMarker(location: any) {
      const posLat = location.latLng.lat();
      const posLng = location.latLng.lng();
      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        posLat,
      });
      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        posLng,
      });
    },
    checkBoxEmailTaskSchedulerAll(type: string) {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;
      const now: any = dayjs().format("HH:mm").toString();

      if (type === "daily") {
        this.emailTaskSchedulerNotification.daily.isWarning = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.isAlarm = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.timedate = daily.isActive ? now : null;
      }
      if (type === "weekly") {
        this.emailTaskSchedulerNotification.weekly.isWarning = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.timedate = weekly.isActive ? now : null;
      }
      if (type === "monthly") {
        this.emailTaskSchedulerNotification.monthly.isWarning = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.timedate = monthly.isActive ? now : null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        emailTaskScheduler,
      });
    },
    checkBoxEmailTaskScheduler() {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;

      if (!daily.isActive) {
        this.emailTaskSchedulerNotification.daily.isWarning = false;
        this.emailTaskSchedulerNotification.daily.isAlarm = false;
        this.emailTaskSchedulerNotification.daily.timedate = null;
      }
      if (!weekly.isActive) {
        this.emailTaskSchedulerNotification.weekly.isWarning = false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = false;
        this.emailTaskSchedulerNotification.weekly.timedate = null;
      }
      if (!monthly.isActive) {
        this.emailTaskSchedulerNotification.monthly.isWarning = false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = false;
        this.emailTaskSchedulerNotification.monthly.timedate = null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        emailTaskScheduler,
      });
    },
    timeEmailTaskScheduler() {
      const emailTaskScheduler = this.emailTaskSchedulerNotification;

      (this as any).$store.dispatch("setEquipmentUpdateForm", {
        ...(this as any).$store.getters.equipmentTagUpdateForm,
        emailTaskScheduler,
      });
    },
  },
});
