
import Vue from "vue";
import dayjs from "dayjs";

import DateRangePicker from "vue2-daterange-picker";
import "@/assets/scss/custom-daterange-picker.css";

import CalendarIcon from "@/components/icons/CalendarIcon.vue";

export default Vue.extend({
  name: "DateTimeRange",
  components: { DateRangePicker, CalendarIcon },
  props: ["disabled"],
  data() {
    const date = new Date();
    const firstDay = dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00");
    const lastDay = dayjs(date).format("YYYY-MM-DDT23:59:59");

    return {
      date,
      dateRange: {
        startDate: firstDay,
        endDate: lastDay,
      },
      opens: "center",
      minDate: null,
      maxDate: null,
    };
  },
  methods: {
    changeDate(ev: any) {
      // const now = dayjs().format("HH:mm:ss");

      this.$emit("submit", {
        startDate: dayjs(ev.startDate).format("YYYY-MM-DDT00:00:00.000[Z]"),
        endDate: dayjs(ev.endDate).format("YYYY-MM-DDT23:59:59.000[Z]"),
      });
    },
    setLimitDate() {
      console.log("setLimitDate");
    },
    clearLimitDate() {
      console.log("clearLimitDate");
    },
  },
});
