
import Vue from "vue";
import { mapActions } from "vuex";
import ModelIcon from "@/components/icons/ModelIcon.vue";

export default Vue.extend({
  name: "EquipmentsMap",
  components: { ModelIcon },
  data() {
    return {
      sidebarWeight: -560,
      equipmentList: [],
      equipmentDetails: [],
      modelsStatus: [],
      is_hover: false,
      hoverStyle: {
        display: "none",
      },
      iconUrl: require("@/assets/icons/iconMarker/Electric.svg"),
      iconMarker: {
        url: require("@/assets/icons/iconMarker/Rotating/RotatingNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      isLoading: false,
      iconsMarker: [],
      filterMap: "All",
      allEquipmentDatas: [],
    };
  },
  computed: {
    allEquipment() {
      const allEqt = this.$store.state.company.structure_item.filter((val) => typeof val === "object");
      return allEqt || 0;
    },
    companyDetails() {
      return this.$store.state.company;
    },
    items() {
      const arrEq = ["All"];
      this.allEquipment.forEach((val) => arrEq.push(val.structureItemTitle));
      return arrEq;
    },
    isAutoRefresh: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.enable;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          enable: value,
        });
        if (value) {
          this.onStartAutoReloadData();
        } else {
          this.onStopAutoReloadData();
        }
      },
    },
    timestamp() {
      return this.$store.state.modelViewData.autoRefresh.timestamp;
    },
    timeout: {
      get() {
        return this.$store.state.modelViewData.autoRefresh.timeout;
      },
      set(value) {
        this.setAutoRefresh({
          ...this.$store.state.modelViewData.autoRefresh,
          timeout: value,
        });
        this.onStopAutoReloadData();
        setTimeout(() => {
          this.onStartAutoReloadData();
        }, 500);
      },
    },
    modelsStatusAll() {
      return this.$store.state.company.dashboard_data;
    },
    isSidebar() {
      return this.$store.state.app.isSidebar;
    },
    structureModelList() {
      return this.$store.state.structure.children;
    },
    center() {
      let totalLat = 0;
      let totalLng = 0;

      this.allEquipmentDatas.forEach((val) => {
        if (val.posLat) {
          totalLat = val.posLat;
          totalLng = val.posLng;
        }
      });

      return { lat: totalLat, lng: totalLng };
    },
  },
  methods: {
    ...mapActions(["getCompany", "getStructure", "setAutoRefresh"]),
    closeSidebar() {
      this.sidebarWeight = -560;
    },
    onHandleClick(title) {
      this.hoverStyle = {
        display: "none",
      };
      this.sidebarWeight = 0;
      this.showDetails(title);
    },
    showDetails(equipment: string) {
      const modelList = this.allEquipment.find((val) => val.structureItemTitle === equipment);
      const path = modelList.path.split("/").slice(1);
      const modelStructure = this.structureModelList;
      let arrModelList;
      path.forEach((val, index) => {
        if (index === 0) {
          arrModelList = modelStructure.find((vl) => vl.structureItemTitle === val);
        } else {
          arrModelList = arrModelList.children.find((vl) => vl.structureItemTitle === val);
        }
      });
      this.equipmentList = arrModelList.equipmentModels.filter((val) => !val.is_offline && val.isActive !== "inactive");

      const modelstat = this.companyDetails.dashboard_data.allEquipmentReportJson.find(
        (val) => val.equipmentTitleName === equipment
      );
      const equipDetail = this.companyDetails.structure_item.find((val) => val.structureItemTitle === equipment);
      if (!modelstat) {
        this.modelsStatus = {
          allModel: 0,
          normalModel: 0,
          warningModel: 0,
          alarmModel: 0,
          stopModel: 0,
        };
      } else {
        this.modelsStatus = modelstat;
      }

      this.equipmentDetails = equipDetail;
    },
    iconEquipmentType(equipment) {
      const equipDetail = this.allEquipment.find((val) => val.structureItemTitle === equipment);
      const equipType = `${equipDetail.equipmentGroupType[0].toUpperCase()}${equipDetail.equipmentGroupType.slice(1)}`;

      const iconMarker = {
        url: require(`@/assets/icons/iconMarker/${equipType}/${equipType}${this.statusEquipment(equipDetail.status)}.svg`),
        scaledSize: {
          width: 50,
          height: 50,
        },
      };
      this.iconsMarker.push(iconMarker);
    },
    statusEquipment(code: number) {
      if (code === -1) return "Notrun";
      if (code === 0) return "Normal";
      if (code === 1) return "Warning";
      if (code === 2) return "Alarm";
      else return "";
    },
    statusName(code: number) {
      if (code === -1) return "#949494";
      if (code === 0) return "#4caf50";
      if (code === 1) return "#ffc12b";
      if (code === 2) return "#f44336";
      else return "";
    },
    statusCode(code: number) {
      if (code === -1) return "Offline";
      if (code === 0) return "Normal";
      if (code === 1) return "Warning";
      if (code === 2) return "Alarm";
      else return "";
    },
    maphover(e: any, title: string) {
      if (this.sidebarWeight === 0) return;
      const circleRect = e.domEvent.target.getBoundingClientRect();
      const x = circleRect.left + circleRect.width / 2;
      const y = circleRect.top + circleRect.height / 2;
      this.showDetails(title);

      this.hoverStyle = {
        display: "block",
        left: `${x}px`,
        top: `${y}px`,
      };
    },
    mapout() {
      this.hoverStyle = {
        display: "none",
      };
    },
    // handleOnchangeFilterMap(filter: string, position) {

    // },
  },
  created() {
    if (this.allEquipment) {
      this.allEquipment.forEach((val) => {
        this.iconEquipmentType(val.structureItemTitle);
      });
      this.allEquipmentDatas = this.allEquipment.map((val) => ({ ...val, show: true }));
      // this.$refs.Map.panTo({
      //   lat: allEquipmentDatas.posLat,
      //   lng: allEquipmentDatas.posLng
      // });
    }
  },
  watch: {
    filterMap(val) {
      const value = typeof val === "object" ? val[0] : val;
      if (value === "All") {
        this.allEquipmentDatas.forEach((val) => {
          val.show = true;
        });
        this.sidebarWeight = -560;
        return;
      }
      const position = this.allEquipment.find((val) => val.structureItemTitle === value);
      this.$refs.Map.panTo({ lat: position.posLat, lng: position.posLng });
      this.onHandleClick(value);
      this.allEquipmentDatas.forEach((val) => {
        if (val.structureItemTitle !== value) val.show = false;
        else val.show = true;
      });
    },
    allEquipment() {
      this.allEquipment.forEach((val) => {
        this.iconEquipmentType(val.structureItemTitle);
      });
      this.allEquipmentDatas = this.allEquipment.map((val) => ({ ...val, show: true }));
    },
  },
});
