
import Vue from "vue";
import RvsOverallStatus from "./overallStatus/RvsOverallStatus.vue";
import McaOverallStatus from "./overallStatus/McaOverallStatus.vue";
import VcaOverallStatus from "./overallStatus/VcaOverallStatus.vue";

export default Vue.extend({
  name: "ModelOverall",
  components: { RvsOverallStatus, McaOverallStatus, VcaOverallStatus },
  data() {
    const datas = [
      {
        tagName: "test001",
        values: 20.5,
      },
      {
        tagName: "test002",
        values: 20.5,
      },
    ];
    const datasVca = [
      {
        tagName: "VCA001",
        values: 20.5,
      },
      {
        tagName: "VCA002",
        values: 20.5,
      },
    ];
    const fetchDatas = [];
    const headers = [
      { text: "TAG NAME", value: "tagName", sortable: false },
      { text: "VALUES", value: "values", sortable: false },
    ];
    return {
      datas,
      datasVca,
      headers,
      fetchDatas,
      checkComponents: false,
      count: 0,
    };
  },
  computed: {
    companyId() {
      return this.$store.state.company.id;
    },
  },
  // async created() {
  //   this.fetchDatas = await this.fetchOverallModel();
  // },
  methods: {
    checkTypeExist(type: string) {
      return this.fetchDatas.filter((val) => val.model_type === type).length > 0;
    },
  },
  // watch: {
  //   async companyId() {
  //     this.fetchDatas = await this.fetchOverallModel();
  //   },
  // },
});
