
import Vue from "vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "SensorDetailEvs",
  components: { NoDataAvailable, LoadingGraph },
  data() {
    const headers = [
      { text: "Status", value: "status", align: "center", sortable: false },
      { text: "Type of Position", value: "typeOfPosition", align: "start", sortable: true },
      { text: "Machine", value: "machine", align: "start", sortable: true },
      { text: "Sensor ID", value: "sensorId", align: "start", sortable: true },
      { text: "Sensor Name", value: "sensorName", align: "start", sortable: true },
      { text: "Acceleration (m/s<sup>2</sup>)", value: "acceleration", align: "center", sortable: false },
      { text: "Velocity (mm/s)", value: "velocity", align: "center", sortable: false },
      { text: "Temperature (&#186;C)", value: "temperature", align: "center", sortable: false },
      { text: "Battery (V)", value: "battery", align: "center", sortable: false },
    ];

    const items: any[] = [];

    return { headers, items, isLoading: true };
  },
  computed: {
    getModelCode() {
      return this.$store.getters.modelDetail?.equipmentCode?.code;
    },
    model() {
      return this.$store.getters.model;
    },
  },
  async created() {
    await (this as any).fetchSensorDetail();
  },
  methods: {
    async fetchSensorDetail() {
      try {
        (this as any).isLoading = true;
        const modelUseCase = (this as any).$dep.modelUseCase;
        const modelCode = this.model.modelCode;

        const result = await modelUseCase.getSensorDetail(modelCode);
        (this as any).items = result || [];
      } catch (error) {
        //
      } finally {
        (this as any).isLoading = false;
      }
    },
    statusName(code: number) {
      if (code === 0) return "normal";
      if (code === 1) return "warning";
      if (code === 2) return "alarm";
      if (code === 3) return "offline";
      else return "";
    },
  },
});
