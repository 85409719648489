
import Vue from "vue";
import pagination from "@/common/mixins/pagination";
import dialog from "@/common/mixins/dialog";
import { mapActions } from "vuex";

import { Member, MemberStatus } from "../models/Member";
import { MemberPaginationForm } from "../forms/MemberPaginationForm";
import MemberCompanyInfo from "./MemberCompanyInfo.vue";
export default Vue.extend({
  mixins: [pagination, dialog],
  components: {
    MemberCompanyInfo,
  },
  data() {
    const isLoading = false;

    const memberHeaders = [
      // {
      //   text: "Name",
      //   align: "start",
      //   value: "name",
      // },
      { text: "", value: "action" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Status", value: "status" },
    ];

    const members: Member[] = [];

    const memberPaginationForm: MemberPaginationForm = {
      search: "",
      page: 0,
      perPage: 10,
    };

    const rowsPerPageItems: Array<any> = [5, 10, 20, 30, 40];
    const paginations: any = {};

    return {
      isLoading,
      memberHeaders,
      members,
      memberPaginationForm,
      rowsPerPageItems,
      paginations,
    };
  },
  computed: {
    isShowModal() {
      return this.$store.getters.getMemberAssignForm.isShowModal;
    },
    equipmentTagId() {
      return this.$store.getters.getMemberAssignForm.equipmentTagId;
    },
    equipmentTagName() {
      return this.$store.getters.getMemberAssignForm.equipmentTagName || "";
    },
    memberList() {
      // return (this as any).members;
      return (this as any).members.map((member: Member) => {
        const statusColor = (() => {
          if (member.status == MemberStatus.ACTIVE) {
            return "success";
          } else if (member.status == MemberStatus.PENDING) {
            return "warning";
          }
        })();

        return {
          id: member.id,
          name: member.name,
          email: member.email,
          statusColor,
          statusName: member.status,
          profileImageUrl: member.profileImageUrl,
        };
      });
    },
    search: {
      get() {
        return this.$store.getters.getMemberAssignForm.search;
      },
      set(value: string) {
        this.setMemberAssignModal({
          selectedMemberIds: this.selectedMemberIds,
          search: value,
        });
      },
    },
    selectedMemberIds: {
      get() {
        return this.$store.getters.getMemberAssignForm.selectedMemberIds;
      },
      set(value) {
        this.setMemberAssignModal({
          selectedMemberIds: value,
          search: this.search,
        });
      },
    },
    company() {
      return this.$store.getters.companyInfos;
    },
  },
  methods: {
    ...mapActions(["setMemberAssignModal", "closeMemberAssignModal"]),
    async getMembers() {
      this.members = [];

      const company_id = sessionStorage.getItem("company_id");
      const { items: dataMemberMembers } = await (this as any).$dep.memberUseCase.getMemberMembers(this.equipmentTagId);
      const { items: dataMember } = await (this as any).$dep.memberUseCase.getMembersOnly(company_id);

      const filter = dataMember.filter((dataMemberEl: any) => {
        return (
          dataMemberMembers.filter((dataMemberMembersEl: any) => {
            return dataMemberMembersEl.id === dataMemberEl.id;
          }).length === 0
        );
      });

      this.members = [...filter, ...dataMemberMembers];
      // (this as any).setPaginationMeta(meta);
    },
    async getFolderAssignMembers(id: number) {
      const assignMembers = await (this as any).$dep.equipmentUseCase.getFolderAssignMembers(id);

      const selectedMemberIds = assignMembers.items.map((e: any) => e.id);

      this.setMemberAssignModal({ selectedMemberIds, search: this.search });
    },
    updatePagination(val: any) {
      const page = val.page;
      const perPage = val.itemsPerPage;

      (this as any).memberPaginationForm = {
        ...(this as any).memberPaginationForm,
        page,
        perPage,
      };
    },
    async save() {
      try {
        this.isLoading = true;
        const company_id = sessionStorage.getItem("company_id");
        const { equipmentTagId, selectedMemberIds } = this.$store.getters.getMemberAssignForm;
        const member_option = [];

        for (const member of this.members) {
          const { id } = member;
          const find = selectedMemberIds.find((param: any) => param === id);
          if (find) {
            member_option.push({ id, is_create: true, is_delete: false });
          } else {
            member_option.push({ id, is_create: false, is_delete: true });
          }
        }

        const body = {
          company_id,
          equipment_tag_id: equipmentTagId,
          member_option,
        };

        await (this as any).$dep.equipmentUseCase.updateFolderAssignMembers(body);

        this.closeMemberAssignModal();
      } catch (error: any) {
        //
        const msgError = error.response?.data?.detail || "Error Assign Member";
        await (this as any).alertError("Error", msgError);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    async isShowModal(value) {
      if (value) {
        try {
          this.isLoading = true;

          await Promise.all([this.getMembers(), this.getFolderAssignMembers(this.equipmentTagId)]);
        } catch (error: any) {
          //
        } finally {
          this.isLoading = false;
        }
      }
    },
    async search() {
      await this.getMembers();

      // await Promise.all([
      //   this.getMembers(),
      //   this.getFolderAssignMembers(this.equipmentTagId),
      // ]);
    },
  },
});
