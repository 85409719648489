
import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  name: "DateTime",
  data() {
    return { interval: null, timer: null };
  },
  created() {
    (this as any).interval = setInterval(() => {
      (this as any).timer = dayjs(new Date()).format("DD MMMM YYYY HH:mm:ss");
    }, 1000);
  },
  beforeDestroy() {
    clearInterval((this as any).interval);
  },
});
