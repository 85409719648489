import { Client } from "../../clients/client";
import { EquipmentTagCreateForm } from "./forms/EquipmentTagCreateForm";
import { EquipmentTagUpdateForm } from "./forms/EquipmentTagUpdateForm";
import { FolderEquipment } from "../folder/models/FolderEquipment";
import { Member } from "../member/models/Member";
import { MemberAssignForm } from "../member/forms/MemberAssignForm";

export class EquipmentUseCase {
  constructor(private client: Client) {}

  public async getEquipmentTagById(id: number): Promise<FolderEquipment> {
    // return await this.client.equipmentClient.getFolder(id);
    return this.client.equipmentClient.getEquipmentTagById(id);
  }

  // public async getEquipmentTagById(id: number): Promise<FolderEquipment> {
  //   return this.client.equipmentClient.getEquipmentTagById(id);
  // }

  public async getFolderAssignMembers(id: number): Promise<Member[]> {
    return await this.client.equipmentClient.getFolderAssignMembers(id);
  }

  public async createEquipmentTag(form: any): Promise<FolderEquipment> {
    return this.client.equipmentClient.postCeateEquipment({
      parentId: form.parentId ? form.parentId : undefined,
      structureItemTitle: form.structureItemTitle,
      equipmentGroupType: form.itemGroupType,
      posLat: form.position?.lat ? form.position.lat : undefined,
      posLng: form.position?.lng ? form.position.lng : undefined,
      isPrimaryFolder: form.isPrimaryFolder,
      structureItemType: form.structureItemType,
      companies_id: form.companies_id,
      createdDate: form.createdDate,
    });
  }

  public async updateEquipmentTag(form: any, id: number): Promise<FolderEquipment> {
    return this.client.equipmentClient.pacthUpdateEquipment(form, id);
  }

  public async updateFolderAssignMembers(form: any) {
    const { company_id, equipment_tag_id, member_option } = form;

    return this.client.equipmentClient.patchFolderAssignMembers(company_id || 0, equipment_tag_id || 0, {
      member_option,
    });
  }

  public async deleteFolder(id: number): Promise<FolderEquipment> {
    return this.client.equipmentClient.deleteFolder(id);
  }

  public async getAllEquipmentTags(): Promise<void> {
    return await this.client.equipmentClient.getAllEquipmentTags();
  }

  public async getExportRecommendationMessageFile(id: number): Promise<void> {
    return await this.client.equipmentClient.getExportRecommendationMessageFile(id);
  }

  public async getEmailTaskSchedulerById(id: number): Promise<void> {
    return await this.client.equipmentClient.getEmailTaskSchedulerById(id);
  }

  public async sendEmailTaskScheduler(params: any): Promise<void> {
    return await this.client.equipmentClient.sendEmailTaskScheduler(params);
  }

  public async updateEmailTaskScheduler(params: any): Promise<void> {
    return await this.client.equipmentClient.updateEmailTaskScheduler(params);
  }

  public async deleteEmailTaskScheduler(jobId: number): Promise<void> {
    return await this.client.equipmentClient.deleteEmailTaskScheduler(jobId);
  }
}
