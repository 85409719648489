
import Vue from "vue";

import NoDataAvailable from "@/components/NoDataAvailable.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "SensorDetailRvs",
  components: { NoDataAvailable, LoadingGraph },
  computed: {
    modelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
  },
  data() {
    const headers = [
      { text: "Status", value: "status", align: "center" },
      { text: "Type of Position", value: "typeOfPosition", align: "start" },
      { text: "Machine", value: "machine", align: "start" },
      { text: "Sensor ID", value: "sensorId", align: "start" },
      { text: "Sensor Name", value: "sensorName", align: "start" },
      { text: "Velocity [mm/s]", value: "velocity", align: "start" },
      { text: "Acceleration [m/s\u00B2]", value: "acceleration", align: "start" },
      { text: "Envelope", value: "envelope", align: "start" },
      { text: "Temperature [\u2103]", value: "temperature", align: "center" },
      { text: "Battery [Volt]", value: "battery", align: "center" },
    ];

    const items = [];

    const itemsPerPageData: { label: string; value: number }[] = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "15", value: 15 },
      { label: "All", value: -1 },
    ];

    return {
      headers,
      items,
      isLoading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      itemsPerPageData,
    };
  },
  async created() {
    await this.fetchSensorDetail();
  },
  methods: {
    async fetchSensorDetail() {
      try {
        this.isLoading = true;

        const dataSensor = await (this as any).$dep.modelUseCase.getSensorDetailRvs(this.modelCode);
        const dataAlarmLimit = await (this as any).$dep.modelUseCase.getAlarmLimitSensorRvs(this.modelCode);
        const result = [];
        for (const alarmLimit of dataAlarmLimit) {
          const sensor = dataSensor.find((s: any) => s.uid.toLowerCase() === alarmLimit.sensor_id.toLowerCase());
          if (sensor) {
            result.push({
              overAllSensorStatus: sensor.overAllSensorStatus,
              typeOfPosition: sensor.typeOfPosition,
              machine: sensor.machine,
              sensorId: alarmLimit.sensor_id,
              sensorName: alarmLimit.sensor_name,
              acceleration: {
                x: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "x").paxis ?? "X",
                  value: sensor.accelerationX,
                  status: sensor.accelerationXStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "x").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "x").warning,
                },
                y: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "y").paxis ?? "Y",
                  value: sensor.accelerationY,
                  status: sensor.accelerationYStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "y").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "y").warning,
                },
                z: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "z").paxis ?? "Z",
                  value: sensor.accelerationZ,
                  status: sensor.accelerationZStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "z").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "acceleration" && r.axis === "z").warning,
                },
              },
              velocity: {
                x: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "x").paxis ?? "X",
                  value: sensor.velocityX,
                  status: sensor.velocityXStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "x").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "x").warning,
                },
                y: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "y").paxis ?? "Y",
                  value: sensor.velocityY,
                  status: sensor.velocityYStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "y").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "y").warning,
                },
                z: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "z").paxis ?? "Z",
                  value: sensor.velocityZ,
                  status: sensor.velocityZStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "z").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "velocity" && r.axis === "z").warning,
                },
              },
              envelope: {
                x: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "x").paxis ?? "X",
                  value: sensor.envelopeX,
                  status: sensor.envelopeXStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "x").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "x").warning,
                },
                y: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "y").paxis ?? "Y",
                  value: sensor.envelopeY,
                  status: sensor.envelopeYStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "y").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "y").warning,
                },
                z: {
                  axis: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "z").paxis ?? "Z",
                  value: sensor.envelopeZ,
                  status: sensor.envelopeZStatus,
                  alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "z").alarm,
                  warning: alarmLimit.alarm_limit.find((r: any) => r.type === "envelope" && r.axis === "z").warning,
                },
              },
              temperature: {
                value: sensor.temperature,
                status: sensor.temperatureStatus,
                alarm: alarmLimit.alarm_limit.find((r: any) => r.type === "temperature").alarm,
                warning: alarmLimit.alarm_limit.find((r: any) => r.type === "temperature").warning,
              },
              battery: {
                value: sensor.battery,
                status: sensor.batteryStatus,
                alarm: alarmLimit.battery_limit.alarm,
                warning: alarmLimit.battery_limit.warning,
              },
            });
          }
        }

        (this as any).items = result || [];

        this.isLoading = false;
      } catch (error) {
        (this as any).items = [];
        this.isLoading = false;
      }
    },
    statusName(code: number) {
      if (code === 0) return "normal";
      if (code === 1) return "warning";
      if (code === 2) return "alarm";
      if (code === 3) return "offline";
      else return "";
    },
    changePerPage(num: number) {
      this.itemsPerPage = num;
    },
  },
  watch: {
    async getModelId() {
      await this.fetchSensorDetail();
    },
  },
});
