
import Vue from "vue";
import dayjs from "dayjs";

import TableCustom from "@/components/TableCustom.vue";
import FaultEventAlarmAcknowledge from "./FaultEventAlarmAcknowledge.vue";
import FaultEventAlarmDone from "./FaultEventAlarmDone.vue";
import FaultEventAlarmDetails from "./FaultEventAlarmDetails.vue";

export default Vue.extend({
  name: "FaultEventAlarm",
  components: { TableCustom, FaultEventAlarmAcknowledge, FaultEventAlarmDone, FaultEventAlarmDetails },
  computed: {
    company() {
      return this.$store.getters.company;
    },
    model() {
      return this.$store.getters.model;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
  },
  data() {
    const newHeaders = [
      { text: "Date/Time", value: "dateTime", sortable: true },
      { text: "Alarm Message", value: "alarmMessage", style: "width: 50%;", sortable: true },
      { text: "Alarm Type", value: "alarmType", sortable: true },
      { text: "Alarm Value", value: "alarmValue", sortable: true },
      { text: "Acknowledge", value: "acknowledge", align: "center", sortable: false },
      { text: "Done", value: "done", align: "center", sortable: false },
      { text: "Details", value: "details", align: "center", sortable: false },
    ];
    const historyHeaders = [
      { text: "Date/Time", value: "dateTime", sortable: true },
      { text: "Alarm Message", value: "alarmMessage", style: "width: 50%;", sortable: true },
      { text: "Alarm Type", value: "alarmType", sortable: true },
      { text: "Alarm Value", value: "alarmValue", sortable: true },
      { text: "Update at", value: "UpdateAt", sortable: false },
      { text: "Details", value: "details", sortable: false },
    ];

    const newDatas: any[] = [];
    const newOriginalDatas: any = [];
    const historyDatas: any[] = [];
    const historyOriginalDatas: any[] = [];

    const members: any[] = [];

    return {
      panel: [],
      isLoading: true,
      newHeaders,
      newDatas,
      newOriginalDatas,
      historyHeaders,
      historyDatas,
      historyOriginalDatas,
      members,
      date: null,
      menu: false,
      isActiveTypeNewAlarm: "All",
      isActiveTypeAlarmHistory: "All",
    };
  },
  async created() {
    await this.fetchFaultEvents(0, 0);
    await (this as any).fetchMembers();
  },
  methods: {
    async fetchFaultEvents(month: number, year: number) {
      try {
        (this as any).isLoading = true;
        (this as any).clearDatas();

        const { id } = this.model;
        const result = await (this as any).$dep.modelUseCase.getNewFaultEvents(id, month, year);

        (this as any).panel = result.new_alarm.length > 0 ? [0] : result.alarm_history.length > 0 ? [0] : [];

        (this as any).newDatas = result.new_alarm;
        (this as any).newOriginalDatas = result.new_alarm;

        (this as any).historyDatas = result.alarm_history;
        (this as any).historyOriginalDatas = result.alarm_history;
      } catch (error) {
        //
        (this as any).clearDatas();
      } finally {
        (this as any).isLoading = false;
      }
    },
    async fetchMembers() {
      try {
        const { id } = this.company;
        const members = await (this as any).$dep.memberUseCase.getMembers(id);
        (this as any).members = members.items;
      } catch (error) {
        (this as any).members = [];
      }
    },
    onSelectType(params: any) {
      const { status, type } = params;

      if (type === "NEW") {
        (this as any).newDatas =
          status === 0
            ? (this as any).newOriginalDatas
            : (this as any).newOriginalDatas.filter((p: any) => p.alarmType === status);
      }
      if (type === "HISTORY") {
        (this as any).historyDatas =
          status === 0
            ? (this as any).historyOriginalDatas
            : (this as any).historyOriginalDatas.filter((p: any) => p.alarmType === status);
      }
    },
    async onSelectAll() {
      (this as any).date = null;
      await (this as any).fetchFaultEvents(0, 0);
    },
    async onChangeDate(ev: any) {
      const month = dayjs(ev).format("M");
      const year = dayjs(ev).format("YYYY");

      await (this as any).fetchFaultEvents(month, year);
    },
    async reload() {
      await (this as any).fetchFaultEvents(0, 0);
    },
    async onDownloadExcel() {
      const { id } = this.model;
      const url = `${process.env.VUE_APP_API_BASE_URL}/api/v1/fault-eventdownload-fault-event?model_id=${id}`;

      window.open(url);
    },
    clearDatas() {
      (this as any).newDatas = [];
      (this as any).newOriginalDatas = [];
      (this as any).historyDatas = [];
      (this as any).historyOriginalDatas = [];
    },
    formatDate(date: any, format = "DD MMM YYYY, HH:mm") {
      return dayjs(date).format(format);
    },
  },
  watch: {
    async getModelId() {
      await this.fetchFaultEvents(0, 0);
      await (this as any).fetchMembers();
    },
  },
});
