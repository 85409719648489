
import Vue from "vue";

import UploadArrowIcon from "@/components/icons/UploadArrowIcon.vue";

export default Vue.extend({
  name: "FormDiagramRvs",
  components: {
    UploadArrowIcon,
  },
  computed: {
    diagramRvs() {
      return (this as any).$store.state.updateModelForm.rvs_diagram_in?.diagram_url || null;
    },
  },
  data() {
    return { fileUpload: null };
  },
  methods: {
    downloadFormat() {
      window.open((this as any).$dep.modelUseCase.downloadFileRecommendation(this.model.modelTypeId, this.model.modelCode));
    },
    async onChange() {
      const file = (this as any).$refs.file.files[0];

      if (!file) return alert("Please select file");

      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        return alert("Please upload file image");
      }

      let formdata = new FormData();
      formdata.append("image_file", file);

      try {
        const imageDiagram = await (this as any).$dep.modelUseCase.modelUploadDiagramRvs(formdata);
        (this as any).$store.dispatch("setUpdateModelForm", {
          ...this.$store.state.updateModelForm,
          rvs_diagram_in: {
            diagram_url: imageDiagram.url || "",
          },
        });
        (this as any).fileUpload = imageDiagram.url || "";
      } catch (error) {
        console.log(error);
      }
    },
    onDrop(event: any) {
      event.preventDefault();

      (this as any).$refs.file.files = event.dataTransfer.files;
      this.onChange();

      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    remove() {
      this.$store.dispatch("setUploadDiagramRvsForm", { formdata: null });
      (this as any).fileUpload = null;
    },
    dragover(event: any) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-[#EAEEF6]")) {
        event.currentTarget.classList.remove("bg-[#FFFFFF]");
        event.currentTarget.classList.add("bg-[#EAEEF6]");
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    humanFileSize(bytes: number) {
      const thresh = 1000;
      if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
      }

      const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      let u = -1;
      const r = 10 ** 0;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return `${bytes.toFixed(0)} / ${bytes.toFixed(0)} ${units[u]}`;
    },
  },
});
