import Vue from "vue";
import VueGoogleMap from "vuejs-google-maps";
import "vuejs-google-maps/dist/vuejs-google-maps.css";

export const mapStyles = {
  styles: [
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "19",
        },
        {
          lightness: "8",
        },
        {
          gamma: "1.60",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "geometry.stroke",
      stylers: [
        {
          saturation: "-64",
        },
        {
          gamma: "2.94",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#a9a9a9",
        },
        {
          weight: "0.01",
        },
        {
          gamma: "1.34",
        },
        {
          lightness: "-9",
        },
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.stroke",
      stylers: [
        {
          lightness: "100",
        },
        {
          gamma: "0.00",
        },
        {
          saturation: "-26",
        },
        {
          color: "#f2dede",
        },
        {
          weight: "0.01",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "administrative.neighborhood",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.attraction",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.government",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.medical",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.place_of_worship",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.sports_complex",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit.station.bus",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit.station.rail",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
  ],
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};
Vue.use(VueGoogleMap, {
  load: {
    apiKey: "AIzaSyDX2XeSh4PnbsL7Bpi4bta1Yo6BPL1J99E",
    libraries: [],
  },
});

export default VueGoogleMap;
