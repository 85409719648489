
import dialog from "@/common/mixins/dialog";
import Vue from "vue";
export default Vue.extend({
  mixins: [dialog],
  props: {
    items: Object,
    index: Number,
    length: Number,
    isOnline: String,
  },
  data() {
    return {
      folderOpen: true,
    };
  },
  computed: {
    hasDisplayActivePermission() {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN"].includes(role) ? true : false;
      return permission;
    },
    currentRole() {
      return (this as any).$store.getters.getCurrentRole;
    },
    currentFolderSelect() {
      return (this as any).$store.getters.currentFolderClick;
    },
    isActive() {
      if ((this as any).currentFolderSelect === `model-${(this as any).items.id}` && (this as any).isOnline === "online") {
        return "active";
      } else {
        return "";
      }
    },
    lastChild() {
      if (this.index === this.length) {
        return "last-child-node";
      } else {
        return "";
      }
    },
  },
  methods: {
    async openModelView(id: any) {
      if ((this as any).isOnline === "online") {
        this.$store.dispatch("clickFolder", `model-${id}`);
        // await this.$store.dispatch("getModelDetail", id);
        this.$router
          .push({
            name: "ModelViewPage",
            params: { id },
            query: { folderSelect: `model-${id}` },
          })
          .catch();
      } else {
        if ((this as any).items.linkUrl) {
          let url = (this as any).items.linkUrl;
          if (!(this as any).items.linkUrl.match(/^https?:\/\//i)) {
            url = "https://" + url;
          }
          window.open(url, "_blank");
        } else {
          (this as any).alertError("Error", "No Link Url");
        }
      }
    },
    openContextMenu(e: any) {
      const role = this.$store.getters.getCurrentRole;
      const permission = ["ADMIN", "OWNER"].includes(role) ? true : false;

      if (permission) {
        e.preventDefault();

        this.$store.dispatch("openContextMenu", {
          folderName: (this as any).items.title,
          folderId: (this as any).items.id,
          folderType: "MODEL",
          isOnline: (this as any).isOnline,
          positionY: e.clientY,
          positionX: e.clientX,
          isShowMenu: true,
        });
      }
    },
    getColorStatus(code: any) {
      return code === 0 ? "status-normal" : code === 1 ? "status-warning" : code === 2 ? "status-danger" : "status-offline";
    },
  },
});
