
import Vue from "vue";
import dayjs from "dayjs";
import axios from "axios";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "EvsModelChart",
  components: { highcharts: Chart, ButtonDanger, ButtonReload, DateTimeRange, LoadingGraph, NoDataAvailable },
  data() {
    const date = new Date();
    const phaseGroup: any[] = [
      { id: 1, title: "Acceleration", value: "acceleration" },
      { id: 2, title: "Temperature", value: "temperature" },
      { id: 3, title: "Velocity", value: "velocity" },
    ];
    const isPhaseGroup = { id: 1, title: "Acceleration", value: "acceleration" };
    return {
      source: null,
      isSensorType: {},
      sensorTypeData: [],
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      chartLoading: true,
      chartOptions: {
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { shared: true, crosshairs: true },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
      phaseGroup,
      isPhaseGroup,
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    modelId() {
      return this.model.id;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.name;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
    getModelType() {
      return this.$store.getters.modelDetail?.equipmentModelType?.id;
    },
    getModelId() {
      return this.$store.getters.modelDetail?.id;
    },
    getModelCode() {
      return this.$store.getters.modelDetail?.equipmentCode?.code;
    },
    getTabResultGroup() {
      return this.$store.getters.modelTabResultGroup;
    },
    getTagGroup() {
      return this.$store.getters.modelTagGroup;
    },
    getPhaseGroup() {
      return this.$store.getters.modelPhaseGroup;
    },
  },
  async created() {
    await (this as any).fetchResultData();
  },
  methods: {
    async fetchSensorTypeData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelHealthMonitoringSensorType(this.isSecondaryTab, id);
        this.sensorTypeData = response.map((prev) => ({ id: prev.id, name: prev.name }));
        this.isSensorType = { id: this.sensorTypeData[0].id, name: this.sensorTypeData[0].name };
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = {};
      }
    },
    async fetchResultData() {
      try {
        this.chartLoading = true;

        // if (this.source) {
        //   this.source.cancel();
        // }
        const res = await this.$dep.modelUseCase.getEquipmentModelDataResultEVS(
          this.model.modelCode,
          this.groupCode,
          this.model.id,
          {
            startDate: this.startDate,
            endDate: this.endDate,
          },
          this.isNormal
        );
        res.sort((a, b) => {
          const timestampA = new Date(a.timestamp);
          const timestampB = new Date(b.timestamp);

          return Number(timestampA) - Number(timestampB);
        });
        this.chartResultData = res;
        this.chartOptions.title.text = `Current Analysis : ${
          typeof this.isPhaseGroup === "string" ? this.isPhaseGroup : this.isPhaseGroup.value
        }`;
        this.chartOptions.xAxis = this.aAxis(res);
        this.chartOptions.series = this.series(res);
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
        this.chartLoading = false;
        this.chartResultData = [];
      } finally {
        this.source = null;
      }
    },
    onChangeSensorType(param: any) {
      const { id, name } = param;
      this.isSensorType = { id, name };
      this.isPhaseGroup = id;
      this.$store.dispatch("setModelPhaseGroup", param);
    },
    onChangeSelectDateType(param: any) {
      this.selectDateType = param;
    },
    aAxis(datas: any) {
      let labels = datas.map((val) => val.timestamp);
      const formatDate = labels.map((label) => dayjs(label).format("DD-MM-YYYY HH:mm"));
      const count = [...new Set(formatDate)].length;
      const uniqueTimestamps = new Set();
      const tagCode: any[] = [...new Set(datas.map((item: any) => item.uid))];
      for (const item of tagCode) {
        datas.forEach((val) => {
          if (val.uid === item) {
            // Replace with the desired "uid"
            uniqueTimestamps.add(val.timestamp);
          }
        });
      }
      return {
        type: "category",
        labels: {
          align: "left",
          step: count <= 10 ? 0 : count <= 100 ? 1 : count <= 200 ? 2 : count <= 300 ? 3 : count <= 400 ? 4 : 5,
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: [...uniqueTimestamps],
      };
    },
    series(res) {
      const data = res;
      // const phaseGroup = (this as any).getPhaseGroup.value;
      const phaseGroup = this.isPhaseGroup.value;
      const tagCode: any[] = [...new Set(data.map((item: any) => item.uid))];
      this.chartOptions.legend.data = tagCode;
      const seriesSets: Array<any> = [];

      for (const item of tagCode) {
        const res = data.filter((p: any) => p.uid.trim() == item.trim());
        const seriesData: number[] = res.map((p: any) => {
          if (phaseGroup === "acceleration") {
            return p.acceleration;
          }
          if (phaseGroup === "temperature") {
            return p.temperature;
          }
          if (phaseGroup === "velocity") {
            return p.velocity;
          }
          return 0;
        });

        const alarmLimit =
          phaseGroup === "acceleration"
            ? res[0]?.acceleration_limit?.alarm || 0
            : phaseGroup === "temperature"
            ? res[0]?.temperature_limit?.alarm || 0
            : phaseGroup === "velocity"
            ? res[0]?.velocity_limit?.alarm || 0
            : 0;

        const warningLimit =
          phaseGroup === "acceleration"
            ? res[0]?.acceleration_limit?.warning || 0
            : phaseGroup === "temperature"
            ? res[0]?.temperature_limit?.warning || 0
            : phaseGroup === "velocity"
            ? res[0]?.velocity_limit?.warning || 0
            : 0;
        seriesSets.push({
          name: item,
          type: "line",
          data: seriesData,
          markLine: {
            silent: true,
            data: [
              {
                name: "Alarm",
                label: {
                  formatter: "{b} ({c})",
                  position: "end",
                },
                yAxis: alarmLimit,
                lineStyle: { color: "#d50a11", width: 2 },
              },
              {
                name: "Warning",
                label: {
                  formatter: "{b} ({c})",
                  position: "end",
                },
                yAxis: warningLimit,
                lineStyle: { color: "#ffd62f", width: 2 },
              },
            ],
          },
        });
      }
      return seriesSets;
    },

    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `tr_${id}__health_monitoring`.toUpperCase();
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const ws = XLSX.utils.json_to_sheet(this.chartResultData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, name); // => sheet name
      XLSX.writeFile(wb, `${name}.xlsx`); // => file name
    },
  },
  watch: {
    async isSecondaryTab() {
      await this.fetchSensorTypeData();
    },
    async isPhaseGroup() {
      this.chartOptions.series = this.series(this.chartResultData);
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
    async modelId() {
      await this.fetchResultData();
    },
    async getTagGroup() {
      const phaseGroup = (this as any).phaseGroup[0];

      this.$store.dispatch("setModelPhaseGroup", phaseGroup);

      setTimeout(async () => {
        await (this as any).fetchResultData();
      }, 1000);
    },
    // async getPhaseGroup() {
    //   setTimeout(async () => {
    //     await (this as any).fetchResultData();
    //   }, 1000);
    // },
  },
});
