
import Vue from "vue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";

import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";

export default Vue.extend({
  name: "TrAnalysisLossesGraph",
  components: {
    highcharts: Chart,
    LoadingGraph,
    ButtonDanger,
    ButtonReload,
    DateTimeRange,
    NoDataAvailable,
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    return {
      startDate: dayjs(new Date()).subtract(30, "day").format("YYYY-MM-DDT00:00"),
      endDate: dayjs(new Date()).format("YYYY-MM-DDT23:59"),
      isSensorType: {},
      sensorTypeData: [],
      chartResultData: [],
      chartOptions: {
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 1,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: { shared: true, crosshairs: true },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        yAxis: { title: { text: null } },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
      renderGraph: false,
    };
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.renderGraph = false;
        const { id, modelCode, equipmentId } = this.model;
        const response = await (this as any).$dep.modelUseCase.getTrLossesResultData(
          id,
          modelCode,
          equipmentId,
          this.startDate,
          this.endDate
        );
        this.chartResultData = response;
        this.chartOptions.title.text = `Advance Analysis : ${this.$store.state.graphInformationTab.is_secondary_tab.name}`;
        this.chartOptions.xAxis = this.aAxis(response);
        this.chartOptions.series = this.setSeries(response);

        this.renderGraph = true;
      } catch (error) {
        //
      }
    },
    aAxis(datas: any) {
      let labels = [];
      for (const res of datas) {
        labels.push(...res.timestamp);
      }
      const formatDate = labels.map((label) => dayjs(label).format("DD-MM-YYYY HH:mm"));
      const count = [...new Set(formatDate)].length;
      return {
        type: "category",
        labels: {
          align: "left",
          step: count <= 10 ? 0 : count <= 100 ? 1 : count <= 200 ? 2 : count <= 300 ? 3 : count <= 400 ? 4 : 5,
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: [...new Set(formatDate)],
      };
    },
    setSeries(datas: any) {
      return datas.map((prev) => ({
        name: prev.tagCode,
        data: prev.data,
        tooltip: { valueSuffix: prev?.unit ? ` ${prev.unit}` : "" },
        lineWidth: 0.5,
      }));
    },
    onChangeSensorType() {
      //
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).format("YYYY-MM-DDT00:00");
      this.endDate = dayjs(ev.endDate).format("YYYY-MM-DDT23:59");
      await this.fetchData();
    },
    async reloadData() {
      await this.fetchData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `tr_${id}_losses`.toUpperCase();

      const ws = XLSX.utils.json_to_sheet(this.chartResultData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, name);
      XLSX.writeFile(wb, `${name}.xlsx`);
    },
  },
});
