
import Vue from "vue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
import McaDiagnostic from "./McaDiagnostic.vue";
import McaOmr from "./McaOmr.vue";

export default Vue.extend({
  name: "McaPetternRecog",
  components: {
    ButtonDanger,
    ButtonReload,
    DateTimeRange,
    LoadingGraph,
    NoDataAvailable,
    McaDiagnostic,
    McaOmr,
  },
  data() {
    const date = new Date();
    return {
      source: null,
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      chartLoading: true,
      tabs: ["Fault Diagnostic"],
      activeTabIndex: 0,
      options: {
        minX: 0,
        maxX: undefined,
        minY: undefined,
        maxY: undefined,
      },
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
    tabSelected() {
      return this.tabs[this.activeTabIndex]; // Access the tab name using the activeTabIndex
    },
  },
  async created() {
    await this.fetchResultData();
  },
  methods: {
    async fetchResultData() {
      try {
        this.chartLoading = true;

        if (this.source) {
          this.source.cancel();
        }
        const res = await (this as any).$dep.modelUseCase.getMcaResultDiagnostic(
          this.model.modelCode,
          this.isSecondaryTab,
          this.startDate,
          this.endDate,
          this.model.id
        );
        this.chartResultData = res || [];
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
        this.chartLoading = false;
      } finally {
        this.source = null;
      }
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `mca_${this.model.modelCode}_${this.tabSelected}`.toUpperCase().replace(/\s+/g, "_");
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const datas = this.chartResultData;
      let res = [];
      const sers = [
        {
          name: "Rotor Bar Crack",
          data: datas?.rotor_bar_crack?.values,
          tooltip: { valueSuffix: ` ${datas?.rotor_bar_crack?.unit}` },
          lineWidth: 2,
          warning: datas?.rotor_bar_crack?.warning,
          alarm: datas?.rotor_bar_crack?.alarm,
        },
        {
          name: "Bearing Failure",
          data: datas?.bearing_failure?.values,
          tooltip: { valueSuffix: ` ${datas?.bearing_failure?.unit}` },
          lineWidth: 2,
          warning: datas?.bearing_failure?.warning,
          alarm: datas?.bearing_failure?.alarm,
        },
        {
          name: "Eccentric Airgap",
          data: datas?.eccentric_airgap?.values,
          tooltip: { valueSuffix: ` ${datas?.eccentric_airgap?.unit}` },
          lineWidth: 2,
          warning: datas?.eccentric_airgap?.warning,
          alarm: datas?.eccentric_airgap?.alarm,
        },
        {
          name: "Ground Fault",
          data: datas?.ground_fault?.values,
          tooltip: { valueSuffix: ` ${datas?.ground_fault?.unit}` },
          lineWidth: 2,
          warning: datas?.ground_fault?.warning,
          alarm: datas?.ground_fault?.alarm,
        },
        {
          name: "Short Turn",
          data: datas?.short_turn?.values,
          tooltip: { valueSuffix: ` ${datas?.short_turn?.unit}` },
          lineWidth: 2,
          warning: datas?.short_turn?.warning,
          alarm: datas?.short_turn?.alarm,
        },
        {
          name: "Terminal Loose",
          data: datas?.terminal_loose?.values,
          tooltip: { valueSuffix: ` ${datas?.terminal_loose?.unit}` },
          lineWidth: 2,
          warning: datas?.terminal_loose?.warning,
          alarm: datas?.terminal_loose?.alarm,
        },
        {
          name: "OMR",
          data: datas?.omr?.values,
          tooltip: { valueSuffix: ` ${datas?.omr?.unit}` },
          lineWidth: 2,
          warning: datas?.omr?.warning,
          alarm: datas?.omr?.alarm,
        },
      ];
      res = sers.filter((series) => series.data.length > 0);

      const result = {
        sname: `mca_${this.tabSelected}`.toUpperCase().replace(/\s+/g, "_"),
        data: res.map((val, key) => {
          return val.data.map((value, idx) => {
            const temp = {
              tagCode: val.name,
              values: value,
              model_code: this.model.modelCode,
              status: value >= val.alarm ? 2 : value >= val.warning ? 1 : 0,
              timestamp: this.chartResultData.timestamp[idx],
            };
            return temp;
          });
        }),
      };
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(result.data.flat());
      XLSX.utils.book_append_sheet(wb, ws, result.sname); // => sheet name

      try {
        XLSX.writeFile(wb, `${name}.xlsx`);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
      }

      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },
    onChangeXHandler(t) {
      this.options.minX = t.min;
      this.options.maxX = t.max;
      this.options = {
        minY: this.options.minY,
        maxY: this.options.maxY,
        minX: t.min,
        maxX: t.max,
      };
    },
    onChangeYHandler(t) {
      this.options.minY = t.min;
      this.options.maxY = t.max;
      this.options = {
        minY: t.min,
        maxY: t.max,
        minX: this.options.minX,
        maxX: this.options.maxX,
      };
    },
  },
  watch: {
    async isSecondaryTab() {
      await this.fetchResultData();
    },
    async isSensorType() {
      await this.fetchResultData();
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
  },
});
