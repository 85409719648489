
import Vue from "vue";

export default Vue.extend({
  name: "ExcelIcon",
  props: {
    color: {
      type: String,
      default: "currentColor",
    },
    width: {
      type: String,
      default: "8",
    },
    height: {
      type: String,
      default: "8",
    },
  },
});
