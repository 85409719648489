import Vue from "vue";
import Vuelidate from "vuelidate";
import vueDebounce from "vue-debounce";
import VueSweetalert2 from "vue-sweetalert2";
import HighchartsVue from "highcharts-vue";
import * as echarts from "echarts/core";
import "echarts-gl";

import "sweetalert2/dist/sweetalert2.min.css";

// import "@babel/polyfill";
import "@/plugins/dayjs";
import vuetify from "@/plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import VueGoogleMap from "./plugins/vuejs-google-maps";
import VueAzureMaps from "./plugins/vue-azure-maps";
import store from "./store";
import * as components from "./common/components";
import * as mixins from "./common/mixins";
import FolderTreeListMenu from "./features_old/folder/components/FolderTreeListMenu.vue";
import { DependencyPlugin } from "./dependencies";
import VueGmaps from "vue-gmaps";

echarts.use([]);

Vue.config.productionTip = false;
Vue.component("FolderTreeListMenu", FolderTreeListMenu);
Object.keys(components).forEach((key) => {
  Vue.component(key, (components as any)[key]);
});
Object.entries(mixins).forEach(([, mixin]) => {
  Vue.mixin(mixin);
});
Vue.use(VueGmaps, {
  key: "AIzaSyCpr35b_ZSoP8nbz0VnBjVz6ABb7iurRCU",
  libraries: ["places"],
  version: "3",
});
Vue.use(DependencyPlugin);
Vue.use(VueAzureMaps);
Vue.use(VueGoogleMap);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(HighchartsVue);
Vue.use(vueDebounce, {
  defaultTime: "700ms",
});

// Custom directives
Vue.directive("uppercase", {
  inserted: function (el: any, _, vnode: any) {
    el.addEventListener("input", async function (e: any) {
      e.target.value = e.target.value.toUpperCase();
      vnode.componentInstance.$emit("input", e.target.value.toUpperCase());
    });
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
