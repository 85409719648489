
import Vue from "vue";

import FormConfigurationInformation from "../forms/FormConfigurationInformation.vue";
import FormLucus from "../forms/FormLucus.vue";
import FormAlarmLimitSensor from "../forms/FormAlarmLimitSensor.vue";
import FormCorrelationMapping from "../forms/FormCorrelationMapping.vue";
import FormRecommendation from "../forms/FormRecommendation.vue";
import FormAlarmManagement from "../forms/FormAlarmManagement.vue";

export default Vue.extend({
  name: "TransformerForm",
  components: {
    FormConfigurationInformation,
    FormLucus,
    FormAlarmLimitSensor,
    FormCorrelationMapping,
    FormRecommendation,
    FormAlarmManagement,
  },
});
