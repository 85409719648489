
import Vue from "vue";

import FormConfigurationInformation from "../forms/FormConfigurationInformation.vue";
import FormRecommendation from "../forms/FormRecommendation.vue";
import FormVibrationSensor from "../forms/FormVibrationSensor.vue";
import FormAlarmManagement from "../forms/FormAlarmManagement.vue";

export default Vue.extend({
  name: "McaForm",
  components: {
    FormConfigurationInformation,
    FormRecommendation,
    FormVibrationSensor,
    FormAlarmManagement,
  },
});
