
import Vue from "vue";

import Card from "@/components/Card.vue";
import GraphViewRvs from "../graph/rvs/GraphViewRvs.vue";
import DiagramInformation from "../details/diagrams/DiagramInformation.vue";
import MachineStatusRvs from "../details/diagrams/MachineStatusRvs.vue";
import ConfigurationInformation from "../details/ConfigurationInformation.vue";
import SensorDetailRvs from "../details/SensorDetailRvs.vue";
import FaultEventAlarm from "../details/faultEventAlarm/FaultEventAlarm.vue";
import NoteHistoryLog from "../details/NoteHistoryLog.vue";
import FailureModeRvs from "../details/FailureModeRvs.vue";

export default Vue.extend({
  name: "RvsDetail",
  components: {
    Card,
    GraphViewRvs,
    DiagramInformation,
    MachineStatusRvs,
    ConfigurationInformation,
    SensorDetailRvs,
    FaultEventAlarm,
    NoteHistoryLog,
    FailureModeRvs,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
});
