var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"flex items-center justify-center h-[242px]"},[_c('spinner',{attrs:{"size":"sm"}})],1):_c('div',[_c('div',{staticClass:"flex container text-center justify-center"},[_c('div',{staticClass:"grid grid-cols-4 gap-5 relative"},_vm._l((_vm.items),function(item){return _c('div',{key:item.tagCode,staticClass:"max-w-[45px]"},[(item.tagCode === 'accessory_oil_level')?_c('div',{staticClass:"relative"},[_c('span',{staticClass:"text__progress"},[_vm._v(_vm._s(item.value))]),_c('div',{staticClass:"progress ml-[10px]"},[_c('span',{class:`absolute left-[20px] whitespace-nowrap text-[10px] bottom-[${
                  ((item.alarm - _vm.calsMeanTs(item.alarm, item.warning)) / (item.alarm * 1.2)) * 100 - 4
                }%]`},[_vm._v(_vm._s(item.alarm)+" "+_vm._s(item.unit))]),_c('span',{class:`absolute left-[20px] whitespace-nowrap text-[10px] bottom-[${
                  (item.warning / (item.alarm * 1.2)) * 100 - 3
                }%]`},[_vm._v(_vm._s(item.warning)+" "+_vm._s(item.unit))]),_c('div',{class:`alarm__progress bottom-[${
                  ((item.alarm - _vm.calsMeanTs(item.alarm, item.warning)) / (item.alarm * 1.2)) * 100
                }%]`}),_c('div',{class:`warning__progress bottom-[${(item.warning / (item.alarm * 1.2)) * 100}%]`}),_c('div',{class:`bar bg-[${_vm.statusName(item.status)}] h-[${
                  item.value > item.alarm * 1.2
                    ? 100
                    : ((item.value - _vm.calsValue(item.value, item.alarm, item.warning)) / (item.alarm * 1.2)) * 100
                }%]`})]),_c('span',{staticClass:"text__progress whitespace-nowrap"},[_vm._v(_vm._s(item.name))])]):_c('div',[_c('span',{staticClass:"text__progress"},[_vm._v(_vm._s(_vm.textValue(item.status, item.value)))]),_c('div',{staticClass:"thermometer ml-[10px]"},[_c('span',{class:`absolute whitespace-nowrap left-[22px] text-[10px] bottom-[${
                  (item.alarm / (item.alarm * 1.2)) * 100 - 4
                }%]`},[_vm._v(_vm._s(item.alarm)+" "+_vm._s(item.unit))]),_c('span',{class:`absolute whitespace-nowrap left-[22px] text-[10px] bottom-[${
                  ((item.warning - _vm.calsMeanTs(item.warning, item.alarm)) / (item.alarm * 1.2)) * 100 - 4
                }%]`},[_vm._v(_vm._s(item.warning)+" "+_vm._s(item.unit))]),_c('div',{class:`alarm__progress bottom-[${(item.alarm / (item.alarm * 1.2)) * 100}%]`}),_c('div',{class:`warning__progress bottom-[${
                  ((item.warning - _vm.calsMeanTs(item.warning, item.alarm)) / (item.alarm * 1.2)) * 100
                }%]`}),_c('div',{staticClass:"stem"},[_c('div',{class:`stem-perct bg-[${_vm.statusName(item.status)}] h-[${
                    item.value > item.alarm * 1.2
                      ? 100
                      : ((item.value - _vm.calsValue(item.value, item.warning, item.alarm)) / (item.alarm * 1.2)) * 100
                  }%]`})]),_c('div',{class:`bulb bg-[${_vm.statusName(item.status)}]`})]),_c('span',{staticClass:"text__progress whitespace-nowrap"},[_vm._v(_vm._s(item.name.split(" ")[0]))])])])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex items-center border-b-[1px] border-border p-[24px] h-[64px]"},[_c('span',{staticClass:"text-[16px] font-[600]"},[_vm._v(" C : Thermal/Oil level ")])])
}]

export { render, staticRenderFns }