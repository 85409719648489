
import Vue from "vue";
import dialog from "@/common/mixins/dialog";
import Container from "@/components/Container.vue";
import TableCustom from "@/components/TableCustom.vue";
import colorStatus from "@/common/mixins/colorStatus";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import AdminFormModal from "@/features/user/components/AdminModalForm.vue";
// import EditIcon from "@/components/icons/EditIcon.vue";
// import InviteAdminPage from "./InviteAdminPage.vue";
export default Vue.extend({
  name: "AdminPage",
  mixins: [colorStatus, dialog],
  components: { Container, TableCustom, DeleteIcon, LoadingGraph, AdminFormModal },
  data() {
    const headers = [
      { text: "Image", value: "title", sortable: false },
      { text: "Fullname", value: "value", sortable: true },
      { text: "Email", value: "unitCode", sortable: true },
      { text: "Latest Date", value: "unitCode", sortable: true },
      { text: "Status", value: "unitCode", sortable: true },
      { text: "", value: "unitCode", sortable: false },
    ];
    return { admins: [], headers, dialog: false, isLoading: false };
  },
  async created() {
    await this.fetchAdmin();
  },
  methods: {
    async fetchAdmin() {
      try {
        this.isLoading = true;
        const response = await (this as any).$dep.companyUseCase.getAdmins();
        this.admins = response;
      } catch (error) {
        this.admins = [];
        //
      } finally {
        this.isLoading = false;
      }
    },
    async inviteAdmin(param: any) {
      try {
        this.isLoading = true;
        const { fullname, email, profileImageUrl } = param;
        await (this as any).$dep.companyUseCase.addAdmins({
          fullname,
          email,
          profileImageUrl,
        });
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Invite admin successfully",
          type: "success",
        });
        this.fetchAdmin();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: invite admin failed",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async editAdmin(param: any) {
      try {
        this.isLoading = true;
        const { fullname, email, profileImageUrl } = param;
        await (this as any).$dep.companyUseCase.editAdmins({
          name: fullname,
          email,
          profileImageUrl,
        });
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: "Edit admin successfully",
          type: "success",
        });
        this.fetchAdmin();
      } catch (error) {
        this.$store.dispatch("openAlertMessage", {
          open: true,
          message: error && error?.detail ? error.detail : "error: invite admin failed",
          type: "error",
        });
        this.isLoading = false;
      }
    },
    async onSubmitModal(param: any) {
      const type = this.$store.state.openAdminFormModal.type;
      if (type === "Add") {
        await this.inviteAdmin(param);
      } else {
        await this.editAdmin(param);
      }
    },
    async resendInvite(data: any) {
      const { name, email } = data.item;
      const isComfirmed = await (this as any).alertBasic(
        name,
        `<p>Are you sure you want to resend invite</p>`,
        "question",
        "Confirm"
      );
      if (isComfirmed) {
        try {
          this.isLoading = true;
          await (this as any).$dep.memberUseCase.resendInviteMember(email);
          await (this as any).alertSuccess("Success", "resend invite completed");
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Resend Invite Admin!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async restoreAdmin(data: any) {
      const { name, email } = data.item;
      const isComfirmed = await (this as any).alertBasic(
        name,
        `<p>Are you sure you want to active this admin?</p>`,
        "question",
        "Confirm"
      );
      if (isComfirmed) {
        try {
          this.isLoading = true;
          this.adminList = [];
          await (this as any).$dep.memberUseCase.activeMember(email);
          await (this as any).alertSuccess("Success", `"${email}" has been active`);
          await this.fetchAdmin();
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Active Admin!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async deleteAdmin(data: any) {
      const { name, email } = data.item;
      const isComfirmed = await (this as any).alertBasic(
        name,
        `<p>Are you sure you want to delete this admin?</p>`,
        "question",
        "Delete"
      );
      if (isComfirmed) {
        try {
          this.isLoading = true;
          this.adminList = [];
          await (this as any).$dep.companyUseCase.deleteAdmins(email);
          await (this as any).alertSuccess("Deleted", `"${email}" has been deleted`);
          await this.fetchAdmin();
        } catch (error: any) {
          const msgError = error.response?.data?.detail || "Can not Delete Admin!";
          (this as any).alertError("Error", msgError);
        } finally {
          this.isLoading = false;
        }
      }
    },
    isOnPermission(status: string, type: string) {
      if (type === "ACTIVE") {
        return ["inactive"].includes(status);
      }
      if (type === "DELETE") {
        return ["active", "pending"].includes(status);
      }
      if (type === "RESEND_INVITE") {
        return ["pending"].includes(status);
      }
      if (type === "EDIT_ROLE") {
        return ["active"].includes(status);
      }
      return false;
    },
  },
});
