
import Vue from "vue";
import dayjs from "dayjs";

import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "Navbar",
  components: { Spinner },
  computed: {
    routeName() {
      return this.$route.name;
    },
    user() {
      return this.$store.getters.user;
    },
    isSidebar: {
      get() {
        return this.$store.state.app.isSidebar;
      },
    },
    visible: {
      get() {
        return this.$store.state.app.visibleSidebar;
      },
      set(value: boolean) {
        this.$store.dispatch("setApp", {
          ...this.$store.state.app,
          visibleSidebar: value,
        });
      },
    },
  },
  data() {
    return { interval: null, timer: null };
  },
  created() {
    const routeNameHideList = ["CompaniesPage"];
    const routeNameCloseList = ["ViewModelPage"];

    const routeHideData = routeNameHideList.find((name: string) => this.routeName === name);
    const routeCloseData = routeNameCloseList.find((name: string) => this.routeName === name);

    this.$store.dispatch("setApp", {
      isSidebar: routeCloseData || routeHideData ? false : true,
      visibleSidebar: routeHideData ? false : true,
    });

    (this as any).interval = setInterval(() => {
      (this as any).timer = dayjs(new Date()).format("dddd, DD MMMM YYYY HH:mm");
    }, 1000);
  },
  methods: {
    handleEditProfile() {
      this.$store.dispatch("openEditUserModal", true);
    },
    handleSettingCompanies() {
      this.$router.push({ name: "CompaniesPage" });
    },
    handleSettingAdmin() {
      this.$router.push({ name: "AdminPage" });
    },
    handleLogout() {
      (this as any).$dep.authUseCase.logout();
    },
    handleGotoCaseManagement() {
      window.open("https://case.repconexis.com", "_blank");
    },
  },
  watch: {
    routeName() {
      // กำหนดเงื่อนไขเพื่อให้แสดงปุ่มเปิดปิด sidebar ในหน้าที่ต้องการ
      const routeNameHideList = ["CompaniesPage", "CompanyMemberPage", "AdminPage"];
      const routeNameCloseList = ["ViewModelPage"];

      const routeHideData = routeNameHideList.find((name: string) => this.routeName === name);
      const routeCloseData = routeNameCloseList.find((name: string) => this.routeName === name);

      this.$store.dispatch("setApp", {
        isSidebar: routeCloseData || routeHideData ? false : true,
        visibleSidebar: routeHideData ? false : true,
      });
    },
  },
  beforeDestroy() {
    clearInterval((this as any).interval);
  },
});
