import Vue from "vue";

export enum PermissionName {
  InviteMemberToCompany = "InviteMemberToCompany",
  RemoveMemberFromCompany = "RemoveMemberFromCompany",
  ManageFolder = "ManageFolder",
  ManageEquipmentTag = "ManageEquipmentTag",
  AssignMemberToEquipmentTag = "AssignMemberToEquipmentTag",
  ManageDataModel = "ManageDataModel",
  ConfigDataModelInfo = "ConfigDataModelInfo",
}

export default Vue.extend({
  methods: {
    hasPermission(permissionName: PermissionName) {
      return (this as any).$dep.authUseCase.hasPermission(permissionName);
    },
  },
});
