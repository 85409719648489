/* eslint-disable no-empty */
import Vue from "vue";
import { ActionContext, ActionTree } from "vuex";

import { EquipmentModelFormType } from "@/features/model/models/EquipmentModelFormType";

import { State } from "./states";
import {
  APP,
  USER,
  COMPANY,
  STRUCTURE,
  MODEL,
  MODEL_VIEW_DATA,
  CREATE_MODEL_FORM,
  UPDATE_MODEL_FORM,
  MODEL_SETTING_FORM,
  UPLOAD_FILE_RECOMMENDATION_FORM,
  GRAPH_INFORMATION_TAB_GROUP_DATA,
  GRAPH_INFORMATION_TAB,
  OPEN_ALERT_MESSAGE,
  OPEN_CREATE_MODEL_MODAL,
  OPEN_FOLDER_FORM_MODAL,
  OPEN_EQUIPMENT_FORM_MODAL,
  OPEN_EDIT_USER_MODAL,
  OPEN_COMPANY_FORM_MODAL,
  OPEN_ADMIN_FORM_MODAL,
  MODEL_IMAGE_INFORMATION,
  MODEL_DOCUMENT_INFORMATION,
  CONTEXT_MENU_STRUCTURE,
  SET_EQUIPMENT_CREATE_FORM,
  MODEL_CONFIGURATION_INFORMATION,
  OPEN_EDIT_MODEL_OFFLINE_MODAL,
  SET_MEMBER_ASSIGN_MODAL,
  OPEN_MEMBER_ASSIGN_MODAL,
  CLOSE_MEMBER_ASSIGN_MODAL,
  OPEN_SET_EMAIL_NOTIFICATION,
  OPEN_MEMBER_FORM_MODAL,
  OPEN_EDIT_MEMBER_MODAL,
  SET_MODEL_MOTOR_DATA_FORM,
  SET_MODEL_PHASE_GROUP,
  SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR,
} from "./mutations";

export const actions: ActionTree<State, State> = {
  setApp({ commit }: ActionContext<State, State>, data: any): void {
    commit(APP, data);
  },
  async getUser({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const { raw, sub } = await Vue.prototype.$dep.authUseCase.getProfileToken();
      const { id, name, email, profileImageUrl, role, status } = await Vue.prototype.$dep.authUseCase.getMyProfile();

      const payload = { id, name, email, profileImageUrl, role, status, raw, sub };
      commit(USER, payload);
    } catch (error) {
      await Vue.prototype.$dep.authUseCase.logout();
    }
  },
  async setUser({ commit }: ActionContext<State, State>, payload): Promise<void> {
    commit(USER, payload);
  },
  async getCompany({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const companyId = sessionStorage.getItem("company_id");
      const data = await Vue.prototype.$dep.companyUseCase.getCompanyById(companyId);

      commit(COMPANY, data);
    } catch (error) {}
  },
  async getStructure({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const companyId = sessionStorage.getItem("company_id");
      const data = await Vue.prototype.$dep.folderUseCase.getFolderById(companyId);

      commit(STRUCTURE, data);
    } catch (error) {}
  },
  async getModel({ commit }: ActionContext<State, State>, modelId: number | string): Promise<void> {
    const data = await Vue.prototype.$dep.modelUseCase.getModel(modelId);
    commit(MODEL, data);
    commit(
      MODEL_CONFIGURATION_INFORMATION,
      data.equipmentModelConfiguration.map((prev: any) => ({
        id: prev.id,
        value: prev.value,
        configInformationId: prev.modelTypeConfiguration.id,
        title: prev.modelTypeConfiguration.title,
        unitCode: prev.modelTypeConfiguration.unitCode,
        isEdit: false,
      }))
    );
  },
  async getModelImageInformation({ commit }: ActionContext<State, State>, modelId: number | string): Promise<void> {
    const data = await Vue.prototype.$dep.modelUseCase.getModelImages(modelId);

    commit(MODEL_IMAGE_INFORMATION, data);
  },
  async getModelDocumentInformation({ commit }: ActionContext<State, State>): Promise<void> {
    const { id } = this.getters.model;
    const pmReport = await Vue.prototype.$dep.modelUseCase.getDocumentations(id, "pm_report");
    const manual = await Vue.prototype.$dep.modelUseCase.getDocumentations(id, "manual");

    commit(MODEL_DOCUMENT_INFORMATION, {
      pm_report: pmReport || null,
      manual: manual || null,
    });
  },
  setAutoRefresh(
    { commit }: ActionContext<State, State>,
    payload: { enable: boolean; timestamp: Date; timeout: number }
  ): void {
    commit(MODEL_VIEW_DATA, {
      ...this.state.modelViewData,
      autoRefresh: {
        enable: payload.enable || false,
        timeout: payload.timeout || 0,
        timestamp: payload.timestamp || new Date(),
      },
    });
  },
  async getModelViewData({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const { id, modelTypeId, modelCode } = this.getters.model;
      const companyId = sessionStorage.getItem("company_id");

      let payload = {};
      switch (modelTypeId) {
        case 1:
          payload = {
            ...this.state.modelViewData,
            tr_diagram_status: await Vue.prototype.$dep.modelUseCase.getTRDiagramStatus(id, companyId),
            tr_summary_status: await Vue.prototype.$dep.modelUseCase.getTrSummaryStatus(id),
            fault_event_alarm_count: (await Vue.prototype.$dep.modelUseCase.getCountFaultEventAlarm(id)) || 0,
          };
          break;
        case 2:
          payload = {
            ...this.state.modelViewData,
            mca_diagram_status: await Vue.prototype.$dep.modelUseCase.modelResultDataStatusMca(modelCode),
          };
          break;
        case 3:
          payload = {
            ...this.state.modelViewData,
            vca_diagram_status: await Vue.prototype.$dep.modelUseCase.modelResultDataStatusVca(modelCode),
          };
          break;
        case 4:
          payload = {
            ...this.state.modelViewData,
            evs_diagram_status: await Vue.prototype.$dep.modelUseCase.modelResultDataStatusEvs(modelCode),
          };
          break;
        case 5:
          payload = {
            ...this.state.modelViewData,
            rvs: {
              rvs_diagram_status: await Vue.prototype.$dep.modelUseCase.modelNewResultDataStatusRvs(modelCode),
              rvs_diagram_image: await Vue.prototype.$dep.modelUseCase.getDiagramRvs(id),
            },
          };
          break;
      }
      commit(MODEL_VIEW_DATA, payload);
    } catch (error) {
      //
    }
  },
  async setConfigurationInformation({ commit }: ActionContext<State, State>, form: any): Promise<void> {
    commit(MODEL_CONFIGURATION_INFORMATION, form);
  },
  async getUpdateModelDataForm({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const { id, modelTypeId, modelCode } = this.getters.model;
      const { title, isActive, equipmentModelConfiguration } = this.state.model;

      const modelVandILocus = await Vue.prototype.$dep.modelUseCase.getModelVandILocus(id);
      const modelCorrelationMapping = await Vue.prototype.$dep.modelUseCase.getModelQSetting(id);
      const alarmLimitSensorNew = await Vue.prototype.$dep.modelUseCase.getAlarmLimitSensorNewForm(id);
      const vibrationSensor = await Vue.prototype.$dep.modelUseCase.getModelVibrationSensorsByIdModel(id);
      const dataMotor = await Vue.prototype.$dep.modelUseCase.getModelMotorDataByIdModel(modelTypeId);
      const vsdAlarmLimitSensorNew = await Vue.prototype.$dep.modelUseCase.getVsdAlarmLimitSensorNewForm(id);
      const vsdTagAlarmLimit = await Vue.prototype.$dep.modelUseCase.getVsdTagAlarmLimit(id);
      const rvsDiagramImageUrl = await Vue.prototype.$dep.modelUseCase.getDiagramRvs(id);
      const vsdTrendSignalAlarmLimit = await Vue.prototype.$dep.modelUseCase.getVsdTrendSignalAlarmLimitSensorForm(id);
      const mcaAlarmLimitSensor = await Vue.prototype.$dep.modelUseCase.getMcaAlarmLimitSensorForm(id);
      const alarmManagement = await Vue.prototype.$dep.modelUseCase.getAlarManagement(modelCode);

      commit(SET_MODEL_MOTOR_DATA_FORM, dataMotor);

      const form = {
        model_in: {
          title,
          isActive,
        },
        configuration_in: equipmentModelConfiguration.map((row) => row.modelTypeConfiguration.id) || [],
        vibration_sensor_in: modelTypeId == 4 ? vibrationSensor : [],
        raw_vibration_sensor_in:
          modelTypeId == 5
            ? vibrationSensor.map((item) => ({
                sensorId: item.sensorId,
                sensorName: item.sensorName,
                selected: item.selected,
                sensorPointId: item.sensorPointId,
                machineTemplateId: item.machineTemplateId,
                acceleration: item.acceleration,
                velocity: item.velocity,
                temperature: item.temperature,
                envelope: item.envelope,
                driven: item.driven || "Pump",
                type_of_position: item.type_of_position || "NDE",
                is_blade_vane: item.is_blade_vane || false,
                is_motor: item.is_motor || false,
                is_bearing: item.is_bearing || false,
                is_belt: item.is_belt || false,
                is_planet_gear: item.is_planet_gear || false,
                is_gear: item.is_gear || false,
                ref_speed: item.ref_speed || 0,
                sensor_config: {
                  belt_configuration: {
                    speed: item.belt_configuration?.speed || 0,
                    pulley_1_dia: item.belt_configuration?.pulley_1_dia || 0,
                    pulley_2_dia: item.belt_configuration?.pulley_2_dia || 0,
                    belt_length: item.belt_configuration?.belt_length || 0,
                    belt_teeth: item.belt_configuration?.belt_teeth || 0,
                  },
                  blade_vane_configuration: {
                    speed: item.blade_vane_configuration?.speed || 0,
                    blade: item.blade_vane_configuration?.blade || 0,
                  },
                  motor_configuration: {
                    speed: item.motor_configuration?.speed || 0,
                    rotor_bar: item.motor_configuration?.rotor_bar || 0,
                    pole: item.motor_configuration?.pole || 0,
                  },
                  bearing_configuration: Array.from({ length: 4 }, (_, i) => ({
                    speed: item.bearing_configuration?.[i]?.speed || 0,
                    bearing_id: item.bearing_configuration?.[i]?.bearing_id || null,
                    running_no: item.bearing_configuration?.[i]?.running_no || i + 1,
                  })),
                  planet_gear_configuration: Array.from({ length: 2 }, (_, i) => ({
                    speed: item.planet_gear_configuration?.[i]?.speed || 0,
                    sun: item.planet_gear_configuration?.[i]?.sun || 0,
                    ring: item.planet_gear_configuration?.[i]?.ring || 0,
                    planet: item.planet_gear_configuration?.[i]?.planet || 0,
                    running_no: item.planet_gear_configuration?.[i]?.running_no || i + 1,
                  })),
                  gear_configuration: Array.from({ length: 3 }, (_, i) => ({
                    speed: item.gear_configuration?.[i]?.speed || 0,
                    pinion: item.gear_configuration?.[i]?.pinion || 0,
                    gear: item.gear_configuration?.[i]?.gear || 0,
                    running_no: item.gear_configuration?.[i]?.running_no || i + 1,
                  })),
                },
              }))
            : [],
        alarm_limit_sensor_in: [],
        trend_signal_alarm_limit_sensor_in: [],
        vsd_tag_alarm_limit_in: vsdTagAlarmLimit || [],
        motor_data_in: dataMotor || [],
        electronic_card_in: [],
        q_setting_in: modelCorrelationMapping.map((item: any) => ({ ...item, tagCode: [item.tagA, item.tagB] })) || [],
        v_and_i_locus_in: modelVandILocus || [],
        alarm_limit_sensor_new_in: modelTypeId == 3 ? [] : alarmLimitSensorNew || [],
        alarm_limit_configuration_in: [],
        vsd_alarm_limit_sensor_new_in: vsdAlarmLimitSensorNew || [],
        rvs_diagram_in: rvsDiagramImageUrl,
        vsd_trend_signal_alarm_limit_sensor: vsdTrendSignalAlarmLimit || [],
        mca_alarm_limit_sensor_new: mcaAlarmLimitSensor || [],
        alarm_management: alarmManagement || [],
      };
      commit(UPDATE_MODEL_FORM, form);
    } catch (error) {}
  },
  async getModelSettingForm({ commit }: ActionContext<State, State>): Promise<void> {
    try {
      const { modelTypeId } = this.getters.model;

      const configuration = await Vue.prototype.$dep.modelUseCase.getModelConfigurationInformationForm(modelTypeId);
      const sensorTagSetting = await Vue.prototype.$dep.modelUseCase.getSensorTagCorrelation(modelTypeId);

      const form = {
        configuration_information: configuration.map((row: { id: number; title: string }) => ({
          id: row.id,
          title: row.title,
        })),
        correlation_mapping_tag_code:
          sensorTagSetting.map((row: { tagCode: string }) => ({ sensorTitle: row.tagCode })) || [],
      };
      commit(MODEL_SETTING_FORM, form);
    } catch (error) {}
  },
  setCreateModelForm({ commit }: ActionContext<State, State>, form: EquipmentModelFormType): void {
    commit(CREATE_MODEL_FORM, form);
  },
  setUpdateModelForm({ commit }: ActionContext<State, State>, form: EquipmentModelFormType): void {
    commit(UPDATE_MODEL_FORM, form);
  },
  setUploadReccomendationForm({ commit }: ActionContext<State, State>, form: any): void {
    commit(UPLOAD_FILE_RECOMMENDATION_FORM, form);
  },
  async getGraphInformationPrimaryTabGroupData({ commit }: ActionContext<State, State>): Promise<void> {
    const { modelTypeId } = this.getters.model;
    const primaryTab = await Vue.prototype.$dep.modelUseCase.getAlarmLimitGroupMaster(modelTypeId);

    commit(GRAPH_INFORMATION_TAB, {
      ...this.state.graphInformationTab,
      is_primary_tab: primaryTab.map((prev: any, i: number) => ({ id: prev.id, name: prev.name, key: i }))[0],
    });
    commit(GRAPH_INFORMATION_TAB_GROUP_DATA, {
      ...this.state.graphInformationTabGroupData,
      graph_primary_tab: primaryTab.map((tab: any, i: number) => ({ id: tab.id, name: tab.name, key: i })) || [],
    });
  },
  async getGraphInformationSecondaryTabGroupData({ commit }: ActionContext<State, State>): Promise<void> {
    const { id } = this.state.graphInformationTab.is_primary_tab;
    const modelType = this.getters.model.modelTypeId;
    if (modelType === 4) {
      const secondaryTab = await Vue.prototype.$dep.modelUseCase.getSensorPoints(this.getters.model.modelCode);
      const dataTab =
        secondaryTab && secondaryTab.length
          ? secondaryTab.map((prev: any, i: number) => ({
              id: prev.id,
              name: prev.title,
              key: i,
            }))
          : "";
      commit(GRAPH_INFORMATION_TAB, {
        ...this.state.graphInformationTab,
        is_secondary_tab: dataTab[0] || { id: 1, name: "", key: 0, groupCode: "" },
      });
      commit(GRAPH_INFORMATION_TAB_GROUP_DATA, {
        ...this.state.graphInformationTabGroupData,
        graph_secondary_tab: dataTab || [],
      });
    } else {
      const secondaryTab = await Vue.prototype.$dep.modelUseCase.getAlarmLimitGroup(id);
      commit(GRAPH_INFORMATION_TAB, {
        ...this.state.graphInformationTab,
        is_secondary_tab:
          secondaryTab && secondaryTab.length
            ? secondaryTab.map((prev: any, i: number) => ({
                id: prev.id,
                name: prev.name,
                key: i,
                groupCode: prev.groupCode,
              }))[0]
            : { id: 1, name: "", key: 0, groupCode: "" },
      });
      commit(GRAPH_INFORMATION_TAB_GROUP_DATA, {
        ...this.state.graphInformationTabGroupData,
        graph_secondary_tab: secondaryTab || [],
      });
    }
  },
  setGraphInformationTabGroup({ commit }: ActionContext<State, State>, payload: any): void {
    commit(GRAPH_INFORMATION_TAB, payload);
  },
  setContextMenuStructure({ commit }: ActionContext<State, State>, form: any): void {
    commit(CONTEXT_MENU_STRUCTURE, form);
  },
  openAlertMessage(
    { commit }: ActionContext<State, State>,
    form: {
      open: boolean;
      message: string;
      type: string;
      status?: string;
    }
  ): void {
    commit(OPEN_ALERT_MESSAGE, form);
  },
  openCompanyFormModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_COMPANY_FORM_MODAL, form);
  },
  openAdminFormModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_ADMIN_FORM_MODAL, form);
  },
  openMemberFormModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_MEMBER_FORM_MODAL, form);
  },
  openFolderFormModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_FOLDER_FORM_MODAL, form);
  },
  openEquipmentFormModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_EQUIPMENT_FORM_MODAL, form);
  },
  openCreateModelModal({ commit }: ActionContext<State, State>, form: any): void {
    commit(OPEN_CREATE_MODEL_MODAL, form);
  },
  openEditModelOfflineModal({ commit }: ActionContext<State, State>, form: boolean): void {
    commit(OPEN_EDIT_MODEL_OFFLINE_MODAL, form);
  },
  openEditUserModal({ commit }: ActionContext<State, State>, form: boolean): void {
    commit(OPEN_EDIT_USER_MODAL, form);
  },
  setEquipmentCreateForm({ commit }: ActionContext<State, State>, form: Record<any, any>): void {
    commit(SET_EQUIPMENT_CREATE_FORM, form);
  },
  openMemberAssignModal({ commit }: ActionContext<State, State>, payload): void {
    commit(OPEN_MEMBER_ASSIGN_MODAL, payload);
  },
  setMemberAssignModal({ commit }: ActionContext<State, State>, payload): void {
    commit(SET_MEMBER_ASSIGN_MODAL, payload);
  },
  closeMemberAssignModal({ commit }: ActionContext<State, State>): void {
    commit(CLOSE_MEMBER_ASSIGN_MODAL);
  },
  openEmailNotificationFormModal({ commit }: ActionContext<State, State>, payload): void {
    commit(OPEN_SET_EMAIL_NOTIFICATION, payload);
  },
  openEditMemberModal({ commit }: ActionContext<State, State>, payload): void {
    commit(OPEN_EDIT_MEMBER_MODAL, payload);
  },
  async setModelMotorDataForm({ commit }: ActionContext<State, State>, data: any): Promise<void> {
    const res = await Vue.prototype.$dep.modelUseCase.getModelMotorDataByIdModel(data.modelId);
    commit(
      SET_MODEL_MOTOR_DATA_FORM,
      data.id ? (res && res.length > 0 ? res : await Vue.prototype.$dep.modelUseCase.getModelMotorDataByIdType(data.id)) : []
    );
  },
  async setModelPhaseGroup({ commit }: ActionContext<State, State>, data: any): Promise<void> {
    commit(SET_MODEL_PHASE_GROUP, data);
  },
  async setModelTrendSignalAlarmLimitSensor(
    { commit }: ActionContext<State, State>,
    params: { id?: number | undefined; form?: any }
  ): Promise<void> {
    const { id, form } = params;
    if (id) {
      const datas = await Vue.prototype.$dep.modelUseCase.getModelTrendSignalAlarmLimitSensorByIdType(id);

      const dataTrendSignalAlarm = datas.map((p: any) => ({
        ...p,
        alarm: p.alarm || 0,
        warning: p.warning || 0,
        rul_alarm: p.rul_alarm || 0,
        rul_warning: p.rul_warning || 0,
        selected: p.selected || false,
      }));

      commit(SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR, dataTrendSignalAlarm);
    } else if (form && form.length) {
      commit(SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR, form);
    } else {
      commit(SET_MODEL_TREND_SIGNAL_ALARM_LIMIT_SENSOR, []);
    }
  },
};
