
import Vue from "vue";
import dayjs from "dayjs";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "SetEmailNotification",
  components: { Spinner },
  // components: {
  //   AzureMap,
  //   AzureMapDataSource,
  //   AzureMapPoint,
  //   AzureMapSymbolLayer,
  // },
  props: {
    validations: {
      type: Object,
    },
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openEmailNotificationFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openEmailNotificationFormModal", {
          ...this.$store.state.openEmailNotificationFormModal,
          open: value,
        });
      },
    },
    contextMenuStructure() {
      return this.$store.state.contextMenuStructure;
    },
    actionType() {
      return this.$store.state.openEmailNotificationFormModal.type;
    },
  },
  data() {
    const isLoading = true;

    const emailTaskSchedulerNotification = {
      type: "none",
      isWarning: false,
      isAlarm: false,
      timedate: null,
      day: "",
      indexDay: 0,
      jobId: 0,
    };

    const day = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return {
      isLoading,
      emailTaskSchedulerNotification,
      equipId: 0,
      day,
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    onSubmit() {
      const { type, isWarning, isAlarm } = this.emailTaskSchedulerNotification;

      if (type) {
        if (!isWarning && !isAlarm) {
          return alert('Error", "กรุณาเลือก Warning หรือ Alarm');
        }
      }

      this.$emit("onSubmit", {
        ...this.$store.state.equipmentTagCreateForm,
        action_type: this.actionType,
        parentId: this.contextMenuStructure.id,
        emailTaskScheduler: this.emailTaskSchedulerNotification,
      });

      this.dialog = false;
    },
    checkBoxEmailTaskSchedulerAll() {
      const now: any = dayjs().format("HH:mm").toString();

      this.emailTaskSchedulerNotification.timedate = now;

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    checkBoxEmailTaskScheduler() {
      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    timeEmailTaskScheduler() {
      const emailTaskScheduler = this.emailTaskSchedulerNotification;

      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    dayEmailTaskScheduler() {
      const indexDay = this.day.indexOf(this.emailTaskSchedulerNotification.day);
      this.emailTaskSchedulerNotification.indexDay = indexDay + 1;
      const emailTaskScheduler = this.emailTaskSchedulerNotification;

      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    radioEmailTaskSchedulerAll() {
      const now: any = dayjs().format("HH:mm").toString();
      this.emailTaskSchedulerNotification.timedate = now;

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    convertIterableToType(iterable: number) {
      switch (iterable) {
        case 1:
          return "daily";
        case 7:
          return "weekly";
        case 30:
          return "monthly";
      }
    },
  },
  watch: {
    async dialog() {
      if (this.dialog) {
        this.isLoading = true;
        this.emailTaskSchedulerNotification = {
          type: "none",
          isWarning: false,
          isAlarm: false,
          timedate: null,
          day: "",
          indexDay: 0,
          jobId: 0,
        };
        this.selectorType = "";
        this.equipId = this.$store.state.openEmailNotificationFormModal.param.id;

        const res = await (this as any).$dep.equipmentUseCase.getEmailTaskSchedulerById(this.equipId);
        if (res.length > 0) {
          this.emailTaskSchedulerNotification = {
            type: this.convertIterableToType(res[0].iterable),
            isWarning: res[0].is_warning,
            isAlarm: res[0].is_alarm,
            timedate: res[0].start_time,
            day: this.day[res[0].day - 1],
            indexDay: res[0].day,
            jobId: res[0].job_id,
          };
        }
        this.isLoading = false;
        // if (res)

        // this.imageDelete = [];
        // if (this.contextMenuStructure.status === "Create") {
        //   (this as any).$store.dispatch("setEquipmentCreateForm", {
        //     parentId: this.contextMenuStructure.id,
        //     structureItemTitle: "",
        //     structureItemAlias: "",
        //     isPrimaryFolder: false,
        //     emailTaskScheduler: {
        //       daily: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //       weekly: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //       monthly: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //     },
        //   });
        // }
        // if (this.contextMenuStructure.status === "Edit") {
        //   const { structureItemTitle, structureItemAlias, equipmentGroupType, posLat, posLng, images } =
        //     this.$store.state.openEmailNotificationFormModal.param;
        //   (this as any).$store.dispatch("setEquipmentCreateForm", {
        //     parentId: this.contextMenuStructure.id,
        //     structureItemTitle,
        //     structureItemAlias,
        //     itemGroupType: equipmentGroupType,
        //     position: { lat: posLat, lng: posLng },
        //     isPrimaryFolder: false,
        //     images: images ? images : [],
        //     emailTaskScheduler: {
        //       daily: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //       weekly: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //       monthly: {
        //         isActive: false,
        //         isWarning: false,
        //         isAlarm: false,
        //         timedate: null,
        //       },
        //     },
        //   });
        //   this.equipmentTypeSelect(equipmentGroupType);
        // }
      }
    },
  },
});
