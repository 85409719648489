
import Vue from "vue";
import { AzureMap, AzureMapDataSource, AzureMapPoint, AzureMapSymbolLayer } from "vue-azure-maps";
import { required, alphaNum } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default Vue.extend({
  components: {
    AzureMap,
    AzureMapDataSource,
    AzureMapPoint,
    AzureMapSymbolLayer,
  },
  props: {
    validations: {
      type: Object,
    },
  },
  validations: {
    itemTitle: { required, alphaNum },
    itemGroupType: { required },
    validationGroup: ["itemTitle", "itemGroupType"],
  },
  data() {
    const map: any = null;
    const isLoading = true;
    const iconType = "";
    const equipmentForm = {
      name: "",
      type: "",
      position: {
        lat: 13.756794406338395,
        lng: 100.51020418766808,
      },
    };

    const equipmentType = [
      {
        text: "Electric",
        value: "electric",
      },
      {
        text: "Rotating",
        value: "rotating",
      },
      {
        text: "Static",
        value: "static",
      },
      {
        text: "Instrument",
        value: "instrument",
      },
      {
        text: "Process",
        value: "process",
      },
    ];

    const emailTaskSchedulerNotification = {
      daily: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
      weekly: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
      monthly: {
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
    };

    return {
      isLoading,
      map,
      iconType,
      equipmentForm,
      equipmentType,
      emailTaskSchedulerNotification,
      iconElectric: require("@/assets/icons/iconMarker/Electric.svg"),
      iconStatic: require("@/assets/icons/iconMarker/Static.svg"),
      iconRotating: require("@/assets/icons/iconMarker/Rotating.svg"),
      iconProcess: require("@/assets/icons/iconMarker/Process.svg"),
      iconInstrument: require("@/assets/icons/iconMarker/Instrument.svg"),
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  computed: {
    itemTitle: {
      get(): string {
        return (this as any).$store.getters.equipmentTagCreateForm.structureItemTitle;
      },
      set(structureItemTitle: string) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.getters.equipmentTagCreateForm,
          structureItemTitle,
        });
      },
    },
    itemGroupType: {
      get(): string {
        return (this as any).$store.getters.equipmentTagCreateForm.itemGroupType;
      },
      set(itemGroupType: string) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.getters.equipmentTagCreateForm,
          itemGroupType,
        });
      },
    },
    position: {
      get(): Record<string, unknown> {
        return (this as any).$store.getters.equipmentTagCreateForm.position;
      },
      set(position: Record<string, unknown>) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.getters.equipmentTagCreateForm,
          position,
        });
      },
    },
    itemTitleError() {
      return (this as any).validations.structureItemTitle.$error ? "Equipment Tag Name is required." : "";
    },
    itemGroupTypeError() {
      return (this as any).validations.itemGroupType.$error ? "Equipment Group Type is required." : "";
    },
    positionError() {
      return (this as any).validations.position.$error ? "Location is required." : "";
    },
  },
  methods: {
    async setMap(mapEvent: any) {
      this.map = mapEvent.map;
      await this.addSprite("electric", this.iconElectric);
      await this.addSprite("static", this.iconStatic);
      await this.addSprite("rotating", this.iconRotating);
      await this.addSprite("process", this.iconProcess);
      await this.addSprite("instrument", this.iconInstrument);
    },
    addSprite(type: string, address: any) {
      if (!this.map) return;
      return this.map.imageSprite.add(type, address);
    },
    changeIcon(equipmentGroupType: string) {
      return {
        iconOptions: {
          size: 0.5,
          image: equipmentGroupType,
        },
      };
    },
    getCurrentPosition(e: any): void {
      this.equipmentForm.position = {
        lat: e.position[1],
        lng: e.position[0],
      };
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.getters.equipmentTagCreateForm,
        position: {
          lat: e.position[1],
          lng: e.position[0],
        },
      });
    },
    equipmentTypeSelect(equipment: string) {
      switch (equipment) {
        case "electric":
          this.equipmentForm.type = "electric";
          this.iconType = this.iconElectric;
          break;
        case "rotating":
          this.equipmentForm.type = "rotating";
          this.iconType = this.iconRotating;
          break;
        case "static":
          this.equipmentForm.type = "static";
          this.iconType = this.iconStatic;
          break;
        case "instrument":
          this.equipmentForm.type = "instrument";
          this.iconType = this.iconInstrument;
          break;
        case "process":
          this.equipmentForm.type = "process";
          this.iconType = this.iconProcess;
          break;
      }
    },
    checkBoxEmailTaskSchedulerAll(type: string) {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;
      const now: any = dayjs().format("HH:mm").toString();

      if (type === "daily") {
        this.emailTaskSchedulerNotification.daily.isWarning = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.isAlarm = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.timedate = daily.isActive ? now : null;
      }
      if (type === "weekly") {
        this.emailTaskSchedulerNotification.weekly.isWarning = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.timedate = weekly.isActive ? now : null;
      }
      if (type === "monthly") {
        this.emailTaskSchedulerNotification.monthly.isWarning = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.timedate = monthly.isActive ? now : null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.getters.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    checkBoxEmailTaskScheduler() {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;

      if (!daily.isActive) {
        this.emailTaskSchedulerNotification.daily.isWarning = false;
        this.emailTaskSchedulerNotification.daily.isAlarm = false;
        this.emailTaskSchedulerNotification.daily.timedate = null;
      }
      if (!weekly.isActive) {
        this.emailTaskSchedulerNotification.weekly.isWarning = false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = false;
        this.emailTaskSchedulerNotification.weekly.timedate = null;
      }
      if (!monthly.isActive) {
        this.emailTaskSchedulerNotification.monthly.isWarning = false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = false;
        this.emailTaskSchedulerNotification.monthly.timedate = null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.getters.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    timeEmailTaskScheduler() {
      const emailTaskScheduler = this.emailTaskSchedulerNotification;

      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.getters.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
  },
});
