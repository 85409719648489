import { render, staticRenderFns } from "./HMainTankDGA.vue?vue&type=template&id=2cd8e148&scoped=true&"
import script from "./HMainTankDGA.vue?vue&type=script&lang=ts&"
export * from "./HMainTankDGA.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd8e148",
  null
  
)

export default component.exports