
import Vue from "vue";

export default Vue.extend({
  data() {
    const realTimeFaultEvent = {
      isLoading: true,
      isDialog: false,
      messageDialog: "",
    };

    return { realTimeFaultEvent };
  },
  computed: {
    isRealTimeFaultEvent: {
      get(): boolean {
        return this.$store.getters.isRealTimeFaultEvent;
      },
    },
  },
  async created() {
    try {
      (this as any).realTimeFaultEvent.isLoading = true;
      await this.$store.dispatch("setIsRealTimeFaultEvent");
    } catch (error) {
      //
    } finally {
      (this as any).realTimeFaultEvent.isLoading = false;
    }
  },
  methods: {
    onSettingCompany() {
      this.$router.push({ name: "SettingCompanyPage" });
    },
    onSettingAdminMember() {
      this.$router.push({ name: "SettingInviteAdminPage" });
    },
    async onChangeRealTime(ev: any) {
      try {
        (this as any).realTimeFaultEvent.isLoading = true;
        await (this as any).$dep.modelUseCase.switchRealTimeFaultEvent(ev);
        await this.$store.dispatch("setIsRealTimeFaultEvent");
        (this as any).realTimeFaultEvent.messageDialog = ev
          ? `"ON" : Real time fault event`
          : `"OFF" : Real time fault event`;
        (this as any).realTimeFaultEvent.isDialog = true;
      } catch (error) {
        //
      } finally {
        (this as any).realTimeFaultEvent.isLoading = false;
      }
    },
  },
});
