
import Vue from "vue";
import { required } from "vuelidate/lib/validators";

import EChart from "@/features/model/components/graph/EChart.vue";
import NoDataRVS from "@/components/NoDataRVS.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

export default Vue.extend({
  name: "RvsWaterfallGraph",
  components: { EChart, LoadingGraph, NoDataRVS },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
  },
  validations: {
    isSensor: { required },
    isFileName: { required },
    validationGroup: ["isSensor", "isFileName"],
  },
  data() {
    return {
      maxLength: 3,
      xAxisType: "hz",
      sensorGroup: [],
      isSensor: null,
      isLoadingSensorGroup: false,
      phaseGroup: [
        {
          id: 1,
          phaseName: "RMS Acceleration [m/s\u00B2]",
          unit: "RMS Acceleration [m/s\u00B2]",
          phaseValue: "acceleration",
        },
        { id: 2, phaseName: "RMS Acceleration [g]", unit: "RMS Acceleration [g]", phaseValue: "accelerationG" },
        { id: 3, phaseName: "RMS Velocity [mm/s]", unit: "RMS Velocity [mm/s]", phaseValue: "velocity" },
        { id: 4, phaseName: "Envelope", unit: "Envelope", phaseValue: "envelope" },
      ],
      isPhase: "acceleration",
      axisGroup: [
        { id: 1, axisName: "H", axisValue: "x" },
        { id: 2, axisName: "V", axisValue: "y" },
        { id: 3, axisName: "A", axisValue: "z" },
      ],
      isAxis: "x",
      sensorPointGroup: [],
      isSensorPoint: null,
      isLoadingSensorPointGroup: false,
      fileNameGroup: [],
      isFileName: [],
      isLoadingFileNameGroup: false,
      excelLoading: false,
      graphResultData: [],
      graphLoading: false,
      chartOptions: {
        title: {
          text: "Waterfall",
          left: "center",
        },
        grid3D: {
          boxWidth: 200,
          boxHeight: 80,
          viewControl: {
            projection: "perspective", // perspective orthographic
            alpha: 15,
            beta: 0,
          },
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.5,
            },
          },
        },
        tooltip: {
          trigger: "item",
          formatter: function (param: any) {
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            return `
            <p>${param.marker} ${param.seriesName}</p>
            <div style="min-width: 220px">
              <div class='flex items-center gap-x-[6px]'>
                <span>X : </span>
                <span>${formatter.format(param.value[0])}</span>
              </div>
              <div class='flex items-center gap-x-[6px]'>
                <span>Y : </span>
                <span>${formatter.format(param.value[2])}</span>
              </div>
            </div>`;
          },
          position: function () {
            // fixed at top
            return ["75%", "0%"];
          },
        },
        legend: { data: [], top: 40 },
        xAxis3D: {
          name: "",
          type: "value",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
        },
        yAxis3D: {
          name: "",
          type: "category",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
        },
        zAxis3D: {
          name: "",
          type: "value",
          nameLocation: "middle",
          nameTextStyle: { fontSize: 13 },
        },
        series: [],
      },
      firstPoint: undefined,
      secondaryPoint: undefined,
      diffPoint: undefined,
      maxXAxisValue: 0,
      minXAxisValue: 0,
      formatter: new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    };
  },
  async created() {
    await this.fetchSensorGroup();
  },
  methods: {
    sidebandHandle() {
      if (this.firstPoint && this.secondaryPoint) {
        this.diffPoint = this.secondaryPoint - this.firstPoint;
        const yAxisName = this.chartOptions.series
          .filter((val) => val.name && val.name !== "markLine")
          .map((val) => val.name);
        let arr = [];
        for (var i = 0; i < 9; i++) {
          if (i < 4) {
            const diff = this.firstPoint + (i + 2) * this.diffPoint;
            if (diff < this.maxXAxisValue) {
              arr.push({
                type: "line3D",
                name: "markLine",
                data: yAxisName.map((val) => [diff, val, 0]),
                lineStyle: {
                  color: "red",
                },
              });
            }
          } else {
            const diff = this.firstPoint + (3 - i) * this.diffPoint;
            if (diff > this.minXAxisValue) {
              arr.push({
                type: "line3D",
                name: "markLine",
                data: yAxisName.map((val) => [diff, val, 0]),
                lineStyle: {
                  color: "red",
                },
              });
            }
          }
        }
        // Add the new markLine to the chart options
        this.chartOptions.series = this.chartOptions.series.concat(arr);
        // Update the chart
        this.updateChart(this.chartOptions);
      }
    },
    harmonicHandle() {
      if (this.firstPoint && this.secondaryPoint) {
        this.diffPoint = this.secondaryPoint - this.firstPoint;
        const yAxisName = this.chartOptions.series
          .filter((val) => val.name && val.name !== "markLine")
          .map((val) => val.name);
        let arr = [];
        for (var i = 0; i < 3; i++) {
          const diff = this.firstPoint + (i + 2) * this.diffPoint;
          if (diff < this.maxXAxisValue) {
            arr.push({
              type: "line3D",
              name: "markLine",
              data: yAxisName.map((val) => [diff, val, 0]),
              lineStyle: {
                color: "red",
              },
            });
          }
        }
        // Add the new markLine to the chart options
        this.chartOptions.series = this.chartOptions.series.concat(arr);
        // Update the chart
        this.updateChart(this.chartOptions);
      }
    },
    clearMarkPoint() {
      this.firstPoint = undefined;
      this.secondaryPoint = undefined;
      this.diffPoint = undefined;
      this.chartOptions.series = this.chartOptions.series.filter((item) => item.name !== "markLine");
      this.updateChart(this.chartOptions, { notMerge: true });
    },
    handleChartClick(params) {
      const value = params.value;
      if (value[0] < this.firstPoint) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Couldn't select 2nd less than ref peak",
        });
        return;
      }
      if (
        params.seriesType === "line3D" &&
        (typeof this.firstPoint === "undefined" || typeof this.secondaryPoint === "undefined") &&
        (typeof this.firstPoint === "undefined" || value[0] > this.firstPoint)
      ) {
        const isFirstClick = typeof this.firstPoint === "undefined";

        const data = this.chartOptions.series
          .filter((val) => val.name && val.name !== "markLine")
          .map((val) => [value[0], val.name, 0]);

        if (isFirstClick) {
          this.firstPoint = value[0];
        } else {
          this.secondaryPoint = value[0];
        }
        const newMarkLine = [
          {
            type: "line3D",
            name: "markLine",
            data,
            lineStyle: {
              color: isFirstClick ? "#282f73" : "red",
            },
          },
          {
            type: "scatter3D",
            name: "markLine",
            data: [value],
            symbolSize: 5,
            itemStyle: {
              borderWidth: 1,
              color: isFirstClick ? "#282f73" : "red",
              borderColor: isFirstClick ? "#282f73" : "red",
            },
          },
        ];
        // Add the new markLine to the chart options
        this.chartOptions.series = this.chartOptions.series.concat(newMarkLine);

        // Update the chart
        this.updateChart(this.chartOptions);
      }
    },
    updateChart(options, opts) {
      this.$root.waterFallRef.setOption(options, opts);
    },
    setMaxMin() {
      const { data } = this.chartOptions.series[0];
      this.maxXAxisValue = data[data.length - 1][0];
      this.minXAxisValue = data[0][0];
    },
    async fetchDataResult() {
      try {
        const data = await (this as any).$dep.modelUseCase.getRvsGraphResultWaterfallData({
          sensorId: this.isSensor.sensorId,
          phase: this.isPhase === "accelerationG" ? "acceleration" : this.isPhase,
          axis: this.isAxis,
          fileNames: this.isFileName,
        });

        const result =
          this.isPhase === "accelerationG"
            ? {
                ...data,
                series: data.series.map((val: any) => ({
                  ...val,
                  data: val.data.map((d: any) => ({
                    ...d,
                    amplitude: d.amplitude / 9.81,
                  })),
                })),
              }
            : data;
        this.graphResultData = result.series;

        this.chartOptions.zAxis3D.name = this.phaseGroup.find((val) => val.phaseValue === this.isPhase).unit || "";
        this.chartOptions.xAxis3D.name = this.xAxisType === "hz" ? "Frequency [Hz]" : "Frequency [CPM]";
        this.chartOptions.legend.data = result.series.map((val: any) => val.file_name);
        this.chartOptions.series = result.series.map((val: any) => ({
          name: val.file_name,
          type: "line3D",
          data: val.data.map((d: any) => [d.x, val.file_name, d.amplitude]),
          lineStyle: {
            width: 1,
            lineStyle: {
              width: 1,
            },
          },
        }));
        this.setMaxMin();
      } catch (error) {
        this.graphResultData = [];
      } finally {
        (this as any).graphLoading = false;
      }
    },
    async fetchSensorPointGroup() {
      try {
        this.isLoadingSensorPointGroup = true;
        const sensorPoint = await (this as any).$dep.modelUseCase.getSensorPoints(this.getModelCode);

        this.sensorPointGroup = sensorPoint;
      } catch (error) {
        this.sensorGroup = [];
      } finally {
        this.isLoadingSensorPointGroup = false;
      }
    },
    async fetchSensorGroup() {
      try {
        this.isLoadingSensorGroup = true;

        const sensorGroup = await (this as any).$dep.modelUseCase.getRvsWaterfallSensorType({
          modelCode: this.getModelCode,
        });

        this.sensorGroup = sensorGroup;
      } catch (error) {
        this.sensorGroup = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },
    async fetchFileNameGroup() {
      try {
        this.isLoadingFileNameGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsGraphResultWaterfallFileName({
          modelCode: this.getModelCode,
          sensor: this.isSensor.sensorId,
          phase: this.isPhase,
          axis: this.isAxis,
        });

        this.fileNameGroup = datas;
      } catch (error) {
        this.fileNameGroup = [];
      } finally {
        this.isLoadingFileNameGroup = false;
      }
    },
    async onSubmit() {
      this.graphResultData = [];
      this.xAxisType = "hz";

      this.graphLoading = true;
      await this.fetchDataResult();
      this.graphLoading = false;
    },
    async onChangeSensorType(val: any) {
      this.isFileName = [];
      this.isSensor = {
        id: val.id,
        sensorId: val.sensorId,
        sensorName: val.sensorName,
      };
      await this.fetchAxis(val.sensorId);
      await this.fetchFileNameGroup();
    },
    async fetchAxis(sensorId: string) {
      const res = await (this as any).$dep.modelUseCase.getRvsAxis(sensorId);
      this.axisGroup = [
        { id: 1, axisName: res.x, axisValue: "x" },
        { id: 2, axisName: res.y, axisValue: "y" },
        { id: 3, axisName: res.z, axisValue: "z" },
      ];
    },
    onChangePhase(value: string) {
      this.isPhase = value;
    },
    onChangeAxis(value: string) {
      this.isAxis = value;
    },
    async onChangeSensorPoint(value: number) {
      this.isSensorPoint = value;

      await this.fetchSensorGroup();
    },
    onChangeFileName(values: any[]) {
      this.isFileName = values.map((val: any) => ({
        alias: val.alias,
        fileName: val.fileName,
      }));
    },
    onChangeXAxisType(val: string) {
      this.clearMarkPoint();
      this.graphLoading = true;

      const datas = this.graphResultData;

      this.chartOptions.series = this.chartOptions.series.map((serie: any, index: number) => ({
        ...serie,
        data:
          val === "cpm"
            ? serie.data.map((d: any) => [d[0] * 60, d[1], d[2]])
            : datas[index].data.map((d: any) => [d.x, datas[index].file_name, d.amplitude]),
      }));
      this.chartOptions.xAxis3D.name = this.xAxisType === "hz" ? "Frequency [Hz]" : "Frequency [CPM]";
      this.setMaxMin();
      this.graphLoading = false;
    },

    limiter(val: any[]) {
      if (val.length > this.maxLength) val.shift();
    },
  },
  watch: {
    async getModelId() {
      this.sensorGroup = [];
      this.isSensor = null;
      this.fileNameGroup = [];
      this.isFileName = [];
      this.graphResultData = [];

      await this.fetchSensorGroup();
    },
  },
});
