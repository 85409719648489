
import Vue from "vue";
import Card from "@/components/Card.vue";
import GCooling from "../summaryStatus/GCooling.vue";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "DiagramTransformer",
  components: { Card, GCooling, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modelId() {
      return this.$store.getters.model.id;
    },
    companyId() {
      return this.$store.getters.company.id;
    },
    items() {
      return this.$store.state.modelViewData.tr_diagram_status;
    },
  },
  methods: {
    statusName(status: number | string): string {
      if (typeof status === "number") {
        return status === -2
          ? "disabled"
          : status === -1
          ? "offline"
          : status === 1
          ? "warning"
          : status === 2
          ? "alarm"
          : "normal";
      } else {
        return status === "-2"
          ? "disabled"
          : status === "-1"
          ? "offline"
          : status === "1"
          ? "warning"
          : status === "2"
          ? "alarm"
          : "normal";
      }
    },
    healthStatus(code: number) {
      var status = this.statusName(code);
      return status.charAt(0).toUpperCase() + status.slice(1);
    },
  },
});
