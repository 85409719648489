
import Vue from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
import VcaOverall from "./VcaOverall.vue";
import VcaGraphRL from "./VcaGraphRL.vue";
import VcaTemperature from "./VcaTemperature.vue";

use([CanvasRenderer, PieChart]);

export default Vue.extend({
  name: "reminingLife",
  components: {
    VcaOverall,
    LoadingGraph,
    NoDataAvailable,
    VcaGraphRL,
    VcaTemperature,
  },
  data() {
    const isLoading = true;
    const items: any[] = [];

    const options = {
      tooltip: {
        trigger: "item",
      },
      series: [],
    };

    const tabMenu = [
      {
        id: 0,
        name: "Overview",
      },
      {
        id: 1,
        name: "RUL",
      },
      {
        id: 2,
        name: "Temperature",
      },
    ];
    const tabSelected = 0;

    return {
      items,
      options,
      isLoading,
      brandSelected: 1,
      brandType: 1,
      brandItems: [],
      healthMonitoringSensorTypeItems: [],
      resultDatas: [],
      tabMenu,
      tabSelected,
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    filteredItems() {
      return this.resultDatas.filter((item: any) => {
        return item.tag?.sensorTypeId === this.brandType;
      });
    },
    modelType() {
      return this.$store.getters.model.modelTypeId;
    },
  },
  async created() {
    this.resultDatas = await (this as any).fetchData();
    await this.fetchHealthMonitoringType();
  },
  methods: {
    async fetchData() {
      try {
        (this as any).isLoading = true;
        const datas = await (this as any).$dep.modelUseCase.modelResultOverallDataStatusVca(this.model.modelCode, "rul_p_y");

        return datas;
      } catch (error) {
        //
      } finally {
        (this as any).isLoading = false;
      }
    },
    setSeriesData(datas: any) {
      const valueNormal = datas.filter((p: any) => p.status === 0);
      const valueWarning = datas.filter((p: any) => p.status === 1);
      const valueDanger = datas.filter((p: any) => p.status === 2);

      const status: any[] = [...new Map(datas.map((item: any) => [item["status"], item])).values()];
      const data = status.map((p: any) => {
        return {
          value: p.status === 0 ? valueNormal.length : p.status === 1 ? valueWarning.length : valueDanger.length,
          name: p.status === 0 ? "Remaining Life > 20%" : p.status === 1 ? "Remaining Life 10-20%" : "Remaining Life < 10%",
          itemStyle: {
            color: p.status === 0 ? "#4caf50" : p.status === 1 ? "#ffc12b" : "#f44336",
          },
        };
      });

      (this as any).options.series = {
        type: "pie",
        radius: ["50%", "90%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data,
      };
    },
    getStatus(code: number) {
      return code === 0 ? "success" : code === 1 ? "warning" : code === 2 ? "error" : "normal";
    },
    async fetchHealthMonitoringType() {
      try {
        const res = await Vue.prototype.$dep.modelUseCase.getVsdSensorType("vsdtag");
        const data = res.filter((val) => val.equipmentModelTypeId == this.modelType);
        this.brandSelected = data[0].id;
        this.brandItems = data.map((row: any) => ({ id: row.id, name: row.name }));

        await this.fetchHealthMonitoringSensorType(data[0].id);
      } catch (error) {
        this.brandItems = [];
      }
    },
    async fetchHealthMonitoringSensorType(id: number) {
      try {
        const data = await Vue.prototype.$dep.modelUseCase.getModelHealthMonitoringSensorType(id);
        this.brandType = data[0].id;
        this.healthMonitoringSensorTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));
        this.setSeriesData(this.filteredItems);
      } catch (error) {
        this.healthMonitoringSensorTypeItems = [];
      }
    },
    async onHealthMonitoringTypeChange(id: number) {
      try {
        this.brandSelected = id;
        await this.fetchHealthMonitoringSensorType(id);
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    healthMonitoringSensorType() {
      this.setSeriesData(this.filteredItems);
    },
  },
});
