
import Vue from "vue";

export default Vue.extend({
  name: "Spinner",
  props: {
    size: {
      type: String,
      default: "md",
    },
  },
});
