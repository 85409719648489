
import Vue from "vue";

import BlankSpace from "@/components/BlankSpace.vue";

export default Vue.extend({
  name: "TableCustom",
  components: { BlankSpace },
  props: {
    headers: {
      type: Array,
      required: true,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemKey: {
      type: String,
      required: false,
      default: "id",
    },
    showSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    const itemsPerPageData: { label: string; value: number }[] = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "15", value: 15 },
      { label: "All", value: -1 },
    ];

    return { page: 1, pageCount: 0, itemsPerPage: this.showPagination ? 10 : -1, itemsPerPageData };
  },
  methods: {
    changePerPage(num: number) {
      this.itemsPerPage = num;
    },
    toggleSelectAll(props: { items: any[]; value: boolean }) {
      this.$emit("selectAll", props);
    },
  },
});
