
import Vue from "vue";
import dayjs from "dayjs";
import axios from "axios";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
// import ButtonReload from "@/components/ButtonReload.vue";
// import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "McaCurrentSpectrum",
  components: { highcharts: Chart, ButtonDanger, LoadingGraph, NoDataAvailable },
  data() {
    const date = new Date();

    //for debug
    const fileName = [];
    const storeData: any = [];
    const additional: any = {
      rotor: [],
      eccentric: [],
      stator: [],
      statorISO: [],
    };
    return {
      source: null,
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      sensorTypeData: ["IA", "IB", "IC"],
      isSensorType: "IA",
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],

      chartLoading: true,
      chartOptions: {
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: false,
            },
          },
        },
        scrollbar: {
          enabled: true,
          showFull: false,
          liveRedraw: false,
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: {
          formatter: function () {
            let tooltipText = `<b>${this.x} Hz</b><br/>`;
            // const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            tooltipText += `<span style="color:${this.point.color}">\u25CF</span> <b>${
              this.point.series.name
            }: </b>${this.y.toFixed(2)} dB<br/>`;

            // Check if the series has only 1 line and add warning/alarm information

            return tooltipText;
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: { type: "category", categories: [] },
        series: [],
        credits: { enabled: false },
      },
      fileName,
      isFileName: {
        alias: "",
        fileName: "",
      },
      storeData,
      additional,
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  async created() {
    // await this.fetchSensorTypeData();
    await this.fetchFileName();
  },
  methods: {
    async fetchSensorTypeData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelHealthMonitoringSensorType(this.isSecondaryTab, id);
        this.sensorTypeData = response.map((prev) => ({ id: prev.id, name: prev.name }));
        this.isSensorType = { id: this.sensorTypeData[0].id, name: this.sensorTypeData[0].name };
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = {};
      }
    },
    async fetchResultData() {
      try {
        this.chartLoading = true;
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        const { id, modelCode, equipmentId } = this.model;
        if (this.source) {
          this.source.cancel();
        }
        let storeData: Array<any> = [];
        storeData = await (this as any).$dep.modelUseCase.getEquipmentModelDataResultFrequenct(
          this.model.modelCode,
          this.isFileName.fileName,
          this.groupCode
        );
        // storeData = await (this as any).$dep.modelUseCase.getEquipmentModelDataResultFrequenct(
        //   "TTA-MCA01-MCA-M1",
        //   "20210914_1.csv",
        //   this.groupCode
        // );
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        (this as any).storeData = storeData.map((item: any) => {
          const { IA_CHECK, IB_CHECK, IC_CHECK } = item;

          const rotor = "Rotor Crack";
          const eccentric = "Eccentric Air Gap";
          const stator = "Stator Short Turn";
          const statorISO = "Stator Short Turn (ISO)";
          const getCheckStatusName = (data: any) =>
            data === 1 ? rotor : data === 2 ? eccentric : data === 3 ? stator : data === 4 ? statorISO : null;
          return {
            ...item,
            IA_CHECK_NAME: getCheckStatusName(IA_CHECK),
            IB_CHECK_NAME: getCheckStatusName(IB_CHECK),
            IC_CHECK_NAME: getCheckStatusName(IC_CHECK),
          };
        });
        this.chartResultData = this.storeData;
        this.chartOptions.title.text = `current spectrum`;
        this.chartOptions.xAxis = this.aAxis(this.storeData);
        this.chartOptions.series = this.series(this.storeData);
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.source = null;
      }
    },
    onChangeSensorType(param: any) {
      const { id, name } = param;
      this.isSensorType = { id, name };
    },
    onChangeSelectDateType(param: any) {
      this.selectDateType = param;
    },
    aAxis(datas: any) {
      let labels = datas.map((val) => val.FHZ);
      this.xAxisMaxRange = labels.length;
      const count = labels.length;
      const maxLabelsToShow = 10; // You can adjust this value to display more or fewer labels

      return {
        type: "category",
        labels: {
          align: "left",
          formatter: function () {
            return `${this.value} Hz`;
          },
        },
        tickPositioner: function () {
          const interval = Math.max(1, Math.ceil(count / maxLabelsToShow));
          const positions = [];
          for (let i = 0; i < count; i += interval) {
            positions.push(i);
          }
          return positions;
        },
        categories: labels,
      };
    },

    series(datas: any) {
      const customLegend = {
        name: "All",
      };
      const checkSensorType = (val: any) => {
        switch (this.isSensorType) {
          case "IA":
            return { type: "IA", data: val.map((val) => val.IA) };
          case "IB":
            return { type: "IB", data: val.map((val) => val.IB) };
          case "IC":
            return { type: "IC", data: val.map((val) => val.IC) };
        }
      };

      const checkStatus = (name: string, val: any, stype: string) => {
        const rotor = [];
        const eccentric = [];
        const stator = [];
        const statorISO = [];

        val.forEach((valCheck, idx) => {
          if (valCheck[`${name}`] === 1 && valCheck[`${stype}_AMP`]) {
            rotor.push([idx, valCheck[`${stype}`]]);
          }
          if (valCheck[`${name}`] === 2 && valCheck[`${stype}_AMP`]) {
            eccentric.push([idx, valCheck[`${stype}`]]);
          }

          if (valCheck[`${name}`] === 3 && valCheck[`${stype}_AMP`]) {
            stator.push([idx, valCheck[`${stype}`]]);
          }
          if (valCheck[`${name}`] === 4 && valCheck[`${stype}_AMP`]) {
            statorISO.push([idx, valCheck[`${stype}`]]);
          }
        });
        return [rotor, eccentric, stator, statorISO];
      };

      const markPoint = (val: any) => {
        let rotor = [];
        let eccentric = [];
        let stator = [];
        let statorISO = [];
        switch (this.isSensorType) {
          case "IA":
            [rotor, eccentric, stator, statorISO] = checkStatus("IA_CHECK", this.storeData, "IA");
            break;
          case "IB":
            [rotor, eccentric, stator, statorISO] = checkStatus("IB_CHECK", this.storeData, "IB");
            break;
          case "IC":
            [rotor, eccentric, stator, statorISO] = checkStatus("IC_CHECK", this.storeData, "IC");
            break;
        }
        return [
          {
            name: "Rotor Crack",
            data: rotor, // Use "null" for the missing value
            marker: {
              enabled: true, // Set this to true to enable markers for this series
              symbol: "circle", // Set the symbol to 'circle'
              radius: 6, // Adjust the radius to change the size of the circle
            },
            type: "scatter",
          },
          {
            name: "Eccentric Air Gap",
            data: eccentric, // Use "null" for the missing value
            marker: {
              enabled: true, // Set this to true to enable markers for this series
              symbol: "circle", // Set the symbol to 'circle'
              radius: 6, // Adjust the radius to change the size of the circle
            },
            type: "scatter",
          },
          {
            name: "Stator Short Turn",
            data: stator, // Use "null" for the missing value
            marker: {
              enabled: true, // Set this to true to enable markers for this series
              symbol: "circle", // Set the symbol to 'circle'
              radius: 6, // Adjust the radius to change the size of the circle
            },
            type: "scatter",
          },
          {
            name: "Stator Short Turn (ISO)",
            data: statorISO, // Use "null" for the missing value
            marker: {
              enabled: true, // Set this to true to enable markers for this series
              symbol: "circle", // Set the symbol to 'circle'
              radius: 6, // Adjust the radius to change the size of the circle
            },
            type: "scatter",
          },
        ];
      };
      const pointAdd = markPoint(datas);
      const dataSensor = checkSensorType(datas);
      const filteredPointAdd = pointAdd.filter((series) => series.data.length > 0);

      const sers = [
        {
          name: dataSensor.type,
          data: dataSensor.data,
          lineWidth: 2,
        },
      ];

      const combined = [...sers, ...filteredPointAdd];
      return combined;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `mca_${id}__current_spectrum`.toUpperCase();
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const ws = XLSX.utils.json_to_sheet(this.chartResultData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, name); // => sheet name
      XLSX.writeFile(wb, `${name}.xlsx`); // => file name
    },
    async onSelectFileName(el: any) {
      this.isFileName = el;
      await this.fetchResultData();
    },
    async fetchFileName() {
      try {
        (this as any).fileName = await (this as any).$dep.modelUseCase.getFileNameMca(this.model.modelCode, this.groupCode);
        // (this as any).fileName = await (this as any).$dep.modelUseCase.getFileNameMca("TTA-MCA01-MCA-M1", this.groupCode);
        if (this.fileName.length === 0) {
          this.chartLoading = false;
          this.chartResultData = [];
          throw "No Datas";
        }
        if ((this as any).isFileName.fileName === (this as any).fileName[0].fileName) {
          await this.fetchResultData();
          (this as any).isFileName = (this as any).fileName[0];
        } else {
          (this as any).isFileName = (this as any).fileName[0];
        }
      } catch (error) {
        //
        if (!(this as any).isFileName) {
          this.chartLoading = false;
          return;
        }
        (this as any).fileName = [];
      }
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
  },
  watch: {
    async isSecondaryTab() {
      await this.fetchFileName();
    },
    async isSensorType() {
      this.chartOptions.series = this.series(this.storeData);
    },
    async isFileName() {
      await this.fetchResultData();
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
  },
});
