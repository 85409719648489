
import Vue from "vue";

import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import TableCustom from "@/components/TableCustom.vue";

export default Vue.extend({
  name: "FormCorrelationMapping",
  components: { DeleteIcon, AddIcon, TableCustom },
  computed: {
    groupTags: {
      get() {
        return this.$store.state.modelSettingForm?.correlation_mapping_tag_code;
      },
    },
    items: {
      get() {
        return this.$store.state.updateModelForm?.q_setting_in;
      },
    },
  },
  data() {
    const maxLength = 2;

    const groupTag: any[] = [];
    const headers = [
      { text: "Tag code (Maximun 2 tags)", value: "tagCode", sortable: false, width: "36%" },
      { text: "Alarm", value: "alarm", sortable: false, width: "20%" },
      { text: "Warning", value: "warning", sortable: false, width: "20%" },
      { text: "Score auto", value: "scoreAuto", sortable: false, width: "20%" },
      { text: "", value: "action", sortable: false, width: "4%" },
    ];

    return { headers, groupTag, maxLength };
  },
  methods: {
    onUpdateState() {
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        q_setting_in: this.items.map((item: any) => ({
          ...item,
          tagA: item.tagCode[0],
          tagB: item.tagCode[1],
          score: item.tagCode.length ? `${item.tagCode[0]}_${item.tagCode[1]}_score` : "",
          alarm: +item.alarm,
          warning: +item.warning,
        })),
      });
    },
    addItem() {
      this.items.push({ tagCode: [], tagA: "", tagB: "", score: "", alarm: 0, warning: 0 });

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        q_setting_in: this.items,
      });
    },
    removeItem(row: any) {
      const { index } = row;
      this.items.splice(index, 1);

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        q_setting_in: this.items,
      });
    },
    limiter(tag: any[]) {
      if (tag.length > this.maxLength) tag.shift();
    },
    scoreAuto(item: any): string {
      if (!item?.tagCode) {
        return "";
      }
      return item.tagCode.length === 2 ? `${item.tagCode[0]}_${item.tagCode[1]}_score` : "";
    },
  },
});
