
import Vue from "vue";

import CheckbokCustom from "@/components/CheckbokCustom.vue";

export default Vue.extend({
  name: "FormConfigurationInformation",
  components: { CheckbokCustom },
  computed: {
    modelTypeId: {
      get(): number {
        return this.$store.getters.model.modelTypeId;
      },
    },
    items: {
      get(): { id: number; title: string }[] {
        return this.$store.state?.modelSettingForm?.configuration_information || [];
      },
    },
    selected: {
      get() {
        return this.$store.state?.updateModelForm?.configuration_in || [];
      },
      set(value: number[]) {
        this.$store.dispatch("setUpdateModelForm", {
          ...this.$store.state.updateModelForm,
          configuration_in: value,
        });
      },
    },
  },
  data() {
    return { isCheckAll: false };
  },
  methods: {
    handleAll(value: boolean) {
      this.selected = value ? this.items.map((item: { id: number; title: string }) => item.id) : [];
    },
  },
  watch: {
    selected() {
      this.isCheckAll = this.selected.length === this.items.length;
    },
  },
});
