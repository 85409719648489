
import Vue from "vue";
import debounce from "debounce";

import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import TableCustom from "@/components/TableCustom.vue";
import FormConfigMachine from "./FormConfigMachine.vue";

export default Vue.extend({
  name: "FormRawVibrationSensor",
  components: { DeleteIcon, AddIcon, TableCustom, FormConfigMachine },
  computed: {
    items: {
      get() {
        return this.$store.state.updateModelForm.raw_vibration_sensor_in;
      },
    },
    filteredBearings() {
      return this.bearings.filter((bearing: any) => {
        return bearing.bearing_type.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  data() {
    const headers = [
      { text: "", value: "sensor", sortable: false },
      { text: "Axis", value: "paxis", sortable: false },
      { text: "Acceleration (m/s<sup>2</sup>)", value: "acceleration", sortable: false },
      { text: "Velocity (mm/s)", value: "velocity", sortable: false },
      { text: "Envelope", value: "envelope", sortable: false },
      { text: "Temperature (&#186;C)", value: "temperature", sortable: false },
      { text: "", value: "bearing", sortable: false },
      { text: "", value: "action", sortable: false },
    ];
    const drivenOptions = [
      { id: "Pump", title: "Pump" },
      { id: "Motor", title: "Motor" },
      { id: "Gearbox", title: "Gearbox" },
      { id: "Planetary_gear", title: "Planetary gear" },
      { id: "Fan", title: "Fan" },
      { id: "Belt", title: "Belt" },
      { id: "Plummer_block_bearing", title: "Plummer block bearing" },
      { id: "Blower", title: "Blower" },
      { id: "Turbine", title: "Turbine" },
    ];
    const positionOptions = [
      { id: "NDE", title: "NDE" },
      { id: "DE", title: "DE" },
    ];
    const sensorPoints: any[] = [];
    const machineTemplate: any[] = [];
    const bearings: any[] = [];
    const axis: any[] = ["H", "V", "A"];
    return {
      headers,
      sensorPoints,
      machineTemplate,
      bearings,
      axis,
      drivenOptions,
      positionOptions,
      search: "",
    };
  },
  async created() {
    await (this as any).fetchSensorPoints();
  },
  methods: {
    async fetchSensorPoints() {
      try {
        const sensorPoints = await (this as any).$dep.modelUseCase.getSensorPointsAll();
        const machineTemplate = await (this as any).$dep.modelUseCase.getMachineTemplate();
        const allItems = [
          ...new Set(
            this.items
              .map((val) => val.bearing_id)
              .filter(function (el) {
                return el != null;
              })
          ),
        ];
        let bearings = [];
        if (allItems && allItems.length > 0) {
          bearings = await (this as any).$dep.modelUseCase.getBearingById(allItems);
        } else {
          bearings = await (this as any).$dep.modelUseCase.getBearing();
        }
        (this as any).sensorPoints = sensorPoints;
        (this as any).machineTemplate = machineTemplate;
        (this as any).bearings = bearings;
      } catch (error) {
        (this as any).sensorPoints = [];
        (this as any).machineTemplate = [];
        (this as any).bearings = [];
      }
    },
    onUpdateState() {
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        raw_vibration_sensor_in: this.items.map((item) => ({
          ...item,
          acceleration: {
            x: {
              warning: +item.acceleration.x.warning,
              alarm: +item.acceleration.x.alarm,
              paxis: item.acceleration.x.paxis,
            },
            y: {
              warning: +item.acceleration.y.warning,
              alarm: +item.acceleration.y.alarm,
              paxis: item.acceleration.y.paxis,
            },
            z: {
              warning: +item.acceleration.z.warning,
              alarm: +item.acceleration.z.alarm,
              paxis: item.acceleration.z.paxis,
            },
          },
          velocity: {
            x: { warning: +item.velocity.x.warning, alarm: +item.velocity.x.alarm, paxis: item.acceleration.x.paxis },
            y: { warning: +item.velocity.y.warning, alarm: +item.velocity.y.alarm, paxis: item.acceleration.y.paxis },
            z: { warning: +item.velocity.z.warning, alarm: +item.velocity.z.alarm, paxis: item.acceleration.z.paxis },
          },
          envelope: {
            x: { warning: +item.envelope.x.warning, alarm: +item.envelope.x.alarm, paxis: item.acceleration.x.paxis },
            y: { warning: +item.envelope.y.warning, alarm: +item.envelope.y.alarm, paxis: item.acceleration.y.paxis },
            z: { warning: +item.envelope.z.warning, alarm: +item.envelope.z.alarm, paxis: item.acceleration.z.paxis },
          },
          temperature: {
            warning: +item.temperature.warning,
            alarm: +item.temperature.alarm,
          },
          ref_speed: +item.ref_speed,
          sensor_config: {
            ...item.sensor_config,
            belt_configuration: this.setObject(item.sensor_config.belt_configuration),
            blade_vane_configuration: this.setObject(item.sensor_config.blade_vane_configuration),
            motor_configuration: this.setObject(item.sensor_config.motor_configuration),
            bearing_configuration: item.sensor_config.bearing_configuration.map((row) => ({
              speed: +row.speed,
              bearing_id: row.bearing_id || null,
              running_no: row.running_no,
            })),
            planet_gear_configuration: item.sensor_config.planet_gear_configuration.map((row) => this.setObject(row)),
            gear_configuration: item.sensor_config.gear_configuration.map((row) => this.setObject(row)),
          },
        })),
      });
    },
    setObject(originalObject: any) {
      const incrementedObject = {};
      for (const key in originalObject) {
        if (Object.hasOwnProperty.call(originalObject, key)) {
          incrementedObject[key] = +originalObject[key];
        }
      }
      return incrementedObject;
    },
    searchBearing: debounce(async function (e: any) {
      this.search = e.target.value;
    }, 500),
    addItem() {
      this.items
        .push({
          sensorId: "",
          sensorName: "",
          acceleration: {
            x: { warning: 0, alarm: 0, paxis: "H" },
            y: { warning: 0, alarm: 0, paxis: "V" },
            z: { warning: 0, alarm: 0, paxis: "A" },
          },
          velocity: {
            x: { warning: 0, alarm: 0, paxis: "H" },
            y: { warning: 0, alarm: 0, paxis: "V" },
            z: { warning: 0, alarm: 0, paxis: "A" },
          },
          envelope: {
            x: { warning: 0, alarm: 0, paxis: "H" },
            y: { warning: 0, alarm: 0, paxis: "V" },
            z: { warning: 0, alarm: 0, paxis: "A" },
          },
          temperature: {
            warning: 0,
            alarm: 0,
          },
          paxis: "",
          sensorPointId: 1,
          machineTemplateId: 1,
          bearing_id: 1,
          is_blade_vane: false,
          is_motor: false,
          is_bearing: false,
          is_belt: false,
          is_planet_gear: false,
          is_gear: false,
          ref_speed: 0,
          driven: "Pump",
          type_of_position: "NDE",
          sensor_config: {
            belt_configuration: {
              speed: 0,
              pulley_1_dia: 0,
              pulley_2_dia: 0,
              belt_length: 0,
              belt_teeth: 0,
            },
            blade_vane_configuration: {
              speed: 0,
              blade: 0,
            },
            motor_configuration: {
              speed: 0,
              rotor_bar: 0,
              pole: 0,
            },
            bearing_configuration: Array.from({ length: 4 }, (_, i) => ({
              speed: 0,
              bearing_id: 0,
              running_no: i + 1,
            })),
            planet_gear_configuration: Array.from({ length: 2 }, (_, i) => ({
              speed: 0,
              sun: 0,
              ring: 0,
              planet: 0,
              running_no: i + 1,
            })),
            gear_configuration: Array.from({ length: 3 }, (_, i) => ({
              speed: 0,
              pinion: 0,
              gear: 0,
              running_no: i + 1,
            })),
          },
        })(this as any)
        .$store.dispatch("setUpdateModelForm", {
          ...this.$store.state.updateModelForm,
          raw_vibration_sensor_in: this.items,
        });
    },
    removeItem(param: any) {
      const { index } = param;
      this.items.splice(index, 1);

      (this as any).$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        raw_vibration_sensor_in: this.items,
      });
    },
  },
});
