
import Vue from "vue";
import dayjs from "dayjs";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "McaDiagnostic",
  props: {
    datas: [Array, Object],
    optionsaXis: Object,
  },
  components: { highcharts: Chart, LoadingGraph, NoDataAvailable },
  data() {
    const date = new Date();
    return {
      source: null,
      isSensorType: { id: "Ia", name: "IA" },
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      chartLoading: true,
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      chartOptions: {
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: false,
            },
          },
        },
        scrollbar: {
          enabled: true,
          showFull: false,
          liveRedraw: false,
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: {
          formatter: function () {
            let tooltipText = `<b>${this.x}</b><br/>`;
            const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            remaining.forEach((series) => {
              if (series.data.length > 0 && series.visible && series.yData[this.point.index] !== null) {
                tooltipText += `<span style="color:${series.color}">\u25CF</span> <b>${series.name}: </b>${
                  series.yData[this.point.index]
                } ${series.options.tooltip.valueSuffix}<br/>`;
              }
            });

            // Check if the series has only 1 line and add warning/alarm information
            if (remaining.length === 1) {
              const { warning, alarm } = this.series.options;

              tooltipText += `<span style="color:#FCA239">\u25CF</span> <b>Warning: </b>${warning || 0}<br/>`;
              tooltipText += `<span style="color:#D9263B">\u25CF</span> <b>Alarm: </b>${alarm || 0}<br/>`;
            }

            return tooltipText;
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: {
          type: "category",
          categories: [],
        },
        series: [],
        credits: { enabled: false },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                const chart = this.chart;
                const series = this.chart.series;
                const seriesIndex = this.index;
                const dataMax = (!this.visible && this.dataMax) || undefined;
                const values = [];
                dataMax && values.push(dataMax);
                this.chart.series.forEach((series, index) => {
                  if (index !== seriesIndex && series.visible) values.push(series.dataMax);
                });
                if (this.name === "All" && this.visible) {
                  series.forEach((val, ind) => {
                    if (ind !== seriesIndex) val.setVisible(false);
                  });
                  return;
                } else if (this.name === "All" && !this.visible) {
                  series.forEach((val, ind) => {
                    if (ind !== seriesIndex) val.setVisible(true);
                  });
                  const remaining = series.filter((val) => val.visible);
                  const seriesY = remaining.map((val) => Math.max(...val.yData));
                  const maxY = Math.max(...[...seriesY]) + 40;

                  this.chart.yAxis[0].update({
                    type: "linear",
                    visible: true,
                    min: null,
                    max: maxY,
                  });

                  this.chart.yAxis[0].removePlotLine("plot-warning");
                  this.chart.yAxis[0].removePlotLine("plot-alarm");
                  return;
                }
                setTimeout(() => {
                  const remaining = series.filter((val) => val.visible);
                  const count = remaining.length;
                  const remainingWithoutAll = series.filter((val) => val.visible && val.data.length > 0);
                  const countWithoutAll = series.filter((val) => val.data.length > 0);
                  if (remainingWithoutAll.length !== countWithoutAll.length) {
                    const all = series.findIndex((val) => val.name === "All");
                    this.chart.series[all].setVisible(false);
                  } else {
                    const all = series.findIndex((val) => val.name === "All");
                    this.chart.series[all].setVisible(true);
                  }
                  if (remainingWithoutAll.length === 1) {
                    const seriesY = remainingWithoutAll[0].yData;
                    const warning = remainingWithoutAll[0].options.warning;
                    const alarm = remainingWithoutAll[0].options.alarm;
                    const minY =
                      Math.min(...[...seriesY, warning, alarm]) >= 0 ? 0 : Math.min(...[...seriesY, warning, alarm]) - 40;
                    const maxY = Math.max(...[...seriesY, warning, alarm]) + 40;
                    this.chart.yAxis[0].update({
                      type: "linear",
                      visible: true,
                      title: { text: null },
                      min: minY,
                      max: maxY,
                    });
                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-warning",
                      value: warning,
                      color: "#FCA239",
                      width: 2,
                      zIndex: 5,
                      dashStyle: "ShortDash",
                    });
                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-alarm",
                      value: alarm,
                      color: "#D9263B",
                      width: 2,
                      zIndex: 5,
                      dashStyle: "ShortDash",
                    });
                  } else {
                    const seriesY = remaining.map((val) => Math.max(...val.yData));
                    const maxY = Math.max(...[...seriesY]) + 40;

                    this.chart.yAxis[0].update({
                      type: "linear",
                      visible: true,
                      min: null,
                      max: maxY,
                    });

                    this.chart.yAxis[0].removePlotLine("plot-warning");
                    this.chart.yAxis[0].removePlotLine("plot-alarm");
                  }
                }, 100);
              },
              click: function () {
                const chart = this.chart;
                const series = this.chart.series;
                const index = this.index;
                const seriesY = series[index].yData;
                const warning = series[index].options.warning;
                const alarm = series[index].options.alarm;
                const minY =
                  Math.min(...[...seriesY, warning, alarm]) >= 0 ? 0 : Math.min(...[...seriesY, warning, alarm]) - 40;
                const maxY = Math.max(...[...seriesY, warning, alarm]) + 40;
                series.forEach((val, ind) => {
                  if (ind !== index) {
                    val.setVisible(false);
                  }
                });
                setTimeout(() => {
                  this.chart.yAxis[0].update({
                    visible: true,
                    title: { text: null },
                    min: minY,
                    max: maxY,
                    type: "linear",
                  });

                  this.chart.yAxis[0].addPlotLine({
                    id: "plot-warning",
                    value: warning,
                    color: "#FCA239",
                    width: 2,
                    zIndex: 5,
                    dashStyle: "ShortDash",
                  });
                  this.chart.yAxis[0].addPlotLine({
                    id: "plot-alarm",
                    value: alarm,
                    color: "#D9263B",
                    width: 2,
                    zIndex: 5,
                    dashStyle: "ShortDash",
                  });
                }, 100);
              },
            },
          },
        },
      },
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  async created() {
    await this.fetchResultData();
  },
  methods: {
    async fetchResultData() {
      try {
        if (this.datas.length === 0) {
          this.chartLoading = false;
          throw "No Data";
        }
        this.chartLoading = true;
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        this.chartOptions.title.text = `Diagnostic`;
        this.chartOptions.xAxis = this.aAxis(this.datas);
        this.chartOptions.series = this.series(this.datas);
        this.chartOptions.yAxis = {
          min: null,
          max: null,
          plotLines: [],
          events: {
            afterSetExtremes: this.updateExtremesY,
          },
        };
        this.xAxisMaxRange = this.datas.timestamp.length;
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
        this.chartLoading = false;
      } finally {
        this.source = null;
      }
    },
    aAxis(datas: any) {
      let labels = datas.timestamp;
      const formatDate = labels.map((label) => dayjs(label).format("DD-MM-YYYY HH:mm"));
      const count = [...new Set(formatDate)].length;

      return {
        type: "category",
        labels: {
          align: "left",
          step: count <= 10 ? 0 : count <= 100 ? 1 : count <= 200 ? 2 : count <= 300 ? 3 : count <= 400 ? 4 : 5,
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: [...new Set(formatDate)],
        events: {
          afterSetExtremes: this.updateExtremes,
        },
      };
    },
    series(datas: any) {
      const customLegend = {
        name: "All",
      };
      const sers = [
        {
          name: "Rotor Bar Crack",
          data: datas?.rotor_bar_crack?.values.map((val) => (val > datas?.omr?.warning ? val : null)),
          tooltip: { valueSuffix: ` ${datas?.rotor_bar_crack?.unit}` },
          lineWidth: 2,
          warning: datas?.rotor_bar_crack?.warning,
          alarm: datas?.rotor_bar_crack?.alarm,
        },
        {
          name: "Bearing Failure",
          data: datas?.bearing_failure?.values,
          tooltip: { valueSuffix: ` ${datas?.bearing_failure?.unit}` },
          lineWidth: 2,
          warning: datas?.bearing_failure?.warning,
          alarm: datas?.bearing_failure?.alarm,
        },
        {
          name: "Eccentric Airgap",
          data: datas?.eccentric_airgap?.values,
          tooltip: { valueSuffix: ` ${datas?.eccentric_airgap?.unit}` },
          lineWidth: 2,
          warning: datas?.eccentric_airgap?.warning,
          alarm: datas?.eccentric_airgap?.alarm,
        },
        {
          name: "Ground Fault",
          data: datas?.ground_fault?.values,
          tooltip: { valueSuffix: ` ${datas?.ground_fault?.unit}` },
          lineWidth: 2,
          warning: datas?.ground_fault?.warning,
          alarm: datas?.ground_fault?.alarm,
        },
        {
          name: "Short Turn",
          data: datas?.short_turn?.values,
          tooltip: { valueSuffix: ` ${datas?.short_turn?.unit}` },
          lineWidth: 2,
          warning: datas?.short_turn?.warning,
          alarm: datas?.short_turn?.alarm,
        },
        {
          name: "Terminal Loose",
          data: datas?.terminal_loose?.values,
          tooltip: { valueSuffix: ` ${datas?.terminal_loose?.unit}` },
          lineWidth: 2,
          warning: datas?.terminal_loose?.warning,
          alarm: datas?.terminal_loose?.alarm,
        },
      ];
      const filteredPointAdd = sers.filter((series) => series.data.length > 0);
      const newsers = [customLegend].concat(filteredPointAdd);
      return newsers;
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
    updateExtremes(e) {
      // if (e.min === this.xAxisMin && e.max === this.xAxisMaxRange) {

      // } else {

      // }
      this.$emit("xchange", e);
    },
    updateExtremesY(e: any) {
      this.$emit("ychange", e);
    },
  },
  watch: {
    async datas() {
      await this.fetchResultData();
    },
    optionsaXis() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];
      xAxis.setExtremes(this.optionsaXis.minX, this.optionsaXis.maxX);
      if (!this.optionsaXis.minY) return;
      yAxis.setExtremes(this.optionsaXis.minY, this.optionsaXis.maxY);
    },
  },
});
