
import Vue from "vue";
import dayjs from "dayjs";

import FormNoteHistoryLog, { FormNoteHistoryLogPropsType } from "../forms/FormNoteHistoryLog.vue";
import BlankSpace from "@/components/BlankSpace.vue";
import DateTimeRange2 from "@/components/DateTimeRange2.vue";
import ChevronRightIcon from "@/components/icons/ChevronRightIcon.vue";
import DocIcon from "@/components/icons/DocIcon.vue";
import DeleteIcon from "@/components/icons/DeleteIcon.vue";
import EditIcon from "@/components/icons/EditIcon.vue";
import SaveIcon from "@/components/icons/SaveIcon.vue";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "NoteHistoryLog",
  components: {
    FormNoteHistoryLog,
    BlankSpace,
    DocIcon,
    ChevronRightIcon,
    DeleteIcon,
    EditIcon,
    SaveIcon,
    Spinner,
    DateTimeRange2,
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    model() {
      return this.$store.getters.model;
    },
  },
  data() {
    const initialValues: FormNoteHistoryLogPropsType = {
      topic: "",
      description: "",
      isUpdating: false,
      isShowModal: false,
    };

    return {
      dialog: false,
      initialValues,
      startDate: dayjs().format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs().format("YYYY-MM-DDT23:59:59"),
      message: "",
      items: [],
      item: null,
      isLoadingNote: true,
      isLoadingComment: true,
    };
  },
  async created() {
    await this.fetchNoteHistoryLog();
  },
  methods: {
    async fetchNoteHistoryLog() {
      try {
        this.isLoadingNote = true;
        const response = await this.$dep.modelUseCase.getNoteHistoryLogAll(
          this.model.id,
          this.model.equipmentId,
          this.startDate,
          this.endDate
        );

        this.items = response;
      } catch (error) {
        this.items = [];
      } finally {
        this.isLoadingNote = false;
      }
    },
    async fetchNoteHistoryLogDetail(noteId: number) {
      try {
        this.isLoadingComment = true;
        const response = await this.$dep.modelUseCase.getNoteHistoryLogComment(noteId);
        this.item = {
          ...response,
          comment: response.comment.map((res) => ({ ...res, isEdit: false })),
        };
      } catch (error) {
        this.item = null;
      } finally {
        this.isLoadingComment = false;
      }
    },
    async onChangeDate(ev: any) {
      this.startDate = ev.startDate;
      this.endDate = ev.endDate;

      await this.fetchNoteHistoryLog();
      this.item = null;
    },
    async onSelectItem(item: any) {
      this.message = "";
      await this.fetchNoteHistoryLogDetail(item.id);
    },
    async createNote(param: any) {
      try {
        this.dialog = false;
        this.isLoadingNote = true;
        await this.$dep.modelUseCase.createNoteHistoryLog(this.model.equipmentId, {
          topic: param.topic,
          description: param.description,
          equipmentModelId: this.model.id,
        });
        await this.fetchNoteHistoryLog();
      } catch (error) {
        //
      }
    },
    async updateNote(noteId, param: any) {
      try {
        this.dialog = false;
        this.isLoadingNote = true;
        await this.$dep.modelUseCase.updateNoteHistoryLog(noteId, {
          topic: param.topic,
          description: param.description,
          equipmentModelId: this.model.id,
        });
        await this.fetchNoteHistoryLog();
        await this.fetchNoteHistoryLogDetail(noteId);
      } catch (error) {
        //
      }
    },
    async deleteNote(noteId: number) {
      try {
        this.isLoadingNote = true;
        await this.$dep.modelUseCase.deleteNoteHistoryLog(noteId);
        await this.fetchNoteHistoryLog();
        this.item = null;
      } catch (error) {
        //
      }
    },
    beforeCreateNote() {
      this.initialValues = {
        topic: "",
        description: "",
        isUpdating: false,
        isShowModal: false,
      };
    },
    beforeEditNote(data: any) {
      this.initialValues = {
        topic: data.topic,
        description: data.description,
        isUpdating: true,
        isShowModal: false,
      };
    },
    async sendMessage() {
      if (!this.message) return;
      try {
        this.isLoadingComment = true;

        await this.$dep.modelUseCase.sendMessageNoteHistoryLog(this.model.equipmentId, {
          description: this.message,
          equipmentModelId: this.model.id,
          noteTopicId: this.item?.topic?.id,
        });
        this.message = "";
        await this.fetchNoteHistoryLogDetail(this.item.topic.id);
        this.$refs.noteHistoryLogComment.scrollTo({
          top: this.$refs.noteHistoryLogComment.scrollHeight,
          behavior: "smooth",
        });
      } catch (error) {
        //
      }
    },
    async updateMessage(data: any) {
      try {
        const index = this.item.comment.findIndex((item) => item.id === data.id);
        this.item.comment[index].isEdit = false;
        this.item.comment[index].updatedDate = dayjs().subtract(7, "hour");

        await this.$dep.modelUseCase.updateMessageNoteHistoryLog(data.id, {
          description: data.description,
          equipmentModelId: this.model.id,
          noteTopicId: this.item?.topic?.id,
        });
      } catch (error) {
        //
      }
    },
    onEditComment(commentId: number) {
      const index = this.item.comment.findIndex((item) => item.id === commentId);
      this.item.comment[index].isEdit = true;
    },
    async onDeleteComment(commentId: number) {
      try {
        this.isLoadingComment = true;

        await this.$dep.modelUseCase.deleteMessageNoteHistoryLog(commentId);
        await this.fetchNoteHistoryLogDetail(this.item.topic.id);

        this.$refs.noteHistoryLogComment.scrollTo({
          top: this.$refs.noteHistoryLogComment.scrollHeight,
          behavior: "smooth",
        });
      } catch (error) {
        //
      }
    },
    formatDate(date: any, format = "DD MMM YYYY, HH:mm") {
      return dayjs(date).add(7, "hour").format(format);
    },
  },
});
