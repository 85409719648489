
import Vue from "vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import boostInit from "highcharts/modules/boost";
import { Chart } from "highcharts-vue";
import XLSX from "xlsx";
import { required } from "vuelidate/lib/validators";

import NoDataRVS from "@/components/NoDataRVS.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";

boostInit(Highcharts);
stockInit(Highcharts);

export default Vue.extend({
  name: "RvsTimeWaveFormGraph",
  components: {
    highcharts: Chart,
    NoDataRVS,
    LoadingGraph,
  },
  validations: {
    isSensor: { required },
    isFileName: { required },
    validationGroup: ["isSensor", "isFileName"],
  },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
    getRotationalSpeed() {
      const model = this.$store.state.model.equipmentModelConfiguration.find(
        (v) => v.modelTypeConfiguration.title === "Rotational speed"
      );

      return model?.value || 1;
    },
  },
  data() {
    return {
      maxLength: 3,
      xAxisType: "time",
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      sensorGroup: [],
      isSensor: null,
      isLoadingSensorGroup: false,
      phaseGroup: [
        {
          id: 1,
          phaseName: "Acceleration",
          phaseValue: "acceleration",
          phaseUnit: "[m/s\u00B2]",
          phaseLabel: "RMS Acceleration [m/s\u00B2]",
        },
        {
          id: 2,
          phaseName: "AccelerationG",
          phaseValue: "accelerationG",
          phaseUnit: "[g]",
          phaseLabel: "RMS Acceleration [g]",
        },
      ],
      isPhase: "acceleration",
      axisGroup: [
        { id: 1, axisName: "H", axisValue: "x" },
        { id: 2, axisName: "V", axisValue: "y" },
        { id: 3, axisName: "A", axisValue: "z" },
      ],
      isAxis: "x",
      fileNameGroup: [],
      isFileName: [],
      isLoadingFileNameGroup: false,
      graphResultData: [],
      graphLoading: false,
      calPoint: [],
      chartOptions: {
        boost: {
          useGPUTranslations: true,
        },
        title: { text: "Time Waveform" },
        subtitle: { text: "", useHTML: true, style: { fontSize: "14px" } },
        chart: {
          type: "line",
          zoomType: "xy",
          height: 500,
          animation: false,
          style: { fontSize: "14px" },
          events: {
            click: (e: any) => (this as any).addNotePoint(e, "area"),
          },
        },
        plotOptions: {
          series: {
            animation: false,
            point: {
              events: {
                click: (e: any) => (this as any).addNotePoint(e, "point"),
              },
            },
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          split: false,
          shared: false,
          style: { fontSize: "14px", padding: "0px" },
          borderWidth: 0,
          shadow: false,
          positioner: function (labelWidth) {
            const x = this.chart.plotWidth - labelWidth;
            const y = 10;
            return { x, y };
          },
          formatter: function (e: any) {
            const formatter = new Intl.NumberFormat("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });

            return `<div style='border: 2px solid black; padding: 5px'>
                  <p><span style='color: ${this.color};'>●</span> ${this.series.name}</span></p>
                  <p>${e.chart.options.xAxis[0].title.text} : ${formatter.format(this.x)}</p>
                  <p>${e.chart.options.yAxis[0].title.text} : ${formatter.format(this.y)}</p>
                  </div>`;
          },
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          itemStyle: { fontSize: "14px", fontWeight: "300" },
        },
        yAxis: { title: { text: "" }, labels: { style: { fontSize: "14px" } }, opposite: false },
        xAxis: {
          title: { text: "Time [Seconds]" },
          type: "linear",
          categories: [],
          ordinal: true,
          labels: {
            style: { fontSize: "14px" },
            formatter: function () {
              return this.value;
            },
          },
          min: undefined,
          max: undefined,
          plotBands: [],
          plotLines: [],
        },
        series: [],
        scrollbar: { liveRedraw: false },
        rangeSelector: { enabled: false, selected: 0 },
        navigator: {
          series: [],
          adaptToUpdatedData: false,
          yAxis: { opposite: false },
          xAxis: {
            type: "linear",
            categories: [],
            ordinal: true,
            labels: {
              style: { fontSize: "10px" },
              formatter: function () {
                return "";
              },
            },
            min: undefined,
            max: undefined,
          },
        },
        credits: { enabled: false },
      },
      excelLoading: false,
    };
  },
  async created() {
    await this.fetchSensorGroup();
  },
  methods: {
    async fetchGraphData() {
      try {
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;

        const param = {
          sensor: this.isSensor,
          phase: this.isPhase === "accelerationG" ? "acceleration" : this.isPhase,
          axis: this.isAxis,
          fileNames: this.isFileName.map((val: any) => val.fileName),
        };
        const data = await (this as any).$dep.modelUseCase.getRvsGraphResultTimeWaveFormData(param);

        const graphData =
          this.isPhase === "accelerationG"
            ? {
                ...data,
                series: data.series.map((serie: any) => ({
                  ...serie,
                  data: serie.data.map((s: any) => [s[0], s[1] / 9.81]),
                })),
              }
            : data;

        this.graphResultData = graphData.series;

        const xAxisMin = 0;
        const xAxisMax = Math.max(...graphData.series.map((item) => item.data[item.data.length - 1][0]));
        const numOfItems = 10;
        const tickPositions = Array.from({ length: numOfItems }, (_, i) =>
          Number((xAxisMin + (i * (xAxisMax - xAxisMin)) / (numOfItems - 1)).toFixed(2))
        );

        const phaseText = (serie) => `${this.phaseGroup.find((p) => p.phaseValue === serie.phase).phaseName}`;
        const phaseUnit = (serie) => `${this.phaseGroup.find((p) => p.phaseValue === serie.phase).phaseUnit}`;

        const series = graphData.series.map((serie: any) => ({
          ...serie,
          name: `${serie.sensor_id} ${phaseText(serie)} ${
            this.axisGroup.find((a) => a.axisValue === serie.axis).axisName
          }-axis ${phaseUnit(serie)}`,
          dataGrouping: { enabled: false },
          lineWidth: 2,
          boostThreshold: 1,
          turboThreshold: 1,
          showInNavigator: false,
          opacity: 0.1,
        }));

        this.xAxisMaxRange = xAxisMax;
        this.chartOptions.yAxis.title.text = this.phaseGroup.find((val) => val.phaseValue === this.isPhase).phaseLabel || "";
        this.chartOptions.xAxis = {
          ...this.chartOptions.xAxis,
          title: { text: this.xAxisType === "revolution" ? "Revolution [REV]" : "Time [seconds]" },
          tickInterval: tickPositions.length / 10,
          tickPositions,
        };
        this.chartOptions.series = series;

        this.chartOptions.navigator.series = series;
        this.chartOptions.navigator.xAxis = {
          ...this.chartOptions.navigator.xAxis,
          tickInterval: tickPositions.length / 10,
          tickPositions,
        };
        this.tooltipFormat();
      } catch (error) {
        this.graphResultData = [];
      }
    },
    async fetchSensorGroup() {
      try {
        this.isLoadingSensorGroup = true;

        const sensorGroup = await (this as any).$dep.modelUseCase.getRvsSensorType(this.getModelId);
        const sensorPoint = await (this as any).$dep.modelUseCase.getSensorPoints(this.getModelCode);

        this.sensorGroup = sensorGroup;
        this.sensorPointGroup = sensorPoint;
      } catch (error) {
        this.sensorGroup = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },
    async fetchFileNameGroup() {
      try {
        this.isLoadingFileNameGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsGraphResultSpectrumFileName({
          modelCode: this.getModelCode,
          sensor: this.isSensor.sensorId,
          phase: this.isPhase === "accelerationG" ? "acceleration" : this.isPhase,
          axis: this.isAxis,
        });

        this.fileNameGroup = datas;
      } catch (error) {
        this.fileNameGroup = [];
      } finally {
        this.isLoadingFileNameGroup = false;
      }
    },
    async onSubmit() {
      this.graphResultData = [];
      this.calPoint = [];
      this.chartOptions.subtitle.text = "";
      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        plotBands: [],
        plotLines: [],
      };
      this.xAxisType = "time";

      this.graphLoading = true;
      await this.fetchGraphData();
      this.graphLoading = false;
    },
    async onChangeSensorType(val: any) {
      this.isSensor = {
        id: val.id,
        sensorId: val.sensorId,
        sensorName: val.sensorName,
      };
      await this.fetchAxis(val.sensorId);
      await this.fetchFileNameGroup();
    },
    async fetchAxis(sensorId: string) {
      const res = await (this as any).$dep.modelUseCase.getRvsAxis(sensorId);
      this.axisGroup = [
        { id: 1, axisName: res.x, axisValue: "x" },
        { id: 2, axisName: res.y, axisValue: "y" },
        { id: 3, axisName: res.z, axisValue: "z" },
      ];
    },
    onChangePhase(value: string) {
      this.isPhase = value;
    },
    onChangeAxis(value: string) {
      this.isAxis = value;
    },
    onChangeFileName(values: any[]) {
      this.isFileName = values.map((val: any) => ({
        fileName: val.fileName,
        alias: val.alias,
      }));
    },
    async reloadData() {
      this.graphResultData = [];

      this.graphLoading = true;
      await this.fetchGraphData();
      this.graphLoading = false;
    },
    addNotePoint(e: any, type: string) {
      if (e.type === "click" && e.srcElement.innerHTML === "") {
        const x = type === "point" ? e.point.options.x : e.xAxis[0].value;
        const y = type === "point" ? e.point.options.y : e.yAxis[0].value;

        this.calPoint.push({ x, y });

        if (this.calPoint.length === 1) {
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            plotLines: [
              {
                color: "#BDBB7F",
                dashStyle: "ShortDash",
                value: x,
                width: 2,
              },
            ],
          };
        }

        if (this.calPoint.length === 2) {
          const left = this.calPoint.reduce((res, obj) => (obj.x < res.x ? obj : res));
          const right = this.calPoint.reduce((res, obj) => (obj.x > res.x ? obj : res));

          const xLeft = left.x.toFixed(2);
          const yLeft = left.y.toFixed(2);
          const xRight = right.x.toFixed(2);
          const yRight = right.y.toFixed(2);
          const f1 = (1 / (right.x - left.x)).toFixed(2);
          const f2 = ((1 / (right.x - left.x)) * 60).toFixed(2);
          const delta = (right.x - left.x).toFixed(2);
          const xAxisType = this.xAxisType === "revolution" ? "Revolution" : "Time";
          const xAxisTypeUnit = this.xAxisType === "revolution" ? "[REV]" : "[Seconds]";

          this.chartOptions.chart.height = 600;
          this.chartOptions.subtitle.text = `<div class='flex flex-col'>
            <div class='grid grid-rows-2'>
              <div class='flex items-center gap-x-[20px]'>
                <div><strong>Left</strong> = Amplitude: ${yLeft}, ${xAxisType}: ${xLeft},</div>
                <div><strong>Right</strong> = Amplitude: ${yRight}, ${xAxisType}: ${xRight},</div>
                <div><strong>ΔT</strong> = ${delta} ${xAxisTypeUnit}</div>
              </div>
              <div>
                <strong>Frequency</strong> =  ${f1} Hz. , ${f2} CPM
              </div>
            </div>
          </div>`;
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            plotBands: [
              {
                color: "#F5F3C1",
                from: left.x,
                to: right.x,
              },
            ],
          };
          this.chartOptions.xAxis.plotLines.push({
            color: "#BDBB7F",
            dashStyle: "ShortDash",
            value: right.x,
            width: 2,
          });
        }
      }
    },
    onChangeXAxisType(val: string) {
      this.graphLoading = true;

      this.clearFilter();

      const hz = this.getRotationalSpeed / 60;
      const datas = this.graphResultData;

      this.chartOptions.series = this.chartOptions.series.map((serie: any, index: number) => ({
        ...serie,
        data: val === "revolution" ? serie.data.map((s: any) => [s[0] * hz, s[1]]) : datas[index].data,
      }));
      this.chartOptions.navigator.series = this.chartOptions.navigator.series.map((serie: any, index: number) => ({
        ...serie,
        data: val === "revolution" ? serie.data.map((s: any) => [s[0] * hz, s[1]]) : datas[index].data,
        lineWidth: 0,
      }));

      const xAxisMin = Math.min(...this.chartOptions.series.map((item) => item.data[0][0]));
      const xAxisMax = Math.max(...this.chartOptions.series.map((item) => item.data[item.data.length - 1][0]));
      const numOfItems = 10;
      const tickPositions = Array.from({ length: numOfItems }, (_, i) =>
        Number((xAxisMin + (i * (xAxisMax - xAxisMin)) / (numOfItems - 1)).toFixed(2))
      );

      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        title: { text: val === "revolution" ? "Revolution [REV]" : "Time [seconds]" },
        tickInterval: tickPositions.length / 10,
        tickPositions,
      };
      this.chartOptions.navigator.xAxis = {
        ...this.chartOptions.navigator.xAxis,
        tickInterval: tickPositions.length / 10,
        tickPositions,
      };
      this.tooltipFormat();

      this.graphLoading = false;
    },
    tooltipFormat() {
      this.chartOptions.tooltip = {
        ...this.chartOptions.tooltip,
        formatter: function (e: any) {
          const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          const xAxisText = e.chart?.options?.xAxis["title"]?.text || e.chart?.options?.xAxis[0]?.title?.text;
          const yAxisText = e.chart?.options?.yAxis["title"]?.text || e.chart?.options?.yAxis[0]?.title?.text;

          return `<div>
                  <p><span style='color: ${this.color};'>●</span> ${this.series.name}</span></p>
                  <p>${xAxisText || "x"} : ${formatter.format(this.x)}</p>
                  <p>${yAxisText || "y"} : ${formatter.format(this.y)}</p>
                  </div>`;
        },
      };
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = parseFloat(this.xAxisMin) > this.xAxisMaxRange ? this.xAxisMaxRange : this.xAxisMin;
      this.xAxisMax = parseFloat(this.xAxisMax) > this.xAxisMaxRange ? this.xAxisMaxRange : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      if (this.calPoint.length) {
        this.calPoint = [];
        this.chartOptions.chart.height = 500;
        this.chartOptions.subtitle.text = "";
        this.chartOptions.xAxis = {
          ...this.chartOptions.xAxis,
          plotBands: [],
          plotLines: [],
        };
      }

      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
    exportExcel() {
      this.excelLoading = true;
      const name = `rvs_time_wave_form_model_${this.getModelId}`.toUpperCase();
      const datas = this.graphResultData;

      const result = datas.map((item, index) => {
        return {
          sname: `${item.sensor_id} ${item.phase} ${item.axis} ${index + 1}`,
          data: item.data.map((dataItem) => ({
            x: dataItem[0],
            y: dataItem[1],
          })),
        };
      });

      const wb = XLSX.utils.book_new();

      for (let i = 0; i < result.length; i++) {
        const { sname, data } = result[i];
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, sname);
      }

      XLSX.writeFile(wb, `${name}.xlsx`);

      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },
    limiter(val: any[]) {
      if (val.length > this.maxLength) val.shift();
    },
    clearRefresh() {
      if (this.calPoint.length) {
        this.calPoint = [];
        this.chartOptions.subtitle.text = "";
        this.chartOptions.xAxis = {
          ...this.chartOptions.xAxis,
          plotBands: [],
          plotLines: [],
        };
      }
    },
  },
  watch: {
    async getModelId() {
      this.sensorGroup = [];
      this.isSensor = null;
      this.fileNameGroup = [];
      this.isFileName = [];
      this.graphResultData = [];
      this.tableBodyData = [];

      await this.fetchSensorGroup();
    },
    getRotationalSpeed() {
      if (this.xAxisType === "revolution") {
        this.onChangeXAxisType("revolution");
      }
    },
  },
});
