
import Vue from "vue";
import dayjs from "dayjs";
import { AzureMap, AzureMapDataSource, AzureMapPoint, AzureMapSymbolLayer } from "vue-azure-maps";
import { required, alphaNum } from "vuelidate/lib/validators";
import UploadArrowIcon from "@/components/icons/UploadArrowIcon.vue";
import FolderIcon from "@/components/icons/FolderIcon.vue";

export default Vue.extend({
  name: "CreateEquipmentPage",
  components: { UploadArrowIcon, FolderIcon },
  // components: {
  //   AzureMap,
  //   AzureMapDataSource,
  //   AzureMapPoint,
  //   AzureMapSymbolLayer,
  // },
  props: {
    validations: {
      type: Object,
    },
  },
  validations: {
    itemTitle: { required, alphaNum },
    itemAliasName: { required },
    itemGroupType: { required },
    validationGroup: ["itemTitle", "itemAliasName", "itemGroupType"],
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.$store.state.openEquipmentFormModal.open;
      },
      set(value: boolean) {
        this.$store.dispatch("openEquipmentFormModal", {
          ...this.$store.state.openEquipmentFormModal,
          open: value,
        });
      },
    },
    contextMenuStructure() {
      return this.$store.state.contextMenuStructure;
    },
    actionType() {
      return this.$store.state.openEquipmentFormModal.type;
    },
    itemAliasName: {
      get(): string {
        return (this as any).$store.state.equipmentTagCreateForm.structureItemAlias;
      },
      set(structureItemAlias: string) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.state.equipmentTagCreateForm,
          structureItemAlias,
        });
      },
    },
    itemTitle: {
      get(): string {
        return (this as any).$store.state.equipmentTagCreateForm.structureItemTitle;
      },
      set(structureItemTitle: string) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.state.equipmentTagCreateForm,
          structureItemTitle,
        });
      },
    },
    itemGroupType: {
      get(): string {
        return (this as any).$store.state.equipmentTagCreateForm.itemGroupType;
      },
      set(itemGroupType: string) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.state.equipmentTagCreateForm,
          itemGroupType,
        });
      },
    },
    position: {
      get(): Record<string, unknown> {
        return (this as any).$store.state.equipmentTagCreateForm.position;
      },
      set(position: Record<string, unknown>) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.state.equipmentTagCreateForm,
          position,
        });
      },
    },
    images: {
      get(): Record<string, unknown> {
        return (this as any).$store.state.equipmentTagCreateForm.images;
      },
      set(images: Record<string, unknown>) {
        (this as any).$store.dispatch("setEquipmentCreateForm", {
          ...(this as any).$store.state.equipmentTagCreateForm,
          images,
        });
      },
    },
    itemTitleError() {
      return (this as any).validations.structureItemTitle.$error ? "Equipment Tag Name is required." : "";
    },
    itemGroupTypeError() {
      return (this as any).validations.itemGroupType.$error ? "Equipment Group Type is required." : "";
    },
    positionError() {
      return (this as any).validations.position.$error ? "Location is required." : "";
    },
  },
  data() {
    const map: any = null;
    const isLoading = true;
    const iconType = "";

    const equipmentType = [
      {
        text: "Electric",
        value: "electric",
      },
      {
        text: "Rotating",
        value: "rotating",
      },
      {
        text: "Static",
        value: "static",
      },
      {
        text: "Instrument",
        value: "instrument",
      },
      {
        text: "Process",
        value: "process",
      },
    ];

    const emailTaskSchedulerNotification = {
      daily: {
        id: 0,
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
      weekly: {
        id: 0,
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
      monthly: {
        id: 0,
        isActive: false,
        isWarning: false,
        isAlarm: false,
        timedate: null,
      },
    };

    return {
      isLoading,
      map,
      iconType,
      equipmentType,
      emailTaskSchedulerNotification,
      iconElectric: {
        url: require("@/assets/icons/iconMarker/Electric/ElectricNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      iconStatic: {
        url: require("@/assets/icons/iconMarker/Static/StaticNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      iconRotating: {
        url: require("@/assets/icons/iconMarker/Rotating/RotatingNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      iconProcess: {
        url: require("@/assets/icons/iconMarker/Process/ProcessNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      iconInstrument: {
        url: require("@/assets/icons/iconMarker/Instrument/InstrumentNotrun.svg"),
        scaledSize: {
          width: 50,
          height: 50,
        },
      },
      imageDelete: [],
      vm: {
        searchPlace: "",
        location: {},
      },
    };
  },
  created() {
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  },
  methods: {
    onSubmit() {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;
      if (daily.isActive) {
        if (!daily.isWarning && !daily.isAlarm) {
          return alert('Error", "ที่ Daily กรุณาเลือก Warning หรือ Alarm');
        }
      }
      if (weekly.isActive) {
        if (!weekly.isWarning && !weekly.isAlarm) {
          return alert('Error", "ที่ Weekly กรุณาเลือก Warning หรือ Alarm');
        }
      }
      if (monthly.isActive) {
        if (!monthly.isWarning && !monthly.isAlarm) {
          return alert('Error", "ที่ Monthly กรุณาเลือก Warning หรือ Alarm');
        }
      }

      this.$emit("onSubmit", {
        ...this.$store.state.equipmentTagCreateForm,
        action_type: this.actionType,
        parentId: this.contextMenuStructure.id,
        emailTaskScheduler: this.emailTaskSchedulerNotification,
        imageDelete: this.imageDelete,
      });

      this.dialog = false;
    },
    async setMap(mapEvent: any) {
      this.map = mapEvent.map;
      await this.addSprite("electric", this.iconElectric);
      await this.addSprite("static", this.iconStatic);
      await this.addSprite("rotating", this.iconRotating);
      await this.addSprite("process", this.iconProcess);
      await this.addSprite("instrument", this.iconInstrument);
    },
    addSprite(type: string, address: any) {
      if (!this.map) return;
      return this.map.imageSprite.add(type, address);
    },
    changeIcon(equipmentGroupType: string) {
      return {
        iconOptions: {
          size: 0.5,
          image: equipmentGroupType,
        },
      };
    },
    getCurrentPosition(e: any): void {
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        position: {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      });
    },
    equipmentTypeSelect(equipment: string) {
      switch (equipment) {
        case "electric":
          this.iconType = this.iconElectric;
          break;
        case "rotating":
          this.iconType = this.iconRotating;
          break;
        case "static":
          this.iconType = this.iconStatic;
          break;
        case "instrument":
          this.iconType = this.iconInstrument;
          break;
        case "process":
          this.iconType = this.iconProcess;
          break;
      }
    },
    checkBoxEmailTaskSchedulerAll(type: string) {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;
      const now: any = dayjs().format("HH:mm").toString();

      if (type === "daily") {
        this.emailTaskSchedulerNotification.daily.isWarning = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.isAlarm = daily.isActive ? true : false;
        this.emailTaskSchedulerNotification.daily.timedate = daily.isActive ? now : null;
      }
      if (type === "weekly") {
        this.emailTaskSchedulerNotification.weekly.isWarning = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = weekly.isActive ? true : false;
        this.emailTaskSchedulerNotification.weekly.timedate = weekly.isActive ? now : null;
      }
      if (type === "monthly") {
        this.emailTaskSchedulerNotification.monthly.isWarning = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = monthly.isActive ? true : false;
        this.emailTaskSchedulerNotification.monthly.timedate = monthly.isActive ? now : null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    checkBoxEmailTaskScheduler() {
      const { daily, weekly, monthly } = this.emailTaskSchedulerNotification;

      if (!daily.isActive) {
        this.emailTaskSchedulerNotification.daily.isWarning = false;
        this.emailTaskSchedulerNotification.daily.isAlarm = false;
        this.emailTaskSchedulerNotification.daily.timedate = null;
      }
      if (!weekly.isActive) {
        this.emailTaskSchedulerNotification.weekly.isWarning = false;
        this.emailTaskSchedulerNotification.weekly.isAlarm = false;
        this.emailTaskSchedulerNotification.weekly.timedate = null;
      }
      if (!monthly.isActive) {
        this.emailTaskSchedulerNotification.monthly.isWarning = false;
        this.emailTaskSchedulerNotification.monthly.isAlarm = false;
        this.emailTaskSchedulerNotification.monthly.timedate = null;
      }

      const emailTaskScheduler = this.emailTaskSchedulerNotification;
      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    timeEmailTaskScheduler() {
      const emailTaskScheduler = this.emailTaskSchedulerNotification;

      (this as any).$store.dispatch("setEquipmentCreateForm", {
        ...(this as any).$store.state.equipmentTagCreateForm,
        emailTaskScheduler,
      });
    },
    onUpload(e) {
      const file = e.target.files;
      file.forEach((fl) => {
        const url = URL.createObjectURL(fl);
        const imageDetail = fl;
        const images = this.images ? this.images : [];
        images.push({ url, image_name: imageDetail.name, image_size: imageDetail.size, imageUpload: imageDetail });
        this.images = images;
      });
    },
    onDeleteImage(index: number) {
      const images = this.images ? this.images : [];
      if (images[index].id) {
        this.imageDelete.push(images[index].id);
      }
      images.splice(index, 1);
      this.images = images;
    },
    onDrop(event: any) {
      event.preventDefault();
      const file = event.dataTransfer.files;

      file.forEach((fl) => {
        const url = URL.createObjectURL(fl);
        const imageDetail = fl;
        const images = this.images ? this.images : [];
        images.push({ url, image_name: imageDetail.name, image_size: imageDetail.size, imageUpload: imageDetail });
        this.images = images;
      });
      // this.onUpload(event);

      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    dragover(event: any) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-[#EAEEF6]")) {
        event.currentTarget.classList.remove("bg-[#FFFFFF]");
        event.currentTarget.classList.add("bg-[#EAEEF6]");
      }
    },
    dragleave(event: any) {
      event.currentTarget.classList.add("bg-[#FFFFFF]");
      event.currentTarget.classList.remove("bg-[#EAEEF6]");
    },
    onChange() {
      (this as any).$refs.file.click();
    },
    truncateImageName(name: string) {
      const length = 50;
      return name.substring(0, length);
    },
  },
  watch: {
    "vm.location": {
      handler(newValue, oldValue) {
        try {
          let lat = this.vm?.location?.geometry.location.lat();
          let lng = this.vm?.location?.geometry.location.lng();
          this.position = {
            lat,
            lng,
          };
          this.$refs.Map.panTo({ lat, lng });
          this.vm.searchPlace = this.vm?.location?.name;
        } catch {
          console.log("error");
        }
      },
      deep: true,
    },
    dialog() {
      if (this.dialog) {
        this.imageDelete = [];
        if (this.contextMenuStructure.status === "Create") {
          (this as any).$store.dispatch("setEquipmentCreateForm", {
            parentId: this.contextMenuStructure.id,
            structureItemTitle: "",
            structureItemAlias: "",
            itemGroupType: "",
            position: { lat: 0, lng: 0 },
            isPrimaryFolder: false,
            images: [],
            emailTaskScheduler: {
              daily: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
              weekly: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
              monthly: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
            },
          });
        }

        if (this.contextMenuStructure.status === "Edit") {
          const { structureItemTitle, structureItemAlias, equipmentGroupType, posLat, posLng, images } =
            this.$store.state.openEquipmentFormModal.param;
          (this as any).$store.dispatch("setEquipmentCreateForm", {
            parentId: this.contextMenuStructure.id,
            structureItemTitle,
            structureItemAlias,
            itemGroupType: equipmentGroupType,
            position: { lat: posLat, lng: posLng },
            isPrimaryFolder: false,
            images: images ? images : [],
            emailTaskScheduler: {
              daily: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
              weekly: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
              monthly: {
                isActive: false,
                isWarning: false,
                isAlarm: false,
                timedate: null,
              },
            },
          });

          this.equipmentTypeSelect(equipmentGroupType);
        }
      }
    },
  },
});
