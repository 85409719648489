
import Vue from "vue";

export default Vue.extend({
  name: "BlankSpace",
  props: {
    height: {
      type: Number,
      default: 250, // Default height is set to 250px
    },
  },
});
