
import Vue from "vue";
import Spinner from "@/components/Spinner.vue";
import colorStatus from "@/common/mixins/colorStatus";

export default Vue.extend({
  name: "DiagramMca",
  mixins: [colorStatus],
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    modelId() {
      return this.$store.getters.model.id;
    },
    companyId() {
      return this.$store.getters.company.id;
    },
    items() {
      return this.$store.state.modelViewData.mca_diagram_status;
    },
    airgap() {
      return this.items.airgapStatus !== undefined && this.items.airgapStatus !== null ? this.items.airgapStatus : 3;
    },
    bearing() {
      return this.items.bearingStatus !== undefined && this.items.bearingStatus !== null ? this.items.bearingStatus : 3;
    },
    overAll() {
      return this.items.overAllStatus !== undefined && this.items.overAllStatus !== null ? this.items.overAllStatus : 3;
    },
    rotor() {
      return this.items.rotorStatus !== undefined && this.items.rotorStatus !== null ? this.items.rotorStatus : 3;
    },
    stator() {
      return this.items.statorStatus !== undefined && this.items.statorStatus !== null ? this.items.statorStatus : 3;
    },
    terminalBox() {
      return this.items.terminalBoxStatus !== undefined && this.items.terminalBoxStatus !== null
        ? this.items.terminalBoxStatus
        : 3;
    },
  },
  methods: {
    statusColor(status: number) {
      if (status === 0) return "txt-normal";
      if (status === 1) return "txt-warning";
      if (status === 2) return "txt-alarm";
      if (status === 3) return "txt-offline";
      else return "txt-offline";
    },
    statusText(status: number) {
      if (status === 0) return "Normal";
      if (status === 1) return "Warning";
      if (status === 2) return "Alarm";
      if (status === 3) return "Offline";
      else return "Offline";
    },
  },
});
