
import Vue from "vue";
export default Vue.extend({
  props: {
    validations: {
      type: Object,
    },
  },
  computed: {
    itemTitle: {
      get(): string {
        return (this as any).$store.getters.folderCreateForm.structureItemTitle;
      },
      set(structureItemTitle: string) {
        (this as any).$store.dispatch("setFolderCreateForm", {
          ...(this as any).$store.getters.folderCreateForm,
          structureItemTitle,
        });
      },
    },
    itemTitleError() {
      return (this as any).validations.structureItemTitle.$error ? "Folder Name is required." : "";
    },
  },
});
