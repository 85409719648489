
import Vue from "vue";
import CompanyInfoView from "@/features_old/company/components/CompanyInfoView.vue";
import FolderSideMenuPage from "@/features_old/folder/pages/FolderSideMenuPage.vue";

import dialog from "../mixins/dialog";

export default Vue.extend({
  mixins: [dialog],
  components: {
    CompanyInfoView,
    FolderSideMenuPage,
  },
  data() {
    const drawer = true;
    const isLoading = true;
    const menus = [
      {
        title: "Invite Member",
        icon: "fas fa-user",
        path: "MemberViewPage",
      },
    ];

    return {
      drawer,
      isLoading,
      menus,
    };
  },
  async created() {
    await this.$store.dispatch("setCompanyInfos");
  },
  computed: {
    companyInfos() {
      return (this as any).$store.getters.companyInfos;
    },
    hasInviteMemberToCompanyPermission() {
      const role = this.$store.getters.getCurrentRole;
      return ["ADMIN"].includes(role) ? true : false;
    },
    isShowMemberMenu() {
      return (this as any).hasInviteMemberToCompanyPermission ? "nav-menu" : "nav-menu-no-member";
    },
  },
  methods: {
    goToPage(name: string) {
      this.$router.push({ name });
    },
  },
});
