
import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",
  props: {
    date: String,
    typeDate: String,
    dateLimit: String,
  },
  data() {
    const dateFormatted: string | null = "";
    return {
      dateFormatted,
      dateCopy: this.date,
      menu1: false,
      limitCopy: this.dateLimit,
    };
  },
  created() {
    this.dateCopy = this.date;
    (this as any).dateFormatted = this.formatDate(this.dateCopy);
  },
  watch: {
    date(val) {
      this.dateCopy = val;
      (this as any).dateFormatted = this.formatDate(this.dateCopy);
    },
  },
  methods: {
    formatDate(date: any) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date: any) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    updateDate() {
      this.menu1 = false;
      this.$emit("update:date", this.dateCopy);
    },
    capitalizeFirstLetter: (str: any) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },
  },
});
