
import Vue from "vue";
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import { required } from "vuelidate/lib/validators";

import { Chart } from "highcharts-vue";
import NoDataRVS from "@/components/NoDataRVS.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import dayjs from "dayjs";
import DateTimeRange from "@/components/DateTimeRange.vue";

stockInit(Highcharts);

export default Vue.extend({
  name: "RvsFailureModeGraph",
  components: {
    highcharts: Chart,
    NoDataRVS,
    LoadingGraph,
    DateTimeRange,
  },
  validations: {
    isSensor: { required },
    validationGroup: ["isSensor"],
  },
  computed: {
    getModelCode() {
      return this.$store.getters.model?.modelCode;
    },
    getModelId() {
      return this.$store.getters.model?.id;
    },
  },
  data() {
    const date = new Date();
    const itemsPerPageData: { label: string; value: number }[] = [
      { label: "5", value: 5 },
      { label: "10", value: 10 },
      { label: "15", value: 15 },
      { label: "All", value: -1 },
    ];

    return {
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      maxLength: 3,
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      sensorGroup: [],
      isSensor: null,
      isLoadingSensorGroup: false,
      phaseGroup: [
        {
          id: 1,
          phaseName: "Acceleration",
          phaseValue: "acceleration",
          phaseUnit: "[m/s\u00B2]",
          phaseLabel: "RMS Acceleration [m/s\u00B2]",
        },
        {
          id: 2,
          phaseName: "AccelerationG",
          phaseValue: "accelerationG",
          phaseUnit: "[g]",
          phaseLabel: "RMS Acceleration [g]",
        },
        {
          id: 3,
          phaseName: "Velocity",
          phaseValue: "velocity",
          phaseUnit: "[mm/s]",
          phaseLabel: "RMS Velocity [mm/s]",
        },
        {
          id: 4,
          phaseName: "Envelope",
          phaseValue: "envelope",
          phaseUnit: "",
          phaseLabel: "Envelope",
        },
      ],
      isPhase: "acceleration",
      axisGroup: [
        { id: 1, axisName: "x", axisValue: "x" },
        { id: 2, axisName: "y", axisValue: "y" },
        { id: 3, axisName: "z", axisValue: "z" },
      ],
      isAxis: "x",
      fileNameGroup: [],
      isFileName: [],
      isLoadingFileNameGroup: false,
      markTypeGroup: [
        { id: 1, markName: "None", markValue: null },
        { id: 2, markName: "Harmonic", markValue: "mark_machine_speed" },
        { id: 3, markName: "BSF", markValue: "mark_bsf" },
        { id: 4, markName: "FTF", markValue: "mark_ftf" },
        { id: 5, markName: "BPFO", markValue: "mark_bpfo" },
        { id: 6, markName: "BPFI", markValue: "mark_bpfi" },
        { id: 7, markName: "GMF", markValue: "mark_gmf" },
        { id: 8, markName: "Sideband_Gear_Input", markValue: "mark_sbin" },
        { id: 9, markName: "Sideband_Gear_Output", markValue: "mark_sbout" },
        { id: 10, markName: "BPF", markValue: "mark_bpf" },
      ],
      isMarkType: null,
      tableHeaderData: [
        { text: "Sensor Name", value: "sensor_name", sortable: false },
        { text: "Vibration Unit", value: "phase", sortable: false },
        { text: "Axis", value: "axis", sortable: false },
        { text: "Fault Frequencies", value: "mark_type", sortable: false },
        { text: "X", value: "x", sortable: true },
        { text: "Y", value: "y", sortable: true },
        { text: "File Name", value: "file_name", sortable: true },
      ],
      tableBodyRawData: [],
      tableBodyData: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      itemsPerPageData,
      graphResultData: [],
      graphLoading: false,
      xAxisType: "hz",
      chartOptions: {
        title: { text: "Failure Mode" },
        subtitle: { text: "" },
        chart: {
          type: "line",
          zoomType: "xy",
          animation: false,
          height: 500,
          style: { fontSize: "14px" },
          events: {
            click: (e: any) => (this as any).addNotePoint(e, "area"),
          },
        },
        plotOptions: {
          series: {
            animation: false,
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        },
        tooltip: {
          useHTML: true,
          split: false,
          shared: true,
          borderWidth: 0,
          shadow: false,
          style: { fontSize: "12px" },
          positioner: function (labelWidth) {
            return {
              x: this.chart.chartWidth - labelWidth,
              y: 0,
            };
          },
          formatter: function () {
            let tooltip = "<div style='padding:8px;'>" + ` <p style='padding: 0px; margin:0px;'>Date Time: ${this.x}</p>`;

            this.points.forEach(function (point) {
              const ff = point.series.name;
              tooltip += `<div style='margin:8px 0px;'>                 
                  <p style='padding: 0px; margin:3px 0px;'><span style='color: ${
                    point.color
                  };font-size:14px;'>●</span> ${ff}</span></p>
                  <p style='padding: 0px; margin:0px;'>${point.y === 0 ? "Normal" : point.y === 1 ? "Warning" : "Danger"}</p>
                </div>`;
            });
            return tooltip + "</div>";
          },
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          align: "center",
          verticalAlign: "top",
          itemStyle: { fontSize: "14px", fontWeight: "300" },
        },
        series: [],
        yAxis: {
          title: { text: "", useHTML: true },
          opposite: false,
          labels: {
            formatter: function () {
              let change = ["Normal", "Warning", "Danger"];
              let value = change[this.value];
              return value !== "undefined" ? value : this.value;
            },
          },
        },
        xAxis: {
          type: "linear",
          title: { text: "Datetime" },
          categories: [],
          ordinal: false,
          labels: {
            style: { fontSize: "14px" },
            formatter: function () {
              return this.value.toLocaleString();
            },
          },
          plotLines: [],
          min: undefined,
          max: undefined,
        },
        scrollbar: { liveRedraw: false },
        rangeSelector: { enabled: false, selected: 0 },
        navigator: {
          series: { type: "line", marker: { enabled: false } },
          yAxis: {},
          xAxis: {
            type: "linear",
            categories: [],
            ordinal: false,
            labels: {
              style: { fontSize: "10px" },
              formatter: function () {
                return "";
              },
            },
            min: undefined,
            max: undefined,
          },
        },
        credits: { enabled: false },
      },
      calPoint: [],
      ref: { x: 0, y: 0 },
      nd: { x: 0, y: 0 },
      diff: 0,
      selectDateType: "normal",
    };
  },
  async created() {
    await this.fetchSensorGroup();
  },
  methods: {
    async fetchGraphData() {
      try {
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;

        const data = await (this as any).$dep.modelUseCase.getFailureModeRvsGraph(
          this.getModelCode,
          this.isSensor.sensorId,
          this.startDate,
          this.endDate
        );
        const graphData = data;

        this.graphResultData = graphData.series.filter((series) => {
          return !series.data.every((point) => point.y === null);
        });

        const xAxisMin = 0;
        const xAxisMax = Math.max(...graphData.series.map((item) => item.data[item.data.length - 1][0]));
        const numOfItems = 10;
        const tickPositions = Array.from({ length: numOfItems }, (_, i) =>
          Number((xAxisMin + (i * (xAxisMax - xAxisMin)) / (numOfItems - 1)).toFixed(2))
        );

        // const phaseText = (serie) => `${this.phaseGroup.find((p) => p.phaseValue === serie.phase).phaseName}`;
        // const phaseUnit = (serie) => `${this.phaseGroup.find((p) => p.phaseValue === serie.phase).phaseUnit}`;

        const yTimestamp = graphData.timestamp;

        setTimeout(() => {
          this.chartOptions.series = this.graphResultData;
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            categories: graphData.timestamp,
            tickPositioner: function () {
              const interval = Math.max(1, Math.ceil(yTimestamp.length / 10));
              const positions = [];
              for (let i = 0; i < yTimestamp.length; i += interval) {
                positions.push(i);
              }
              return positions;
            },
          };
          this.chartOptions.navigator.xAxis = {
            ...this.chartOptions.navigator.xAxis,
            categories: graphData.timestamp,
          };
          this.xAxisMaxRange = graphData.timestamp.length;
        }, 500);
        // this.tooltipFormat();
      } catch (error) {
        this.graphResultData = [];
        this.tableBodyData = [];
      }
    },
    async fetchSensorGroup() {
      try {
        this.isLoadingSensorGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsSensorType(this.getModelId);

        this.sensorGroup = datas;
      } catch (error) {
        this.sensorGroup = [];
      } finally {
        this.isLoadingSensorGroup = false;
      }
    },
    async fetchFileNameGroup() {
      try {
        this.isLoadingFileNameGroup = true;

        const datas = await (this as any).$dep.modelUseCase.getRvsGraphResultSpectrumFileName({
          modelCode: this.getModelCode,
          sensor: this.isSensor.sensorId,
          phase: this.isPhase,
          axis: this.isAxis,
        });

        this.fileNameGroup = datas;
      } catch (error) {
        this.fileNameGroup = [];
      } finally {
        this.isLoadingFileNameGroup = false;
      }
    },
    async onSubmit() {
      this.graphResultData = [];
      this.xAxisType = "hz";
      this.calPoint = [];
      this.graphLoading = true;
      await this.fetchGraphData();
      this.graphLoading = false;
    },
    async onChangeSensorType(val: any) {
      this.isSensor = {
        id: val.id,
        sensorId: val.sensorId,
        sensorName: val.sensorName,
      };
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    onChangePhase(value: string) {
      this.isPhase = value;
    },
    onChangeAxis(value: string) {
      this.isAxis = value;
    },
    onChangeMarkType(value: string) {
      this.isMarkType = value;
    },
    onChangeFileName(values: any[]) {
      this.isFileName = values.map((val: any) => ({
        fileName: val.fileName,
        alias: val.alias,
      }));
    },
    onChangeXAxisType(val: string) {
      this.graphLoading = true;

      const datas = this.graphResultData;
      const tableDatas = this.tableBodyRawData;

      this.chartOptions.series = this.chartOptions.series.map((serie: any, index: number) => ({
        ...serie,
        opacity: 0.1,
        data:
          val === "cpm"
            ? serie.data.map((val: any) =>
                Array.isArray(val) ? [val[0] * 60, val[1]] : typeof val === "object" ? { ...val, x: val.x * 60 } : val
              )
            : datas[index].data,
      }));

      const xAxisMax = Math.max(...this.chartOptions.series.map((item) => item.data[item.data.length - 1][0]));

      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        title: { text: val === "cpm" ? "Frequency [CPM]" : "Frequency [Hz]" },
        tickInterval: xAxisMax / 10,
        // plotLines: this.chartOptions.xAxis.plotLines.map((plot: any, index: number) => ({
        //   ...plot,
        //   value: val === "cpm" ? plot.value * 60 : tableDatas[index].x,
        //   label: {
        //     text: `x:${(val === "cpm" ? plot.value * 60 : tableDatas[index].x).toFixed(2)}`,
        //     style: {
        //       fontSize: "11px",
        //     },
        //     verticalAlign: "middle",
        //     textAlign: "center",
        //   },
        // })),
        plotLines: this.chartOptions.xAxis.plotLines.map((plot: any, index: number) => ({
          ...plot,
          value: val === "cpm" ? plot.value * 60 : plot.value / 60,
          label: {
            text: `x:${(val === "cpm" ? plot.value * 60 : plot.value / 60).toFixed(2)}`,
            style: {
              fontSize: "11px",
            },
            verticalAlign: "middle",
            textAlign: "center",
          },
        })),
      };
      // this.tooltipFormat();

      this.tableBodyData = val === "cpm" ? tableDatas.map((t) => ({ ...t, x: t.x * 60 })) : this.tableBodyRawData;

      this.graphLoading = false;
    },
    tooltipFormat() {
      this.chartOptions.tooltip = {
        ...this.chartOptions.tooltip,
        formatter: function (e: any) {
          const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });

          const xAxisText = e.chart?.options?.xAxis["title"]?.text || e.chart?.options?.xAxis[0]?.title?.text;
          const yAxisText = e.chart?.options?.yAxis["title"]?.text || e.chart?.options?.yAxis[0]?.title?.text;

          return `<div>
                  <p><span style='color: ${this.color};'>●</span> ${this.series.name}</span></p>
                  <p>${xAxisText || "x"} : ${formatter.format(this.x)}</p>
                  <p>${yAxisText || "y"} : ${formatter.format(this.y)}</p>
                  </div>`;
        },
      };
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = parseFloat(this.xAxisMin) > this.xAxisMaxRange ? this.xAxisMaxRange : this.xAxisMin;
      this.xAxisMax = parseFloat(this.xAxisMax) > this.xAxisMaxRange ? this.xAxisMaxRange : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        xAxis.setExtremes(xAxisMin, xAxisMax);
      }

      if (axis === "y") {
        yAxis.setExtremes(yAxisMin, yAxisMax);
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
    changePerPage(num: number) {
      this.itemsPerPage = num;
    },
    limiter(val: any[]) {
      if (val.length > this.maxLength) val.shift();
    },
    formatNumber(num: number) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      return formatter.format(num);
    },
    addNotePoint(e: any, type: string) {
      if (e.type === "click" && e.srcElement.innerHTML === "") {
        const x = type === "point" ? e.point.options.x : e.xAxis[0].value;
        const y = type === "point" ? e.point.options.y : e.yAxis[0].value;
        if (this.calPoint.length >= 2) return;

        this.calPoint.push({ x, y });

        if (this.calPoint.length === 1) {
          this.ref = { x, y };
          this.chartOptions.xAxis = {
            ...this.chartOptions.xAxis,
            plotLines: [
              {
                color: "#BDBB7F",
                dashStyle: "ShortDash",
                value: x,
                width: 2,
                label: {
                  text: `x:${this.ref.x.toFixed(2)}`,
                  style: {
                    fontSize: "11px",
                  },
                  verticalAlign: "middle",
                  textAlign: "center",
                },
              },
            ],
          };
        }

        if (this.calPoint.length === 2) {
          this.nd = { x, y };
          const left = this.calPoint.reduce((res, obj) => (obj.x < res.x ? obj : res)).x;
          const right = this.calPoint.reduce((res, obj) => (obj.x > res.x ? obj : res)).x;

          const diffFreq = right - left;

          this.diff = diffFreq;

          this.chartOptions.chart.height = 600;
          this.chartOptions.subtitle.text = `<div class='flex flex-col'>
            <div class='grid grid-rows-2'>
              <div class='flex items-center gap-x-[20px]'>
                <div><strong>Ref peak</strong> : ${left.toFixed(2)}</div>
                <div><strong>2nd</strong> : ${right.toFixed(2)}</div>
                <div><strong>Diff</strong> : ${diffFreq.toFixed(2)}</div>
              </div>
            </div>
          </div>`;

          this.chartOptions.xAxis.plotLines.push({
            color: "#BDBB7F",
            dashStyle: "ShortDash",
            value: x,
            width: 2,
            label: {
              text: `x:${x.toFixed(2)}`,
              style: {
                fontSize: "11px",
              },
              verticalAlign: "middle",
              textAlign: "center",
            },
          });
        }
      }
    },
    drawPlotlines(type: string) {
      if (this.calPoint.length > 2 || this.calPoint.length !== 2) return;

      if (this.ref.x > this.nd.x) {
        this.$swal({
          icon: "warning",
          title: "Warning",
          text: "Couldn't select 2nd less than ref",
        });
        return;
      }

      this.chartOptions.xAxis = {
        ...this.chartOptions.xAxis,
        plotBands: [],
        plotLines: [],
      };

      this.chartOptions.xAxis.plotLines.push({
        color: "#BDBB7F",
        dashStyle: "ShortDash",
        value: this.ref.x,
        width: 2,
        label: {
          text: `x:${this.ref.x.toFixed(2)}`,
          style: {
            fontSize: "11px",
          },
          verticalAlign: "middle",
          textAlign: "center",
        },
      });
      const calmin = Math.min(
        ...this.graphResultData.map((val) => {
          return Math.min(...val.data.map((item) => item[0]));
        })
      );

      const calmax = Math.max(
        ...this.graphResultData.map((val) => {
          return Math.max(...val.data.map((item) => item[0]));
        })
      );

      const min = this.xAxisType === "cpm" ? calmin * 60 : calmin;
      const max = this.xAxisType === "cpm" ? calmax * 60 : calmax;

      if (type === "harmonic") {
        let contCount = true;
        let cal = this.ref;
        let round = 1;
        while (contCount) {
          cal.x += this.diff;
          const plotline = {
            color: "#BDBB7F",
            dashStyle: "ShortDash",
            value: cal.x,
            width: 2,
            label: {
              text: `x:${cal.x.toFixed(2)}`,
              style: {
                fontSize: "11px",
              },
              verticalAlign: "middle",
              textAlign: "center",
            },
          };
          this.chartOptions.xAxis.plotLines.push(plotline);

          this.calPoint.push(plotline);

          if (cal.x > max || round >= 4) contCount = false;
          round++;
        }
      }

      if (type === "slideban") {
        let round = 5;
        let minDiff = this.diff * round;
        while (this.ref.x - minDiff < min) {
          round -= 1;
          minDiff = this.diff * round;
        }
        const calStart = this.ref.x - this.diff * round;
        let cal = this.ref;
        cal.x = calStart;
        round += 5;

        let count = 0;
        while (count <= round && cal.x < max) {
          const plotline = {
            color: "#BDBB7F",
            dashStyle: "ShortDash",
            value: cal.x,
            width: 2,
            label: {
              text: `x:${cal.x.toFixed(2)}`,
              style: {
                fontSize: "11px",
              },
              verticalAlign: "middle",
              textAlign: "center",
            },
          };
          this.chartOptions.xAxis.plotLines.push(plotline);

          cal.x += this.diff;

          this.calPoint.push(plotline);

          count++;
        }
      }
    },
    clearRefresh() {
      if (this.calPoint.length) {
        this.calPoint = [];
        this.chartOptions.subtitle.text = "";
        this.chartOptions.xAxis = {
          ...this.chartOptions.xAxis,
          plotBands: [],
          plotLines: [],
        };
      }
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DD HH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DD HH:mm:ss");
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
    async onChangeSelectDateType(param: any) {
      this.selectDateType = param;
      this.isNormal = param === "yearly" ? false : true;
      this.isGraphLoading = true;
      await this.fetchGraphData();
      this.isGraphLoading = false;
    },
  },
  watch: {
    async getModelId() {
      this.sensorGroup = [];
      this.isSensor = null;
      this.fileNameGroup = [];
      this.isFileName = [];
      this.graphResultData = [];
      this.tableBodyData = [];

      await this.fetchSensorGroup();
    },
  },
});
