import { RouteConfig } from "vue-router";

import EditModelFormPage from "./pages/EditModelFormPage.vue";
import ViewModelPage from "./pages/ViewModelPage.vue";

export const ModelRouter: (prefix?: string) => RouteConfig[] = (prefix = "model") => [
  {
    name: "EditModelFormPage",
    path: `setting/${prefix}/:id`,
    component: EditModelFormPage,
    meta: { title: "Setting Model Online" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
  {
    name: "ViewModelPage",
    path: `${prefix}/:id`,
    component: ViewModelPage,
    meta: { title: "Model View" },
    beforeEnter: (to, from, next) => {
      const { meta } = to;
      document.title = meta?.title;
      next();
    },
  },
];
