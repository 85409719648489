
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "LoadingRedirectPage",
  components: { Spinner },
  props: {
    title: {
      type: String,
      default: "Please wait a moment...",
    },
    message: {
      type: String,
      default: "We are loading information for you.",
    },
  },
});
