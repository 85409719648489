import { render, staticRenderFns } from "./ConfigurationInformation.vue?vue&type=template&id=06c64424&scoped=true&"
import script from "./ConfigurationInformation.vue?vue&type=script&lang=ts&"
export * from "./ConfigurationInformation.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c64424",
  null
  
)

export default component.exports