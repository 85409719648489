
import Vue from "vue";

export default Vue.extend({
  name: "CheckbokCustom",
  props: {
    label: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    onChange(val: boolean) {
      this.$emit("onChange", val || false);
    },
  },
});
