
import Vue from "vue";

import Spinner from "./Spinner.vue";

export default Vue.extend({
  name: "LoadingGraph",
  components: { Spinner },
  props: {
    height: {
      type: Number,
      default: 450,
    },
  },
});
