
import Vue from "vue";

import TableCustom from "@/components/TableCustom.vue";

export default Vue.extend({
  name: "FormMotorDataTable",
  components: {
    TableCustom,
  },
  computed: {
    items: {
      get() {
        return this.$store.state.updateModelForm.motor_data_in;
      },
    },
    filteredItems() {
      return (this as any).items.filter((item: any) => {
        return item.sensorTypeId === this.healthMonitoringSensorType;
      });
    },
    modelTypeId() {
      return this.$store.getters.model.modelTypeId;
    },
    motorData: {
      get() {
        return this.$store.getters.modelMotorDataForm;
      },
    },
  },
  data() {
    const headers = [
      {
        text: "Parameter Tag",
        align: "start",
        sortable: false,
        value: "modelConfigurationTag.title",
        width: "30%",
      },
      {
        text: "Value",
        align: "center",
        sortable: false,
        value: "value",
        width: "30%",
      },
      {
        text: "",
        align: "start",
        sortable: false,
        value: "space",
        width: "40%",
      },
    ];

    return {
      headers,
      healthMonitoringType: 1,
      healthMonitoringSensorType: 1,
      healthMonitoringTypeItems: [],
      healthMonitoringSensorTypeItems: [],
    };
  },
  async created() {
    await this.getMotorData(this.$store.getters.model.modelTypeId, this.$store.getters.model.id);
  },
  methods: {
    async fetchHealthMonitoringType() {
      try {
        const data = await Vue.prototype.$dep.modelUseCase.getAlarmLimitGroup();

        this.healthMonitoringType = data[0].id;
        this.healthMonitoringTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));

        await this.fetchHealthMonitoringSensorType(data[0].id);
      } catch (error) {
        this.healthMonitoringTypeItems = [];
      }
    },
    async fetchHealthMonitoringSensorType(id: number) {
      try {
        const data = await Vue.prototype.$dep.modelUseCase.getModelHealthMonitoringSensorType(id);

        this.healthMonitoringSensorType = data[0].id;
        this.healthMonitoringSensorTypeItems = data.map((row: any) => ({ id: row.id, name: row.name }));
      } catch (error) {
        this.healthMonitoringSensorTypeItems = [];
      }
    },
    async onHealthMonitoringTypeChange(id: number) {
      try {
        this.healthMonitoringType = id;
        await this.fetchHealthMonitoringSensorType(id);
      } catch (error) {
        console.log(error);
      }
    },

    onUpdateState() {
      const motor_data_in = this.motorData.map((item: any) => {
        return {
          value: item.value,
          modelConfigurationTagCode: item.modelConfigurationTagCode,
        };
      });
      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        motor_data_in,
      });
    },
    onSelectAll(props: { items: any[]; value: boolean }) {
      const { items, value } = props;

      const x = Object.fromEntries(items.map((o) => [o.id, { ...o, isSelect: value }]));
      const result = this.items.map((o: any) => x[o.id] || o);

      this.$store.dispatch("setUpdateModelForm", {
        ...this.$store.state.updateModelForm,
        motor_data_in: result,
      });
    },
    async getMotorData(id: number, modelId: number) {
      await (this as any).$store.dispatch("setModelMotorDataForm", { id, modelId });
    },
  },
});
