
import Vue from "vue";

export default Vue.extend({
  name: "DiagramEvs",
  computed: {
    modelCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  data() {
    return {
      resultData: {
        overAllEquipmentStatus: 0,
        driverStatus: 0,
        transmissionStatus: 0,
        drivenStatus: 0,
      },
      resultLastTimeStamp: "",
    };
  },
  methods: {
    statusName(code: number) {
      if (code === -1) return "offline";
      if (code === 0) return "normal";
      if (code === 1) return "warning";
      if (code === 2) return "alarm";
      if (code === 3) return "offline";
      else return "";
    },
  },
  async created() {
    const result = await (this as any).$dep.modelUseCase.modelResultDataStatusEvs(this.modelCode);
    const resultInit = {
      modelCode: "",
      timestamp: "",
      id: 0,
      overAllEquipmentStatus: -1,
      transmissionStatus: -1,
      driverStatus: -1,
      drivenStatus: -1,
    };
    (this as any).resultData = result || resultInit;
    (this as any).resultLastTimeStamp = result?.timestamp || "";
  },
});
