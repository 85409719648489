var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('container',[_c('member-invite-modal',{on:{"invite":_vm.inviteMemberInviteModal}}),_c('member-edit-invite-modal',{on:{"onSubmit":_vm.editMemberEditInviteModal},model:{value:(_vm.memberEditInviteForm),callback:function ($$v) {_vm.memberEditInviteForm=$$v},expression:"memberEditInviteForm"}}),_c('v-dialog',{attrs:{"persistent":"","width":"1200"},model:{value:(_vm.isShowModal),callback:function ($$v) {_vm.isShowModal=$$v},expression:"isShowModal"}},[_c('v-card',{staticClass:"dialog-padding"},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Assign Member")])]),_c('v-card-text',[_c('section',{staticClass:"flex items-center justify-between mb-[10px]"},[_c('div',{staticClass:"flex relative items-center justify-start"},[_c('span',[_vm._v("Assign member to Equipment tags:")])]),_c('div',{staticClass:"flex relative items-center justify-end"},[_c('div',{staticClass:"bg-[#F1EFEF] p-[14px] rounded-md"},[_c('img',{staticClass:"w-[16px] h-[16px]",attrs:{"src":require("@/assets/icons/equipment-icon.svg"),"alt":"add_equipment"}})]),_c('div',{staticClass:"grid ml-[10px]"},[_c('span',[_vm._v(_vm._s(_vm.equipmentTagName))])])])]),_c('div',{staticClass:"border-b-2 mb-[10px] border-[#F1EFEF] p-[10px]"}),_c('v-text-field',{staticClass:"mb-[15px]",attrs:{"prepend-inner-icon":"fas fa-search","label":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.memberHeaders,"items":_vm.memberList,"footer-props":{
            itemsPerPageOptions: _vm.rowsPerPageItems,
          },"search":_vm.search},on:{"update:options":_vm.updatePagination},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_c('v-checkbox',{attrs:{"value":row.item.id,"color":"#005ff9"},model:{value:(_vm.selectedMemberIds),callback:function ($$v) {_vm.selectedMemberIds=$$v},expression:"selectedMemberIds"}})],1),_c('td',[_c('v-avatar',{attrs:{"color":"primary","size":"30"}},[_c('img',{attrs:{"src":row.item.profileImageUrl}})]),_vm._v(" "+_vm._s(row.item.name || "-")+" ")],1),_c('td',[_c('label',[_vm._v(_vm._s(row.item.email))])]),_c('td',[_c('label',[_vm._v(_vm._s(row.item.role))])]),_c('td',[_c('v-chip',{class:`${row.item.statusColor} capitalized`,attrs:{"dark":"","small":""}},[_vm._v(" "+_vm._s(row.item.status)+" ")])],1),_c('td',[_c('div',{staticClass:"flex items-center justify-center gap-x-[10px]"},[(_vm.isOnPermission(row.item.status, 'EDIT_ROLE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.dispatch('openEditMemberModal', {
                        open: true,
                        type: 'CREATE',
                        param: row.item,
                      })}}},[_c('v-icon',{staticClass:"fas fa-pencil-alt mx-2",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.status, 'ACTIVE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.restoreMember(row.item)}}},[_c('v-icon',{staticClass:"far fa-check-circle mx-3",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.status, 'RESEND_INVITE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.resendInviteMember(row.item.email)}}},[_c('v-icon',{staticClass:"fas fa-redo-alt mx-3",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.status, 'DELETE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteMember(row.item.email)}}},[_c('delete-icon',{attrs:{"width":"14","height":"14"}})],1):_vm._e()])])])]}}])})],1),_c('v-card-actions',[_c('div',{staticClass:"flex gap-x-[16px] justify-center"},[_c('v-btn',{staticClass:"!capitalize !tracking-normal !w-[149px] !h-[44px] !rounded-md !bg-[#FFFFFF] !border-[1px] !border-[#CACFD5]",attrs:{"outlined":"","loading":_vm.isLoading},on:{"click":function($event){_vm.isShowModal = false}}},[_c('span',{staticClass:"text-[#000000] font-[600] text-[16px]"},[_vm._v("Cancel")])]),_c('v-btn',{staticClass:"!capitalize !tracking-normal !w-[203px] !h-[44px] !rounded-md !bg-[#FF0000] !border-[1px] !border-[#FF0000]",attrs:{"outlined":"","loading":_vm.isLoading},on:{"click":_vm.save}},[_c('span',{staticClass:"text-[#FFFFFF] font-[600] text-[16px]"},[_vm._v("Save")])])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }