
import Vue from "vue";

import NoDataAvailable from "@/components/NoDataAvailable.vue";
import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "MachineStatusRvs",
  components: { NoDataAvailable, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      return this.$store.state.modelViewData?.rvs?.rvs_diagram_status || [];
    },
  },
  methods: {
    overAllStatus(datas: any[]) {
      return datas.length ? Math.max(...datas.map((m) => m.overAllSensorStatus)) : 0;
    },
    statusName(code: number) {
      if (code === 0) return "normal";
      if (code === 1) return "warning";
      if (code === 2) return "alarm";
      if (code === 3) return "offline";
      else return "";
    },
  },
});
