
import Vue from "vue";
import TableCustom from "@/components/TableCustom.vue";
import colorStatus from "@/common/mixins/colorStatus";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
import LockedIcon from "@/components/icons/LockedIcon.vue";

export default Vue.extend({
  name: "FailureModeRvs",
  mixins: [colorStatus],
  components: { TableCustom, NoDataAvailable, LockedIcon },
  computed: {
    companyId(): any {
      return this.$store.getters.company.id || 0;
    },
    modelCode(): any {
      return this.$store.getters.model.modelCode || "";
    },
    isNotUpgrade(): any {
      if (this.$store.state.user.role === "admin") return false;
      const rvsUpgrade = this.$store.state.company.rvs_upgrade ? false : true;
      return rvsUpgrade;
    },
    colorExpand(): any {
      if (this.$store.state.user.role === "admin") return "#FFFFFF";
      const color = this.$store.state.company.rvs_upgrade ? "#FFFFFF" : "#EEEEEE";
      return color;
    },
  },
  data() {
    let datas = [];
    let headersOverall = [];
    let overallStatus = -1;

    headersOverall = [
      { text: "Sensor Location", value: "sensorLocation", width: "5%", sortable: false },
      { text: "Sensor ID", value: "sensorId", width: "5%", sortable: false },
      { text: "Failure Mode", value: "failuremode", width: "30%", sortable: false },
    ];

    return {
      datas: [],
      headersOverall,
      overallStatus,
      showDetails: false,
      recommend: "",
      dialog: false,
      position: { x: 0, y: 0 },
      detailsDialog: [],
    };
  },
  async mounted() {
    await this.fetchOverallModel();
  },
  methods: {
    async fetchOverallModel() {
      if (!this.modelCode) {
        return;
      }
      if (this.isNotUpgrade) return;
      this.datas = await Vue.prototype.$dep.modelUseCase.getFailureModeRvs(this.modelCode);
    },
    colorStatus(status: number) {
      if (status === 0) {
        return "normal";
      } else if (status === 1) {
        return "warning";
      } else if (status === 2) {
        return "alarm";
      } else if (status === -1) {
        return "offline";
      } else {
        return "disabled";
      }
    },
    statusCode(status: number) {
      if (status === 2) {
        return "#f44336";
      } else if (status === 1) {
        return "#ffc12b";
      } else if (status === 0) {
        return "#4caf50";
      } else if (status === -1) {
        return "#a5acb2";
      } else {
        return "#808080";
      }
    },
    handleOpenMenu(id: any) {
      this.$router.push({ name: "ViewModelPage", params: { id } });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updatePosition(event: any, recommend: string) {
      // Get the center coordinates of the hovered element
      const circleRect = event.target.getBoundingClientRect();
      const x = circleRect.left + circleRect.width / 2;
      const y = circleRect.top + circleRect.height / 2 - 100;

      this.recommend = recommend;
      // Update the position based on the calculated center coordinates
      this.position = { x, y };
    },
    handleOnclickDialog(details: any) {
      this.detailsDialog = details;
      this.dialog = true;
    },
  },
  watch: {
    async modelCode() {
      await this.fetchOverallModel();
    },
    async isNotUpgrade() {
      await this.fetchOverallModel();
    },
  },
});
