import { HTTPClient } from "@/common/HTTPClient";

export interface MemberGetRequestParams {
  search?: string;
  role?: string;
  page?: number;
  limit?: number;
}

export interface MemberInviteRequestParams {
  email: string;
  role: string;
}

export interface MemberUpdateProfileRequestParams {
  name: string;
  profileImageUrl?: string;
}

export interface MemberUpdateRequestParams {
  id: number;
  role: string;
}

export class MemberClient {
  constructor(private httpClient: HTTPClient) {}

  public async getMyProfile(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/users/me",
      method: "GET",
    });

    return data;
  }

  public async getMembers(compamy_id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${compamy_id}/members?company_id=${compamy_id}`,
      method: "GET",
    });

    return data;
  }

  public async getMembersOnly(compamy_id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${compamy_id}/members-only?company_id=${compamy_id}`,
      method: "GET",
    });

    return data;
  }

  public async getMemberMembers(equipment_tag_id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment_model_member?equipment_tag_id=${equipment_tag_id}`,
      method: "GET",
    });

    return data;
  }

  public async createMemberInvite(company_id: number, params: MemberInviteRequestParams): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${company_id}/members/invite`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async postMemberInviteResend(email: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/member/reinvite`,
      method: "POST",
      data: { email },
    });

    return data;
  }

  public async updateMember(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/member/profile`,
      method: "PUT",
      data: params,
    });

    return data;
  }
  public async memberSwitchRole(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/memberswitch-role?user_id=${id}`,
      method: "PUT",
    });

    return data;
  }

  public async updateUserProfile(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/member/profile",
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async postMemberActive(email: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/member/active`,
      method: "POST",
      data: { email },
    });

    return data;
  }

  public async uploadProfileImage(form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/profiles`,
      method: "POST",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  public async deleteMember(email: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/member/inactive`,
      method: "DELETE",
      data: { email },
    });

    return data;
  }
  public async updateMemberProfile(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/member/profile-edit`,
      method: "PUT",
      data: params,
    });

    return data;
  }
}
