import { Client } from "../../clients/client";
import { FolderCreateForm } from "./forms/FolderCreateForm";
import { FolderUpdateForm } from "./forms/FolderUpdateForm";
import { FolderEquipment } from "./models/FolderEquipment";

export class FolderUseCase {
  constructor(private client: Client) {}

  public async getFolderById(id: number): Promise<FolderEquipment> {
    return await this.client.equipmentClient.getFolder(id);
  }

  public async createFolder(form: any): Promise<FolderEquipment> {
    return this.client.equipmentClient.postCeateFolder(form);
  }

  public async updateFolderTitle(form: any, id: number): Promise<FolderEquipment> {
    return this.client.equipmentClient.pacthUpdateFolder(form, id);
  }

  public async deleteFolder(id: number): Promise<FolderEquipment> {
    return this.client.equipmentClient.deleteFolder(id);
  }
}
