
import Vue from "vue";
import Spinner from "@/components/Spinner.vue";
import EChart from "../../graph/EChart.vue";
export default Vue.extend({
  name: "ALoadConsumption",
  components: { EChart, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    item() {
      const raw = this.$store.state.modelViewData.tr_summary_status.a;
      const findValue = (tagCode) => raw.find((prev) => prev.tagCode === tagCode);
      const val = findValue("consumption_load_percen");
      const sub = findValue("consumption_apparent_power_va");
      const energy = findValue("consumption_energy_wh");
      return {
        alarm: val.alarm,
        warning: val.warning,
        value: val.value,
        unit: val.unit,
        subValue: sub.value,
        subUnit: sub.unit,
        energyName: energy.name,
        energyValue: energy.value,
        energyUnit: energy.unit,
      };
    },
  },
  data() {
    const initOption = {
      type: "gauge",
      progress: { show: false },
      pointer: { show: false },
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: false },
      detail: { show: false },
    };
    return {
      initOption,
      option: { series: [] },
      isLenderComponent: false,
    };
  },
  methods: {
    renderGraph() {
      this.isLenderComponent = false;
      const val = this.item.value;
      const warning = this.item.warning;
      const alarm = this.item.alarm;
      const subValue = this.item.subValue;
      const subUnit = this.item.subUnit;
      (this as any).option.series = [
        {
          ...this.initOption,
          axisLine: { lineStyle: { width: 10 } },
          axisLabel: {
            distance: -42,
            color: "#333333",
            fontSize: 14,
            fontWeight: 600,
            formatter: function (value) {
              return value === warning || value === alarm ? value : "";
            },
          },
        },
        {
          ...this.initOption,
          name: "value",
          progress: { show: true, width: 10, itemStyle: { color: this.statusCode(val, warning, alarm) } },
          data: [{ value: val }],
        },
        {
          ...this.initOption,
          name: "warning-pointer",
          pointer: {
            show: warning ? true : false,
            icon: "rect",
            width: 3,
            length: 23,
            offsetCenter: [0, -54],
            itemStyle: {
              color: "#FDBA36",
              borderCap: "square",
            },
          },
          z: 1999,
          data: [{ value: warning }],
        },
        {
          ...this.initOption,
          name: "alarm-pointer",
          pointer: {
            show: alarm ? true : false,
            icon: "rect",
            width: 3,
            length: 23,
            offsetCenter: [0, -54],
            itemStyle: {
              color: "#D9263B",
              borderCap: "square",
            },
          },
          z: 1999,
          data: [{ value: alarm }],
        },
        {
          ...this.initOption,
          name: "detail",
          detail: { offsetCenter: [0, "-15%"], fontSize: 35, formatter: "{value} " + this.item.unit },
          data: [{ value: val, name: `${subValue.toLocaleString()} ${subUnit}` }],
        },
      ];
      this.isLenderComponent = true;
    },
    statusCode(val: number, warning?: number, alarm?: number) {
      if (val > alarm) {
        return "#D9263B";
      } else if (val > warning) {
        return "#FCA239";
      } else {
        return "#2EC577";
      }
    },
  },
  watch: {
    item() {
      this.renderGraph();
    },
  },
});
