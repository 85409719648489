import { TagMessageRecommendationForm } from "@/features_old/model/forms/MessageAndRecommendationForm";
import { TagStatusMessageForm } from "@/features_old/model/forms/TagStatusMessageForm";

import { HTTPClient } from "./../common/HTTPClient";

export interface UpdateModelConfiguration {
  value: string;
}

export interface CreateModelImage {
  url: string;
  equipmentModelId: number;
}

export interface ModelCreateRequestParam {
  structureItemId: number;
  title: string;
  modelType: number;
  linkUrl?: string;
  isOnline: string;
  isActive: string;
  equipmentCode: string;
  modelConfigurations: Array<any>;
}

export interface ModelUpdateRequestParam {
  title: string;
  modelType: number;
  linkUrl?: string;
  isOnline: string;
  isActive: string;
  equipmentCode: string;
  modelConfigurations: Array<any>;
}

export interface ModelOfflineCreateRequestParam {
  structureItemId: number;
  title: string;
  linkUrl: string;
  isOnline: string;
  isActive: string;
}

export interface ModelOfflineUpdateRequestParam {
  title: string;
  linkUrl?: string;
  isOnline: string;
  isActive: string;
}

export interface QueryEquipmentModelDataResultRequestParam {
  startDate: string;
  endDate: string;
}

export interface ModelTagAlarmLimitRequestParam {
  equipmentModelId?: string | undefined;
  equipmentCode: string;
  sensorCode: string;
  warning: number;
  alarm: number;
}

export class ModelClient {
  constructor(private httpClient: HTTPClient) {}

  public async getModelById(id: number): Promise<any> {
    const url = `/api/v1/equipment-models/${id}`;
    return this.httpClient.request({ method: "GET", url });
  }

  public async getModelOfflineById(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-offlines/equipment-model-offline/${id}`,
      method: "GET",
    });
    return data;
  }

  public async getModelConfigurations(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/configuration-informations`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentCodes(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/equipment-codes`,
      method: "GET",
    });

    return data;
  }

  // public async postCeateModel(params: ModelCreateRequestParam): Promise<any> {
  //   const { data } = await this.httpClient.request({
  //     url: "/equipment-models",
  //     method: "POST",
  //     data: params,
  //   });

  //   return data;
  // }

  public async postCeateModel(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/equipment-models",
      method: "POST",
      data: params,
    });

    return data;
  }

  public async postCeateOfflineModel(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-offlines`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async pactchUpdateModel(id: number, params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}`,
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async pactchUpdateModelOffline(id: number, params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-offlines/${id}`,
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async updateModelConfiguration(id: number, params: UpdateModelConfiguration): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-configurations/${id}`,
      method: "PATCH",
      data: params,
    });

    return data;
  }

  public async uploadImage(form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/models`,
      method: "POST",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  public async getModelImages(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-images/${id}`,
      method: "GET",
    });

    return data;
  }

  public async postModelImage(params: CreateModelImage): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-images`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async deleteModelImage(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-images/${id}`,
      method: "DELETE",
    });
    return data;
  }

  public async deleteModel(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}`,
      method: "DELETE",
    });
    return data;
  }

  public async deleteModelOffline(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-model-offlines/${id}`,
      method: "DELETE",
    });
    return data;
  }

  public async getEquipmentModelDataResult(equipmentCode: string, groupCode: string, params: any): Promise<any> {
    const { startDate, endDate } = params;

    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data/${equipmentCode}/tr-models/${groupCode}?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultMCA(equipmentCode: string, groupCode: string, params: any): Promise<any> {
    const { startDate, endDate } = params;

    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data/${equipmentCode}/mca-models/${groupCode}?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultVCA(equipmentCode: string, groupCode: string, params: any): Promise<any> {
    const { startDate, endDate } = params;

    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data/${equipmentCode}/vca/${groupCode}?startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultEVS(
    equipmentCode: string,
    tagCode: string,
    modelId: number,
    dateRange: any,
    isNormal: boolean
  ): Promise<any> {
    const { startDate, endDate } = dateRange;

    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data/${equipmentCode}/evs-models/?equipment_model_id=${modelId}&tag_code=${tagCode}&startDate=${startDate}&endDate=${endDate}&is_normal=${isNormal}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultFrequenct(
    equipmentCode: string,
    filename: string,
    frequency: string
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/mca_model_result_data/frequency-data?fileName=${filename}&modelCode=${equipmentCode}&type=${frequency}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultFrequenctAnalysis(equipmentCode: string, filename: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/vca-result-data/frequency_data?model_code=${equipmentCode}&file_name=${filename}`,
      method: "GET",
    });
    return data;
  }

  public async getEquipmentModelDataResultTimeDomain(modelCode: string, filename: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/vca-result-data/time-domain?model_code=${modelCode}&file_name=${filename}`,
      method: "GET",
    });
    return data;
  }

  public async getResultGroup(id: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/result_group/${id}`,
      method: "GET",
    });

    return data;
  }
  public async getFileNameMca(equipmentCode: string, type: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/mca_model_result_data/${equipmentCode}/file_name?type=${type}`,
      method: "GET",
    });

    return data;
  }

  public async getFileNameVca(modelCode: string, groupCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/vca-result-data/file-names?group_code=${groupCode}&model_code=${modelCode}`,
      method: "GET",
    });

    return data;
  }

  public async getAvailableGroupTags(id: string, resultGroupId: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/tag_groups?result_group_id=${resultGroupId}`,
      method: "GET",
    });

    return data;
  }

  public async getLimitAlarms(id: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/alarm-limit-sensors`,
      method: "GET",
    });

    return data;
  }

  public async getFaultEvents(id: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/fault-events`,
      method: "GET",
    });

    return data;
  }

  public async getNewFaultEvents(id: string, month = 0, year = 0): Promise<any> {
    const url = month && year ? `/api/v1/fault-event/${id}?month=${month}&year=${year}` : `/api/v1/fault-event/${id}`;
    const { data } = await this.httpClient.request({
      url: url,
      method: "GET",
    });

    return data;
  }

  public async getCommentsNewFaultEvents(id: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-event/comments/${id}`,
      method: "GET",
    });

    return data;
  }

  public async postFaultEventAcknowledge(equipmentId: number, emailForm: string, body: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-event/acknowledge?equipment_tag_id=${equipmentId}&email_from=${emailForm}`,
      method: "POST",
      data: body,
    });

    return data;
  }

  public async postFaultEventDone(equipmentId: number, emailForm: string, body: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-event/done?equipment_tag_id=${equipmentId}&email_from=${emailForm}`,
      // url: `/api/v1/fault-event/acknowledge?equipment_tag_id=${equipmentId}&email_from=${emailForm}`,
      method: "POST",
      data: body,
    });

    return data;
  }

  public async updateFaultEventComments(body: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/fault-event/comment",
      method: "PATCH",
      data: body,
    });

    return data;
  }

  public async getRecommendations(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/recommendations`,
      method: "GET",
    });

    return data;
  }

  public async modelResultDataStatusTr(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/tr-model`,
      method: "GET",
    });

    return data;
  }

  public async modelResultDataStatusMca(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/mca`,
      method: "GET",
    });

    return data;
  }

  public async modelResultDataStatusVca(equipmentCode: string, resultType?: string): Promise<any> {
    const url = !resultType
      ? `/api/v1/model-result-data-summary-status/${equipmentCode}/vca`
      : `/api/v1/model-result-data-summary-status/${equipmentCode}/vca?result_type=${resultType}`;
    const { data } = await this.httpClient.request({
      url,
      method: "GET",
    });

    return data;
  }

  public async modelResultDataStatusEvs(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/equipment-code/evs-models?model_code=${equipmentCode}`,
      method: "GET",
    });

    return data;
  }

  public async modelResultDataStatusRvs(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/rvs`,
      method: "GET",
    });

    return data;
  }

  public async modelNewResultDataStatusRvs(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/rvs-summary-status`,
      method: "GET",
    });

    return data;
  }

  public async postCreateModelTagAlarmLimit(params: ModelTagAlarmLimitRequestParam[]): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/equipment-model-tag-alarm-limit`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async getTagStatusMessage(sensorCode: string, equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/tag-status-messages/${sensorCode}/${equipmentCode}`,
      method: "GET",
    });

    return data as TagStatusMessageForm;
  }

  public async postCreateTagStatusMessage(form: TagStatusMessageForm): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/tag-status-messages`,
      method: "POST",
      data: form,
    });

    return data;
  }

  public async getRecommendationMessages(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/recommendation-messages`,
      method: "GET",
    });

    return data;
  }

  public async postCreateRecommendationMessage(id: number, message: string): Promise<any> {
    const body: any = {};
    if (id !== undefined) {
      body.id = id;
    }
    body.message = message;

    const { data } = await this.httpClient.request({
      url: `/recommendation-messages`,
      method: "POST",
      data: body,
    });

    return data;
  }

  public async getTagRecommendationList(tagId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/tag-status-messages/recommendation-messages/${tagId}`,
      method: "GET",
    });

    return data;
  }

  public async postUpsertTagRecommendationList(tagMessageRecommendationForm: TagMessageRecommendationForm[]): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/tag-status-messages/recommendation-messages`,
      method: "POST",
      data: tagMessageRecommendationForm,
    });

    return data;
  }

  public async modelUploadFileRecommendation(id: number, modelCode: string, form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${id}/new-upload-recommendation-message?modelCode=${modelCode}`,
      method: "POST",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  public async getDiagramRvs(modelId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/rvs-diagram/?model_id=${modelId}`,
      method: "GET",
    });

    return data;
  }

  public async modelUploadDiagramRvs(form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/rvs-diagram`,
      method: "POST",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  public async getSensorBrands(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/sensor-brands`,
      method: "GET",
    });

    return data;
  }

  public async getSensorModels(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/sensor-models?brand_id=${id}`,
      method: "GET",
    });

    return data;
  }

  public async getDocumentations(id: number, type: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/documentations?equipment_model_id=${id}&type=${type}`,
      method: "GET",
    });

    return data;
  }

  public async uploadDocumentations(id: number, type: string, form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/documentations?equipment_model_id=${id}&type=${type}`,
      method: "POST",
      data: form,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return data;
  }

  public async getSensorPoints(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/sensor-points?modelCode=${equipmentCode}`,
      method: "GET",
    });

    return data;
  }
  public async getSensorPointsAll(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/sensor-points/sensor-point-all`,
      method: "GET",
    });

    return data;
  }

  public async getMachineTemplate(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/machine-templates`,
      method: "GET",
    });

    return data;
  }

  public async getBearing(txt?: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/rvs-ball-bearing/?search_text=${txt || ""}`,
      method: "GET",
    });

    return data;
  }

  public async getBearingById(id: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/rvs-ball-bearing-by-id/`,
      method: "POST",
      data: id,
    });

    return data;
  }

  public async getSensorDetail(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/evs`,
      method: "GET",
    });

    return data;
  }

  public async getSensorDetailRvs(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/model-result-data-summary-status/${equipmentCode}/rvs-summary-status`,
      method: "GET",
    });

    return data;
  }

  public async getAlarmLimitSensorRvs(equipmentCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/alarm-limit-sensor?modelCode=${equipmentCode}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsResultDataFileName(
    modelCode: string,
    resultGroup: string,
    phase: string,
    axis: string,
    sensorId: string,
    sensorPointId: number
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/filename/${modelCode}?resultGroupType=${resultGroup}&phase=${phase}&axis=${axis}&sensorId=${sensorId}&senserPointId=${sensorPointId}`,
      method: "GET",
    });

    return data;
  }
  public async getRvsSensor(modelCode: string, sensorPointId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/sensorId/${modelCode}?sensorPointId=${sensorPointId}`,
      method: "GET",
    });

    return data;
  }
  public async getRvsResultData(
    sensorId: string,
    resultGroup: string,
    phase: string,
    axis: string,
    filename: string
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/model-result-data/${sensorId}/rvs?resultGroupType=${resultGroup}&phase=${phase}&axis=${axis}&filename=${filename}`,
      method: "GET",
    });

    return data;
  }
  public async getRvsTrendResultData(
    modelCode: string,
    sensorId: string,
    sensorPointId: number,
    startDate: string,
    endDate: string
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-result-data/rvs-trend-result-data/${sensorId}?senserPointId=${sensorPointId}&modelCode=${modelCode}&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
    });

    return data;
  }

  public async getTrPartialChartResultData(filename: string, modelCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/pd-data?fileName=${filename}&modelCode=${modelCode}`,
      method: "GET",
    });

    return data;
  }
  public async getTrPartialChartResultDataFilename(modelCode: string, type: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/filename?modelCode=${modelCode}&type=${type}`,
      method: "GET",
    });

    return data;
  }
  public async getTrCurrentSpectrumData(modelCode: string, filename: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/tr-frequency?fileName=${filename}&modelCode=${modelCode}`,
      method: "GET",
    });

    return data;
  }
  public async getTrPartialChartQData(modelCode: string, startDate: string, endDate: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/pd-q?modelCode=${modelCode}&startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });

    return data;
  }
  public async getTrPartialChartQTable(modelCode: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/pd-q-table?modelCode=${modelCode}&page=1&size=50`,
      method: "GET",
    });

    return data;
  }
  public async getTrCorrelationResultData(
    modelId: number,
    modelCode: string,
    equipmentId: number,
    tagA: string,
    tagB: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/correlation-result-data?modelCode=${modelCode}&tagA=${tagA}&tagB=${tagB}&equipment_model_id=${modelId}&equipment_tag_id=${equipmentId}&startDate=${startDate}&endDate=${endDate}`,

      method: "GET",
    });

    return data;
  }
  public async getTrCorrelationResultDataScore(
    modelCode: string,
    equipmentId: number,
    tagScore: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/tr_model_result_data/correlation-score?modelCode=${modelCode}&tagScore=${tagScore}&equipment_tag_id=${equipmentId}&startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
    });

    return data;
  }
  public async getSensorTagSetting(modelType: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/${modelType}/sensor-tag-setting`,
      method: "GET",
    });

    return data;
  }

  public async getSensorTagCorrelation(modelType: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/equipment-models/correlation/tag-code/all?equipment_model_type_id=${modelType}`,
      method: "GET",
    });

    return data;
  }

  public async getRealTimeFaultEvent(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-eventget-scheduler-status`,
      method: "GET",
    });

    return data;
  }
  public async enableRealTimeFaultEvent(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-eventcreate-realtime-notification`,
      method: "GET",
    });

    return data;
  }
  public async disableRealTimeFaultEvent(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/fault-event/scheduler/`,
      method: "DELETE",
    });

    return data;
  }

  public async getRvsSensorType(modelId: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/rvs-sensor/?model_id=${modelId}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultTrendData(
    modelCode: string,
    phase: string,
    startDate: string,
    endDate: string,
    sensor: any[],
    isNormal: boolean
  ): Promise<any> {
    const sensorId =
      sensor.length === 1 ? `sensor_ids=${sensor[0].id}` : `sensor_ids=${sensor[0].id}&sensor_ids=${sensor[1].id}`;
    const sensorName =
      sensor.length === 1
        ? `sensor_names=${sensor[0].sensorName}`
        : `sensor_names=${sensor[0].sensorName}&sensor_names=${sensor[1].sensorName}`;
    const sensorNameId =
      sensor.length === 1
        ? `sensor_name_ids=${sensor[0].sensorId}`
        : `sensor_name_ids=${sensor[0].sensorId}&sensor_name_ids=${sensor[1].sensorId}`;

    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/trend/?model_code=${modelCode}&phase=${phase}&start_date=${startDate}&end_date=${endDate}&${sensorId}&${sensorName}&${sensorNameId}&is_normal=${
        isNormal ? "true" : "false"
      }`,
      method: "GET",
    });

    return data;
  }

  public async getSensorPointNote(
    modelCode: string,
    sensor: any[],
    phase: string,
    startDate: string,
    endDate: string
  ): Promise<any> {
    const sensorId =
      sensor.length === 1
        ? `sensor_id=${sensor[0].sensorId}`
        : `sensor_id=${sensor[0].sensorId}&sensor_id=${sensor[1].sensorId}`;

    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-sensor-point-note?model_code=${modelCode}&${sensorId}&type=${phase}&start_date=${startDate}&end_date=${endDate}`,
      method: "GET",
    });

    return data;
  }

  public async createSensorPointNote(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/rvs-sensor-point-note",
      method: "POST",
      data: params,
    });

    return data;
  }

  public async updateSensorPointNote(
    modelCode: string,
    description: string,
    rvsGraphId: number,
    noteId: number
  ): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-sensor-point-note`,
      method: "PUT",
      data: {
        description: description,
        model_code: modelCode,
        rvs_graph_id: rvsGraphId,
        note_id: noteId,
      },
    });

    return data;
  }

  public async deleteSensorPointNote(params: any): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/rvs-sensor-point-note",
      method: "DELETE",
      data: params,
    });

    return data;
  }

  public async getRvsGraphResultSpectrumFileName({
    modelCode,
    sensor,
    phase,
    axis,
  }: {
    modelCode: string;
    sensor: string;
    phase: string;
    axis: string;
  }): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/filename/?model_code=${modelCode}&result_group_type=spectrum&phase=${phase}&axis=${axis}&sensor_name_id=${sensor}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultSpectrumData({
    sensor,
    phase,
    axis,
    mark,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    mark: string;
    fileNames: any[];
  }): Promise<any> {
    const fileName = fileNames.map((item) => `file_names=${item}`).join("&");
    const searchParams = new URLSearchParams(fileName);

    const { data } = await this.httpClient.request({
      url: !mark
        ? `/api/v1/rvs-graph-result/spectrum/?rvs_phase_type=${phase}&axis=${axis}&sensor_name_id=${
            sensor.sensorId
          }&sensor_name=${sensor.sensorName}&${searchParams.toString()}`
        : `/api/v1/rvs-graph-result/spectrum/?rvs_phase_type=${phase}&axis=${axis}&sensor_name_id=${
            sensor.sensorId
          }&sensor_name=${sensor.sensorName}&${searchParams.toString()}&mark_type=${mark}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultSpectrumTable({
    sensor,
    phase,
    axis,
    mark,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    mark: string;
    fileNames: any[];
  }): Promise<any> {
    const fileName = fileNames.map((item) => `file_names=${item}`).join("&");
    const searchParams = new URLSearchParams(fileName);

    const { data } = await this.httpClient.request({
      url: !mark
        ? `/api/v1/rvs-graph-result/spectrum-table/?rvs_phase_type=${phase}&axis=${axis}&sensor_name_id=${
            sensor.sensorId
          }&sensor_name=${sensor.sensorName}&${searchParams.toString()}`
        : `/api/v1/rvs-graph-result/spectrum-table/?rvs_phase_type=${phase}&axis=${axis}&sensor_name_id=${
            sensor.sensorId
          }&sensor_name=${sensor.sensorName}&${searchParams.toString()}&mark_type=${mark}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultTimeWaveFormData({
    sensor,
    phase,
    axis,
    fileNames,
  }: {
    sensor: any;
    phase: string;
    axis: string;
    fileNames: any[];
  }): Promise<any> {
    const fileName = fileNames.map((item) => `file_names=${item}`).join("&");
    const searchParams = new URLSearchParams(fileName);

    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/time-waveform/?rvs_phase_type=${phase}&axis=${axis}&sensor_name_id=${
        sensor.sensorId
      }&sensor_name=${sensor.sensorName}&${searchParams.toString()}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultWaterfallFileName({
    modelCode,
    sensor,
    phase,
    axis,
  }: {
    modelCode: string;
    sensor: string;
    phase: string;
    axis: string;
  }): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/water-fall/file-name/?model_code=${modelCode}&phase=${phase}&axis=${axis}&sensor_id=${sensor}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsWaterfallSensorType({ modelCode }: { modelCode: string }): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/sensor-id/water-fall/?model_code=${modelCode}`,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultWaterfallData({
    sensorId,
    phase,
    axis,
    fileNames,
  }: {
    sensorId: string;
    phase: string;
    axis: string;
    fileNames: any[];
  }): Promise<any> {
    const fileName = fileNames.map((item) => `file_names=${item.fileName}&alias_file_names=${item.alias}`).join("&");
    const searchParams = new URLSearchParams(fileName);

    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/water-fall/?phase=${phase}&axis=${axis}&sensor_id=${sensorId}&${searchParams.toString()}`,
      method: "GET",
    });

    return data;
  }
  public async modelResultOverallDataStatusVca(equipmentCode: string, resultType?: string): Promise<any> {
    const url = `/api/v1/model-result-data-summary-status/${equipmentCode}/overall_vca?result_type=${resultType}`;
    const { data } = await this.httpClient.request({
      url,
      method: "GET",
    });

    return data;
  }

  public async modelRVSFailureMode(): Promise<any> {
    const url = `/api/v1/rvs-failure-mode`;
    const { data } = await this.httpClient.request({
      url,
      method: "GET",
    });
    return data;
  }

  public async modelRVSFailureModeById(id: number): Promise<any> {
    const url = `/api/v1/rvs-failure-mode/fault-parameter?failure_mode_id=${id}`;
    const { data } = await this.httpClient.request({
      url,
      method: "GET",
    });

    return data;
  }

  public async getRvsGraphResultParameter({
    keyValue,
    parameterValue,
    modelCode,
    startDate,
    endDate,
    isHistory,
    sensorId,
  }: {
    keyValue: string[];
    parameterValue: string;
    modelCode: string;
    startDate: string;
    endDate: string;
    isHistory: boolean;
    sensorId: string;
  }): Promise<any> {
    const keyValues = keyValue.map((item) => `parameter_trends=${item}`).join("&");
    const searchParams = new URLSearchParams(keyValues);

    const { data } = await this.httpClient.request({
      url: `/api/v1/rvs-graph-result/parameter-trend?start_date=${startDate}&end_date=${endDate}&is_history=${isHistory}&model_code=${modelCode}&fault_parameter=${parameterValue}&sensor_id=${sensorId}&${searchParams.toString()}`,
      method: "GET",
    });

    return data;
  }
}
