
import Vue from "vue";

import Spinner from "@/components/Spinner.vue";
import colorStatus from "@/common/mixins/colorStatus";

export default Vue.extend({
  name: "FBushing",
  mixins: [colorStatus],
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    items() {
      return this.$store.state.modelViewData.vca_diagram_status;
    },
    dcLink() {
      let status = -2;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "dc_link_capacitor");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    coolingFan() {
      let status = -2;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "cooling_fan");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    rectifier() {
      let status = -2;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "rectifier");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    inverter() {
      let status = -2;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "inverter");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
    control_card() {
      let status = -2;
      try {
        const filteredItems = this.items?.filter((val) => val.tagCode === "control_card");
        const latestItem = filteredItems.reduce((prev, current) => {
          return prev.timestamp > current.timestamp ? prev : current;
        });
        status = latestItem.status;
        return status;
      } catch (error) {
        return status;
      }
    },
  },
});
