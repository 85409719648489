
import Vue from "vue";

import RvsTrendGraph from "./RvsTrendGraph.vue";
import RvsSpectrumGraph from "./RvsSpectrumGraph.vue";
import RvsWaterfallGraph from "./RvsWaterfallGraph.vue";
import RvsTimeWaveFormGraph from "./RvsTimeWaveFormGraph.vue";
import RvsParameterTrendGraph from "./RvsParameterTrendGraph.vue";
import Rvs3dTimeWaveFormGraph from "./Rvs3dTimeWaveFormGraph.vue";
import RvsFailureModeGraph from "./RvsFailureModeGraph.vue";
import LockedIcon from "@/components/icons/LockedIcon.vue";

export default Vue.extend({
  name: "GraphViewRvs",
  components: {
    RvsTrendGraph,
    RvsSpectrumGraph,
    RvsTimeWaveFormGraph,
    RvsWaterfallGraph,
    RvsParameterTrendGraph,
    Rvs3dTimeWaveFormGraph,
    RvsFailureModeGraph,
    LockedIcon,
  },
  data() {
    return {
      tab: 0,
      items: [
        "Overall Trend",
        "2D Spectrum",
        "3D Spectrum",
        "Time Waveform",
        "3D Time Waveform",
        "Parameter Trend",
        "Failure History",
      ],
    };
  },
  computed: {
    isNotUpgrade(): any {
      const rvsUpgrade = this.$store.state.company.rvs_upgrade ? false : true;
      return rvsUpgrade;
    },
    role(): any {
      return this.$store.state.user.role;
    },
  },
  methods: {
    checkUpgrade(index: number) {
      if (this.role === "admin") return false;
      if ((index === 5 && this.isNotUpgrade) || (index === 6 && this.isNotUpgrade)) {
        return true;
      } else {
        return false;
      }
    },
  },
});
