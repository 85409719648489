
import Vue from "vue";
import dayjs from "dayjs";
import axios from "axios";
import XLSX from "xlsx";
import { Chart } from "highcharts-vue";
import ButtonDanger from "@/components/ButtonDanger.vue";
import ButtonReload from "@/components/ButtonReload.vue";
import DateTimeRange from "@/components/DateTimeRange.vue";
import LoadingGraph from "@/components/LoadingGraph.vue";
import NoDataAvailable from "@/components/NoDataAvailable.vue";
export default Vue.extend({
  name: "VcaTrendSignal",
  components: { highcharts: Chart, ButtonDanger, ButtonReload, DateTimeRange, LoadingGraph, NoDataAvailable },
  data() {
    const date = new Date();
    return {
      source: null,
      isSensorType: {},
      sensorTypeData: [],
      selectDateType: "normal",
      startDate: dayjs(date).subtract(30, "day").format("YYYY-MM-DDT00:00:00"),
      endDate: dayjs(date).format("YYYY-MM-DDT23:59:59"),
      isNormal: true,
      chartResultData: [],
      xAxisMaxRange: 0,
      yAxisMaxRange: 0,
      xAxisMin: undefined,
      xAxisMax: undefined,
      yAxisMin: undefined,
      yAxisMax: undefined,
      chartLoading: true,
      yAxis: {
        type: "linear",
      },
      chartOptions: {
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              enabled: false,
            },
          },
        },
        scrollbar: {
          enabled: true,
          showFull: false,
          liveRedraw: false,
        },
        chart: {
          type: "line",
          height: 450,
          zoomType: "xy",
          borderWidth: 3,
          borderRadius: 6,
          borderColor: "#F1EFEF",
          spacing: [30, 50, 30, 50],
        },
        title: { text: null },
        subtitle: { text: null },
        tooltip: {
          formatter: function () {
            let tooltipText = `<b>${this.x}</b><br/>`;
            let unit = "-";
            const remaining = this.series.chart.series.filter((val) => val.data.length > 0 && val.visible);
            // Show Y value for each series (exclude warning and alarm)
            remaining.forEach((series) => {
              if (series.data.length > 0 && series.visible) {
                tooltipText += `<span style="color:${series.color}">\u25CF</span> <b>${series.name}: </b>${
                  series.yData[this.point.index]
                } ${series.options.tooltip.valueSuffix}<br/>`;
                unit = series.options.tooltip.valueSuffix;
              }
            });

            // Check if the series has only 1 line and add warning/alarm information
            if (remaining.length === 1) {
              const { warning, alarm } = this.series.options;

              tooltipText += `<span style="color:#FCA239">\u25CF</span> <b>Warning: </b>${warning || 0} ${unit}<br/>`;
              tooltipText += `<span style="color:#D9263B">\u25CF</span> <b>Alarm: </b>${alarm || 0} ${unit}<br/>`;
            }

            return tooltipText;
          },
        },
        legend: {
          layout: "horizontal",
          align: "left",
          verticalAlign: "top",
          itemStyle: { fontSize: "12px", fontWeight: "300", color: "#000000" },
        },
        xAxis: {
          type: "category",
          categories: [],
        },
        yAxis: {
          title: {
            text: `-`,
          },
          labels: {
            format: `{value}`, // Example format: add '°C' to the label value
          },
        },
        series: [],
        credits: { enabled: false },
        plotOptions: {
          series: {
            events: {
              legendItemClick: function () {
                const chart = this.chart;
                const series = this.chart.series;
                const seriesIndex = this.index;
                const dataMax = (!this.visible && this.dataMax) || undefined;
                const values = [];
                dataMax && values.push(dataMax);
                this.chart.series.forEach((series, index) => {
                  if (index !== seriesIndex && series.visible) values.push(series.dataMax);
                });
                if (this.name === "All" && this.visible) {
                  series.forEach((val, ind) => {
                    if (ind !== seriesIndex) val.setVisible(false);
                  });
                  return;
                } else if (this.name === "All" && !this.visible) {
                  series.forEach((val, ind) => {
                    if (ind !== seriesIndex) val.setVisible(true);
                  });
                  const remaining = series.filter((val) => val.visible);
                  const seriesY = remaining.map((val) => Math.max(...val.yData));
                  const maxY = Math.max(...[...seriesY]) + 40;

                  this.chart.yAxis[0].update({
                    type: "linear",
                    visible: true,
                    min: null,
                    max: maxY,
                  });

                  this.chart.yAxis[0].removePlotLine("plot-warning");
                  this.chart.yAxis[0].removePlotLine("plot-alarm");
                  return;
                }
                setTimeout(() => {
                  const remaining = series.filter((val) => val.visible);
                  const remainingWithoutAll = series.filter((val) => val.visible && val.data.length > 0);
                  const countWithoutAll = series.filter((val) => val.data.length > 0);
                  if (remainingWithoutAll.length !== countWithoutAll.length) {
                    const all = series.findIndex((val) => val.name === "All");
                    this.chart.series[all].setVisible(false);
                  } else {
                    const all = series.findIndex((val) => val.name === "All");
                    this.chart.series[all].setVisible(true);
                  }
                  if (remainingWithoutAll.length === 1) {
                    const seriesY = remainingWithoutAll[0].yData;
                    const warning = remainingWithoutAll[0].options.warning;
                    const alarm = remainingWithoutAll[0].options.alarm;
                    const minY =
                      Math.min(...[...seriesY, warning, alarm]) >= 0 ? 0 : Math.min(...[...seriesY, warning, alarm]) - 40;
                    const maxY = Math.max(...[...seriesY, warning, alarm]) + 40;
                    this.chart.yAxis[0].update({
                      type: "linear",
                      visible: true,
                      title: { text: null },
                      min: minY,
                      max: maxY,
                    });
                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-warning",
                      value: warning,
                      color: "#FCA239",
                      width: 2,
                      zIndex: 5,
                      dashStyle: "ShortDash",
                    });
                    this.chart.yAxis[0].addPlotLine({
                      id: "plot-alarm",
                      value: alarm,
                      color: "#D9263B",
                      width: 2,
                      zIndex: 5,
                      dashStyle: "ShortDash",
                    });
                  } else {
                    const seriesY = remaining.map((val) => Math.max(...val.yData));
                    const maxY = Math.max(...[...seriesY]) + 40;

                    this.chart.yAxis[0].update({
                      type: "linear",
                      visible: true,
                      min: null,
                      max: maxY,
                    });

                    this.chart.yAxis[0].removePlotLine("plot-warning");
                    this.chart.yAxis[0].removePlotLine("plot-alarm");
                  }
                }, 100);
              },
              click: function () {
                const chart = this.chart;
                const series = this.chart.series;
                const index = this.index;
                const seriesY = series[index].yData;
                const warning = series[index].options.warning;
                const alarm = series[index].options.alarm;
                const minY =
                  Math.min(...[...seriesY, warning, alarm]) >= 0 ? 0 : Math.min(...[...seriesY, warning, alarm]) - 40;
                const maxY = Math.max(...[...seriesY, warning, alarm]) + 40;
                series.forEach((val, ind) => {
                  if (ind !== index) {
                    val.setVisible(false);
                  }
                });
                setTimeout(() => {
                  this.chart.yAxis[0].update({
                    visible: true,
                    title: { text: null },
                    min: minY,
                    max: maxY,
                    type: "linear",
                  });

                  this.chart.yAxis[0].addPlotLine({
                    id: "plot-warning",
                    value: warning,
                    color: "#FCA239",
                    width: 2,
                    zIndex: 5,
                    dashStyle: "ShortDash",
                  });
                  this.chart.yAxis[0].addPlotLine({
                    id: "plot-alarm",
                    value: alarm,
                    color: "#D9263B",
                    width: 2,
                    zIndex: 5,
                    dashStyle: "ShortDash",
                  });
                }, 100);
              },
            },
          },
        },
      },
    };
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
    isSecondaryTab() {
      return this.$store.state.graphInformationTab.is_secondary_tab.id;
    },
    groupCode() {
      return this.$store.state.graphInformationTab.is_secondary_tab.groupCode;
    },
    equipmentCode() {
      return this.$store.state.model.equipmentCode.code;
    },
  },
  async created() {
    await this.fetchResultData();
  },
  methods: {
    async fetchSensorTypeData() {
      try {
        const { id } = this.model;
        const response = await (this as any).$dep.modelUseCase.getModelHealthMonitoringSensorType(this.isSecondaryTab, id);
        this.sensorTypeData = response.map((prev) => ({ id: prev.id, name: prev.name }));
        this.isSensorType = { id: this.sensorTypeData[0].id, name: this.sensorTypeData[0].name };
      } catch (error) {
        this.sensorTypeData = [];
        this.isSensorType = {};
      }
    },
    async fetchResultData() {
      try {
        this.chartLoading = true;
        this.xAxisMin = undefined;
        this.xAxisMax = undefined;
        this.yAxisMin = undefined;
        this.yAxisMax = undefined;
        const { id, modelCode, equipmentId } = this.model;
        if (this.source) {
          this.source.cancel();
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        const response = await (this as any).$dep.modelUseCase.getVsdTrendsignalResultData(
          {
            modelId: id,
            modelCode: modelCode,
            sensorId: this.isSecondaryTab,
            startDate: this.startDate,
            endDate: this.endDate,
            isNormal: this.isNormal,
            equipmentId: equipmentId,
          },
          cancelToken
        );
        this.chartResultData = response;
        this.chartOptions.title.text = `Trend Signal : ${this.$store.state.graphInformationTab.is_secondary_tab.name}`;
        // this.chartOptions.yAxis.title = response[0]?.unit;
        this.chartOptions.yAxis = { type: "linear", max: Math.max(...response.map((val) => val.data).flat()) + 40 };
        this.chartOptions.xAxis = this.aAxis(response);
        this.chartOptions.series = this.series(response);
        if (response.length === 1) {
          const warning = response.map((val) => val.warning).flat();
          const alarm = response.map((val) => val.alarm).flat();
          const combined = [...warning, ...alarm, ...response.map((val) => val.data).flat()];

          this.chartOptions.yAxis.min = Math.min(...combined) - 20;
          this.chartOptions.yAxis.max = Math.max(...combined) + 20;
          this.chartOptions.yAxis.plotLines = [
            {
              value: warning[0],
              width: 2,
              dashStyle: "dash",
              color: "#FCA239",
            },
            {
              value: alarm[0],
              width: 2,
              dashStyle: "dash",
              color: "#D9263B",
            },
          ];
        }
        this.chartLoading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.source = null;
      }
    },
    onChangeSensorType(param: any) {
      const { id, name } = param;
      this.isSensorType = { id, name };
    },
    onChangeSelectDateType(param: any) {
      this.selectDateType = param;
    },
    aAxis(datas: any) {
      let labels = [];
      for (const res of datas) {
        labels.push(...res.timestamp);
      }
      this.xAxisMaxRange = labels.length;
      const formatDate = labels.map((label) => dayjs(label).format("DD-MM-YYYY HH:mm"));
      const count = formatDate.length;
      return {
        type: "category",
        labels: {
          align: "left",
        },
        tickInterval: count <= 10 ? 0 : count <= 500 ? 15 : 100,
        categories: formatDate,
      };
    },
    series(datas: any) {
      const customLegend = {
        name: "All",
      };
      const sers = datas.map((prev) => ({
        name: prev.tagCode,
        data: prev.data,
        tooltip: { valueSuffix: ` ${prev.unit}` },
        lineWidth: 2,
        warning: prev.warning,
        alarm: prev.alarm,
      }));
      const newsers = [customLegend].concat(sers);
      return newsers;
    },
    async onChangeDate(ev: any) {
      this.startDate = dayjs(ev.startDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      this.endDate = dayjs(ev.endDate).subtract(7, "hour").format("YYYY-MM-DDTHH:mm:ss");
      await this.fetchResultData();
    },
    async reloadData() {
      await this.fetchResultData();
    },
    exportExcel() {
      const { id } = this.model;
      const name = `vsd_trend_signal`.toUpperCase();
      // const values = (this as any).chartResultData.data.map((val) => val);
      // const timestamp = (this as any).chartResultData.timestamp.map((val) => val);
      const result = {
        sname: `vsd_${this.$store.state.graphInformationTab.is_secondary_tab.name}`.toUpperCase().replace(/\s+/g, "_"),
        data: this.chartResultData.map((val, key) => {
          return val.data.map((value, idx) => {
            const temp = {
              timestamp: val.timestamp[key],
              tagCode: val.tagCode,
              values: value,
              warning: val.warning,
              alarm: val.alarm,
            };
            return temp;
          });
        }),
      };

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(result.data.flat());
      XLSX.utils.book_append_sheet(wb, ws, result.sname); // => sheet name

      try {
        XLSX.writeFile(wb, `${name}.xlsx`);
      } catch (error) {
        console.error("Error exporting to Excel:", error);
      }

      setTimeout(() => {
        this.excelLoading = false;
      }, 3000);
    },
    updateAxis(axis: string) {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin =
        parseFloat(this.xAxisMin) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMin;
      this.xAxisMax =
        parseFloat(this.xAxisMax) > this.xAxisMaxRange
          ? this.xAxisMaxRange
          : parseFloat(this.xAxisMin) < 1
          ? 1
          : this.xAxisMax;

      const xAxisMin = this.xAxisMin ? parseFloat(this.xAxisMin) - 1 : undefined;
      const xAxisMax = this.xAxisMax ? parseFloat(this.xAxisMax) - 1 : undefined;
      const yAxisMin = this.yAxisMin ? parseFloat(this.yAxisMin) : undefined;
      const yAxisMax = this.yAxisMax ? parseFloat(this.yAxisMax) : undefined;

      if (axis === "x") {
        if (!isNaN(xAxisMin) && !isNaN(xAxisMax)) {
          xAxis.setExtremes(xAxisMin, xAxisMax);
        } else {
          xAxis.setExtremes(undefined, undefined);
        }
      }

      if (axis === "y") {
        if (!isNaN(yAxisMin) && !isNaN(yAxisMax)) {
          yAxis.setExtremes(yAxisMin, yAxisMax);
        } else {
          yAxis.setExtremes(undefined, undefined);
        }
      }
    },
    clearFilter() {
      const chart = this.$refs.chart.chart;
      const xAxis = chart.xAxis[0];
      const yAxis = chart.yAxis[0];

      this.xAxisMin = undefined;
      this.xAxisMax = undefined;
      this.yAxisMin = undefined;
      this.yAxisMax = undefined;

      xAxis.setExtremes(undefined, undefined);
      yAxis.setExtremes(undefined, undefined);

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
  },
  watch: {
    async isSecondaryTab() {
      await this.fetchSensorTypeData();
    },
    async isSensorType() {
      await this.fetchResultData();
    },
    async selectDateType() {
      if (this.selectDateType === "yearly") {
        this.isNormal = false;
      } else {
        this.isNormal = true;
      }
    },
  },
});
