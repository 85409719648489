import Vue from "vue";
import jwtDecode from "jwt-decode";

import { Client } from "@/clients/client";

interface AuthOptions {
  logout_redirect_uri: string;
}

interface AccessToken {
  sub: string;
  raw: string;
  permissions: string[];
}

interface ProfileToken {
  sub: string;
  name: string;
  picture: string;
  email: string;
  raw: string;
}

export class AuthUseCase {
  constructor(private client: Client, private options: AuthOptions) {}

  public async login(): Promise<void> {
    return this.client.auth0Client.loginWithRedirect();
  }

  public logout(): void {
    this.client.auth0Client.logout({
      returnTo: this.options.logout_redirect_uri,
    });
  }

  public async getAccessToken(): Promise<AccessToken> {
    if (process.env.VUE_APP_BASE_URL.includes("localhost")) {
      await this.client.auth0Client.getTokenSilently();
      const idToken = await this.client.auth0Client.getIdTokenClaims();

      return { sub: idToken?.sub || "", permissions: [], raw: idToken?.__raw || "" };
    } else {
      const tokenSilently = await this.client.auth0Client.getTokenSilently({
        audience: process.env.VUE_APP_API_BASE_URL,
      });
      const { sub, permissions } = jwtDecode<any>(tokenSilently);
      const idToken = await this.client.auth0Client.getIdTokenClaims({
        audience: process.env.VUE_APP_API_BASE_URL,
      });
      const raw = (() => (!permissions.length ? idToken?.__raw || "" : tokenSilently))();

      return { sub, permissions, raw };
    }
  }

  public async getProfileToken(): Promise<ProfileToken> {
    try {
      if (process.env.VUE_APP_BASE_URL.includes("localhost")) {
        const { raw } = await this.getAccessToken();
        const { sub, name, email, picture } = jwtDecode<any>(raw);

        return { sub, name, email, picture, raw };
      } else {
        await this.getAccessToken();
        const idToken = await this.client.auth0Client.getIdTokenClaims({
          audience: process.env.VUE_APP_API_BASE_URL,
        });
        return {
          sub: idToken?.sub || "",
          name: idToken?.name || "",
          picture: idToken?.picture || "",
          email: idToken?.email || "",
          raw: idToken?.__raw || "",
        };
      }
    } catch (error) {
      this.logout();
      return { sub: "", name: "", picture: "", email: "", raw: "" };
    }
  }

  public async getMyProfile(): Promise<any> {
    return this.client.memberClient.getMyProfile();
  }

  public async setupToken(): Promise<void> {
    const { raw } = await this.getAccessToken();
    this.client.httpClient.setToken({ accessToken: raw });
  }

  public async clearToken(): Promise<void> {
    return this.client.httpClient.clearToken();
  }

  public getPermissions(): any {
    const token = jwtDecode<any>(Vue.prototype.$dep.httpClient.cacheAccessToken);
    return (token && token.permissions) || [];
  }

  public hasPermission(permission: string): any {
    return this.getPermissions().includes(permission);
  }
}
