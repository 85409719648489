
import Vue from "vue";

import MonitorIcon from "@/components/icons/MonitorIcon.vue";

export default Vue.extend({
  name: "NoDataAvailable",
  props: {
    height: {
      type: Number,
      default: 210,
    },
    message: {
      type: String,
      default: "No data available",
    },
  },
  components: { MonitorIcon },
});
