
import Vue from "vue";
import { mapActions } from "vuex";

import Spinner from "@/components/Spinner.vue";

export default Vue.extend({
  name: "AuthLoginRedirectPage",
  components: { Spinner },
  async created() {
    try {
      await (this as any).$dep.authUseCase.setupToken();
      await this.$store.dispatch("getUser");
      const { role } = this.$store.getters.user;
      const isPermission = ["ADMIN"].includes(role);

      if (isPermission) {
        this.$router.push({ name: "CompaniesPage" });
      } else {
        const { items } = await (this as any).$dep.companyUseCase.getCompanies();
        sessionStorage.setItem("company_id", items[0].id);
        await this.getCompany();
        await this.getStructure();
        this.$router.push({ name: "CompanyPage", params: { id: items[0].id } });
      }
    } catch (error) {
      this.$router.push({ name: "AuthLogoutRedirectPage" });
      (this as any).$dep.authUseCase.logout();
    }
  },
  methods: {
    ...mapActions(["getUser", "getCompany", "getStructure"]),
  },
});
