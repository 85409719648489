import { HTTPClient } from "@/common/HTTPClient";
import { CreateCompanyForm } from "./../features_old/company/forms/CompanyForm";

export interface CompanyInfoUpdateRequestParams {
  description?: string;
  logoUrl?: string;
}

export interface CompanyRequestParams {
  title: string | null | undefined;
  initial_code: string | null | undefined;
  description: string | null | undefined;
  image_path: string | null | undefined;
}

export interface AdminRequestParams {
  fullname: string | null | undefined;
  email: string | null | undefined;
}

export class CompanyClient {
  constructor(private httpClient: HTTPClient) {}

  public async getCompanies(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/companies",
      method: "GET",
    });

    return data;
  }

  public async getCompanyById(companyId: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${companyId}`,
      method: "GET",
    });

    return data;
  }

  public async createCompany(params: CreateCompanyForm): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async uploadCompanyImage(form: FormData): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/upload/companys`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: form,
    });

    return data;
  }

  public async updateCompany(id: number, params: CompanyRequestParams): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${id}`,
      method: "PUT",
      data: params,
    });

    return data;
  }

  public async deleteCompany(id: number): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/companies/${id}`,
      method: "DELETE",
    });

    return data;
  }

  public async getAdmins(): Promise<any> {
    const { data } = await this.httpClient.request({
      url: "/api/v1/admins",
      method: "GET",
    });

    return data;
  }

  public async addAdmins(params: AdminRequestParams): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/admins`,
      method: "POST",
      data: params,
    });

    return data;
  }

  public async deleteAdmins(email: string): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/admins/${email}`,
      method: "DELETE",
    });

    return data;
  }

  public async editAdmins(params: AdminRequestParams): Promise<any> {
    const { data } = await this.httpClient.request({
      url: `/api/v1/admins/profile-edit`,
      method: "PUT",
      data: params,
    });

    return data;
  }
}
