import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=4c2c5825&scoped=true&"
import script from "./Sidebar.vue?vue&type=script&lang=ts&"
export * from "./Sidebar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c2c5825",
  null
  
)

export default component.exports