var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('container',[_c('div',{staticClass:"flex items-center justify-between mb-[32px]"},[_c('div',[_c('h3',{staticClass:"text-[30px] font-[600] text-black"},[_vm._v("Setting Admin")]),_c('span',{staticClass:"text-[16px] font-[400] text-[#747B83]"},[_vm._v(_vm._s(_vm.admins.length)+" users")])]),_c('admin-form-modal',{on:{"onSubmit":_vm.onSubmitModal}}),_c('button',{staticClass:"w-[196px] h-[44px] rounded-[6px] bg-[#FF0000]",attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.dispatch('openAdminFormModal', {
          open: true,
          type: 'Add',
          param: null,
        })}}},[_c('span',{staticClass:"text-[#ffffff] font-[600]"},[_vm._v(" Invite Admin ")])])],1),(_vm.isLoading)?_c('loading-graph',{attrs:{"height":420}}):_c('table-custom',{attrs:{"headers":_vm.headers,"items":_vm.admins,"showPagination":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',[_c('v-avatar',{attrs:{"size":"32"}},[_c('img',{attrs:{"src":row.item.item.profileImageUrl,"alt":row.item.item.name}})])],1),_c('td',[_c('span',[_vm._v(_vm._s(row.item.item.name))])]),_c('td',[_c('span',[_vm._v(_vm._s(row.item.item.email))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.$date(row.item.item.updatedDate).format("DD/MM/YYYY")))])]),_c('td',[_c('div',{staticClass:"bg-primary flex items-center justify-center capitalize h-[24px] rounded-xl text-[14px] font-[400]",class:`color-status-auth-${row.item.item.status}`},[_vm._v(" "+_vm._s(row.item.item.status)+" ")])]),_c('td',[_c('div',{staticClass:"flex items-center justify-center gap-x-[10px]"},[(_vm.isOnPermission(row.item.item.status, 'EDIT_ROLE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.$store.dispatch('openAdminFormModal', {
                  open: true,
                  type: 'Edit',
                  param: row.item.item,
                })}}},[_c('v-icon',{staticClass:"fas fa-pencil-alt mx-2",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.item.status, 'ACTIVE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.restoreAdmin(row.item)}}},[_c('v-icon',{staticClass:"far fa-check-circle mx-3",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.item.status, 'RESEND_INVITE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.resendInvite(row.item)}}},[_c('v-icon',{staticClass:"fas fa-redo-alt mx-3",attrs:{"size":"14"}})],1):_vm._e(),(_vm.isOnPermission(row.item.item.status, 'DELETE'))?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteAdmin(row.item)}}},[_c('delete-icon',{attrs:{"width":"14","height":"14"}})],1):_vm._e()])])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }